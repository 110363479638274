import React, { useEffect, useRef, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import { FloatingMenuIndex } from 'structure/Layer.js';
import PageControl from 'views/PageControl.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Views, { AltBadge } from 'views/Main.js';

const LeadLookupField = ({ appendContent, containerStyle, icon, inline, onChange, placeholder, utils, value }) => {

    const limit = 5;
    const offset = useRef(0);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [text, setText] = useState(null);

    const onClick = async lead => {

        // empty results array, set selected lead, and update text field value
        setResults([]);
        setSelected(lead);
        setText(lead.full_name);

        // notify subscribers of new selection
        if(typeof(onChange) === 'function') {
            onChange(lead);
        }
    }

    const onTextChange = text => {
        setText(text);
        fetchResults(text);
    }

    const getStyles = () => {
        if(inline === false) {
            return {
                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                border: `1px solid rgba(175,175,175,0.25)`,
                borderRadius: 8,
                boxShadow: window.theme === 'dark' ? '4px 4px 7px rgba(174,174,174,0.25)' : '4px 4px 7px rgba(0,0,0,0.15)',
                left: 0,
                maxHeight: 250,
                overflowY: 'scroll',
                padding: 0,
                position: 'absolute',
                right: 0,
                textAlign: 'left',
                top: 43,
                zIndex: FloatingMenuIndex,
            }
        }
        return {
            backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
            border: `1px solid ${Appearance.colors.divider()}`,
            borderRadius: 12,
            marginBottom: 12,
            marginTop: 8,
            overflow: 'hidden',
            padding: 0,
            textAlign: 'left'
        }
    }

    const fetchResults = async text => {
        try {

            // prevent moving forward if an invalid amount of text was provided
            if(!text || text.length < 3) {

                // end loading and empty results array
                setLoading(false);                    
                setResults([]);

                // notify subscribers that the selected lead has be deselected
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            // submit request to server
            setLoading(true);
            let { leads, paging } = await Request.post(utils, '/leads/', {
                limit: limit,
                offset: offset.current,
                search_props: { text: text },
                sort_key: 'full_name',
                sort_type: 2,
                type: 'advanced_search'
            });

            // end loading and set results object
            setLoading(false);
            setPaging(paging);
            setResults(leads);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(value) {
            setSelected(value);
            setText(value.full_name);
        }
    }, [value]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            appendContent={appendContent}
            autoCapitalize={'sentences'}
            autoComplete={false}
            autoCorrect={false}
            icon={icon || 'search'}
            loading={loading}
            onChange={onTextChange}
            placeholder={placeholder}
            useDelay={true}
            value={text}
            style={{
                flexGrow: 1,
                ...Appearance.textStyles.title()
            }}/>
            {results.length > 0 && (
                <div style={getStyles()}>
                    {results.map((lead, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onClick.bind(this, lead),
                                title: lead.full_name,
                                subTitle: lead.phone_number || 'No phone number provided',
                                rightContent: (
                                    <AltBadge 
                                    content={lead.status} 
                                    style={{
                                        marginRight: 0 
                                    }}/>
                                )
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset.current}
                        onClick={props => {
                            offset.current = props;
                            fetchResults(text);
                        }} />
                    )}
                </div>
            )}
            {!selected && typeof(text) === 'string' && text.length > 0 && results.length === 0 && loading === false && (
                <div style={getStyles()}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'Nothing was found for your search',
                        title: 'No Leads Found',
                        onClick: setText.bind(this, null)
                    })}
                </div>
            )}
        </div>
    )
}
export default LeadLookupField;
