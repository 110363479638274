import React, { useRef, useState, useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';
import { VelocityComponent } from 'velocity-react';

const options = [{
    key: 'anchors',
    title: 'Anchors'
},{
    key: 'bubbles',
    title: 'Bubbles'
},{
    key: 'circles',
    title: 'Circles'
},{
    key: 'charlie_brown',
    title: 'Charlie Brown'
},{
    key: 'diamonds',
    title: 'Diamonds'
},{
    key: 'dots',
    title: 'Dots'
},{
    key: 'leaves',
    title: 'Leaves'
},{
    key: 'stars',
    title: 'Stars'
},{
    key: 'stripes',
    title: 'Stripes'
},{
    key: 'triangles',
    title: 'Triangles'
}]

const PatternPickerField = ({ containerStyle, collapsible, disableScroll, onChange, placeholder, selected, showValidation, utils }) => {

    const scrollEnd = useRef(null);
    const scrollStart = useRef(null);

    const selectedRef = useRef(null);
    const [text, setText] = useState(null);
    const [showOptions, setShowOptions] = useState(collapsible ? false : true);
    const [selectedKey, setSelectedKey] = useState(selected);
    const [maxHeight, setMaxHeight] = useState(0);

    const onValidate = () => {
        return selectedKey ? true : false;
    }

    const onFieldClick = () => {
        if(collapsible === false) {
            return;
        }
        setShowOptions(showOptions => !showOptions);
        if(scrollStart.current && disableScroll !== true) {
            scrollStart.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    const onShowOptionsChange = () => {
        setMaxHeight(showOptions ? window.innerHeight / 3 : 0);
    }

    const onClick = option => {
        setSelectedKey(option.key);
        if(collapsible !== false) {
            setShowOptions(false);
        }
        if(typeof(onChange) === 'function') {
            onChange(option.key);
        }
    }

    const getValue = () => {
        if(!selectedKey) {
            return null;
        }
        let val = options.find(option => option.key === selectedKey);
        return val ? `"${val.title}" pattern selected` : null;
    }

    useEffect(() => {
        onShowOptionsChange();
    }, [showOptions])

    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            value={getValue()}
            focused={showOptions}
            onValidate={showValidation ? onValidate : null}
            placeholder={'Choose a pattern from below...'}
            onClick={onFieldClick}
            style={{
                flexGrow: 1,
                fontSize: 12,
                fontWeight: 500,
                color: selectedKey ? Appearance.colors.text() : Appearance.colors.subText()
            }}/>
            <div ref={scrollStart} />
            {options.length > 0
                ?
                <VelocityComponent
                duration={500}
                animation={{
                    maxHeight: maxHeight
                }}>
                    <div style={{
                        borderRadius: 12,
                        overflowY: 'scroll',
                        maxHeight: maxHeight,
                        border: `${showOptions ? 1 : 0}px solid ${Appearance.colors.divider()}`,
                        backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                        marginTop: showOptions ? 8 : 0,
                        marginBottom: showOptions ? 12 : 0
                    }}>
                        {options.map((option, index) => {
                            return (
                                <div
                                key={index}
                                className={'text-button'}
                                onClick={onClick.bind(this, option)}>
                                    <div style={{
                                        display: 'block',
                                        width: '100%',
                                        padding: '8px 12px 8px 12px',
                                        borderBottom: index !== options.length - 1 ? `1px solid ${Appearance.colors.divider()}` : 0
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%'
                                        }}>
                                            <div style={{
                                                padding: 5,
                                                overflow: 'hidden',
                                                width: '100%'
                                            }}>
                                                <div
                                                className={option.key}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    height: 125,
                                                    borderRadius: 5,
                                                    border: `1px solid ${Appearance.colors.divider()}`,
                                                    backgroundColor: Appearance.colors.background()
                                                }}>
                                                    {selectedKey === option.key && (
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 40,
                                                            height: 40,
                                                            backgroundColor: 'white',
                                                            border: `1px solid ${Appearance.colors.softBorder()}`,
                                                            borderRadius: 20,
                                                            overflow: 'hidden'
                                                        }}>
                                                            <img
                                                            src={'images/checkmark-small-green.png'}
                                                            style={{
                                                                width: 18,
                                                                height: 18,
                                                                objectFit: 'contain'
                                                            }}/>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </VelocityComponent>
                :
                null
            }
            <div ref={scrollEnd} />
        </div>
    )
}
export default PatternPickerField;
export const convertPattern = key => {
    let match = options.find(option => option.key === key);
    return match ? match.title : null;
}
