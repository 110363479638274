import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';

import { getIcon } from 'views/TextField.js';

import Appearance from 'styles/Appearance.js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import Payment from 'classes/Payment.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const CreditsPicker = ({ cost, onChange, onCreditsPurchased, onMethodAdded, onMethodRemoved, utils }) => {

    const stripeProps = useRef({});

    const [credits, setCredits] = useState(null);
    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState(null);
    const [newMethodCompleted, setNewMethodCompleted] = useState(false);
    const [purchaseActive, setPurchaseActive] = useState(null);
    const [selectedCreditsMethod, setSelectedCreditsMethod] = useState(null);

    const onAddNewMethod = async sourceCategory => {
        try {
            setLoading(purchaseActive);
            let element = stripeProps.current.elements.getElement(CardElement);
            let { token } = await stripeProps.current.stripe.createToken(element);

            let { method } = await Request.post(utils, '/payments/', {
                source_category: sourceCategory,
                source_id: token.card.id,
                token: token.id, 
                type: 'new_method'
            });

            // end loading and clear card element inputs
            setLoading(false);
            element.clear();

            // add payment method to methods collection
            let nextMethod = Payment.Method.create(method);
            setMethods(methods => update(methods, {
                $unshift: [ nextMethod ]
            }));

            // notify callback to update user payment methods list if applicable
            if(sourceCategory === Payment.Method.source_categories.get().user && typeof(onMethodAdded) === 'function') {
                onMethodAdded(nextMethod);
            }

            // notify subscribers that payment methods have changed
            utils.events.emit('payment_method_change');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding your payment method. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onBuyCredits = async ({ amount, source_category, method }) => {
        try {

            let creditsMethod = credits[purchaseActive];
            setLoading(purchaseActive);

            await Utils.sleep(0.25);
            await Request.post(utils, '/payments/', {
                type: 'buy_credits',
                amount: amount,
                card_id: method.id,
                source_category: source_category,
                method: {
                    user_id: creditsMethod.user_id,
                    dealership_id: creditsMethod.dealership_id
                }
            });

            setLoading(false);
            setCredits(credits => update(credits, {
                [purchaseActive]: {
                    balance: {
                        $apply: balance => balance + parseFloat(amount)
                    }
                }
            }));
            setPurchaseActive(null);

            if(typeof(onCreditsPurchased) === 'function') {
                onCreditsPurchased(amount);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your purchase. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onClickCreditsMethod = (method, index) => {
        if(method.balance >= cost) {
            setSelectedCreditsMethod(method);
            return;
        }
        utils.alert.show({
            title: 'Just a Second',
            message: `It looks like you don't have enough credits to complete this transaction. Please select a payment method and purchase additional credits to continue.`
        });
    }

    const onClickCreditsOptions = (method, index, evt) => {
        evt.stopPropagation();
        setPurchaseActive(prev_index => prev_index !== index ? index : null);
    }

    const onRemovePaymentMethod = (evt, sourceCategory, method) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Remove Payment Method',
            message: `Are you sure that you wnat to remove this payment method? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'deafult'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemovePaymentMethodConfirm(sourceCategory, method);
                    return;
                }
            }
        })
    }

    const onRemovePaymentMethodConfirm = async (sourceCategory, method) => {
        try {
            setLoading(purchaseActive);
            await Utils.sleep(0.25);
            await Request.post(utils, '/payments/', {
                card_id: method.id,
                source_category: sourceCategory,
                type: 'remove_payment_method'
            });

            setLoading(false);
            setMethods(methods => {
                return methods.filter(prev_method => {
                    return prev_method.id !== method.id;
                })
            });

            // notify subscribers that method has been removed
            if(typeof(onMethodRemoved) === 'function') {
                onMethodRemoved(method);
            }

            // notify subscribers that payment methods have changed
            utils.events.emit('payment_method_change');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this payment method from your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSelectPaymentMethod = (sourceCategory, method) => {

        let amount = 0;
        let values = [ 1, 5, 10, 25, 50, 100, 250, 500, 1000 ];
        utils.alert.show({
            title: 'Confirm Payment',
            message: `Please select an amount of credits from the list below to complete your purchase`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <select
                    className={`custom-select ${window.theme}`}
                    onChange={e => {
                        amount = Utils.attributeForKey.select(e, 'amount');
                    }}
                    style={{
                        width: '100%'
                    }}>
                        <option>{'Choose an amount...'}</option>
                        {values.map(val => {
                            return (
                                <option key={val} amount={val}>{Utils.toCurrency(val)}</option>
                            )
                        })}
                    </select>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Buy Credits',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Buy Credits',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onBuyCredits({
                        amount: amount,
                        method: method,
                        source_category: sourceCategory
                    });
                    return;
                }
            }
        })
    }

    const getCredits = () => {
        return (
            <LayerItem
            title={'Credits'}
            collapsed={false}>
                {getCreditsContents()}
            </LayerItem>
        )
    }

    const getCreditsBadge = method => {
        if(!selectedCreditsMethod) {
            return null;
        }
        if(selectedCreditsMethod.user_id && selectedCreditsMethod.user_id === method.user_id) {
            return {
                text: 'Selected',
                color: Appearance.colors.primary()
            }
        }
        if(selectedCreditsMethod.dealership_id && selectedCreditsMethod.dealership_id === method.dealership_id) {
            return {
                text: 'Selected',
                color: Appearance.colors.primary()
            }
        }
        return null;
    }

    const getCreditsContents = () => {
        if(loading === true || credits === null) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }

        return credits.map((method, index) => {
            let sourceCategory = method.user_id ? Payment.Method.source_categories.get().user : Payment.Method.source_categories.get().dealership;
            return (
                <div
                key={index}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: index !== credits.length - 1 ? 8 : 0
                }}>
                    {Views.entry({
                        key: index,
                        title: method.user_id ? 'Personal Credits' : 'Dealership Credits',
                        subTitle: `Balance: ${Utils.toCurrency(method.balance)}`,
                        badge: getCreditsBadge(method),
                        icon: {
                            path: method.user_id ? 'images/credits-user-primary.png' : 'images/credits-dealership-secondary.png'
                        },
                        loading: loading === index,
                        bottomBorder: false,
                        bottomBorder: false,
                        onClick: cost && onClickCreditsMethod.bind(this, method, index),
                        rightContent: (
                            <img
                            src={`images/details-button-light-grey.png`}
                            className={'text-button'}
                            onClick={onClickCreditsOptions.bind(this, method, index)}
                            style={{
                                width: 20,
                                height: 20,
                                objectFit: 'contain'
                            }} />
                        )
                    })}
                    {purchaseActive === index && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            borderTop: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            {getMethods(sourceCategory)}
                            {getStripeCardComponent(sourceCategory)}
                        </div>
                    )}
                </div>
            )
        })
    }

    const getMethods = sourceCategory => {
        if(methods === null) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        return methods.map((method, index) => {
            if(sourceCategory !== method.source_category.code) {
                return null;
            }
            return (
                <div
                key={index}
                className={`view-entry ${window.theme}`}
                onClick={onSelectPaymentMethod.bind(this, sourceCategory, method)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    padding: 10,
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 8
                    }}>
                        <i
                        className={getIcon('payment')}
                        style={{
                            color: Appearance.colors.text(),
                            fontSize: 13
                        }}/>
                    </div>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        flexGrow: 1
                    }}>{method.getTitle()}</span>
                    <img
                    className={'text-button'}
                    onClick={evt => onRemovePaymentMethod(evt, sourceCategory, method)}
                    src={'images/red-x-icon.png'}
                    style={{
                        width: 20,
                        height: 20,
                        objectFit: 'contain',
                        marginLeft: 8
                    }} />
                </div>
            )
        })
    }

    const getStripeCardComponent = sourceCategory => {
        return (
            <Elements stripe={utils.stripe}>
                <ElementsConsumer>
                    {props => {
                        stripeProps.current = props;
                        return (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '100%',
                                padding: 10
                            }}>
                                <div style={{
                                    flexGrow: 1
                                }}>
                                    <CardElement
                                    onChange={({ complete }) => setNewMethodCompleted(complete)}
                                    options={{
                                        hidePostalCode: true,
                                        style: {
                                            invalid: {
                                                color: Appearance.colors.red,
                                            },
                                            base: {
                                                fontSize: '12px',
                                                color: Appearance.colors.text(),
                                                '::placeholder': {
                                                    color: Appearance.colors.subText(),
                                                },
                                            }
                                        }
                                    }} />
                                </div>
                                {newMethodCompleted && (
                                    <img
                                    className={'text-button'}
                                    onClick={onAddNewMethod.bind(this, sourceCategory)}
                                    src={'images/next-arrow-blue-small.png'}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        objectFit: 'contain',
                                        marginLeft: 8
                                    }} />
                                )}
                            </div>
                        )
                    }}
                </ElementsConsumer>
            </Elements>
        )
    }

    const fetchCredits = async () => {
        try {
            let { credits } = await Request.get(utils, '/payments/', {
                type: 'credits'
            });
            setCredits(credits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your credits balance. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchPaymentMethods = async () => {
        try {
            setLoading(true);
            let { methods } = await Request.get(utils, '/payments/', {
                type: 'all_methods'
            });

            setLoading(false);
            setMethods(methods.map(method => Payment.Method.create(method)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your payment methods. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(selectedCreditsMethod && typeof(onChange) === 'function') {
            onChange(selectedCreditsMethod);
        }
    }, [selectedCreditsMethod])

    useEffect(() => {
        fetchCredits();
        fetchPaymentMethods();
        utils.events.on('credits_picker', 'payment_method_change', fetchPaymentMethods);
        return () => {
            utils.events.off('credits_picker', 'payment_method_change', fetchPaymentMethods);
        }
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        }}>
            {getCredits()}
        </div>
    )
}
export default CreditsPicker;
