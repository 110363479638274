import React, { useRef, useState, useEffect } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import AltFieldMapper from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import CallLog from 'classes/CallLog.js';
import { CallLogDetails, LeadDetails } from 'managers/Leads.js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import Checkbox from 'views/Checkbox.js';
import CreditsPicker from 'views/CreditsPicker.js';
import CustomListField from 'views/CustomListField.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import Dealership from 'classes/Dealership.js';
import Demo from 'classes/Demo.js';
import { DemoDetails, DemoRequestDetails } from 'managers/Demos.js';
import DemoRequest from 'classes/DemoRequest.js';
import Event from 'classes/Event.js';
import { EventDetails } from 'managers/Dealerships.js';
import FieldMapper, { formatFields } from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import MultipleUserLookupField from 'views/MultipleUserLookupField.js';
import Notification from 'classes/Notification.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Payment from 'classes/Payment.js';
import Lead from 'classes/Lead.js';
import LeadLookupField from 'views/LeadLookupField.js';
import Request from 'files/Request.js';
import Scheduler from 'views/Scheduler.js';
import SystemEvent from 'classes/SystemEvent.js';
import TableListHeader from 'views/TableListHeader.js';
import TextField, { getIcon } from 'views/TextField.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

// Panels
export const Notifications = ({ index, options, utils }) => {

    const panelID = 'notifications';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            let { notifications, paging } = await Request.get(utils, '/notifications/', {
                type: 'all',
                limit: limit,
                offset: offset,
                search_text: searchText
            });

            setLoading(false);
            setPaging(paging);
            setNotifications(notifications.map(notification => Notification.create(notification)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the notifications list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNotificationClick = notification => {
        utils.layer.open({
            id: `notification_details_${notification.id}`,
            abstract: Abstract.create({
                type: 'notification',
                object: notification
            }),
            Component: NotificationDetails
        })
    }

    const getContent = () => {
        if(notifications.length === 0) {
            return (
                Views.entry({
                    title: `No Notifications Found`,
                    subTitle: `No notifications have been sent to your account`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return notifications.map((notification, index) => {
            return (
                Views.entry({
                    key: index,
                    title: notification.title,
                    subTitle: notification.message,
                    icon: {
                        path: notification.from_user ? notification.from_user.avatar : 'images/push-notification-icon-white.png',
                        style: {
                            borderRadius: '50%',
                            overflow: 'hidden'
                        },
                        imageStyle: {
                            backgroundColor: getNotificationIconColor(notification)
                        }
                    },
                    bottomBorder: true,
                    onClick: onNotificationClick.bind(this, notification)
                })
            )
        })
    }

    useEffect(() => {
        fetchNotifications();
    }, [offset, searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchNotifications);
        utils.content.subscribe(panelID, [ 'notification' ], {
            onFetch: fetchNotifications
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchNotifications);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Notifications'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by title or message...',
                onChange: text => setSearchText(text)
            },
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => setOffset(next)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const SystemEvents = ({ index, options, utils }) => {

    const panelID = 'system_events';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [events, setEvents] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onEventClick = evt => {
        utils.layer.open({
            id: `system_event_details_${evt.id}`,
            abstract: Abstract.create({
                type: 'system_event',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getContent = () => {
        if(events.length === 0) {
            return (
                Views.entry({
                    title: `No System Events Found`,
                    subTitle: `No system events were found for your dealership`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return events.map((evt, index) => {
            let { values } = getSystemEventProps(evt);
            return (
                Views.entry({
                    key: index,
                    title: evt.title,
                    subTitle: values.length > 0 ? `${values[0]}${values.length > 1 ? ` and ${values.length - 1} other ${values.length - 1 === 1 ? 'change':'changes'}` : ''}` : 'No overview available',
                    badge: getSystemEventBadges(evt),
                    icon: {
                        path: evt.user.avatar
                    },
                    bottomBorder: true,
                    onClick: onEventClick.bind(this, evt)
                })
            )
        });
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('system', 'on_new_event', fetchEvents);
        } catch(e) {
            console.error(e.message)
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('system', 'on_new_event', fetchEvents);
        } catch(e) {
            console.error(e.message)
        }
    }

    const fetchEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/utils/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                search_text: searchText
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [offset, searchText]);

    useEffect(() => {

        connectToSockets();

        // setup dealership on change listener
        utils.events.on(panelID, 'dealership_change', fetchEvents);

        // subscribe to system event updates
        utils.content.subscribe(panelID, ['system_event'], {
            onFetch: fetchEvents
        });

        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchEvents);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'System Events'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: text => setSearchText(text)
            },
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => setOffset(next)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const SystemEventsLayerItem = ({ abstract, permissions = [], utils }) => {

    const limit = 5;
    const offset = useRef(0);

    const [collapsed, setCollapsed] = useState(true);
    const [events, setEvents] = useState([]);
    const [hasFetchedEvents, setHasFetchedEvents] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system_event_details_${evt.id}`,
            abstract: Abstract.create({
                type: 'system_event',
                object: evt
            }),
            Component: SystemEventDetails
        });
    } 
    
    const getContent = () => {

        // prevent moving forward if feature is disabled for current user
        let match = permissions.find(key => utils.user.permissions.get(key) === false);
        if(match) {
            return null;
        }

        // return a loading component if applciable
        if(loading === true) {
            return (
                <LayerItem title={'System Events'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 15,
                        width: '100%'
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                        style={{
                            height: 50,
                            width: 50
                        }}/>
                    </div>
                </LayerItem>
            )
        }

        // return a placeholder if events fetch has not been requested
        if(hasFetchedEvents === false) {
            return (
                <LayerItem title={'System Events'}>
                    <div 
                    className={'text-button'}
                    onClick={fetchEvents}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 24,
                        width: '100%'
                    }}>
                        <img 
                        src={window.theme === 'dark' ? 'images/lead-panel-click-white.png' : 'images/lead-panel-click-grey.png'}
                        style={{
                            height: 50,
                            marginBottom: 8,
                            objectFit: 'contain',
                            width: 50
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title(),
                            marginBottom: 2,
                            textAlign: 'center'
                        }}>{'Click to Load System Events'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            textAlign: 'center',
                            whiteSpace: 'wrap'
                        }}>{'We automatically hide the system events list to improve the overall performance of your dealership.'}</span>
                    </div>
                </LayerItem>
            )
        }

        return (
            <LayerItem title={'System Events'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {events.map((evt, index) => {
                        let { values } = getSystemEventProps(evt);
                        if(!values) {
                            return null;
                        }
                        return (
                            Views.entry({
                                bottomBorder: index !== events.length - 1,
                                key: index,
                                title: evt.alt_title || evt.title,
                                subTitle: values.length > 0 ? `${values[0]}${values.length > 1 ? ` and ${values.length - 1} other ${values.length - 1 === 1 ? 'change':'changes'}` : ''}` : 'No overview available',
                                badge: getSystemEventBadges(evt),
                                icon: {
                                    path: evt.user.avatar
                                },
                                onClick: onSystemEventClick.bind(this, evt)
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset.current}
                        onClick={next => {
                            setLoading(true);
                            offset.current = next;
                            fetchEvents();
                        }} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const fetchEvents = async () => {
        try {

            setLoading(true);
            let { events, paging } = await Request.get(utils, '/utils/', {
                limit: limit,
                offset: offset.current,
                target_id: abstract.getID(),
                target_type: abstract.type,
                type: 'system_events'
            });

            setLoading(false);
            setPaging(paging);
            setHasFetchedEvents(true);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    return getContent();
}

export const Users = ({ level, panelID, shortName, title }, { index, options, utils }) => {

    const limit = 15;
    const offset = useRef(0);
    const showInactive = useRef(false);
    const sorting = useRef(null);

    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [users, setUsers] = useState([]);

    const onNewUser = () => {
        utils.alert.aft({
            title: 'Just a Second',
            message: `To provide the best experience possible, we've moved user management over to AFT. Please login to AFT to create a new account.`
        });
    }

    const onPrintUsers = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { users } = await Request.get(utils, '/users/', {
                    type: 'all',
                    levels: [level],
                    ...sorting.current,
                    ...props
                });

                setLoading(false);
                resolve(users.map(user => User.create(user)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onUserClick = user => {
        utils.layer.open({
            abstract: Abstract.create({
                object: user,
                type: 'user'
            }),
            Component: UserDetails,
            id: `user_details_${user.user_id}`,
            permissions: ['users.details']
        });
    }

    const getButtons = () => {

        let buttons = [];
        if(utils.user.get().level <= User.levels.get().admin || ![
            User.levels.get().admin,
            User.levels.get().region_director,
            User.levels.get().division_director,
            User.levels.get().area_director,
            User.levels.get().dealer
        ].includes(level)) {
            buttons.push({
                key: 'new',
                title: `New ${shortName}`,
                style: 'default',
                onClick: onNewUser
            })
        }
        return buttons.concat([{
            key: 'active',
            title: `${showInactive.current ? 'Hide' : 'Show'} Inactive`,
            style: showInactive.current ? 'default' : 'grey',
            onClick: () => {
                offset.current = 0;
                showInactive.current = !showInactive.current;
                fetchUsers();
            }
        }])
    }

    const getContent = () => {
        if(users.length === 0) {
            return (
                Views.entry({
                    title: `No ${title} Found`,
                    subTitle: `No ${title} were found in the system`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {users.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (user, index, users) => {

        let target = user || {};
        let fields = [{
            key: 'full_name',
            title: 'Name',
            icon: target.avatar,
            value: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.users, target.full_name)
        },{
            key: 'email_address',
            sortable: false,
            title: 'Email',
            value: utils.groups.apply('email_address', User.Group.categories.users, target.email_address)
        },{
            key: 'phone_number',
            sortable: false,
            title: 'Phone',
            value: utils.groups.apply('phone_number', User.Group.categories.users, target.phone_number)
        }];

        if(level !== User.levels.get().customer) {
            fields = fields.concat([{
                key: 'last_login',
                title: 'Last Login',
                value: utils.groups.apply('last_login', User.Group.categories.users, target.last_login ? moment(target.last_login).format('MM/DD/YYYY [at] h:mma') : 'No Logins Found')
            }])
        }

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!user) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchUsers();
                }}
                value={sorting.current}/>
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: index !== users.length - 1 && `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'pl-2 pr-3 py-2 flexible-table-column'}
                    onClick={onUserClick.bind(this, user)}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            {typeof(field.icon) === 'string' && (
                                <img
                                src={field.icon}
                                style={{
                                    borderRadius: 12.5,
                                    height: 25,
                                    minHeight: 25,
                                    minWidth: 25,
                                    objectFit: 'cover',
                                    overflow: 'hidden',
                                    marginRight: 8,
                                    width: 25
                                }}/>
                            )}
                            <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                        </div>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {

        let headers = [{
            key: 'full_name',
            title: 'Name'
        },{
            key: 'email_address',
            title: 'Email'
        },{
            key: 'phone_number',
            title: 'Phone'
        }];
        if([ User.levels.get().safety_advisor, User.levels.get().safety_associate ].includes(level)) {
            headers = headers.concat([{
                key: 'next_demo',
                title: 'Next Demo'
            }])
        }
        if(level !== User.levels.get().customer) {
            headers = headers.concat([{
                key: 'last_login',
                title: 'Last Login'
            }])
        }

        return {
            headers: headers,
            onFetch: onPrintUsers,
            onRenderItem: (item, index, items) => ({
                full_name: item.full_name,
                email_address: item.email_address,
                phone_number: item.phone_number,
                next_demo: item.next_demo ? moment(item.next_demo).format('MM/DD/YYYY [at] h:mma') : 'None Scheduled',
                last_login: item.last_login ? moment(item.last_login).format('MM/DD/YYYY [at] h:mma') : 'No Logins Found'
            })
        }
    }

    const fetchUsers = async () => {
        try {
            setLoading(true);
            let { users, paging } = await Request.get(utils, '/users/', {
                levels: [level],
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                show_inactive: showInactive.current,
                type: 'all',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setUsers(users.map(user => User.create(user)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the users list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchUsers);
        utils.content.subscribe(panelID, ['user'], {
            onFetch: fetchUsers,
            onUpdate: abstract => {
                setUsers(users => {
                    return users.map(user => {
                        return user.user_id === abstract.getID() ? abstract.object : user
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchUsers);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={title}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: setSearchText
            },
            print: getPrintProps(),
            buttons: getButtons(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchUsers();
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const UsersAvailabilityCalendar = ({ index, options, utils }) => {

    const panelID = 'users_availability_calendar';
    const limit = 15;

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [panelWidth, setPanelWidth] = useState(0);
    const [resources, setResources] = useState([]);
    const [showInactive, setShowInactive] = useState(false);
    const [targetDate, setTargetDate] = useState(moment());

    const onAvailabilityAdded = data => {
        try {
            if(data.from_user === utils.user.get().user_id) {
                return;
            }
            setEvents(events => update(events, {
                $push: [{
                    id: data.id,
                    available: data.available,
                    start: data.start_date,
                    end: data.end_date,
                    resource_id: data.user_id,
                    title: `${Utils.ucFirst(data.time_of_day)} Availability`,
                    color: data.available ? Appearance.colors.primary() : Appearance.colors.grey(),
                    user: data.user
                }]
            }))
        } catch(e) {
            console.error(e.message);
        }
    }

    const onAvailabilityUpdated = data => {
        try {
            setEvents(events => {
                return events.map(evt => {
                    if(evt.id === data.id) {
                        return {
                            ...evt,
                            ...data,
                            start: data.start_date,
                            end: data.end_date,
                            color: data.available ? Appearance.colors.primary() : Appearance.colors.grey()
                        }
                    }
                    return evt;
                })
            })
        } catch(e) {
            console.error(e.message);
        }
    }

    const onAvailabilityDeleted = data => {
        try {
            setEvents(events => {
                return events.filter(evt => {
                    return evt.id !== data.id;
                })
            })
        } catch(e) {
            console.error(e.message);
        }
    }

    const onDeleteEvent = evt => {
        utils.alert.show({
            title: 'Delete Timeslot',
            message: 'Are you sure that you want to delete this timeslot?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteEventConfirm(evt);
                    return;
                }
            }
        })
    }

    const onDeleteEventConfirm = async evt => {
        try {
            await Request.post(utils, '/users/', {
                type: 'delete_availability',
                id: evt.id
            })
            setEvents(events => {
                return events.filter(prevEvent => prevEvent.id !== evt.id)
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEventClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'set_demo',
                title: 'Set Demo',
                style: 'default'
            },{
                key: 'available',
                title: `Set as ${evt.available ? 'Unavailable':'Available'}`,
                style: evt.available ? 'destructive' : 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'set_demo') {
                onSetDemo(evt);
                return;
            }
            if(key === 'delete') {
                onDeleteEvent(evt);
                return;
            }
            if(key === 'available') {
                onSetAvailabilityStatus(evt);
                return;
            }
        })
    }

    const onEventRender = target => {
        let color = Appearance.colors.timeOfDay(target.time_of_day);
        return (
            <div
            className={`text-button mb-1`}
            style={{
                width: '100%',
                borderRadius: 5,
                overflow: 'hidden',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color)
            }}>
                <div style={{
                    padding: '8px 12px 8px 12px'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span
                        className={'d-block w-100'}
                        style={{
                            fontSize: 10,
                            color: 'white',
                            fontWeight: 600,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{target.user.full_name}</span>
                        <span
                        className={'d-block w-100'}
                        style={{
                            fontSize: 10,
                            color: 'white',
                            fontWeight: 300,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{`${moment(target.start_date).format('h:mma')} to ${moment(target.end_date).format('h:mma')}`}</span>
                    </div>
                </div>
            </div>
        )
    }

    const onNewEvent = async ({ end, id, start }) => {
        try {
            let response = await Request.post(utils, '/users/', {
                type: 'new_availability',
                user_id: id,
                start_date: start,
                end_date: end
            });

            setEvents(events => update(events, {
                $push: [{
                     id: response.id,
                     available: true,
                     start: start,
                     end: end,
                     resource_id: id,
                     title: `${Utils.ucFirst(response.time_of_day)} Availability`,
                     color: Appearance.colors.primary(),
                     user: resources.find(resource => id === resource.user_id)
                }]
            }))

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating the timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRenderCallout = ({ evt, title, start, end, color }) => {
        return (
            <div style={{
                maxWidth: 350
            }}>
                {Views.entry({
                    title: evt.user.full_name,
                    subTitle: `Available from ${moment(start).format('h:mma')} to ${moment(end).format('h:mma')}`,
                    icon: {
                        path: evt.user.avatar
                    },
                    borderBottom: false,
                    rightContent: (
                        <img
                        className={'text-button'}
                        src={'images/red-x-icon.png'}
                        onClick={onDeleteEvent.bind(this, evt)}
                        style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginLeft: 12
                        }} />
                    )
                })}
            </div>
        )
    }

    const onRenderEvent = (evt, props) => {
        return (
            <div
            {...props}
            className={`pinstripes text-button ${props.className}`}
            style={{
                height: 25,
                backgroundColor: evt.color,
                borderRadius: 25,
                overflow: 'hidden',
                padding: '8px 12px 8px 12px',
                ...props.style
            }} />
        )
    }

    const onRenderResource = resource => {
        return (
            Views.entry({
                bottomBorder: false,
                icon: {
                    path: resource.avatar,
                    imageStyle: {
                        boxShadow: null
                    }
                },
                onClick: onUserClick.bind(this, resource.user_id),
                subTitle: utils.groups.apply('phone_number', User.Group.categories.users, resource.phone_number),
                title: utils.groups.apply(['first_name', 'last_name'], User.Group.categories.users, resource.full_name)
            })
        )
    }

    const onSetAvailabilityStatus = evt => {
        utils.alert.show({
            title: `Set as ${evt.available ? 'Unavailable' : 'Available'}`,
            message: `Are you sure that you want to set this timeslot as ${evt.available ? 'unavailable' : 'available'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetAvailabilityStatusConfirm(evt);
                    return;
                }
            }
        })
    }

    const onSetAvailabilityStatusConfirm = async evt => {
        try {
            let nextAvailable = !evt.available;
            await Request.post(utils, '/users/', {
                type: 'set_availability_status',
                id: evt.id,
                available: nextAvailable
            })
            setEvents(events => {
                return events.map(prevEvent => {
                    if(prevEvent.id === evt.id) {
                        prevEvent.available = nextAvailable;
                        prevEvent.color = nextAvailable ? Appearance.colors.primary() : Appearance.colors.grey()
                    }
                    return prevEvent;
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetDemo = evt => {
        let selected = false;
        utils.alert.show({
            title: `Set Demo`,
            message: `Please search for a Lead to continue`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <LeadLookupField
                    utils={utils}
                    placeholder={'Search by first or last name...'}
                    onChange={lead => selected = lead} />
                </div>
            ),
            buttons: [{
                key: 'continue',
                title: 'Continue',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(selected && key === 'continue') {
                    onSetDemoConfirm(evt, selected);
                }
            }
        })
    }

    const onSetDemoConfirm = async (evt, lead) => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            // prevent booking for archived
            if(lead.active === false) {
                throw new Error('This lead is currently on the Archived list and is not available for a Demo')
            }

            // prevent booking for do not call
            if(lead.status.code === Lead.status.get().do_not_call) {
                throw new Error('This lead is currently on the Do Not Call list and is not available for a Demo')
            }

            // submit demo
            await Request.post(utils, '/demos/', {
                end_date: evt.end,
                lead_id: lead.id,
                start_date: evt.start,
                type: 'new',
                user_id: evt.user && evt.user.user_id
            });

            setLoading(false);
            utils.content.fetch('demo');

            // show alert confirmation with information about assigned user if applicable
            if(evt.user) {
                utils.alert.show({
                    title: 'All Done!',
                    message: `The Demo for ${lead ? lead.full_name : 'this Lead'} has been assigned to ${evt.user.full_name} for ${moment(evt.start).format('MMMM Do, YYYY [at] h:mma')}`
                })
                return;
            }

            // fallback to showing standard alert without assignment information
            utils.alert.show({
                title: 'All Done!',
                message: `The Demo for ${lead ? lead.full_name : 'this Lead'} has been set for ${moment(evt.start).format('MMMM Do, YYYY [at] h:mma')}`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue booking this demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateEvent = async ({ next_evt, prev_evt }) => {
        try {
            await Request.post(utils, '/users/', {
                type: 'update_availability',
                id: next_evt.id,
                user_id: next_evt.user_id,
                start_date: next_evt.start,
                end_date: next_evt.end
            })
            setEvents(events => {
                return events.map(evt => {
                    if(evt.id === prev_evt.id) {
                        evt.start = next_evt.start;
                        evt.end = next_evt.end;
                    }
                    return evt;
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${userID}`,
                permissions: ['users.details']
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'active',
            title: `${showInactive ? 'Hide' : 'Show'} Inactive`,
            style: showInactive ? 'default' : 'grey',
            onClick: () => {
                setOffset(0);
                setShowInactive(status => !status);
            }
        }]
    }

    const fetchEvents = async () => {
        try {
            setLoading(true);
            let { cal_events, paging } = await Request.get(utils, '/users/', {
                type: 'availability_calendar',
                start_date: targetDate.format('YYYY-MM-DD'),
                end_date: moment(targetDate).add(7, 'days').format('YYYY-MM-DD'),
                limit: limit,
                offset: offset,
                show_inactive: showInactive
            });

            console.log(cal_events)

            setLoading(false);
            setPaging(paging);
            setResources(resources => {
                if(resources) {
                    resources.forEach(async resource => {
                        try {
                            await utils.sockets.off('users', `on_add_availability_${resource.id}`, onAvailabilityAdded);
                            await utils.sockets.off('users', `on_add_availability_recurring_${resource.id}`, fetchEvents);
                            await utils.sockets.off('users', `on_update_availability_${resource.id}`, onAvailabilityUpdated);
                            await utils.sockets.off('users', `on_update_availability_recurring_${resource.id}`, fetchEvents);
                            await utils.sockets.off('users', `on_delete_availability_${resource.id}`, onAvailabilityDeleted);
                        } catch(e) {
                            console.error(`websocket subscription error for user ${resource.id}: ${e.message}`);
                        }
                    });
                }

                // add listeners for new list of events
                cal_events.forEach(async resource => {
                    try {
                        await utils.sockets.off('users', `on_add_availability_${resource.id}`, onAvailabilityAdded);
                        await utils.sockets.off('users', `on_add_availability_recurring_${resource.id}`, fetchEvents);
                        await utils.sockets.off('users', `on_update_availability_${resource.id}`, onAvailabilityUpdated);
                        await utils.sockets.off('users', `on_update_availability_recurring_${resource.id}`, fetchEvents);
                        await utils.sockets.off('users', `on_delete_availability_${resource.id}`, onAvailabilityDeleted);
                    } catch(e) {
                        console.error(`websocket subscription error for user ${resource.id}: ${e.message}`);
                    }
                });

                // create user objects and return
                return cal_events.map(item => User.create(item.user));
            });

            setEvents(cal_events.reduce((array, item) => {
                let { events } = item;
                return array.concat(events.map(evt => ({
                     id: evt.id,
                     available: evt.available,
                     start: evt.start_date,
                     end: evt.end_date,
                     resource_id: evt.user_id,
                     title: `${Utils.ucFirst(evt.time_of_day)} Availability`,
                     color: evt.available ? Appearance.colors.primary() : Appearance.colors.grey(),
                     user: User.create(item.user)
                 })))
            }, []))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the availability calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [offset, showInactive, targetDate]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchEvents);
        utils.content.subscribe(panelID, ['user'], {
            onFetch: fetchEvents,
            onUpdate: fetchEvents
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchEvents);
            setResources(resources => {
                if(resources) {
                    resources.forEach(async resource => {
                        try {
                            await utils.sockets.off('users', `on_add_availability_${resource.id}`, onAvailabilityAdded);
                            await utils.sockets.off('users', `on_add_availability_recurring_${resource.id}`, fetchEvents);
                            await utils.sockets.off('users', `on_update_availability_${resource.id}`, onAvailabilityUpdated);
                            await utils.sockets.off('users', `on_update_availability_recurring_${resource.id}`, fetchEvents);
                            await utils.sockets.off('users', `on_delete_availability_${resource.id}`, onAvailabilityDeleted);
                        } catch(e) {
                            console.error(`websocket subscription error for user ${resource.id}: ${e.message}`);
                        }
                    });
                }
                return resources;
            })
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        utils={utils}
        name={'Availability'}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            onSizeChange: ({ width }) => setPanelWidth(width - 30),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => setOffset(next)
            }
        }}>
            <div style={{
                overflow: 'hidden',
                width: '100%'
            }}>
                <Scheduler
                width={panelWidth}
                utils={utils}
                events={events}
                resources={resources}
                onDateChange={date => setTargetDate(date)}
                onDelete={onDeleteEvent}
                onNew={onNewEvent}
                onClick={onEventClick}
                onRenderEvent={onRenderEvent}
                onRenderCallout={onRenderCallout}
                onRenderResource={onRenderResource}
                onUpdate={onUpdateEvent}
                options={{
                    resizable: true,
                    moveable: true,
                    crossResourceMove: false
                }} />
            </div>
        </Panel>
    )
}

export const UserGroups = ({ index, options, utils }) => {

    const panelID = 'user_groups';
    const limit = 15;

    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const getActiveStatus = group => {
        if(!group) {
            return;
        }
        let color = group.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, group)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{group.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const getFields = (group, index) => {

        let target = group || {};
        let fields = [{
            key: 'title',
            title: 'Title',
            value: target.title
        },{
            key: 'description',
            title: 'Description',
            value: target.description
        },{
            key: 'group_type',
            title: 'Group Type',
            value: target.group_type ? target.group_type.text : null
        },{
            key: 'category',
            title: 'Category',
            value: target.category ? target.category.text : null
        },{
            key: 'active',
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // Headers
        if(!group) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 text-button flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onUserGroupClick.bind(this, group)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const onChangeActiveStatus = async (group, e) => {
        try {
            e.stopPropagation();
            let nextActive = !group.active;
            await Request.post(utils, '/users/', {
                type: 'set_user_group_active_status',
                id: group.id,
                active: nextActive
            });

            setGroups(groups => {
                return groups.map(prevGroup => {
                    if(prevGroup.id === group.id) {
                        prevGroup.active = nextActive;
                    }
                    return prevGroup;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status for this group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewUserGroup = () => {
        utils.sheet.show({
            title: 'User Group Category',
            message: 'What type of user group would you like to create?',
            items: [{
                key: 'demos',
                title: 'Demos',
                style: 'default'
            },{
                key: 'demo_requests',
                title: 'Demo Requests',
                style: 'default'
            },{
                key: 'leads',
                title: 'Leads',
                style: 'default'
            },{
                key: 'users',
                title: 'User Accounts',
                style: 'default'
            }]
        }, key => {
            if(key === 'cancel') {
                return;
            }
            utils.layer.open({
                abstract: Abstract.create({
                    object: User.Group.new(),
                    type: 'user_groups'
                }),
                Component: AddEditUserGroup.bind(this, {
                    category: User.Group.categories[key],
                    isNewTarget: true
                }),
                id: 'new_user_group',
                permissions: ['users.groups.actions.new']
            })
        })
    }

    const onPrintGroups = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { groups } = await Request.get(utils, '/users/', {
                    type: 'groups',
                    ...props
                });

                setLoading(false);
                resolve(groups);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const fetchGroups = async () => {
        try {
            setLoading(true);
            let { groups, paging } = await Request.get(utils, '/users/', {
                type: 'groups',
                limit: limit,
                offset: offset,
                search_text: searchText
            });

            setLoading(false);
            setPaging(paging);
            setGroups(groups.map(group => User.Group.create(group)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the user groups list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserGroupClick = group => {
        utils.layer.open({
            abstract: Abstract.create({
                object: group,
                type: 'user_group'
            }),
            Component: UserGroupDetails,
            id: `user_group_details_${group.id}`,
            permissions: ['users.groups.details']
        });
    }

    const getPrintProps = () => {

        let headers = [{
            key: 'title',
            title: 'Title'
        },{
            key: 'description',
            title: 'Description'
        },{
            key: 'group_type',
            title: 'Group Type'
        },{
            key: 'category',
            title: 'Category'
        },{
            key: 'active',
            title: 'Status'
        }];

        return {
            headers: headers,
            onFetch: onPrintGroups,
            onRenderItem: (item, index, items) => ({
                title: item.title,
                description: item.description,
                group_type: item.group_type ? item.group_type.text : null,
                category: item.category ? item.category.text : null,
                active: item.active ? 'Yes' : 'No'
            })
        }
    }

    const getContent = () => {
        if(groups.length === 0) {
            return (
                Views.entry({
                    title: `No Groups Found`,
                    subTitle: `No user groups were found in the system`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {groups.map((group, index) => {
                        return getFields(group, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchGroups();
    }, [offset, searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchGroups);
        utils.content.subscribe(panelID, [ 'user_group' ], {
            onFetch: fetchGroups,
            onUpdate: abstract => {
                setGroups(groups => {
                    return groups.map(group => {
                        return group.id === abstract.getID() ? abstract.object : group
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchGroups);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'User Groups'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            search: {
                placeholder: 'Search by title or category name...',
                onChange: text => setSearchText(text)
            },
            print: getPrintProps(),
            buttons: [{
                key: 'new',
                permissions: ['users.groups.actions.new'],
                title: 'New User Group',
                style: 'default',
                onClick: onNewUserGroup
            }],
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => setOffset(next)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

// Layers
export const AddEditUserGroup = ({ category, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_user_group' : `edit_user_group_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [group, setGroup] = useState(null);

    const onDoneClick = async () => {

        // Valdiate fields
        let items = getFields().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please fill out the "${required.title}" before moving on`
            });
            return;
        }

        try {
            if(isNewTarget) {
                setLoading('done');
                await Utils.sleep(0.25);
                await abstract.object.submit(utils);

                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The "${abstract.object.title}" User Group has been created`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            setLoading('done');
            await Utils.sleep(0.25);
            await abstract.object.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" User Group has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this user group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLevelChange = async (level, selected) => {
        try {
            if(!group.levels) {
                onUpdateTarget({ levels: [ level ]});
                return;
            }
            if(selected === false) {
                onUpdateTarget({
                    levels: group.levels.filter(prevLevel => {
                        return prevLevel !== level;
                    })
                });
                return;
            }
            onUpdateTarget({
                levels: update(group.levels, {
                    $push: [ level ]
                })
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setGroup(edits);
    }

    const onValueChange = (key, value) => {
        if([ 'description', 'group_type', 'levels', 'title', 'users' ].includes(key)) {
            let edits = abstract.object.set({ [key]: value })
            setGroup(edits);
            return;
        }
        let edits = abstract.object.set({
            props: {
                ...group.props,
                [key]: value
            }
        })
        setGroup(edits);
    }

    const onVisibilityChange = (item) => {
        onValueChange(item.key, item.selected === false ? true : false);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }];
    }

    const getFields = () => {

        if(!group) {
            return [];
        }

        return [{
            key: 'details',
            title: 'About this Group',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for a user group will be used to identify this group across your Dealership.',
                component: 'textfield',
                value: group.title,
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'dealership',
                visible: isNewTarget && utils.user.get().level <= User.levels.get().admin,
                title: 'Dealership',
                description: `The Dealership for a user group will dictate which users are effected by this group's restrictions`,
                value: group.dealership,
                component: 'dealership_lookup',
                onChange: dealership => onUpdateTarget({ dealership: dealership })
            },{
                key: 'group_type',
                required: true,
                title: 'Group Type',
                description: 'Do you want to select individual users for this group or do you want to choose all users with a matching account type?',
                value: group.group_type ? group.group_type.text : null,
                onChange: item => onUpdateTarget({ group_type: item }),
                component: 'list',
                items: [{
                    code: 1,
                    text: 'Account Type'
                },{
                    code: 2,
                    text: 'Individual Users'
                }]
            },{
                key: 'description',
                title: 'Description',
                description: 'The description for a user group should be used to explain the purpose of this user group.',
                component: 'textview',
                value: group.description,
                onChange: text => onUpdateTarget({ description: text })
            }]
        }]
    }

    const getLevels = () => {

        if(!group || !group.group_type || group.group_type.code !== User.Group.types.levels) {
            return null;
        }
        return (
            <LayerItem
            title={'Account Types'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%',
                    padding: 10
                }}>
                    {[
                        User.levels.get().booking_coordinator,
                        User.levels.get().marketing_director,
                        User.levels.get().safety_advisor,
                        User.levels.get().safety_associate
                    ].map((level, index, levels) => (
                        <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: index === levels.length - 1 ? 0 : 12,
                            width: '100%',
                            textAlign: 'left'
                        }}>
                            <Checkbox
                            checked={group.levels.includes(level)}
                            onChange={onLevelChange.bind(this, level)} />
                            <span style={{
                                ...Appearance.textStyles.title(),
                                marginLeft: 8
                            }}>{User.levels.toText(level)}</span>
                        </div>
                    ))}
                </div>
            </LayerItem>
        )
    }

    const getPropFields = () => {

        if(!group) {
            return [];
        }

        let items = [];
        if(category === User.Group.categories.demos) {
            items = [{
                key: 'details',
                title: 'Basic Details',
                collapsed: false,
                items: [{
                    key: 'id',
                    title: 'ID'
                },{
                    key: 'start_date',
                    title: 'Start Date'
                },{
                    key: 'end_date',
                    title: 'End Date'
                },{
                    key: 'status',
                    title: 'Status'
                }]
            },{
                key: 'ext_details',
                title: 'Extended Details',
                collapsed: false,
                items: [{
                    key: 'booked_by',
                    title: 'Booked By'
                },{
                    key: 'partner',
                    title: 'Partner'
                },{
                    key: 'ride_along',
                    title: 'Safety Associate'
                },{
                    key: 'trainee',
                    title: 'Trainee'
                },{
                    key: 'feedback_template',
                    title: 'Feedback Template'
                },{
                    key: 'affiliate_link',
                    title: 'Affiliate Link'
                }]
            }]
        }

        if(category === User.Group.categories.demo_requests) {
            items = [{
                key: 'request',
                title: 'Request',
                collapsed: false,
                items: [{
                    key: 'id',
                    title: 'ID'
                },{
                    key: 'requested_by_user',
                    title: 'Requested By'
                },{
                    key: 'created',
                    title: 'Created'
                },{
                    key: 'date',
                    title: 'Scheduled For'
                },{
                    key: 'status',
                    title: 'Status'
                }]
            }]
        }

        if(category === User.Group.categories.leads) {
            items = [{
                key: 'customer',
                title: 'Customer',
                collapsed: false,
                items: [{
                    key: 'first_name',
                    title: 'First Name'
                },{
                    key: 'last_name',
                    title: 'Last Name'
                },{
                    key: 'phone_number',
                    title: 'Phone Number'
                },{
                    key: 'email_address',
                    title: 'Email Address'
                },{
                    key: 'address',
                    title: 'Physical Address'
                }]
            },{
                key: 'spouse',
                title: 'Spouse',
                collapsed: false,
                items: [{
                    key: 'spouse_first_name',
                    title: 'First Name'
                },{
                    key: 'spouse_last_name',
                    title: 'Last Name'
                },{
                    key: 'spouse_phone_number',
                    title: 'Phone Number'
                },{
                    key: 'spouse_email_address',
                    title: 'Email Address'
                }]
            },{
                key: 'location',
                title: 'Location',
                collapsed: false,
                items: [{
                    key: 'location',
                    title: 'Location'
                },{
                    key: 'address',
                    title: 'Address'
                },{
                    key: 'maps',
                    title: 'Directions'
                }]
            },{
                key: 'about',
                title: 'About this Lead',
                collapsed: false,
                items: [{
                    key: 'lead_type',
                    title: 'Lead Type'
                },{
                    key: 'lead_sub_type',
                    title: 'Lead Sub-Type'
                },{
                    key: 'lead_script',
                    title: 'Lead Script'
                }]
            },{
                key: 'details',
                title: 'Details',
                collapsed: false,
                items: [{
                    key: 'affiliate',
                    title: 'Affiliate'
                },{
                    key: 'program_credit',
                    title: 'Program Credit'
                },{
                    key: 'enrollment_credit',
                    title: 'Survey or Program Credit'
                },{
                    key: 'priority',
                    title: 'Priority'
                },{
                    key: 'tags',
                    title: 'Tags'
                },{
                    key: 'sale_date',
                    title: 'Sale Date'
                },{
                    key: 'status',
                    title: 'Status'
                },{
                    key: 'notes',
                    title: 'Notes'
                }]
            }]
        }

        if(category === User.Group.categories.users) {
            items = [{
                key: 'details',
                title: 'Account Details',
                collapsed: false,
                items: [{
                    key: 'user_id',
                    title: 'User ID'
                },{
                    key: 'first_name',
                    title: 'First Name'
                },{
                    key: 'last_name',
                    title: 'Last Name'
                },{
                    key: 'level',
                    title: 'Account Type'
                },{
                    key: 'dealership',
                    title: 'Dealership'
                }]
            },{
                key: 'contact',
                title: 'Contact Information',
                collapsed: false,
                items: [{
                    key: 'email',
                    title: 'Email Address'
                },{
                    key: 'phone',
                    title: 'Phone Number'
                }]
            },{
                key: 'location',
                title: 'Location',
                collapsed: false,
                items: [{
                    key: 'location',
                    title: 'Location'
                },{
                    key: 'address',
                    title: 'Physical Address'
                },{
                    key: 'timezone',
                    title: 'Timezone'
                }]
            },{
                key: 'ext_details',
                title: 'Extended Details',
                collapsed: false,
                items: [{
                    key: 'next_demo',
                    title: 'Next Demo'
                },{
                    key: 'last_login',
                    title: 'Last Login'
                },{
                    key: 'active',
                    title: 'Active'
                }]
            }];
        }

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return items.map(item => {
            item.items = item.items.map(innerItem => {
                return {
                    ...innerItem,
                    component: 'auto_toggle',
                    selected: group.props[innerItem.key] !== false,
                    value: (
                        <span style={{
                            ...Appearance.textStyles.value(),
                            color: group.props[innerItem.key] === false ? Appearance.colors.red : Appearance.colors.green
                        }}>{group.props[innerItem.key] === false ? 'Hidden' : 'Visible'}</span>
                    ),
                    rightContent: (
                        <img
                        src={group.props[innerItem.key] === false ? `images/non-visible-eye-${window.theme === 'dark' ? 'white':'grey'}.png` : `images/visible-eye-${window.theme === 'dark' ? 'white':'grey'}.png`}
                        style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginLeft: 8
                        }} />
                    )
                }
            })
            return item;
        });
    }

    const getUsers = () => {

        if(!group || !group.group_type || group.group_type.code !== User.Group.types.user_ids) {
            return null;
        }
        return (
            <LayerItem
            title={'Users'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12
                }}>
                    <MultipleUserLookupField
                    utils={utils}
                    users={group.users}
                    onChange={users => onUpdateTarget({ users: users })} />
                </div>
            </LayerItem>
        )
    }

    const setupTarget = async () => {
        try {
            let edits = abstract.object.open();
            if(isNewTarget === true) {
                edits = abstract.object.set({
                    category: category,
                    dealership: utils.dealership.get()
                });
            }
            if(isNewTarget === false) {
                let { users } = await Request.get(utils, '/users/', {
                    type: 'user_group_members',
                    id: abstract.getID(),
                    paging: false
                });
                edits = abstract.object.set({ users: users.map(user => User.create(user)) });
            }
            setGroup(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this user group. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New User Group' : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={true}/>

            {getUsers()}
            {getLevels()}

            <FieldMapper
            editable={utils}
            fields={getPropFields()}
            onEditClick={onVisibilityChange} />
        </Layer>
    )
}

export const NotificationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `notification_details_${abstract.getID()}`;

    const getFields = () => {

        let notification = abstract.object;
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: notification.id
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(notification.date)
            },{
                key: 'title',
                title: 'Title',
                value: notification.title
            },{
                key: 'description',
                title: 'Message',
                value: notification.message
            }],
            style: {
                marginBottom: 0
            }
        }];
    }

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Details for "${abstract.getTitle()}"`}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
        }}>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const PaymentsOverview = ({ abstract, index, options, utils }) => {

    const layerID = `payments_overview_${abstract.getID()}`;
    const stripeProps = useRef({});

    const [dealershipPayments, setDealershipPayments] = useState(null);
    const [dealershipPaymentsLimit, setDealershipPaymentsOLimit] = useState(5);
    const [dealershipPaymentsOffset, setDealershipPaymentsOffset] = useState(0);
    const [dealershipPaymentsPaging, setDealershipPaymentsPaging] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState(null);
    const [newMethodCompleted, setNewMethodCompleted] = useState(false);
    const [userPayments, setUserPayments] = useState(null);
    const [userPaymentsLimit, setUserPaymentsLimit] = useState(5);
    const [userPaymentsOffset, setUserPaymentsOffset] = useState(0);
    const [userPaymentsPaging, setUserPaymentsPaging] = useState(null);

    const onAddNewMethod = async () => {
        try {
            setLoading(true);
            let element = stripeProps.current.elements.getElement(CardElement);
            let { token } = await stripeProps.current.stripe.createToken(element);

            let { method } = await Request.post(utils, '/payments/', {
                source_category: Payment.Method.source_categories.get().user,
                source_id: token.card.id,
                token: token.id,
                type: 'new_method'
            });

            setLoading(false);
            element.clear();

            let nextMethod = Payment.Method.create(method);
            setMethods(methods => update(methods, {
                $unshift: [ nextMethod ]
            }));

            // notify subscribers that payment methods have changed
            utils.events.emit('payment_method_change');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding your payment method. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCreditsPurchased = amount => {

        setUserPaymentsOffset(0);
        setDealershipPaymentsOffset(0);
        fetchUserPayments();
        fetchDealershipPayments();
        utils.alert.show({
            title: 'All Done!',
            message: `Your purchase of ${Utils.toCurrency(amount)} for credits has been completed`
        })
    }

    const onMethodAdded = method => {
        setMethods(methods => update(methods, {
            $push: [ method ]
        }))
    }

    const onMethodRemoved = method => {
        setMethods(methods => {
            return methods.filter(prev_method => {
                return prev_method.id !== method.id;
            })
        })
    }

    const onPaymentClick = () => {
        utils.alert.show({
            title: 'Payment Details',
            message: `To provide the best experience possible, we've moved payment history and management over to AFT. You can view details for this payment using the Applied Fire Technologies website.`
        });
    }

    const onRemovePaymentMethod = method => {
        utils.alert.show({
            title: 'Remove Payment Method',
            message: `Are you sure that you wnat to remove this payment method? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'deafult'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemovePaymentMethodconfirm(method);
                    return;
                }
            }
        })
    }

    const onRemovePaymentMethodconfirm = async method => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/payments/', {
                card_id: method.id,
                source_category: method.source_category && method.source_category.code,
                type: 'remove_payment_method',
            });

            setLoading(false);
            setMethods(methods => {
                return methods.filter(prev_method => {
                    return prev_method.id !== method.id;
                })
            });

            // notify subscribers that payment methods have changed
            utils.events.emit('payment_method_change');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this payment method from your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSelectPaymentMethod = method => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                onRemovePaymentMethod(method);
                return;
            }
        })
    }

    const getStripeCardComponent = () => {
        return (
            <Elements stripe={utils.stripe}>
                <ElementsConsumer>
                    {props => {
                        stripeProps.current = props;
                        return (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '100%',
                                padding: 10
                            }}>
                                <div style={{
                                    flexGrow: 1
                                }}>
                                    <CardElement
                                    onChange={({ complete }) => setNewMethodCompleted(complete)}
                                    options={{
                                        hidePostalCode: true,
                                        style: {
                                            invalid: {
                                                color: Appearance.colors.red,
                                            },
                                            base: {
                                                fontSize: '12px',
                                                color: Appearance.colors.text(),
                                                '::placeholder': {
                                                    color: Appearance.colors.subText(),
                                                },
                                            }
                                        }
                                    }} />
                                </div>
                                {newMethodCompleted && (
                                    <img
                                    className={'text-button'}
                                    onClick={onAddNewMethod}
                                    src={'images/next-arrow-blue-small.png'}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        objectFit: 'contain',
                                        marginLeft: 8
                                    }} />
                                )}
                            </div>
                        )
                    }}
                </ElementsConsumer>
            </Elements>
        )
    }

    const getPayments = category => {

        let targets = category === Payment.Method.source_categories.get().user ? userPayments : dealershipPayments;
        if(targets === null) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        
        let filtered_payments = targets.filter(payment => payment.source_category.code === category);
        if(filtered_payments.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No payments have been made with your account',
                    hideIcon: true,
                    bottomBorder: false
                })
            )
        }
        return (
            <>
            {filtered_payments.map((payment, index, payments) => {
                return (
                    Views.entry({
                        badge: [{
                            text: Utils.toCurrency(payment.amount),
                            color: payment.status.color
                        }],
                        bottomBorder: index !== payments.length - 1,
                        hideIcon: true,
                        key: index,
                        subTitle: `From ${Utils.formatDate(payment.date)}`,
                        title: payment.category.text,
                        onClick: onPaymentClick.bind(this, payment)
                    })
                )
            })}
            {category === Payment.Method.source_categories.get().user && (
                <PageControl
                data={userPaymentsPaging}
                limit={userPaymentsLimit}
                offset={userPaymentsOffset}
                onClick={next => setUserPaymentsOffset(next)}/>
            )}
            {category === Payment.Method.source_categories.get().dealership && (
                <PageControl
                data={dealershipPaymentsPaging}
                limit={dealershipPaymentsLimit}
                offset={dealershipPaymentsOffset}
                onClick={next => setDealershipPaymentsOffset(next)}/>
            )}
            </>
        )
    }

    const getPaymentComponents = () => {
        return (
            <>
            <LayerItem
            title={utils.user.get().level > User.levels.get().dealer ? 'Payments' : 'Personal Payments'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getPayments(Payment.Method.source_categories.get().user)}
                </div>
            </LayerItem>
            {utils.user.get().level <= User.levels.get().dealer && (
                <LayerItem
                title={'Dealership Payments'}
                collapsed={false}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {getPayments(Payment.Method.source_categories.get().dealership)}
                    </div>
                </LayerItem>
            )}
            </>
        )
    }

    const getPaymentMethods = () => {
        if(methods === null) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        if(methods.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No payment methods have been added to your account',
                    hideIcon: true,
                    bottomBorder: false
                })
            )
        }

        return (
            <>
            {methods.map((method, index) => {
                return (
                    <div
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        padding: 10,
                        borderBottom: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingRight: 8
                        }}>
                            <i
                            className={getIcon('payment')}
                            style={{
                                color: Appearance.colors.text(),
                                fontSize: 13
                            }}/>
                        </div>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            flexGrow: 1
                        }}>{method.getTitle()}</span>
                        <img
                        className={'text-button'}
                        onClick={onRemovePaymentMethod.bind(this, method)}
                        src={'images/red-x-icon.png'}
                        style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginLeft: 8
                        }} />
                    </div>
                )
            })}
            {getStripeCardComponent()}
            </>
        )
    }

    const fetchDealershipPayments = async () => {
        try {
            let { paging, payments } = await Request.get(utils, '/payments/', {
                dealership_id: utils.dealership.get().id,
                limit: dealershipPaymentsLimit,
                offset: dealershipPaymentsOffset,
                source_category: Payment.Method.source_categories.get().dealership,
                type: 'all'
            });
            setDealershipPaymentsPaging(paging);
            setDealershipPayments(payments.map(payment => Payment.create(payment)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dealership payment history. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchUserPayments = async () => {
        try {
            let { paging, payments } = await Request.get(utils, '/payments/', {
                limit: userPaymentsLimit,
                offset: userPaymentsOffset,
                source_category: Payment.Method.source_categories.get().user,
                type: 'all',
                user_id: utils.user.get().user_id
            });
            setUserPaymentsPaging(paging);
            setUserPayments(payments.map(payment => Payment.create(payment)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your payment history. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchPaymentMethods = async () => {
        try {
            let { methods } = await Request.get(utils, '/payments/', {
                source_category: Payment.Method.source_categories.get().user,
                type: 'methods'
            });
            setMethods(methods.map(method => Payment.Method.create(method)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your credit options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchDealershipPayments();
    }, [dealershipPaymentsOffset]);

    useEffect(() => {
        fetchUserPayments();
    }, [userPaymentsOffset]);

    useEffect(() => {
        fetchPaymentMethods();
        utils.events.on(layerID, 'payment_method_change', fetchPaymentMethods);
        return () => {
            utils.events.off(layerID, 'payment_method_change', fetchPaymentMethods);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Payments for ${abstract.object.full_name}`}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <CreditsPicker
            utils={utils}
            onMethodAdded={onMethodAdded}
            onMethodRemoved={onMethodRemoved}
            onCreditsPurchased={onCreditsPurchased}/>

            <LayerItem
            title={'My Credit and Debit Cards'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getPaymentMethods()}
                </div>
            </LayerItem>

            {getPaymentComponents()}
        </Layer>
    )
}

export const SystemEventDetails = ({ abstract, index, options, utils }) => {

    const layerID = `system_event_details_${abstract.getID()}`;
    const [target, setTarget] = useState(null);

    const onCallLogClick = async () => {
        try {
            utils.layer.open({
                abstract: Abstract.create({
                    object: target,
                    type: 'call_log'
                }),
                Component: CallLogDetails,
                id: `call_log_details_${abstract.object.target.object.id}`,
                permissions: ['calls.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this entry. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCreatorClick = async () => {
        try {
            let user = await User.get(utils, abstract.object.user.user_id);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDemoClick = async () => {
        try {
            utils.layer.open({
                abstract: Abstract.create({
                    object: target,
                    type: 'demo'
                }),
                Component: DemoDetails,
                id: `demo_details_${abstract.object.target.object.id}`,
                permissions: ['demos.details']
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDemoRequestClick = async () => {
        try {
            utils.layer.open({
                abstract: Abstract.create({
                    object: target,
                    type: 'demo_request'
                }),
                Component: DemoRequestDetails,
                id: `demo_request_details_${abstract.object.target.object.id}`,
                permissions: ['demo_requests.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLeadClick = async () => {
        try {
            utils.layer.open({
                abstract: Abstract.create({
                    object: target,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${abstract.object.target.object.id}`,
                permissions: ['leads.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserClick = async () => {
        try {
            utils.layer.open({
                abstract: Abstract.create({
                    object: target,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${abstract.object.target.object.user_id}`,
                permissions: ['users.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContentDiff = () => {
        let { components } = getSystemEventProps(abstract.object);
        return (
            <LayerItem
            key={index}
            title={'Details'}>
                {components}
            </LayerItem>
        )
    }

    const getTarget = () => {

        let props = {};
        let title = Utils.ucFirst(abstract.object.target.type);

        // return loading placeholder if target is not prepared
        if(!target) {
            return (
                <LayerItem title={title}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {Views.entry({
                            title: `Loading...`,
                            hideIcon: true,
                            bottomBorder: false,
                            bottomBorder: false,
                        })}
                    </div>
                </LayerItem>
            );
        }

        // wrap abstract target attemps in a try/catch for objects that are deleted from the database
        // crash will happend otherwise for undefined nested values
        try {
            switch(abstract.object.target.type) {
                case 'call_log':
                title = target.method === 'email' ? 'Email' : 'Phone Call';
                props = {
                    title: target.author ? target.author.full_name : 'Name not available',
                    subTitle: moment(target.start_date).format('MMMM Do, YYYY [at] h:mma'),
                    hideIcon: true,
                    onClick: onCallLogClick
                }
                break;

                case 'demo':
                title = 'Demo';
                props = {
                    title: target.lead.full_name,
                    subTitle: target.lead.phone_number,
                    hideIcon: true,
                    onClick: onDemoClick
                }
                break;

                case 'demo_request':
                title = 'Demo Request';
                props = {
                    title: target.lead.full_name,
                    subTitle: target.lead.phone_number,
                    hideIcon: true,
                    onClick: onDemoRequestClick
                }
                break;

                case 'lead':
                title = 'Lead';
                props = {
                    title: target.full_name,
                    subTitle: target.phone_number,
                    hideIcon: true,
                    onClick: onLeadClick
                }
                break;

                case 'user':
                title = User.levels.toText(target.level);
                props = {
                    title: target.full_name,
                    subTitle: target.phone_number,
                    hideIcon: true,
                    onClick: onUserClick
                }
                break;

                default:
                return null;
            }

        } catch(e) {
            return null;
        }

        return (
            <LayerItem title={title}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        ...props,
                        bottomBorder: false,
                        bottomBorder: false,
                    })}
                </div>
            </LayerItem>
        );
    }

    const getUser = () => {
        let { user } = abstract.object;
        if(!user) {
            return null;
        }

        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 20
            }}>
                {Views.entry({
                    badge: {
                        text: Utils.formatDate(abstract.object.date),
                        color: Appearance.colors.primary()
                    },
                    bottomBorder: false,
                    icon: {
                        path: user.avatar
                    },
                    onClick: onCreatorClick,
                    subTitle: user.email_address,
                    title: user.full_name
                })}
            </div>
        )
    }

    const fetchTarget = async () => {
        try {
            switch(abstract.object.target.type) {
                case 'call_log':
                let call = await CallLog.get(utils, abstract.object.target.object.id);
                setTarget(call);
                break;

                case 'demo':
                let demo = await Demo.get(utils, abstract.object.target.object.id);
                setTarget(demo);
                break;

                case 'demo_request':
                let request = await DemoRequest.get(utils, abstract.object.target.object.id);
                setTarget(request);
                break;

                case 'lead':
                let lead = await Lead.get(utils, abstract.object.target.object.id);
                setTarget(lead);
                break;

                case 'user':
                let user = await User.get(utils, abstract.object.target.object.user_id);
                setTarget(user);
                break;
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving some of the information for this system event. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            {getUser()}
            {getTarget()}
            {getContentDiff()}
        </Layer>
    )
}

export const UserDetails = ({ abstract, index, options, utils }) => {

    const layerID = `user_details_${abstract.getID()}`;
    const [dealership, setDealership] = useState(abstract.object.dealership);
    const [loading, setLoading] = useState(true);
    const [nextDemo, setNextDemo] = useState(null);
    const [permissions, setPermissions] = useState(abstract.object.permissions || {});

    const canEditUser = () => {
        try {

            // prevent someone from editing an account with higher access than them
            let user = utils.user.get();
            if(abstract.object.level < user.level) {
                throw new Error('You are not authorized to make changes to this type of account');
            }

            // only allow editing for owner of user record or administrators
            if(user.user_id !== abstract.object.user_id) {

                // check if current user dealership matches target user dealership
                if(user.level > User.levels.get().dealer) {
                    throw new Error('You are not authorized to make changes to this type of account');
                }
                // check if current user dealership matches target user dealership
                if(user.level !== User.levels.get().admin && user.dealership_id !== abstract.object.dealership_id) {
                    throw new Error('You are not authorized to make changes to accounts in this Dealership. You can only make changes to accounts in your own Dealership');
                }
            }
            return true;

        } catch(e) {
            return e;
        }
    }

    const onEditClick = async () => {
        utils.alert.show({
            title: 'Just a Second',
            message: `To provide the best experience possible, we've moved user management over to AFT. Would you like to open this account in AFT?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onOpenAFTUserManagement();
                    return;
                }
            }
        });
    }

    const onEditPermissionsClick = () => {

        // prevent permissions editing for the current user if development mode is disabled
        let user = utils.user.get();
        if(API.dev_env === false && user.user_id === abstract.getID()) {
            utils.alert.show({
                title: 'Just a Second',
                message: `You are not able to change the permissions for your own account. ${user.level > User.levels.get().dealer ? 'Please speak with your dealer if you have any questions.' : 'Please contact Home Office if you have any questions.'}`
            });
            return;
        }

        // open permissions editing layer
        utils.layer.open({
            id: `user_permissions_${abstract.getID()}`,
            abstract: abstract,
            Component: UserPermissions.bind(this, {
                onChange: result => {
                    abstract.object.permissions = result;
                    setPermissions(result);
                }
            })
        });
    }

    const onOpenAFTUserManagement = () => {
        let url = `${API.aft_server}/?route=user_details&user_id=${abstract.getID()}`;
        window.open(url);
    }

    const onPermissionsChange = data => {
        setPermissions(data);
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'permissions',
                title: 'Set Permissions',
                style: 'default',
                visible: canEditUser() === true
            },{
                key: 'states',
                title: 'View Demo History',
                style: 'default',
                visible: false
            }],
            target: evt.target
        }, key => {
            if(key === 'permissions') {
                onEditPermissionsClick();
                return;
            }
            if(key === 'states') {
                //onEditPermissionsClick();
                //return;
            }
        });
    }

    const getButtons = () => {
        
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options',
            visible: utils.user.get().level <= User.levels.get().dealer
        },{
            color: canEditUser() === true ? 'primary' : 'danger',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getFields = () => {
        let user = abstract.object;
        let items = [{
            key: 'details',
            permissions: ['users.details.general'],
            title: 'Details',
            items: [{
                key: 'level',
                title: 'Account Type',
                value: User.levels.toText(user.level)
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: dealership ? dealership.name : null
            },{
                key: 'first_name',
                title: 'First Name',
                value: user.first_name
            },{
                key: 'last_name',
                title: 'Last Name',
                value: user.last_name
            },{
                key: 'last_login',
                title: 'Last Login',
                value: user.last_login ? moment(user.last_login).format('MMMM Do, YYYY [at] h:mma') : 'Not available'
            },{
                key: 'user_id',
                title: 'User ID',
                value: user.user_id
            },{
                key: 'username',
                title: 'Username',
                value: user.username
            }]
        },{
            key: 'contact',
            permissions: ['users.details.contact'],
            title: 'Contact Information',
            items: [{
                key: 'email',
                title: 'Email Address',
                value: user.email_address
            },{
                key: 'phone',
                title: 'Phone Number',
                value: user.phone_number
            }]
        },{
            key: 'location',
            permissions: ['users.details.location'],
            title: 'Location',
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                visible:  user.address && user.location ? true : false,
                value: user.location
            },{
                key: 'address',
                title: 'Physical Address',
                value: user.address ? Utils.formatAddress(user.address) : null
            },{
                key: 'timezone',
                title: 'Timezone',
                value: user.timezone
            }]
        },{
            key: 'ext_details',
            permissions: ['users.details.ext'],
            title: 'Extended Details',
            visible: user.level < User.levels.get().customer,
            items: [{
                color: user.active ?  Appearance.colors.green : Appearance.colors.red,
                key: 'active',
                title: 'AFT Access',
                value: user.active ? 'Active' : 'Inactive'
            },{
                color: user.gdl_active ?  Appearance.colors.green : Appearance.colors.red,
                key: 'gdl_active',
                title: 'Global Data Access',
                value: user.active && user.gdl_active ? 'Active' : 'Inactive',
                visible: user.level > User.levels.get().exigent_admin
            },{
                key: 'next_demo',
                title: 'Next Demo',
                value: nextDemo ? moment(nextDemo).format('MMMM Do, YYYY [at] h:mma') : 'Not available',
                visible: [User.levels.get().safety_advisor, User.levels.get().safety_associate].includes(user.level) ? true : false
            },{
                color: permissions && permissions.date && Appearance.colors.green,
                key: 'permissions',
                title: 'Permissions',
                value: permissions && permissions.date ? 'Configured' : 'Not Configured'
            }]
        },{
            key: 'pay_rates',
            lastItem: true,
            permissions: ['users.details.pay_rates'],
            title: 'Pay Rates',
            visible: user.pay_rates && user.level === User.levels.get().marketing_director && utils.user.get().level <= User.levels.get().dealer ? true : false,
            items: [{
                key: 'default',
                title: 'Default',
                value: user.pay_rates && user.pay_rates.default ? Utils.toCurrency(user.pay_rates.default) : '$0.00'
            }]
        }];

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.users}
            utils={utils}/>
        );
    }

    const getUserManagementBanner = () => {

        
        // determine if user has permission to view this content
        if(utils.user.permissions.get('users.details.general') === false) {
            return null;
        }

        return (
            <div
            className={'text-button'}
            onClick={onOpenAFTUserManagement}
            style={{
                alignItems: 'center',
                background: Appearance.colors.softGradient(Appearance.colors.secondary()),
                border: `2px solid ${Appearance.colors.secondary()}`,
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 24,
                minWidth: 0,
                overflow: 'hidden',
                padding: '6px 10px 6px 10px',
                width: '100%'
            }}>
                <img
                src={'images/alert-icon-white-small.png'}
                style={{
                    height: 30,
                    marginRight: 8,
                    minHeight: 30,
                    minWidth: 30,
                    width: 30
                }} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        color: 'white',
                        fontWeight: 800
                    }}>{'AFT User Management'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: 'white',
                        fontWeight: 600
                    }}>{'View this account in AFT to get a full look at this user'}</span>
                </div>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 8
                }}>
                    <img
                    src={'images/next-arrow-white-small.png'}
                    style={{
                        height: 12,
                        objectFit: 'contain',
                        width: 12
                    }} />
                </div>
            </div>
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('users', `on_permissions_change_${abstract.getID()}`, onPermissionsChange);
        } catch(e) {
            console.error(e.message)
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('users', `on_permissions_change_${abstract.getID()}`, onPermissionsChange);
        } catch(e) {
            console.error(e.message)
        }
    }

    const setupTarget = async () => {
        try {

            // fetch details for dealership if no dealership has been set
            if(!abstract.object.dealership && abstract.object.dealership_id) {
                let dealership = await Dealership.get(utils, abstract.object.dealership_id);
                abstract.object.dealership = dealership;
                setDealership(dealership);
            }

            // fetch date of next demo for user
            let { next_demo, permissions } = await Request.get(utils, '/users/', {
                type: 'profile_details',
                user_id: abstract.getID()
            });

            setLoading(false);
            setNextDemo(next_demo && moment(next_demo));
            setPermissions(permissions);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing some of your account information. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(permissions) {
            abstract.object.permissions = permissions;
        }
    }, [permissions]);

    useEffect(() => {

        connectToSockets();
        setupTarget();
        utils.content.subscribe(layerID, ['demo', 'demo_status'], {
            onUpdate: setupTarget
        });
        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            {getUserManagementBanner()}
            {getFields()}
        </Layer>
    )
}

export const UserGroupDetails = ({ abstract, index, options, utils }) => {

    const layerID = `user_group_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [users, setUsers] = useState([]);

    const getFields = () => {

        let group = abstract.object;
        let items = [{
            key: 'details',
            permissions: ['users.groups.details.general'],
            title: 'Details',
            items: [{
                key: 'category',
                title: 'Category',
                value: group.category ? group.category.text : null
            },{
                key: 'dealership',
                title: 'Dealership',
                value: group.dealership ? group.dealership.name : null,
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'description',
                title: 'Description',
                value: group.description
            },{
                key: 'group_type',
                title: 'Group Type',
                value: group.group_type ? group.group_type.text : null
            },{
                key: 'id',
                title: 'ID',
                value: group.id
            },{
                key: 'title',
                title: 'Title',
                value: group.title
            },]
        }];

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            utils={utils} />
        )
    }

    const getLevels = () => {
        if(abstract.object.group_type.code === User.Group.types.user_ids) {
            return null;
        }
        return (
            <LayerItem title={'Account Types'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {abstract.object.levels.map((level, index, levels) => {
                        return (
                            Views.entry({
                                key: index,
                                title: User.levels.toText(level),
                                hideIcon: true,
                                bottomBorder: index !== levels.length - 1,
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getPropFields = () => {

        let items = [];
        let { props } = abstract.object;
        if(abstract.object.category.code === User.Group.categories.demos) {
            items = [{
                key: 'details',
                permissions: ['users.groups.details.permissions.demos'],
                title: 'Basic Details',
                items: [{
                    key: 'id',
                    title: 'ID'
                },{
                    key: 'start_date',
                    title: 'Start Date'
                },{
                    key: 'end_date',
                    title: 'End Date'
                },{
                    key: 'status',
                    title: 'Status'
                }]
            },{
                key: 'ext_details',
                permissions: ['users.groups.details.permissions.demos'],
                title: 'Extended Details',
                items: [{
                    key: 'booked_by',
                    title: 'Booked By'
                },{
                    key: 'partner',
                    title: 'Partner'
                },{
                    key: 'ride_along',
                    title: 'Safety Associate'
                },{
                    key: 'trainee',
                    title: 'Trainee'
                },{
                    key: 'feedback_template',
                    title: 'Feedback Template'
                },{
                    key: 'affiliate_link',
                    title: 'Affiliate Link'
                }]
            }]
        }

        if(abstract.object.category.code === User.Group.categories.demo_requests) {
            items = [{
                key: 'request',
                permissions: ['users.groups.details.permissions.demo_requests'],
                title: 'Request',
                items: [{
                    key: 'id',
                    title: 'ID'
                },{
                    key: 'requested_by_user',
                    title: 'Requested By'
                },{
                    key: 'created',
                    title: 'Created'
                },{
                    key: 'date',
                    title: 'Scheduled For'
                },{
                    key: 'status',
                    title: 'Status'
                }]
            }]
        }

        if(abstract.object.category.code === User.Group.categories.leads) {
            items = [{
                key: 'customer',
                permissions: ['users.groups.details.permissions.leads'],
                title: 'Customer',
                items: [{
                    key: 'first_name',
                    title: 'First Name'
                },{
                    key: 'last_name',
                    title: 'Last Name'
                },{
                    key: 'phone_number',
                    title: 'Phone Number'
                },{
                    key: 'email_address',
                    title: 'Email Address'
                },{
                    key: 'address',
                    title: 'Physical Address'
                }]
            },{
                key: 'spouse',
                permissions: ['users.groups.details.permissions.leads'],
                title: 'Spouse',
                items: [{
                    key: 'spouse_first_name',
                    title: 'First Name'
                },{
                    key: 'spouse_last_name',
                    title: 'Last Name'
                },{
                    key: 'spouse_phone_number',
                    title: 'Phone Number'
                },{
                    key: 'spouse_email_address',
                    title: 'Email Address'
                }]
            },{
                key: 'location',
                permissions: ['users.groups.details.permissions.leads'],
                title: 'Location',
                items: [{
                    key: 'location',
                    title: 'Location'
                },{
                    key: 'address',
                    title: 'Address'
                },{
                    key: 'maps',
                    title: 'Directions'
                }]
            },{
                key: 'about',
                permissions: ['users.groups.details.permissions.leads'],
                title: 'About this Lead',
                items: [{
                    key: 'lead_type',
                    title: 'Lead Type'
                },{
                    key: 'lead_sub_type',
                    title: 'Lead Sub-Type'
                },{
                    key: 'lead_script',
                    title: 'Lead Script'
                }]
            },{
                key: 'details',
                permissions: ['users.groups.details.permissions.leads'],
                title: 'Details',
                items: [{
                    key: 'affiliate',
                    title: 'Affiliate'
                },{
                    key: 'program_credit',
                    title: 'Program Credit'
                },{
                    key: 'enrollment_credit',
                    title: 'Survey or Program Credit'
                },{
                    key: 'priority',
                    title: 'Priority'
                },{
                    key: 'tags',
                    title: 'Tags'
                },{
                    key: 'status',
                    title: 'Status'
                },{
                    key: 'notes',
                    title: 'Notes'
                }]
            }]
        }

        if(abstract.object.category.code === User.Group.categories.users) {
            items = [{
                key: 'details',
                permissions: ['users.groups.details.permissions.users'],
                title: 'Details',
                items: [{
                    key: 'user_id',
                    title: 'User ID'
                },{
                    key: 'first_name',
                    title: 'First Name'
                },{
                    key: 'last_name',
                    title: 'Last Name'
                },{
                    key: 'level',
                    title: 'Account Type'
                },{
                    key: 'dealership',
                    title: 'Dealership'
                }]
            },{
                key: 'contact',
                permissions: ['users.groups.details.permissions.users'],
                title: 'Contact Information',
                items: [{
                    key: 'email',
                    title: 'Email Address'
                },{
                    key: 'phone',
                    title: 'Phone Number'
                }]
            },{
                key: 'location',
                permissions: ['users.groups.details.permissions.users'],
                title: 'Location',
                items: [{
                    key: 'location',
                    title: 'Location'
                },{
                    key: 'address',
                    title: 'Physical Address'
                },{
                    key: 'timezone',
                    title: 'Timezone'
                }]
            },{
                key: 'ext_details',
                permissions: ['users.groups.details.permissions.users'],
                title: 'Extended Details',
                items: [{
                    key: 'next_demo',
                    title: 'Next Demo'
                },{
                    key: 'last_login',
                    title: 'Last Login'
                },{
                    key: 'active',
                    title: 'Active'
                }]
            }];
        }

        // prepare component value label for each item
        items = items.map(item => {
            item.items = item.items.map(innerItem => {
                return {
                    ...innerItem,
                    value: (
                        <span style={{
                            ...Appearance.textStyles.value(),
                            color: props[innerItem.key] === false ? Appearance.colors.red : Appearance.colors.green
                        }}>{props[innerItem.key] === false ? 'Hidden' : 'Visible'}</span>
                    )
                }
            })
            return item;
        });

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            utils={utils} />
        )
    }

    const getUsers = () => {
        if(users.length === 0 || abstract.object.group_type.code === User.Group.types.levels) {
            return null;
        }
        return (
            <LayerItem title={'Users'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {users.map((user, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: user.full_name,
                                subTitle: user.phone_number,
                                icon: {
                                    path: user.avatar
                                },
                                onClick: onUserClick.bind(this, user),
                                singleItem: users.length === 1,
                                firstItem: index === 0,
                                bottomBorder: true,
                            })
                        )
                    })}
                    <PageControl
                    data={paging}
                    limit={5}
                    offset={offset}
                    onClick={next => setOffset(next)}/>
                </div>
            </LayerItem>
        )
    }

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set "${abstract.object.title}" as ${abstract.object.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/users/', {
                type: 'set_user_group_active_status',
                active: !abstract.object.active,
                user_id: abstract.getID()
            });

            abstract.object.active = !abstract.object.active;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This user group has been set as ${abstract.object.active ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this user group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            abstract: abstract,
            Component: AddEditUserGroup.bind(this, {
                category: abstract.object.category.code,
                isNewTarget: false
            }),
            id: `edit_user_group_${abstract.getID()}`,
            permissions: ['users.groups.actions.edit']
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                permissions: ['users.groups.actions.status'],
                title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
        });
    }

    const onUserClick = user => {
        utils.layer.open({
            abstract: Abstract.create({
                object: user,
                type: 'user'
            }),
            Component: UserDetails,
            id: `user_details_${user.user_id}`,
            permissions: ['users.details']
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            permissions: ['users.groups.actions.edit'],
            text: 'Edit'
        }];
    }

    const fetchUsers = async () => {
        if(abstract.object.group_type !== User.Group.types.user_ids) {
            return;
        }
        try {
            let { paging, users } = await Request.get(utils, '/users/', {
                type: 'user_group_members',
                id: abstract.getID()
            });
            setPaging(paging);
            setUsers(users.map(user => User.create(user)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the user accounts for this user group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [offset]);

    useEffect(() => {
        utils.content.subscribe(layerID, [ 'user', 'user_group' ], {
            onFetch: fetchUsers,
            onUpdate: fetchUsers
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            {getFields()}
            {getUsers()}
            {getLevels()}
            {getPropFields()}

        </Layer>
    )
}

export const UserPermissions = ({ onChange }, { abstract, index, options, utils }) => {

    const layerID = `user_permissions_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [edits, setEdits] = useState(abstract.object.permissions && abstract.object.permissions.values || {});
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templates, setTemplates] = useState([]);
    console.log(abstract.object.permissions);

    const onRemoveTemplate = item => {
        utils.alert.show({
            title: 'Remove Template',
            message: `Are you sure that you want to remove the "${item.title}" template?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveTemplateConfirm({ id: item.id, name: item.title });
                    return; 
                }
            }
        });
    }

    const onRemoveTemplateConfirm = async target => {
        try {
            setLoading(true);
            await Request.post(utils, '/users/', {
                id: target.id,
                type: 'remove_user_permissions_template'
            });

            // update local state with new list of templates
            setLoading(false);
            setTemplates(templates => templates.filter(template => template.id !== target.id));

            // update local state for selected template if applicable
            setSelectedTemplate(template => template && template.id === target.id ? null : template);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `The "${target.name}" template  has been removed`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this template. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    
    const onRequestTemplateName = async () => {
        return new Promise((resolve, reject) => {
            let name = null;
            utils.alert.show({
                title: 'Save as Template',
                message: 'What would you like to call this template?',
                content: (
                    <div style={{
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        width: '100%'
                    }}>
                        <TextField 
                        autoCapitalize={'words'}
                        fieldStyle={{ fieldStyle: 'center' }}
                        onChange={text => name = text}
                        placeholder={'Template Name'} />
                    </div>
                ),
                buttons: [{
                    key: 'confirm',
                    title: 'Done',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(name && key === 'confirm') {
                        resolve({ name });
                        return;
                    }
                    let error = new Error('user-cancelled');
                    reject(error);
                }
            });
        });
    }

    const onSelectionChange = (section, value) => {
        setEdits(edits => {
            section.items.forEach(item => {
                edits[item.key] = value;
            });
            return { ...edits };
        });
    }

    const onSaveTemplate = async () => {
        try {

            // request name for template from user
            let { name } = await onRequestTemplateName();

            // set loading flag and submit request
            setLoading('template');
            let { template } = await Request.post(utils, '/users/', {
                name: name,
                permissions: edits,
                type: 'new_user_permissions_template',
                user_id: abstract.getID()
            });

            // add template to list of templates
            setTemplates(items => {
                return update(items, {
                    $push: [template]
                }).sort((a,b) => {
                    return a.name.localeCompare(b.name);
                });
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The permissions for this account have been saved as a template named "${name}"`
            });
            
        } catch(e) {
            setLoading(false);
            if(e.message === 'user-cancelled') {
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your template. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = async () => {
        try {

            // set loading flag and submit request
            setLoading('submit');
            let { permissions } = await Request.post(utils, '/users/', {
                permissions: edits,
                type: 'update_permissions',
                user_id: abstract.getID()
            });

            // notify subscribers of data change
            if(typeof(onChange) === 'function') {
                onChange(permissions);
            }

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The permissions for this account have been updated and will be applied immediately.`,
                onClick: setLayerState.bind(this, 'close')
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the permissions for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onTemplateChange = item => {

        // update local state with selected item
        setSelectedTemplate(item);

        // declare template object using selected item
        let target = templates.find(template => item && item.id === template.id);

        // prevent moving forward if no template was found
        if(!target) {
            return;
        }

        // apply template permissions to user permissions edits
        setEdits(edits => ({
            ...edits,
            ...target.permissions
        }));
    }

    const onVisibilityChange = item => {
        setEdits(edits => ({ 
            ...edits,
            [item.key]: !item.selected
        }));
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'template',
            loading: loading === 'template',
            onClick: onSaveTemplate,
            text: 'Save as Template'
        },{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmit,
            text: 'Submit'
        }];
    }

    const getContent = () => {

        // prepare field components
        let fields = getFields().map(section => {
            section.collapsed = false;
            section.items = section.items.map(item => ({
                ...item,
                component: 'auto_toggle',
                rightContent: (
                    <img
                    src={edits[item.key] ? `images/checkmark-button-green-small.png` : `images/red-x-icon.png`}
                    style={{
                        height: 20,
                        marginLeft: 8,
                        objectFit: 'contain',
                        width: 20
                    }} />
                ),
                selected: edits[item.key] ? true : false,
                value: (
                    <span style={{
                        ...Appearance.textStyles.value(),
                        color: edits[item.key] ? Appearance.colors.green : Appearance.colors.red
                    }}>{edits[item.key] ? 'Enabled' : 'Disabled'}</span>
                )
            }));
            return section;
        });

        return (
            <FieldMapper 
            editable={true}
            fields={fields}
            onEditClick={onVisibilityChange} 
            utils={utils} />
        )
    }

    const getFields = () => {

        return [{
            key: 'calendar',
            rightContent: getSelectionButtons,
            title: 'Calendar',
            items: [{
                component: 'bool_list',
                key: 'calendar.time_view',
                title: 'Time View',
                value: edits['calendar.time_view']
            },{
                component: 'bool_list',
                key: 'calendar.week_view',
                title: 'Week View',
                value: edits['calendar.week_view']
            }]
        },{
            key: 'events.actions',
            rightContent: getSelectionButtons,
            title: 'Calendar Events',
            items: [{
                component: 'bool_list',
                key: 'events.actions.new',
                title: 'Create',
                value: edits['events.actions.new']
            },{
                component: 'bool_list',
                key: 'events.actions.delete',
                title: 'Delete',
                value: edits['events.actions.delete']
            },{
                component: 'bool_list',
                key: 'events.actions.download',
                title: 'Download',
                value: edits['events.actions.download']
            },{
                component: 'bool_list',
                key: 'events.actions.edit',
                title: 'Edit',
                value: edits['events.actions.edit']
            }]
        },{
            key: 'events.notes',
            rightContent: getSelectionButtons,
            title: 'Calendar Event Notes',
            items: [{
                component: 'bool_list',
                key: 'events.notes.actions.new',
                title: 'Create',
                value: edits['events.notes.actions.new']
            },{
                component: 'bool_list',
                key: 'events.notes.actions.delete',
                title: 'Delete',
                value: edits['events.notes.actions.delete']
            },{
                component: 'bool_list',
                key: 'events.notes.actions.edit',
                title: 'Edit',
                value: edits['events.notes.actions.edit']
            },{
                component: 'bool_list',
                key: 'events.notes',
                title: 'View',
                value: edits['events.notes']
            }]
        },{
            key: 'calls',
            rightContent: getSelectionButtons,
            title: 'Calls and Emails',
            items: [{
                component: 'bool_list',
                key: 'calls.details.ext',
                title: 'Additional Details',
                value: edits['calls.details.ext']
            },{
                component: 'bool_list',
                key: 'calls.calendar',
                title: 'Calendar',
                value: edits['calls.calendar']
            },{
                key: 'calls.actions.new',
                permissions: ['calls.actions.new'],
                title: 'Create',
                style: 'default'
            },{
                component: 'bool_list',
                key: 'calls.details.dates',
                title: 'Dates and Times',
                value: edits['calls.details.dates']
            },{
                key: 'calls.actions.delete',
                permissions: ['calls.actions.delete'],
                title: 'Delete',
                style: 'default'
            },{
                key: 'calls.actions.edit',
                permissions: ['calls.actions.edit'],
                title: 'Edit',
                style: 'default'
            },{
                component: 'bool_list',
                key: 'calls.details.general',
                title: 'General Details',
                value: edits['calls.details.general']
            },{
                component: 'bool_list',
                key: 'calls.details.follow_up',
                title: 'Follow Up Dates and Times',
                value: edits['calls.details.follow_up']
            },{
                component: 'bool_list',
                key: 'calls.list.all',
                title: 'General List',
                value: edits['calls.list.all']
            },{
                component: 'bool_list',
                key: 'calls.details.system_events',
                title: 'System Events',
                value: edits['calls.details.system_events']
            }]
        },{
            key: 'demos',
            rightContent: getSelectionButtons,
            title: 'Demo Lists',
            items: [{
                component: 'bool_list',
                key: 'demos.list.customer_responses',
                title: 'Customer Responses',
                value: edits['demos.list.customer_responses']
            },{
                component: 'bool_list',
                key: 'demos.list.all',
                title: 'View',
                value: edits['demos.list.all']
            }]
        },{
            key: 'demos',
            rightContent: getSelectionButtons,
            title: 'Demo Actions',
            items: [{
                component: 'bool_list',
                key: 'demos.actions.cancel',
                title: 'Cancel',
                value: edits['demos.actions.cancel']
            },{
                component: 'bool_list',
                key: 'demos.actions.status',
                title: 'Change Status',
                value: edits['demos.actions.status']
            },{
                component: 'bool_list',
                key: 'demos.actions.delete',
                title: 'Delete',
                value: edits['demos.actions.delete']
            },{
                component: 'bool_list',
                key: 'demos.actions.edit',
                title: 'Edit',
                value: edits['demos.actions.edit']
            },{
                component: 'bool_list',
                key: 'demos.actions.import',
                title: 'Import',
                value: edits['demos.actions.import']
            },{
                component: 'bool_list',
                key: 'demos.actions.print',
                title: 'Print',
                value: edits['demos.actions.print']
            },{
                component: 'bool_list',
                key: 'demos.actions.reschedule',
                title: 'Reschedule',
                value: edits['demos.actions.reschedule']
            },{
                component: 'bool_list',
                key: 'demos.actions.set',
                title: 'Set',
                value: edits['demos.actions.set']
            }]
        },{
            key: 'demos.details',
            rightContent: getSelectionButtons,
            title: 'Demo Details',
            items: [{
                component: 'bool_list',
                key: 'demos.details.users',
                title: 'Assignments and Credit',
                value: edits['demos.details.users']
            },{
                component: 'bool_list',
                key: 'demos.details.calls',
                title: 'Calls and Emails',
                value: edits['demos.calls']
            },{
                component: 'bool_list',
                key: 'demos.details.general',
                title: 'General Details',
                value: edits['demos.details.general']
            },{
                component: 'bool_list',
                key: 'demos.details.lead',
                title: 'Lead Details',
                value: edits['demos.details.lead']
            },{
                component: 'bool_list',
                key: 'demos.details.system_events',
                title: 'System Events',
                value: edits['demos.details.system_events']
            },{
                component: 'bool_list',
                key: 'demos.details',
                title: 'View',
                value: edits['demos.details']
            }]
        },{
            key: 'demos.notes',
            rightContent: getSelectionButtons,
            title: 'Demo Notes',
            items: [{
                component: 'bool_list',
                key: 'demos.notes.actions.new',
                title: 'Create',
                value: edits['demos.notes.actions.new']
            },{
                component: 'bool_list',
                key: 'demos.notes.actions.delete',
                title: 'Delete',
                value: edits['demos.notes.actions.delete']
            },{
                component: 'bool_list',
                key: 'demos.notes.actions.edit',
                title: 'Edit',
                value: edits['demos.notes.actions.edit']
            },{
                component: 'bool_list',
                key: 'demos.notes',
                title: 'View',
                value: edits['demos.notes']
            }]
        },{
            key: 'demo_requests',
            rightContent: getSelectionButtons,
            title: 'Demo Requests',
            items: [{
                component: 'bool_list',
                key: 'demo_requests.list.all',
                title: 'View',
                value: edits['demo_requests.list.all']
            }]
        },{
            key: 'demo_requests.actions',
            rightContent: getSelectionButtons,
            title: 'Demo Request Actions',
            items: [{
                component: 'bool_list',
                key: 'demo_requests.actions.cancel',
                title: 'Cancel',
                value: edits['demo_requests.actions.cancel']
            },{
                component: 'bool_list',
                key: 'demo_requests.actions.download',
                title: 'Download',
                value: edits['demo_requests.actions.download']
            },{
                component: 'bool_list',
                key: 'demo_requests.actions.print',
                title: 'Print',
                value: edits['demo_requests.actions.print']
            },{
                component: 'bool_list',
                key: 'demo_requests.actions.set',
                title: 'Set',
                value: edits['demo_requests.actions.set']
            },{
                component: 'bool_list',
                key: 'demo_requests.actions.reschedule',
                title: 'Reschedule',
                value: edits['demo_requests.actions.reschedule']
            }]
        },{
            key: 'demo_requests.details',
            rightContent: getSelectionButtons,
            title: 'Demo Request Details',
            items: [{
                component: 'bool_list',
                key: 'demo_requests.details.general',
                title: 'General Details',
                value: edits['demo_requests.details.general']
            },{
                component: 'bool_list',
                key: 'demo_requests.details.lead',
                title: 'Lead Details',
                value: edits['demo_requests.details.lead']
            },{
                component: 'bool_list',
                key: 'demo_requests.details.location',
                title: 'Location',
                value: edits['demo_requests.details.location']
            },{
                component: 'bool_list',
                key: 'demo_requests.details.quick_look',
                title: 'Quick Look',
                value: edits['demo_requests.details.quick_look']
            },{
                component: 'bool_list',
                key: 'demo_requests.details',
                title: 'View',
                value: edits['demo_requests.details']
            }]
        },{
            key: 'demo_requests.notes',
            rightContent: getSelectionButtons,
            title: 'Demo Request Notes',
            items: [{
                component: 'bool_list',
                key: 'demo_requests.notes.actions.new',
                title: 'Create',
                value: edits['demo_requests.notes.actions.new']
            },{
                component: 'bool_list',
                key: 'demo_requests.notes.actions.delete',
                title: 'Delete',
                value: edits['demo_requests.notes.actions.delete']
            },{
                component: 'bool_list',
                key: 'demo_requests.notes.actions.edit',
                title: 'Edit',
                value: edits['demo_requests.notes.actions.edit']
            },{
                component: 'bool_list',
                key: 'demo_requests.notes',
                title: 'View',
                value: edits['demo_requests.notes']
            }]
        },{
            key: 'dialer',
            rightContent: getSelectionButtons,
            title: 'Dialer',
            items: [{
                component: 'bool_list',
                key: 'dialer',
                title: 'View',
                value: edits['dialer']
            }]
        },{
            key: 'leads',
            rightContent: getSelectionButtons,
            title: 'Lead Lists',
            items: [{
                component: 'bool_list',
                key: 'leads.list.general',
                title: 'All',
                value: edits['leads.list.general']
            },{
                component: 'bool_list',
                key: 'leads.list.archived',
                title: 'Archived',
                value: edits['leads.list.archived']
            },{
                component: 'bool_list',
                key: 'leads.list.assigned',
                title: 'Assigned',
                value: edits['leads.list.assigned']
            },{
                component: 'bool_list',
                key: 'leads.list.do_not_call',
                title: 'Do Not Call',
                value: edits['leads.list.do_not_call']
            },{
                component: 'bool_list',
                key: 'leads.list.follow_up',
                title: 'Follow Ups',
                value: edits['leads.list.follow_up']
            },{
                component: 'bool_list',
                key: 'leads.list.locations',
                title: 'Locations',
                value: edits['leads.list.locations']
            },{
                component: 'bool_list',
                key: 'leads.list.new',
                title: 'New',
                value: edits['leads.list.new']
            },{
                component: 'bool_list',
                key: 'leads.list.out_of_service_area',
                title: 'Out of Service Area',
                value: edits['leads.list.out_of_service_area']
            },{
                component: 'bool_list',
                key: 'leads.list.scripts',
                title: 'Scripts',
                value: edits['leads.list.scripts']
            },{
                component: 'bool_list',
                key: 'leads.list.sub_types',
                title: 'Sub-Types',
                value: edits['leads.list.sub_types']
            },{
                component: 'bool_list',
                key: 'leads.list.transfers',
                title: 'Transfers',
                value: edits['leads.list.transfers']
            },{
                component: 'bool_list',
                key: 'leads.list.types',
                title: 'Types',
                value: edits['leads.list.types']
            },{
                component: 'bool_list',
                key: 'leads.list.unreleased',
                title: 'Unreleased',
                value: edits['leads.list.unreleased']
            }]
        },{
            key: 'leads',
            rightContent: getSelectionButtons,
            title: 'Lead Actions',
            items: [{
                component: 'bool_list',
                key: 'leads.actions.do_not_call.new',
                title: 'Add to Do Not Call List',
                value: edits['leads.actions.do_not_call.new']
            },{
                component: 'bool_list',
                key: 'leads.actions.archive',
                title: 'Archive',
                value: edits['leads.actions.archive']
            },{
                component: 'bool_list',
                key: 'leads.actions.status',
                title: 'Change Status',
                value: edits['leads.actions.status']
            },{
                component: 'bool_list',
                key: 'leads.actions.new',
                title: 'Create',
                value: edits['leads.actions.new']
            },{
                component: 'bool_list',
                key: 'leads.actions.delete',
                title: 'Delete',
                value: edits['leads.actions.delete']
            },{
                component: 'bool_list',
                key: 'leads.actions.disqualify',
                title: 'Disqualify',
                value: edits['leads.actions.disqualify']
            },{
                component: 'bool_list',
                key: 'leads.actions.download',
                title: 'Download',
                value: edits['leads.actions.download']
            },{
                component: 'bool_list',
                key: 'leads.actions.edit',
                title: 'Edit',
                value: edits['leads.actions.edit']
            },{
                component: 'bool_list',
                key: 'leads.actions.import',
                title: 'Import',
                value: edits['leads.actions.import']
            },{
                component: 'bool_list',
                key: 'leads.actions.print',
                title: 'Print',
                value: edits['leads.actions.print']
            },{
                component: 'bool_list',
                key: 'leads.actions.release',
                title: 'Release',
                value: edits['leads.actions.release']
            },{
                component: 'bool_list',
                key: 'leads.actions.do_not_call.delete',
                title: 'Remove from Do Not Call List',
                value: edits['leads.actions.do_not_call.delete']
            },{
                component: 'bool_list',
                key: 'leads.actions.sms',
                title: 'Send Text Message',
                value: edits['leads.actions.sms']
            },{
                component: 'bool_list',
                key: 'leads.actions.set_demo',
                title: 'Set Demo',
                value: edits['leads.actions.set_demo']
            },{
                component: 'bool_list',
                key: 'leads.actions.transfer',
                title: 'Transfer',
                value: edits['leads.actions.transfer']
            },{
                component: 'bool_list',
                key: 'leads.actions.unassign',
                title: 'Unassign',
                value: edits['leads.actions.unassign']
            },{
                component: 'bool_list',
                key: 'leads.actions.unrelease',
                title: 'Unrelease',
                value: edits['leads.actions.unrelease']
            }]
        },{
            key: 'leads.board',
            rightContent: getSelectionButtons,
            title: 'Lead Board',
            items: [{
                component: 'bool_list',
                key: 'leads.board.actions.print',
                title: 'Print',
                value: edits['leads.board.actions.print']
            },{
                component: 'bool_list',
                key: 'leads.board',
                title: 'View',
                value: edits['leads.board']
            }]
        },{
            key: 'lead.details',
            rightContent: getSelectionButtons,
            title: 'Lead Details',
            items: [{
                component: 'bool_list',
                key: 'leads.details.ext',
                title: 'Additional Details',
                value: edits['leads.details.ext']
            },{
                component: 'bool_list',
                key: 'leads.details.users',
                title: 'Assignments and Credit',
                value: edits['leads.details.users']
            },{
                component: 'bool_list',
                key: 'leads.details.unstructured',
                title: 'Custom Dealership Details',
                value: edits['leads.details.unstructured']
            },{
                component: 'bool_list',
                key: 'leads.details.customer',
                title: 'Customer Details',
                value: edits['leads.details.customer']
            },{
                component: 'bool_list',
                key: 'leads.details.customer_response',
                title: 'Customer Survey Response',
                value: edits['leads.details.customer_response']
            },{
                component: 'bool_list',
                key: 'leads.details.general',
                title: 'General Details',
                value: edits['leads.details.general']
            },{
                component: 'bool_list',
                key: 'leads.details.spouse',
                title: 'Spouse Details',
                value: edits['leads.details.spouse']
            },{
                component: 'bool_list',
                key: 'leads.details.system_events',
                title: 'System Events',
                value: edits['leads.details.system_events']
            },{
                component: 'bool_list',
                key: 'leads.details.sms',
                title: 'Text Messages',
                value: edits['leads.details.sms']
            },{
                component: 'bool_list',
                key: 'leads.details',
                title: 'View',
                value: edits['leads.details']
            }]
        },{
            key: 'lead.duplicates',
            rightContent: getSelectionButtons,
            title: 'Lead Duplicates',
            items: [{
                component: 'bool_list',
                key: 'leads.duplicates.actions.edit',
                title: 'Edit',
                value: edits['lead.duplicates.actions.edit']
            },{
                component: 'bool_list',
                key: 'leads.duplicates',
                title: 'View',
                value: edits['lead.duplicates']
            }]
        },{
            key: 'lead.notes',
            rightContent: getSelectionButtons,
            title: 'Lead Notes',
            items: [{
                component: 'bool_list',
                key: 'leads.notes.actions.new',
                title: 'Create',
                value: edits['lead.notes.actions.new']
            },{
                component: 'bool_list',
                key: 'leads.notes.actions.delete',
                title: 'Delete',
                value: edits['leads.notes.actions.delete']
            },{
                component: 'bool_list',
                key: 'leads.notes.actions.edit',
                title: 'Edit',
                value: edits['lead.notes.actions.edit']
            },{
                component: 'bool_list',
                key: 'leads.notes',
                title: 'View',
                value: edits['lead.notes']
            }]
        },{
            key: 'lead.scripts',
            rightContent: getSelectionButtons,
            title: 'Lead Scripts',
            items: [{
                component: 'bool_list',
                key: 'leads.scripts.actions.status',
                title: 'Change Status',
                value: edits['leads.scripts.actions.status']
            },{
                component: 'bool_list',
                key: 'leads.scripts.actions.new',
                title: 'Create',
                value: edits['leads.scripts.actions.new']
            },{
                component: 'bool_list',
                key: 'leads.scripts.actions.delete',
                title: 'Delete',
                value: edits['leads.scripts.actions.delete']
            },{
                component: 'bool_list',
                key: 'leads.scripts.actions.edit',
                title: 'Edit',
                value: edits['leads.scripts.actions.edit']
            },{
                component: 'bool_list',
                key: 'leads.scripts.details',
                title: 'General Details',
                value: edits['leads.scripts.details']
            },{
                component: 'bool_list',
                key: 'leads.scripts.actions.default',
                title: 'Set as Dealership Default',
                value: edits['leads.scripts.actions.default']
            }]
        },{
            key: 'lead.sub_types',
            rightContent: getSelectionButtons,
            title: 'Lead Sub-Types',
            items: [{
                component: 'bool_list',
                key: 'leads.sub_types.actions.status',
                title: 'Change Status',
                value: edits['leads.sub_types.actions.status']
            },{
                component: 'bool_list',
                key: 'leads.sub_types.actions.new',
                title: 'Create',
                value: edits['leads.sub_types.actions.new']
            },{
                component: 'bool_list',
                key: 'leads.sub_types.actions.delete',
                title: 'Delete',
                value: edits['leads.sub_types.actions.delete']
            },{
                component: 'bool_list',
                key: 'leads.sub_types.actions.edit',
                title: 'Edit',
                value: edits['leads.sub_types.actions.edit']
            },{
                component: 'bool_list',
                key: 'leads.sub_types.details',
                title: 'General Details',
                value: edits['leads.sub_types.details']
            }]
        },{
            key: 'lead.types',
            rightContent: getSelectionButtons,
            title: 'Lead Types',
            items: [{
                component: 'bool_list',
                key: 'leads.types.actions.status',
                title: 'Change Status',
                value: edits['leads.types.actions.status']
            },{
                component: 'bool_list',
                key: 'leads.types.actions.new',
                title: 'Create',
                value: edits['leads.types.actions.new']
            },{
                component: 'bool_list',
                key: 'leads.types.actions.delete',
                title: 'Delete',
                value: edits['leads.types.actions.delete']
            },{
                component: 'bool_list',
                key: 'leads.types.actions.edit',
                title: 'Edit',
                value: edits['leads.types.actions.edit']
            },{
                component: 'bool_list',
                key: 'leads.types.details',
                title: 'General Details',
                value: edits['leads.types.details']
            },{
                component: 'bool_list',
                key: 'leads.types.actions.default',
                title: 'Set as Dealership Default',
                value: edits['leads.types.actions.default']
            }]
        },{
            key: 'programs.list',
            rightContent: getSelectionButtons,
            title: 'Program and Survey Lists',
            items: [{
                component: 'bool_list',
                key: 'programs.list.all',
                title: 'Programs',
                value: edits['programs.list.all']
            },{
                component: 'bool_list',
                key: 'programs.list.enrollment',
                title: 'Safety Associate Enrollment',
                value: edits['programs.list.enrollment']
            },{
                component: 'bool_list',
                key: 'programs.list.surveys',
                title: 'Surveys',
                value: edits['programs.list.surveys']
            },{
                component: 'bool_list',
                key: 'programs.list.templates',
                title: 'Templates',
                value: edits['programs.list.templates']
            }]
        },{
            key: 'programs.actions',
            rightContent: getSelectionButtons,
            title: 'Program Actions',
            items: [{
                component: 'bool_list',
                key: 'programs.actions.status',
                title: 'Change Status',
                value: edits['programs.actions.status']
            },{
                component: 'bool_list',
                key: 'programs.actions.enroll',
                title: 'Create Enrollment',
                value: edits['programs.actions.enroll']
            },{
                component: 'bool_list',
                key: 'programs.actions.edit',
                title: 'Edit',
                value: edits['programs.actions.edit']
            },{
                component: 'bool_list',
                key: 'programs.actions.edit_agreement',
                title: 'Edit Agreement',
                value: edits['programs.actions.edit_agreement']
            }]
        },{
            key: 'programs.details',
            rightContent: getSelectionButtons,
            title: 'Program Details',
            items: [{
                component: 'bool_list',
                key: 'programs.details.ext',
                title: 'Additional Details',
                value: edits['programs.details.ext']
            },{
                component: 'bool_list',
                key: 'programs.details.general',
                title: 'General Details',
                value: edits['programs.details.general']
            },{
                component: 'bool_list',
                key: 'programs.details',
                title: 'View',
                value: edits['programs.details']
            }]
        },{
            key: 'programs.survey.actions',
            rightContent: getSelectionButtons,
            title: 'Survey Actions',
            items: [{
                component: 'bool_list',
                key: 'programs.surveys.actions.url.status',
                title: 'Change Custom Url Status',
                value: edits['programs.surveys.actions.url.status']
            },{
                component: 'bool_list',
                key: 'programs.surveys.actions.status',
                title: 'Change Status',
                value: edits['programs.surveys.actions.status']
            },{
                component: 'bool_list',
                key: 'programs.surveys.actions.new',
                title: 'Create',
                value: edits['programs.surveys.actions.new']
            },{
                component: 'bool_list',
                key: 'programs.surveys.actions.url.new',
                title: 'Create Custom Url',
                value: edits['programs.surveys.actions.url.new']
            },{
                component: 'bool_list',
                key: 'programs.surveys.actions.edit',
                title: 'Edit',
                value: edits['programs.surveys.actions.edit']
            }]
        },{
            key: 'programs.survey.details',
            rightContent: getSelectionButtons,
            title: 'Survey Details',
            items: [{
                component: 'bool_list',
                key: 'programs.surveys.details.ext',
                title: 'Additional Details',
                value: edits['programs.surveys.details.ext']
            },{
                component: 'bool_list',
                key: 'programs.surveys.details.urls',
                title: 'Custom Urls',
                value: edits['programs.surveys.details.urls']
            },{
                component: 'bool_list',
                key: 'programs.surveys.details.general',
                title: 'General Details',
                value: edits['programs.surveys.details.general']
            },{
                component: 'bool_list',
                key: 'programs.surveys.details.template',
                title: 'Template',
                value: edits['programs.surveys.details.template']
            },{
                component: 'bool_list',
                key: 'programs.surveys.details',
                title: 'View',
                value: edits['programs.surveys.details']
            }]
        },{
            key: 'programs.survey_monkey',
            rightContent: getSelectionButtons,
            title: 'Survey Monkey',
            items: [{
                component: 'bool_list',
                key: 'programs.survey_monkey.actions.edit',
                title: 'Edit',
                value: edits['programs.survey_monkey.actions.edit']
            },{
                component: 'bool_list',
                key: 'programs.survey_monkey',
                title: 'View',
                value: edits['programs.survey_monkey']
            }]
        },{
            key: 'programs.templates.actions',
            rightContent: getSelectionButtons,
            title: 'Survey Template Actions',
            items: [{
                component: 'bool_list',
                key: 'programs.templates.actions.status',
                title: 'Change Status',
                value: edits['programs.templates.actions.status']
            },{
                component: 'bool_list',
                key: 'programs.templates.actions.new',
                title: 'Create',
                value: edits['programs.templates.actions.new']
            },{
                component: 'bool_list',
                key: 'programs.templates.actions.edit',
                title: 'Edit',
                value: edits['programs.templates.actions.edit']
            },{
                component: 'bool_list',
                key: 'programs.templates.details',
                title: 'View',
                value: edits['programs.templates.details']
            },]
        },{
            key: 'reports',
            rightContent: getSelectionButtons,
            title: 'Reports',
            items: [{
                component: 'bool_list',
                key: 'reports.demos.appointment_overview',
                title: 'Demo Appointment Overview',
                value: edits['reports.demos.appointment_overview']
            },{
                component: 'bool_list',
                key: 'reports.users.just_the_facts',
                title: 'Just the Facts',
                value: edits['reports.users.just_the_facts']
            },{
                component: 'bool_list',
                key: 'reports.leads.affiliates',
                title: 'Lead Affiliates',
                value: edits['reports.leads.affiliates']
            },{
                component: 'bool_list',
                key: 'reports.leads.credit',
                title: 'Lead Credit',
                value: edits['reports.leads.credit']
            },{
                component: 'bool_list',
                key: 'reports.leads.follow_ups',
                title: 'Lead Follow Ups',
                value: edits['reports.leads.follow_ups']
            },{
                component: 'bool_list',
                key: 'reports.marketing.call_times',
                title: 'Marketing Call Times',
                value: edits['reports.marketing.call_times']
            },{
                component: 'bool_list',
                key: 'reports.users.marketing_director_progress_overview',
                title: 'Marketing Director Progress Overview',
                value: edits['reports.users.marketing_director_progress_overview']
            },{
                component: 'bool_list',
                key: 'reports.marketing.statistics',
                title: 'Marketing Statistics',
                value: edits['reports.marketing.statistics']
            },{
                component: 'bool_list',
                key: 'reports.leads.pending',
                title: 'Pending Leads',
                value: edits['reports.leads.pending']
            },{
                component: 'bool_list',
                key: 'reports.programs.registrations',
                title: 'Program Registrations',
                value: edits['reports.programs.registrations']
            },{
                component: 'bool_list',
                key: 'reports.programs.associate_registrations',
                title: 'Safety Associate Program Registrations',
                value: edits['reports.programs.associate_registrations']
            },{
                component: 'bool_list',
                key: 'reports.users.safety_advisor_progress_overview',
                title: 'Safety Advisor Progress Overview',
                value: edits['reports.users.safety_advisor_progress_overview']
            },{
                component: 'bool_list',
                key: 'reports.programs.program_interactions',
                title: 'Survey Interactions',
                value: edits['reports.programs.program_interactions']
            }]
            
        },{
            key: 'settings',
            rightContent: getSelectionButtons,
            title: 'Settings',
            items: [{
                component: 'bool_list',
                key: 'settings.actions.edit',
                title: 'Edit',
                value: edits['settings.actions.edit']
            },{
                component: 'bool_list',
                key: 'settings',
                title: 'View',
                value: edits['settings']
            }]
        },{
            key: 'users',
            rightContent: getSelectionButtons,
            title: 'User Lists',
            items: [{
                component: 'bool_list',
                key: 'users.list.availability',
                title: 'Availability',
                value: edits['users.list.availability']
            },{
                component: 'bool_list',
                key: 'users.list.booking_coordinators',
                title: 'Booking Coordinators',
                value: edits['users.list.booking_coordinators']
            },{
                component: 'bool_list',
                key: 'users.list.groups',
                title: 'Groups',
                value: edits['users.list.groups']
            },{
                component: 'bool_list',
                key: 'users.list.marketing_directors',
                title: 'Marketing Directors',
                value: edits['users.list.marketing_directors']
            },{
                component: 'bool_list',
                key: 'users.list.notifications',
                title: 'Notifications',
                value: edits['users.list.notifications']
            },{
                component: 'bool_list',
                key: 'users.list.safety_advisors',
                title: 'Safety Advisors',
                value: edits['users.list.safety_advisors']
            },{
                component: 'bool_list',
                key: 'users.list.safety_associates',
                title: 'Safety Associates',
                value: edits['users.list.safety_associates']
            },{
                component: 'bool_list',
                key: 'users.list.system_events',
                title: 'System Events and Activities',
                value: edits['users.list.system_events']
            }]
        },{
            key: 'users.details',
            rightContent: getSelectionButtons,
            title: 'User Details',
            items: [{
                component: 'bool_list',
                key: 'users.details.ext',
                title: 'Additional Details',
                value: edits['users.details.ext']
            },{
                component: 'bool_list',
                key: 'users.details.contact',
                title: 'Contact Information',
                value: edits['users.details.contact']
            },{
                component: 'bool_list',
                key: 'users.details.general',
                title: 'General Details',
                value: edits['users.details.general']
            },{
                component: 'bool_list',
                key: 'users.details.location',
                title: 'Location',
                value: edits['users.details.location']
            },{
                component: 'bool_list',
                key: 'users.details.pay_rates',
                title: 'Pay Rates',
                value: edits['users.details.pay_rates']
            },{
                component: 'bool_list',
                key: 'users.details.permissions',
                title: 'Permissions',
                value: edits['users.details.permissions']
            },{
                component: 'bool_list',
                key: 'users.details',
                title: 'View',
                value: edits['users.details']
            }]
        },{
            key: 'users.groups.actions',
            rightContent: getSelectionButtons,
            title: 'User Group Actions',
            items: [{
                component: 'bool_list',
                key: 'users.groups.actions.status',
                title: 'Change Status',
                value: edits['users.groups.actions.status']
            },{
                component: 'bool_list',
                key: 'users.groups.actions.new',
                title: 'Create',
                value: edits['users.groups.actions.new']
            },{
                component: 'bool_list',
                key: 'users.groups.actions.edit',
                title: 'Edit',
                value: edits['users.groups.actions.edit']
            }]
        },{
            key: 'users.groups.details',
            rightContent: getSelectionButtons,
            title: 'User Group Details',
            items: [{
                component: 'bool_list',
                key: 'users.groups.details.permissions.demos',
                title: 'Demo Permissions',
                value: edits['users.groups.details.permissions.demos']
            },{
                component: 'bool_list',
                key: 'users.groups.details.permissions.demo_requests',
                title: 'Demo Request Permissions',
                value: edits['users.groups.details.permissions.demo_requests']
            },{
                component: 'bool_list',
                key: 'users.groups.details.permissions.leads',
                title: 'Lead Permissions',
                value: edits['users.groups.details.permissions.leads']
            },{
                component: 'bool_list',
                key: 'users.groups.details.permissions.users',
                title: 'User Permissions',
                value: edits['users.groups.details.permissions.users']
            },{
                component: 'bool_list',
                key: 'users.groups.details.general',
                title: 'General Details',
                value: edits['users.groups.details.general']
            },{
                component: 'bool_list',
                key: 'users.groups.details',
                title: 'View',
                value: edits['users.groups.details']
            }]
        }];
    }

    const getSelectionButtons = section => {

        // prepare count for enabled and disabled items
        let disabled = section.items.filter(item => edits[item.key] === false);
        let enabled = section.items.filter(item => edits[item.key] === true);

        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                justifyContent: 'flex-end',
                marginRight: 8
            }}>
                {disabled.length !== section.items.length && (
                    <AltBadge
                    onClick={onSelectionChange.bind(this, section, false)}
                    content={{
                        color: Appearance.colors.red,
                        text: 'Disable All',
                    }}
                    style={{
                        marginLeft: 4,
                        marginRight: 0,
                        padding: '2px 10px 2px 10px'
                    }}/>
                )}
                {enabled.length !== section.items.length && (
                    <AltBadge
                    onClick={onSelectionChange.bind(this, section, true)}
                    content={{
                        color: Appearance.colors.green,
                        text: 'Enable All'
                    }}
                    style={{
                        marginLeft: 4,
                        marginRight: 0,
                        padding: '2px 10px 2px 10px',
                    }}/>
                )}
            </div>
        )
    }

    const getTemplates = () => {
        return templates.length > 0 && (
            <LayerItem 
            childrenStyle={{
                overflow: 'visible'
            }}
            title={'Templates'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    overflow: 'visible',
                    padding: 12
                }}>
                    <CustomListField
                    items={getTemplateItems()}
                    onChange={onTemplateChange} 
                    onRemoveItem={onRemoveTemplate}
                    placeholder={'Select a template from the list...'}
                    removable={true} 
                    value={selectedTemplate} />
                </div>
            </LayerItem>
        )
    }

    const getTemplateItems = () => {
        return templates.map(template => ({
            id: template.id,
            removable: true,
            title: template.name
        }));
    }

    const fetchTemplates = async () => {
        try {
            setLoading(true);
            let { templates } = await Request.get(utils, '/users/', {
                type: 'user_permission_templates'
            });

            setLoading(false);
            setTemplates(templates);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of permission templates. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    
    useEffect(() => {
        fetchTemplates();
    }, []);

    return (
        <Layer 
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Permissions for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getTemplates()}
            {getContent()}
        </Layer>
    )
}

// Components
export const getNotificationIconColor = notification => {
    if(notification.category.includes('CANCELLED')) {
        return Appearance.colors.red;
    }
    if(notification.category.includes('RESCHEDULED')) {
        return Appearance.colors.secondary();
    }
    return Appearance.colors.primary();
}

export const getSystemEventBadges = evt => {

    let badges = [];
    switch(evt.action.code) {
        case SystemEvent.actions.create:
        badges.push({
            text: 'Created',
            color: Appearance.colors.primary()
        });
        break;

        case SystemEvent.actions.update:
        badges.push({
            text: 'Updated',
            color: Appearance.colors.secondary()
        });
        break;

        case SystemEvent.actions.delete:
        badges.push({
            text: 'Deleted',
            color: Appearance.colors.red
        });
        break;

        case SystemEvent.actions.warning:
        badges.push({
            text: 'Warning',
            color: Appearance.colors.orange
        });
        break;

        case SystemEvent.actions.note:
        badges.push({
            text: 'Note',
            color: Appearance.colors.tertiary()
        });
        break;
    }
    return badges.concat([{
        text: Utils.formatDate(evt.date),
        color: Appearance.colors.grey()
    }])
}

export const getSystemEventProps = evt => {

    // create => use abstract generated title information
    if(evt.action.code === SystemEvent.actions.create) {
        let val = `${evt.user.full_name} created a ${evt.target.getTitle()}`;
        if(evt.target.type === 'user') {
            val = `${evt.user.full_name} created a ${User.levels.toText(evt.target.object.level)} account for ${evt.target.getTitle()}`;
        }
        return {
            values: [ val ],
            components: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 8,
                    padding: '6px 12px 6px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        whiteSpace: 'normal'
                    }}>
                        <span style={{
                            fontWeight: 700,
                            color: Appearance.colors.text()
                        }}>
                            {evt.user.full_name}
                        </span>
                        {evt.target.type === 'user' ? ` created a ${User.levels.toText(evt.target.object.level)} account for ${evt.target.getTitle()}` : ` created a ${evt.target.getTitle()}`}
                    </span>
                </div>
            )
        }
    }

    // note or warning => use abstract generated title information
    let { message } = evt.props || {};
    if([ SystemEvent.actions.note, SystemEvent.actions.warning ].includes(evt.action.code)) {
        return {
            values: message ? [ message ] : [],
            components: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 8,
                    padding: '6px 12px 6px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        whiteSpace: 'normal'
                    }}>
                        {message || 'Message is no longer available'}
                    </span>
                </div>
            )
        }
    }

    // delete => use abstract generated title information
    if(evt.action.code === SystemEvent.actions.delete) {
        let val = `${evt.user.full_name} deleted the ${evt.target.getTitle()}`;
        return {
            values: [ val ],
            components: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 8,
                    padding: '6px 12px 6px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        whiteSpace: 'normal'
                    }}>
                        <span style={{
                            fontWeight: 700,
                            color: Appearance.colors.text()
                        }}>
                            {evt.user.full_name}
                        </span>
                        {` deleted the ${evt.target.getTitle()}`}
                    </span>
                </div>
            )
        }
    }

    // check for original and current update properties
    let { original, current } = evt.props || {};
    if(!original || !current) {
        //console.log('missing original or current');
        return { values: [] };
    }

    // use key to create title for data point
    const getTitleFromKey = key => {

        // target specific data points
        switch(evt.target.type) {

            case 'call_log':
            switch(key) {
                case 'assign_to':
                return 'User Assignment';

                case 'author':
                case 'created_by':
                return 'Created By';

                case 'follow_up_end_date':
                return 'Follow Up End Date and Time';

                case 'follow_up_start_date':
                return 'Follow Up Start Date and Time';
            }
            break;

            case 'demo':
            switch(key) {
                case 'qualified':
                return 'Qualified';

                case 'primary':
                case 'user':
                return 'Primary Assignment';

                case 'ride_along':
                return 'Safety Associate';

                case 'partner':
                return 'Partner';

                case 'trainee':
                return 'Trainee';

                case 'feedback_template_id':
                return 'Feedback Template ID';
            }
            break;

            case 'lead':
            switch(key) {
                case 'spouse_first_name':
                return 'Spouse First Name';

                case 'spouse_last_name':
                return 'Spouse Last Name';

                case 'spouse_phone_number':
                return 'Spouse Phone Number';

                case 'spouse_email_address':
                return 'Email Address';

                case 'lead_type':
                return 'Lead Type';

                case 'lead_sub_type':
                return 'Lead Sub-Type';

                case 'lead_script':
                return 'Lead Script';

                case 'homeowner_status':
                return 'Homeowner Status';

                case 'marital_status':
                return 'Marital Status';

                case 'occupational_status':
                return 'Occupational Status';

                case 'user':
                case 'user_id':
                return 'Lead Credit';

                case 'assignment_user':
                case 'assignment_user_id':
                return 'Assigned User';

                case 'enrollment_user':
                case 'enrollment_user_id':
                return 'Survey or Program Credit';

                case 'marketing_director_user':
                case 'marketing_director_user_id':
                return 'Marketing Director';

                case 'program_credit':
                return 'Program Credit';

                case 'opportunity':
                return 'Opportunity';

                case 'out_of_service_area':
                return 'Out of Service Area Flag';

                case 'priority':
                return 'Priority';

                case 'released':
                return 'Release';

                case 'notes':
                return 'Notes';

                case 'affiliate':
                return 'Affiliate';

                case 'tags':
                return 'Tags';

                case 'outof_service_area':
                return 'Out of Service Area';

                case 'active':
                return 'Archive Status';

                case 'sale_date':
                return 'Sale Date';
            }
        }

        // universal data points
        switch(key) {
            case 'id':
            return 'ID';

            case 'user_id':
            return 'User ID';

            case 'username':
            return 'Username';

            case 'first_name':
            return 'First Name';

            case 'last_name':
            return 'Last Name';

            case 'full_name':
            return 'Full Name';

            case 'phone_number':
            return 'Phone Number';

            case 'email_address':
            return 'Email Address';

            case 'address':
            return 'Address';

            case 'location':
            return 'Location Coordinates';

            case 'dealership':
            return 'Dealership';

            case 'dealership_id':
            return 'Dealership ID';

            case 'date':
            return 'Date';

            case 'start_date':
            return 'Start Date';

            case 'end_date':
            return 'End Date';

            case 'timezone':
            return 'Timezone';

            case 'status':
            return 'Status';

            case 'notes':
            return 'Notes';
        }
        return 'Unknown data point';
    }

    // check for specialty formatting based on data point key or value
    const getValue = (key, target) => {

        // target specific data points
        switch(evt.target.type) {
            case 'call_log':
            switch(key) {
                case 'assign_to':
                case 'author':
                case 'created_by':
                return target[key] ? target[key].full_name : null;

                case 'follow_up_end_date':
                case 'follow_up_start_date':
                return target[key] ? Utils.formatDate(target[key]) : null;
            }
            break;

            case 'demo':
            switch(key) {
                case 'qualified':
                return target[key] ? 'Yes' : 'No';

                case 'partner':
                case 'primary':
                case 'ride_along':
                case 'trainee':
                case 'user':
                return target[key] ? target[key].full_name : null;
            }
            break;

            case 'lead':
            switch(key) {
                case 'lead_type':
                return target[key] ? target[key].text : null;

                case 'lead_sub_type':
                return target[key] ? target[key].text : null;

                case 'lead_script':
                return target[key] ? target[key].title : null;

                case 'user':
                case 'assignment_user':
                case 'enrollment_user':
                case 'marketing_director_user':
                return target[key] ? target[key].full_name : null;

                case 'user_id':
                case 'assignment_user_id':
                case 'enrollment_user_id':
                case 'marketing_director_user_id':
                return target[key] ? `User ${target[key]}` : null;

                case 'homeowner_status':
                case 'marital_status':
                case 'occupational_status': 
                if(typeof(target[key]) === 'string') {
                    return target[key];
                }
                if(target[key] && typeof(target[key]) === 'object' && target[key].text) {
                    return target[key].text;
                }
                return null;

                case 'program_credit':
                return target[key] ? target[key].name : null;

                case 'opportunity':
                case 'priority':
                return target[key] ? 'Yes' : 'No';

                case 'notes':
                return target[key]

                case 'affiliate':
                return target[key] ? target[key].full_name : null;

                case 'tags':
                return target[key] ? Utils.oxfordImplode(target[key].map(tag => tag.text)) : null;

                case 'out_of_service_area':
                return target[key] ? 'Yes' : 'No';

                case 'active':
                return target[key] ? 'No' : 'Yes'; // inverted to represent the "archived" data point

                case 'sale_date':
                return target[key] ? Utils.formatDate(target[key]) : null;
            }
            break;
        }

        // universal data points
        switch(key) {
            case 'address':
            return target[key] ? Utils.formatAddress(target[key]) : null;

            case 'location':
            return target[key] ? Utils.formatLocation(target[key]) : null;

            case 'date':
            case 'start_date':
            case 'end_date':
            return target[key] ? Utils.formatDate(target[key]) : null;

            case 'status':
            return target[key] ? target[key].text : null;

            case 'active':
            return  target[key] ? 'Yes' : 'No';
        }

        if(target[key] && typeof(target[key]) === 'object') {
            return 'Unsupported value';
        }
        return target[key];
    }

    // check for specific color based on data point or value
    const getValueColor = (key, val, target) => {

        // prevent moving forward if no value was provided
        if(!val) {
            return Appearance.colors.grey();
        }

        // return explicit color is there is one
        if(target[key] && target[key].color) {
            return target[key].color;
        }

        // set color based on active flag
        if(key === 'active') {
            switch(evt.target.type) {
                case 'lead':
                return val === 'Yes' ? Appearance.colors.red : Appearance.colors.green; // inverted to represent the "archived" data point

                default:
                return val === 'Yes' ? Appearance.colors.green : Appearance.colors.red;
            }
        }
        return null;
    }

    // create text overview for system event
    let keys = Object.keys(original);
    Object.keys(current).forEach(key => {
        if(!keys.includes(key)) {
            keys.push(key);
        }
    })
    let values = keys.filter(key => {
        let originalVal = getValue(key, original);
        let currentVal = getValue(key, current);
        return originalVal !== currentVal;
    }).map(key => {
        let title = getTitleFromKey(key);
        let originalVal = getValue(key, original);
        let currentVal = getValue(key, current);
        return `${evt.user.full_name} changed the ${title.toLowerCase()} from ${originalVal || 'no value'} to ${currentVal || 'no value'}`;
    })

    // create components with text overview of system event
    let components = keys.filter(key => {
        let originalVal = getValue(key, original);
        let currentVal = getValue(key, current);
        return originalVal !== currentVal;
    }).map((key, index) => {

        let originalVal = getValue(key, original);
        let currentVal = getValue(key, current);

        let title = getTitleFromKey(key);
        return (
            <div
            key={index}
            style={{
                ...Appearance.styles.unstyledPanel(),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 8,
                padding: '6px 12px 6px 12px',
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: Appearance.colors.text(),
                    whiteSpace: 'normal'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontWeight: 700
                    }}>
                        {evt.user.full_name}
                    </span>
                    {` changed the ${title.toLowerCase()} from `}
                    <span style={{
                        fontWeight: 700,
                        color: getValueColor(key, originalVal, original) || Appearance.colors.text()
                    }}>
                        {originalVal || 'no value'}
                    </span>
                    {` to `}
                    <span style={{
                        color: getValueColor(key, currentVal, current) || Appearance.colors.green,
                        fontWeight: 700
                    }}>
                        {currentVal || 'no value'}
                    </span>
                </span>
            </div>
        )
    })

    return {
        values: values || [],
        components: components
    }
}

export const onNotificationAction = (utils, notification, onClick) => {

    const onShowDemoDetails = async id => {
        try {
            let demo = await Demo.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo'
                }),
                Component: DemoDetails,
                id: `demo_details_${demo.id}`,
                permissions: ['demos.details']
            });
        } catch(e){
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowDemoRequestDetails = async id => {
        try {
            let request = await DemoRequest.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: request,
                    type: 'demo_request'
                }),
                Component: DemoRequestDetails,
                id: `demo_request_details_${request.id}`,
                permissions: ['demo_requests.details']
            });
        } catch(e){
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowEventDetails = async id => {
        try {
            let event = await Event.get(utils, id);
            utils.layer.open({
                id: `event_details_${event.id}`,
                abstract: Abstract.create({
                    type: 'event',
                    object: event
                }),
                Component: EventDetails
            });
        } catch(e){
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this event. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowLeadDetails = async id => {
        try {
            let lead = await Lead.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });
        } catch(e){
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    // prepare buttons array
    let buttons = [];

    // determine if a payload was provided with resource details
    if(notification.payload) {

        // add support for viewing a demo if applicable
        if(notification.payload.demo_id) {
            buttons.push({
                key: 'demo_id',
                title: 'View Demo Details',
                style: 'default'
            });
        }

        // add support for viewing a demo request if applicable
        if(notification.payload.demo_request_id) {
            buttons.push({
                key: 'demo_request_id',
                title: 'View Demo Request Details',
                style: 'default'
            });
        }

        // add support for viewing a custom event if applicable
        if(notification.payload.event_id) {
            buttons.push({
                key: 'event_id',
                title: 'View Event Details',
                style: 'default'
            });
        }

        // add support for viewing a lead if applicable
        if(notification.payload.lead_id) {
            buttons.push({
                key: 'lead_id',
                title: 'View Lead Details',
                style: 'default'
            });
        }
    }

    // show alert with notification action buttons
    utils.alert.show({
        title: notification.title,
        message: notification.message,
        icon: {
            path: notification.from_user ? notification.from_user.avatar : 'images/push-notification-icon-white.png',
            style: {
                backgroundColor: getNotificationIconColor(notification)
            }
        },
        buttons: buttons.concat([{
            key: 'cancel',
            title: 'Okay',
            style: 'cancel'
        }]),
        onClick: async key => {
            if(typeof(onClick) === 'function') {
                onClick(notification, key);
            }
            switch(key) {
                case 'demo_id':
                onShowDemoDetails(notification.payload.demo_id);
                break;

                case 'demo_request_id':
                onShowDemoRequestDetails(notification.payload.demo_request_id);
                break;

                case 'event_id':
                onShowEventDetails(notification.payload.event_id);
                break;

                case 'lead_id':
                onShowLeadDetails(notification.payload.lead_id);
                break;
            }
        }
    });
}

export const reassignTasks = async (utils, userID, targets, onLoading) => {

    const setLoading = val => {
        if(typeof(onLoading) === 'function') {
            onLoading(val);
        }
    }
    const onConfirm = async (user, next_user, props) => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let { count } = await Request.post(utils, '/dealerships/', {
                type: 'reassign_tasks',
                targets: targets,
                ...props
            });

            setLoading(false);
            utils.content.fetch(targets);
            utils.alert.show({
                title: 'All Done!',
                message: `We have re-assigned ${count} ${count === 1 ? 'task' : 'tasks'} from ${user.full_name} to ${next_user.full_name}`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue re-assigning the tasks for ${user.full_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    try {
        let user = await User.get(utils, userID);
        let startDate = moment().startOf('day');
        let endDate = moment().endOf('day');
        let destinationUser = null;

        utils.alert.show({
            title: 'Re-Assign Tasks',
            message: `You can automatically re-assign all scheduled calls, emails, and Demos for ${user.full_name} if they are out of office or unable to complete their assignments. Please choose a user to accept ${user.first_name}'s assignments and a date range of assignments to move over to the new user.`,
            content: (
                <div style={{
                    width: '100%',
                    padding: 12,
                    paddingTop: 0
                }}>
                    <LayerItem title={'Assign To'}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            padding: 12,
                            backgroundColor: Appearance.colors.transparent
                        }}>
                            <UserLookupField
                            utils={utils}
                            icon={'search'}
                            avatarClick={false}
                            placeholder={'Search by first or last name...'}
                            onChange={result => destinationUser = result} />
                        </div>
                    </LayerItem>

                    <LayerItem title={'Date Range'}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            padding: 12,
                            backgroundColor: Appearance.colors.transparent
                        }}>
                            <DateDurationPickerField
                            utils={utils}
                            selected={startDate}
                            overrideAlerts={true}
                            placeholder={'Choose a start date...'}
                            onChange={date => startDate = date}
                            style={{
                                marginBottom: 8
                            }}/>

                            <DateDurationPickerField
                            utils={utils}
                            selected={endDate}
                            overrideAlerts={true}
                            placeholder={'Choose an end date...'}
                            onChange={date => endDate = date} />
                        </div>
                    </LayerItem>
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Re-Assign',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'done' && destinationUser && startDate && endDate) {
                    onConfirm(user, destinationUser, {
                        user_id: userID,
                        destination_user_id: destinationUser.user_id,
                        start_date: startDate.format('YYYY-MM-DD HH:mm:ss'),
                        end_date: endDate.format('YYYY-MM-DD HH:mm:ss')
                    });
                    return;
                }
            }
        })

    } catch(e) {
        utils.alert.show({
            title: 'Oops!',
            message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
        })
    }
}

export const unassignTasks = async (utils, userID, targets, onLoading) => {

    const setLoading = val => {
        if(typeof(onLoading) === 'function') {
            onLoading(val);
        }
    }
    const onConfirm = async (user, props) => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let { count } = await Request.post(utils, '/dealerships/', {
                type: 'reassign_tasks',
                targets: targets,
                ...props
            });

            setLoading(false);
            utils.content.fetch(targets);
            utils.alert.show({
                title: 'All Done!',
                message: `We have un-assigned ${count} ${count === 1 ? 'task' : 'tasks'} from ${user.full_name}`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue un-assigning the tasks for ${user.full_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    try {
        let user = await User.get(utils, userID);
        let startDate = moment().startOf('day');
        let endDate = moment().endOf('day');

        utils.alert.show({
            title: 'Un-Assign Tasks',
            message: `You can automatically un-assign all scheduled calls, emails, and Demos for ${user.full_name} if they have left your office or unable to complete their assignments. This will open up all calls, emails, and Demos to new assignments.`,
            content: (
                <div style={{
                    width: '100%',
                    padding: 12,
                    paddingTop: 0
                }}>
                    <LayerItem title={'Date Range'}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            padding: 12,
                            backgroundColor: Appearance.colors.transparent
                        }}>
                            <DateDurationPickerField
                            utils={utils}
                            selected={startDate}
                            overrideAlerts={true}
                            placeholder={'Choose a start date...'}
                            onChange={date => startDate = date}
                            style={{
                                marginBottom: 8
                            }}/>

                            <DateDurationPickerField
                            utils={utils}
                            selected={endDate}
                            overrideAlerts={true}
                            placeholder={'Choose an end date...'}
                            onChange={date => endDate = date} />
                        </div>
                    </LayerItem>
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Re-Assign',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'done' && startDate && endDate) {
                    onConfirm(user, {
                        user_id: userID,
                        start_date: startDate.format('YYYY-MM-DD HH:mm:ss'),
                        end_date: endDate.format('YYYY-MM-DD HH:mm:ss')
                    });
                    return;
                }
            }
        })

    } catch(e) {
        utils.alert.show({
            title: 'Oops!',
            message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
        })
    }
}
