import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import Lead from 'classes/Lead.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class DemoClass {

    affiliate_link = null;
    booked_by = null;
    created = null;
    end_date = null;
    feedback_template = null; // internal use only
    feedback_template_id = null;
    id = null;
    lead = null;
    notes = null;
    partner = null;
    qualified = null;
    ride_along = null;
    start_date = null;
    status = null;
    timezone = null;
    trainee = null;
    primary = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.affiliate_link = props.affiliate_link;
        this.booked_by = props.booked_by && User.create(props.booked_by);
        this.created = props.created;
        this.end_date = props.end_date && moment(props.end_date);
        this.feedback_template_id = props.feedback_template_id;
        this.id = props.id;
        this.lead = props.lead && Lead.create(props.lead);
        this.notes = props.notes;
        this.partner = props.partner && User.create(props.partner);
        this.qualified = props.qualified;
        this.ride_along = props.ride_along && User.create(props.ride_along);
        this.start_date = props.start_date && moment(props.start_date);
        this.status = props.status;
        this.timezone = props.timezone;
        this.trainee = props.trainee && User.create(props.trainee);
        this.primary = props.primary && User.create(props.primary);
        return this;
    }

    open = () => {
        this.edits = {
            end_date: this.end_date,
            feedback_template: this.feedback_template,
            partner: this.partner,
            primary: this.primary,
            qualified: this.qualified,
            ride_along: this.ride_along,
            start_date: this.start_date,
            trainee: this.trainee,
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.end_date = this.edits.end_date;
        this.feedback_template = this.edits.feedback_template;
        this.partner = this.edits.partner;
        this.primary = this.edits.primary;
        this.qualified = this.edits.qualified;
        this.ride_along = this.edits.ride_along;
        this.start_date = this.edits.start_date;
        this.trainee = this.edits.trainee;
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { status } = await Request.post(utils, '/demos/', {
                    type: 'update',
                    id: this.id,
                    ...this.toJSON(this.edits),
                    ...props
                })

                this.close();
                this.status = status;

                utils.content.update({
                    type: 'demo',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            end_date: target.end_date && moment(target.end_date).format('YYYY-MM-DD HH:mm:ss'),
            feedback_template_id: target.feedback_template && target.feedback_template.id,
            id: this.id,
            partner_user_id: target.partner && target.partner.user_id,
            primary_user_id: target.primary && target.primary.user_id,
            qualified: target.qualified,
            ride_along_user_id: target.ride_along && target.ride_along.user_id,
            start_date: target.start_date && moment(target.start_date).format('YYYY-MM-DD HH:mm:ss'),
            trainee_user_id: target.trainee && target.trainee.user_id
        }
    }
}

class DemoSlotClass {

    dealership_id = null;
    end_hour = null;
    id = null;
    name = null;
    start_hour = null;
    user_id = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    close = () => {
        this.end_hour = this.edits.end_hour;
        this.name = this.edits.name;
        this.start_hour = this.edits.start_hour;
        return this;
    }

    create = (props = {}) => {
        this.dealership_id = props.dealership_id;
        this.end_hour = props.end_hour;
        this.id = props.id;
        this.name = props.name;
        this.start_hour = props.start_hour;
        this.user_id = props.user_id;
        return this;
    }

    open = () => {
        this.edits = {
            end_hour: this.end_hour,
            name: this.name,
            start_hour: this.start_hour
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = utils => {
        return new Promise(async (resolve, reject) => {
            try {
                // submit request to server
                let { id } = await Request.post(utils, '/dealerships/', {
                    ...this.edits,
                    type: 'new_demo_slot'
                });

                // set id and close edits
                this.id = id;
                this.close();

                // notify subscribers that slot data has changed
                utils.content.fetch('demo_slot');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = utils => {
        return new Promise(async (resolve, reject) => {
            try {
                // submit request to server
                await Request.post(utils, '/dealerships/', {
                    ...this.edits,
                    id: this.id,
                    type: 'update_demo_slot'
                });

                // close edits
                this.close();

                // notify subscribers that slot data has changed
                utils.content.update({
                    object: this,
                    type: 'demo_slot'
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

const fetchDemo = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { demo } = await Request.get(utils, '/demos/', {
                type: 'details',
                id: id
            });
            let obj = new DemoClass().create(demo);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    });
}

const statusCodes = {
    pending: -1,
    new: 1,
    set: 100,
    assigned: 125,
    confirmed: 150,
    sale: 200,
    recall: 250,
    do_not_call: 275,
    did_not_sell: 300,
    not_home: 400,
    not_interested: 450,
    incomplete: 500,
    wrong_number: 525,
    left_voicemail: 550,
    rescheduled: 700,
    cancelled: 800,
    turndown: 900,
    unqualified: 1000
};

const statusCodeEntries = [{
    key: statusCodes.new,
    title: 'New',
    color: '#003A5D',
    status: statusCodes.new
},{
    key: statusCodes.pending,
    title: 'Pending',
    color: Appearance.colors.lighterGrey,
    status: statusCodes.pending
},{
    key: statusCodes.confirmed,
    title: 'Confirmed',
    color: Appearance.colors.primary(),
    status: statusCodes.confirmed
},{
    key: statusCodes.assigned,
    title: 'Assigned',
    color: Appearance.colors.tertiary(),
    status: statusCodes.assigned
},{
    key: statusCodes.set,
    title: 'Set',
    color: Appearance.colors.secondary(),
    status: statusCodes.set
},{
    key: statusCodes.sale,
    title: 'Sale',
    color: '#6ABF4B',
    status: statusCodes.sale
},{
    key: statusCodes.recall,
    title: 'Recall',
    color: Appearance.colors.lightGrey,
    status: statusCodes.recall
},{
    key: statusCodes.do_not_call,
    title: 'Do Not Call',
    color: Appearance.colors.darkRed,
    status: statusCodes.do_not_call
},{
    key: statusCodes.did_not_sell,
    title: 'Did Not Sell',
    color: Appearance.colors.orange,
    status: statusCodes.did_not_sell
},{
    key: statusCodes.not_home,
    title: 'Not Home',
    color: Appearance.colors.lightGrey,
    status: statusCodes.not_home
},{
    key: statusCodes.not_interested,
    title: 'Not Interested',
    color: Appearance.colors.darkGrey,
    status: statusCodes.not_interested
},{
    key: statusCodes.incomplete,
    title: 'Incomplete',
    color: Appearance.colors.yellow,
    status: statusCodes.incomplete
},{
    key: statusCodes.wrong_number,
    title: 'Wrong Number',
    color: Appearance.colors.lightGrey,
    status: statusCodes.wrong_number
},{
    key: statusCodes.left_voicemail,
    title: 'Left Voicemail',
    color: Appearance.colors.lightGrey,
    status: statusCodes.left_voicemail
},{
    key: statusCodes.rescheduled,
    title: 'Rescheduled',
    color: '#7193BB',
    status: statusCodes.rescheduled
},{
    key: statusCodes.cancelled,
    title: 'Cancelled',
    color: Appearance.colors.red,
    status: statusCodes.cancelled
},{
    key: statusCodes.turndown,
    title: 'Turndown',
    color: Appearance.colors.darkGrey,
    status: statusCodes.turndown
},{
    key: statusCodes.unqualified,
    title: 'Unqualified',
    color: Appearance.colors.darkRed,
    status: statusCodes.unqualified
}];

export default {
    create: props => new DemoClass().create(props),
    get: (utils, id) => fetchDemo(utils, id),
    status: {
        get: () => statusCodes,
        toText: key => {
            let match = statusCodeEntries.find(entry => entry.key === key);
            return match ? match.title : 'Unknown status';
        }
    },
    styles: {
        status: statusCodeEntries,
        colorForStatus: code => {
            let match = statusCodeEntries.find(entry => entry.status === code);
            return match ? match.color : Appearance.colors.grey();
        }
    },
    Slot: {
        create: props => new DemoSlotClass().create(props),
        new: () => new DemoSlotClass()
    }
}
