import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance';
import LottieView from 'views/Lottie.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const Loader = ({ animate }) => {

    const [visible, setVisible] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [scale, setScale] = useState(0);

    const show = async () => {
        try {
            setVisible(true);
            await Utils.sleep(0.25);

            setScale(1);
            setOpacity(1);

        } catch(e) {
            console.error(e.message);
        }
    }

    const hide = async callback => {
        try {
            setScale(0);
            setOpacity(0);

            await Utils.sleep(0.5);
            setVisible(false);
            if(typeof(callback) === 'function') {
                callback();
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        if(animate) {
            setTimeout(show, 250);
            return;
        }
        hide();

    }, [animate])

    return visible ? (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <VelocityComponent
            duration={500}
            animation={{
                opacity: opacity
            }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    backgroundColor: Appearance.colors.dim,
                }} />
            </VelocityComponent>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%'
            }}>
                <VelocityComponent
                easing={[250, 20]}
                duration={500}
                animation={{
                    opacity: opacity,
                    scale: scale
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 75,
                        height: 75,
                        zIndex: 9999
                    }}>
                        <div
                        className={'circle-loader'}
                        style={{
                            position: 'absolute',
                            width: 70,
                            height: 70
                        }}/>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={require('files/lottie/hal-loader.json')}
                        style={{
                            width: 65,
                            height: 65
                        }}/>
                    </div>
                </VelocityComponent>
            </div>
        </div>
    ) : null;
}

export default Loader;
