import moment from 'moment-timezone';

import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';

class TemplateClass {

    active = null;
    date = null;
    dealership = null;
    dealership_id = null;
    description = null;
    id = null;
    is_default = false;
    props = {};
    questions = [];
    title = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.date = props.date && moment(props.date);
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.dealership_id = props.dealership_id;
        this.description = props.description;
        this.id = props.id;
        this.is_default = props.is_default;
        this.props = props.props || {};
        this.questions = props.questions ? props.questions.map(question => new TemplateQuestionClass().create(question)) : [];
        this.title = props.title;
        return this;
    }

    open = () => {
        this.edits = {
            dealership: this.dealership,
            description: this.description,
            props: this.props,
            questions: this.questions,
            title: this.title
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.dealership = this.edits.dealership;
        this.title = this.edits.title;
        this.description = this.edits.description;
        this.props = this.edits.props;
        this.questions = this.edits.questions;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, questions } = await Request.post(utils, '/dealerships/', {
                    type: 'new_feedback_template',
                    ...this.toJSON(this.edits)
                });
                this.close();
                this.id = id;
                this.questions = questions ? questions.map(question => new TemplateQuestionClass().create(question)) : [];

                utils.content.fetch('feedback_template');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { questions } = await Request.post(utils, '/dealerships/', {
                    type: 'update_feedback_template',
                    id: this.id,
                    ...this.toJSON(this.edits)
                });
                this.close();
                this.questions = questions ? questions.map(question => new TemplateQuestionClass().create(question)) : [];

                utils.content.update({
                    type: 'feedback_template',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership ? target.dealership.id : null,
            description: target.description,
            props: target.props,
            questions: target.questions && target.questions.map(question => ({
                dealership_id: question.dealership_id,
                id: question.id,
                order_index: question.order_index,
                props: question.props,
                required: question.required,
                title: question.title,
                type: question.type
            })),
            title: target.title
        }
    }
}

class TemplateQuestionClass {

    id = null;
    required = null;
    type = null;
    title = null;
    props = {};
    dealership_id = null;
    order_index = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.required = props.required;
        this.type = props.type;
        this.title = props.title;
        this.props = props.props || {};
        this.dealership_id = props.dealership_id;
        this.order_index = props.order_index;
        return this;
    }
}

class FeedbackResponseClass {

    id = null;
    title = null;
    full_name = null;
    start_date = null;
    demo_id = null;
    dealership_id = null;
    template_id = null;
    date = null;
    props = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.full_name = props.full_name;
        this.start_date = props.start_date ? moment(props.start_date) : null;
        this.demo_id = props.demo_id;
        this.dealership_id = props.dealership_id;
        this.template_id = props.template_id;
        this.date = props.date ? moment(props.date) : null;
        this.props = props.props;
        return this;
    }
}

export default {
    Template: {
        create: props => new TemplateClass().create(props),
        new: () => new TemplateClass(),
        Question: {
            create: props => new TemplateQuestionClass().create(props),
            new: () => new TemplateQuestionClass(),
            type: {
                list: 1,
                checkboxes: 2,
                picker: 3,
                textfield: 4,
                textview: 5
            }
        }
    },
    Response: {
        create: props => new FeedbackResponseClass().create(props),
        new: () => new FeedbackResponseClass()
    }
}
