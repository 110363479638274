import Abstract from 'classes/Abstract.js';
class ContentManager {

    subscribers = {};

    fetch = type => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onFetch } = subscriber.callbacks;
            if(typeof(onFetch) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                let emitter_types = Array.isArray(type) ? type : [ type ];
                if(emitter_types.includes(subscriber_type)) {
                    onFetch(subscriber_type);
                }
            })
        })
    }
    set = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onSet } = subscriber.callbacks;
            if(typeof(onSet) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onSet(Abstract.create({ ...abstract }));
                }
            })
        })
    }
    exists = async (type, id) => {
        return new Promise(async (resolve, reject) => {
            try {
                let subscribers = Object.values(this.subscribers).filter(subscriber => {
                    // check for valid callback for subscriber
                    let { onFetch } = subscriber.callbacks;
                    if(typeof(onFetch) !== 'function') {
                        return false;
                    }

                    // find types for subscriber
                    // subscriber type could be string of array of strings
                    let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
                    subscriber_types.forEach(subscriber_type => {

                        // find types for emitter
                        // emitter type could be string of array of strings
                        let emitter_types = Array.isArray(type) ? type : [ type ];
                        if(emitter_types.includes(subscriber_type)) {
                            return true;
                        }
                    })
                    return false;
                });

                if(subscribers.length === 0) {
                    resolve(null);
                }
                for(var i in subscribers) {
                    let object = await subscribers[i].callbacks.onExists(type, id);
                    if(object) {
                        resolve(object);
                        return;
                    }
                }
                resolve(null);

            } catch(e) {
                reject(e);
            }
        })
        return this;
    }
    dealership = {
        set: (abstract, dealership) => {
            Object.values(this.subscribers).forEach(subscriber => {

                // check for valid callback for subscriber
                let { onSetDealership } = subscriber.callbacks;
                if(typeof(onSetDealership) !== 'function') {
                    return;
                }

                // find types for subscriber
                // subscriber type could be string of array of strings
                let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
                subscriber_types.forEach(subscriber_type => {

                    // find types for emitter
                    // emitter type could be string of array of strings
                    if(abstract.type === subscriber_type) {
                        onSetDealership(Abstract.create({ ...abstract }), dealership);
                    }
                })
            })
        }
    }
    update = (abstract, category) => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onUpdate } = subscriber.callbacks;
            if(typeof(onUpdate) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onUpdate(Abstract.create({ ...abstract }), category);
                }
            })
        })
    }
    upsert = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onUpsert } = subscriber.callbacks;
            if(typeof(onUpsert) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onUpsert(Abstract.create({ ...abstract }));
                }
            })
        })
        return this;
    }
    refresh = type => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onRefresh } = subscriber.callbacks;
            if(typeof(onRefresh) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                let emitter_types = Array.isArray(type) ? type : [ type ];
                if(emitter_types.includes(subscriber_type)) {
                    onRefresh(subscriber_type);
                }
            })
        })
    }
    remove = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onRemove } = subscriber.callbacks;
            if(typeof(onRemove) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onRemove(Abstract.create({ ...abstract }));
                }
            })
        })
    }

    subscribe = (id, type, callbacks) => {
        this.subscribers[id] = {
            type: type,
            callbacks: callbacks
        };
    }

    unsubscribe = (id) => {
        delete this.subscribers[id];
    }
}
export default {
    new: () => new ContentManager(),
    sorting: {
        type: {
            alphabetically: 1,
            ascending: 2,
            descending: 3,
            status: 4
        }
    }
};
