import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Layer from 'structure/Layer.js';
import Utils from 'files/Utils.js';

const EditTarget = ({ description, children, id, layerProps = {}, onCommit, onValidate, title, useReturnKey }, { index, options, utils }) => {

    const layerID = id || 'edit_target';
    const [layerState, setLayerState] = useState(null);

    const onDoneClick = async () => {
        try {
            if(typeof(onValidate) === 'function') {
                await onValidate();
            }
            setLayerState('close');
            if(typeof(onCommit) === 'function') {
                onCommit();
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: e.message || 'An unknown error occurred'
            })
        }
    }

    const onKeydown = async e => {
        try {
            if(useReturnKey === false) {
                return;
            }
            if(e.keyCode === 13) {
                await Utils.sleep(0.25);
                onDoneClick();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onDoneClick,
            text: 'Done'
        }];
    }


    useEffect(() => {
        document.body.addEventListener('keydown', onKeydown)
        return () => {
            document.body.removeEventListener('keydown', onKeydown)
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        utils={utils}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState,
            sizing: layerProps.sizing || 'small'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.title(),
                    marginBottom: 4
                }}>{title}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 15,
                    whiteSpace: 'normal'
                }}>{description}</span>
                {children}
            </div>
        </Layer>
    )
}
export default EditTarget;
