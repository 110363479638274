import moment from 'moment-timezone';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';

class UserClass {

    active = null;
    address = null;
    avatar = null;
    dealership = null;
    dealership_id = null;
    date = null;
    email_address = null;
    first_name = null;
    full_name = null;
    gdl_active = null;
    last_login = null;
    last_name = null;
    level = null;
    location = null;
    next_demo = null;
    pay_rates = null;
    permissions = null;
    phone_number = null;
    spouse = null;
    timezone = moment.tz.guess();
    token = null;
    user_id = null;
    username = null;

    constructor() {
        return this;
    }

	create = (props = {}) => {
        this.active = props.active;
		this.address = props.address;
		this.avatar = props.avatar;
		this.date = props.date;
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.dealership_id = props.dealership_id;
        this.email_address = props.email_address;
		this.first_name = props.first_name;
		this.full_name = props.full_name;
        this.gdl_active = props.gdl_active;
        this.last_login = props.last_login;
		this.last_name = props.last_name;
        this.level = props.level;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.next_demo = props.next_demo && moment(props.next_demo);
		this.password = props.password;
        this.pay_rates = props.pay_rates;
        this.permissions = props.permissions;
		this.phone_number = props.phone_number;
        this.spouse = props.spouse;
        this.timezone = props.timezone;
		this.token = props.token;
		this.user_id = props.user_id;
		this.username = props.username;
        return this;
	}

    open = () => {
        this.edits = {
            address: this.address,
            dealership: this.dealership,
            email_address: this.email_address,
            first_name: this.first_name,
            full_name: this.full_name,
            last_name: this.last_name,
            level: this.level,
            location: this.location,
            password: this.password,
            pay_rates: this.pay_rates,
            phone_number: this.phone_number,
            spouse: this.spouse,
            timezone: this.timezone,
            username: this.username
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props,
            avatar: props.image ? props.image.data : this.edits.avatar,
            tmpImage: props.image ? props.image : this.edits.tmpImage
        }
        return this.edits;
    }

    close = () => {
        this.address = this.edits.address;
        this.avatar = this.edits.avatar;
        this.dealership = this.edits.dealership;
        this.email_address = this.edits.email_address;
        this.first_name = this.edits.first_name;
        this.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
        this.last_name = this.edits.last_name;
        this.level = this.edits.level;
        this.location = this.edits.location;
        this.pay_rates = this.edits.pay_rates;
        this.phone_number = this.edits.phone_number;
        this.spouse = this.edits.spouse;
        this.timezone = this.edits.timezone;
        this.username = this.edits.username;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { user_id } = await Request.post(utils, '/users/', {
                    type: 'new',
                    ...this.toJSON(this.edits),
                    ...props
                });
                this.close();
                this.user_id = user_id;
                utils.content.fetch('user');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let fetch = this.level !== this.edits.level;
                await Request.post(utils, '/users/', {
                    type: 'update',
                    user_id: this.user_id,
                    ...this.toJSON(this.edits)
                });

                this.close();
                if(fetch) {
                    utils.content.fetch('user');
                    resolve();
                    return;
                }
                utils.content.update({
                    type: 'user',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            address: target.address,
            dealership_id: target.dealership ? target.dealership.id : null,
            email_address: target.email_address,
            first_name: target.first_name,
            full_name: target.full_name,
            last_name: target.last_name,
            level: target.level,
            location: target.location && {
                lat: target.location.latitude,
                long: target.location.longitude
            },
            password: target.password,
            pay_rates: target.pay_rates,
            phone_number: target.phone_number,
            spouse: target.spouse ? target.spouse.user_id : null,
            timezone: target.timezone,
            username: target.username
        }
    }
}

export const fetchUser = async (utils, userID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { user } = await Request.get(utils, '/users/', {
                type: 'details',
                user_id: userID
            });
            resolve(new UserClass().create(user));
        } catch(e){
            reject(e);
        }
    })
}

class UserGroupClass {

    active = null;
    category = null;
    date = null;
    dealership = null;
    description = null;
    id = null;
    levels = [];
    group_type = null;
    props = {};
    title = null;
    users = []; // internal use only
    user_ids = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.category = props.category;
        this.date = props.date;
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.description = props.description;
        this.group_type = props.group_type;
        this.id = props.id;
        this.levels = props.levels;
        this.props = props.props;
        this.title = props.title;
        this.user_ids = props.user_ids;
        return this;
    }

    open = () => {
        this.edits = {
            category: this.category,
            dealership: this.dealership,
            description: this.description,
            group_type: this.group_type,
            levels: this.levels || [],
            props: this.props || {},
            title: this.title,
            users: this.users
        }
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.category = this.edits.category;
        this.dealership = this.edits.dealership;
        this.description = this.edits.description;
        this.group_type = this.edits.group_type;
        this.levels = this.edits.levels;
        this.props = this.edits.props;
        this.title = this.edits.title;
        this.users = this.edits.users;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { category, id } = await Request.post(utils, '/users/', {
                    type: 'new_group',
                    ...this.toJSON(this.edits)
                });
                this.close();
                this.id = id;
                this.category = category;
                utils.content.fetch('user_group');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/users/', {
                    type: 'update_group',
                    id: this.id,
                    ...this.toJSON(this.edits)
                });

                this.close();
                utils.content.update({
                    type: 'user_group',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership ? target.dealership.id : null,
            title: target.title,
            description: target.description,
            group_type: target.group_type ? target.group_type.code : null,
            category: this.id ? target.category.code : target.category,
            user_ids: target.users ? target.users.map(user => user.user_id) : target.user_ids,
            levels: target.levels,
            props: target.props
        }
    }
}

const levels = {
    system_admin: 1,
    admin: 2,
    region_director: 3000,
    division_director: 3250,
    area_director: 3500,
    dealer: 4000,
    booking_coordinator: 4250,
    marketing_director: 4251,
    safety_advisor: 5000,
    safety_associate: 7000,
    customer: 9999
}

const levelToText = code => {
    switch(code) {
        case levels.system_admin:
        return 'System Admin';

        case levels.admin:
        return 'Admin';

        case levels.region_director:
        return 'Region Director';

        case levels.division_director:
        return 'Division Director';

        case levels.area_director:
        return 'Area Director';

        case levels.dealer:
        return 'Dealer';

        case levels.booking_coordinator:
        return 'Booking Coordinator';

        case levels.marketing_director:
        return 'Marketing Director';

        case levels.safety_advisor:
        return 'Safety Advisor';

        case levels.safety_associate:
        return 'Safety Associate';

        case levels.customer:
        return 'Customer';

        default:
        return 'Unknown account type';
    }
}

export default {
    new: () => new UserClass(),
    get: (utils, userID) => fetchUser(utils, userID),
    create: props => new UserClass().create(props),
    Group: {
        new: () => new UserGroupClass(),
        create: props => new UserGroupClass().create(props),
        types: {
            levels: 1,
            user_ids: 2
        },
        categories: {
            demos: 1,
            demo_requests: 2,
            leads: 3,
            users: 4
        }
    },
    levels: {
        get: () => levels,
        toText: levelToText
    }
};
