import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { getIcon } from 'views/TextField.js';
import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

const DateDurationPickerField = ({ fieldStyle, format, icon, maxHour, minHour, onChange, overrideAlerts, placeholder, requireInteraction, selected, style, utils }) => {

    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [timeOfDay, setTimeOfDay] = useState(null);
    const [times, setTimes] = useState([...new Array(144)].map((_, index) => moment().startOf('day').add(index * (1/12), 'hours').format('h:mm')));

    const onDateChange = date => {
        let formatted = date.format('YYYY-MM-DD');
        setDate(formatted);
    }

    const onFieldClick = () => {
        utils.datePicker.show({
            dateTime: false,
            date: date ? moment(date) : moment(),
            onDateChange: onDateChange,
            overrideAlerts: overrideAlerts
        });
    }

    const onFormatDate = () => {
        if(!date) {
            return placeholder || 'Select a date...';
        }
        return moment(date).format(format || 'MMM Do, YYYY');
    }

    const onParseDateTime = () => {
        if(!date || !time || !timeOfDay) {
            return;
        }
        let nextDate = moment(`${date} ${time}:00${timeOfDay}`, 'YYYY-MM-DD hh:mm:ssa');
        if(typeof(onChange) === 'function') {
            onChange(nextDate);
        }
    }

    const onSetRestrictions = () => {
        if(!minHour && !maxHour) {
            return;
        }

        let current = moment().startOf('day').add(minHour || 0, 'hours').unix();
        let min = moment().startOf('day').add(minHour || 0, 'hours').unix();
        let max = moment().startOf('day').add(maxHour || 23, 'hours').unix();
        setTimes(() => {
            let times = [];
            while(current <= max) {
                let next = moment.unix(current);
                if(next.format('a') === timeOfDay) {
                    times.push(next.format('h:mm'));
                }
                current = next.add(5, 'minutes').unix();
            }
            return times;
        })
    }

    const getIconComponent = () => {
        let val = getIcon(icon || 'calendar');
        if(val) {
            return (
                <div style={{
                    paddingRight: 8,
                    marginLeft: 4
                }}>
                    <i
                    className={val}
                    style={{
                        marginRight: 8,
                        color: Appearance.colors.icon(),
                        fontSize: 13
                    }}/>
                </div>
            )
        }
        return null;
    }

    const getTextColor = () => {
        return date ? Appearance.colors.text() : Appearance.colors.subText();
    }

    const setupTarget = () => {

        // prepare target dates
        let target = selected ? moment(selected) : moment();
        let next = Utils.conformDate(target, 5);

        // set time of day regardless of interaction flag
        let tod = next.format('a');
        setTimeOfDay(tod);

        // prevent date and time set if interaction is required
        if(!selected && requireInteraction) {
            return;
        }

        // set date and time values
        setDate(next.format('YYYY-MM-DD'));
        setTime(next.format('h:mm'));
    }

    useEffect(() => {
        onParseDateTime();
    }, [date, time, timeOfDay]);

    useEffect(() => {
        onSetRestrictions();
    }, [timeOfDay])

    useEffect(() => {
        setupTarget();
    }, [selected]);

    return (
        <div
        className={'row p-0 m-0'}
        style={{
            width: '100%'
        }}>
            <div className={'col-12 col-md-6 p-0'}>
                <div
                onClick={onFieldClick}
                className={`dummy-field ${window.theme} cursor-pointer`}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexGrow: 1,
                    position: 'relative',
                    height: 35,
                    border: `1px solid ${Appearance.colors.divider()}`,
                    backgroundColor: Appearance.colors.textField(),
                    borderColor: Appearance.colors.divider(),
                    ...style
                }}>
                    {getIconComponent()}
                    <span style={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: getTextColor(),
                        width: '100%',
                        flexGrow: 1,
                        ...fieldStyle
                    }}>{onFormatDate()}</span>
                </div>
            </div>
            <div className={'col-12 col-md-3 p-0 px-md-1'}>
                <select
                className={`custom-select ${window.theme}`}
                value={time || 'Time'}
                onChange={e => {
                    let index = Utils.attributeForKey.select(e, 'id');
                    setTime(times[index]);
                }}
                style={{
                    marginRight: 8,
                    color: time ? Appearance.colors.text() : Appearance.colors.subText()
                }}>
                    <option disabled={true}>{'Time'}</option>
                    {times.map((time, index) => {
                        return <option key={index} id={index}>{time}</option>
                    })}
                </select>
            </div>
            <div className={'col-12 col-md-3 p-0'}>
                <select
                className={`custom-select ${window.theme}`}
                value={timeOfDay ? timeOfDay.toUpperCase() : 'AM'}
                onChange={e => {
                    let text = Utils.attributeForKey.select(e, 'id');
                    setTimeOfDay(text);
                }}
                style={{
                    marginRight: 8
                }}>
                    {[ 'am', 'pm' ].map((text, index) => {
                        return <option key={index} id={text}>{text.toUpperCase()}</option>
                    })}
                </select>
            </div>
        </div>
    )
}
export default DateDurationPickerField;
