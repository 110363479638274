import React, { useEffect, useRef, useState } from 'react'

import { findDOMNode } from 'react-dom';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import DatePickerField from 'views/DatePickerField.js';
import { DragSource, DropTarget } from 'react-dnd';
import Utils from 'files/Utils.js';

export const SchedulerEventHeight = 50;
export const SchedulerTypes = {
    standard: 'standard'
}

const SchedulerComponent = ({ date, endHour = 24, events, minuteSteps = 30, onClick, onDateChange, onNew, operatingHours, onRenderEvent, onRenderResource, onUpdate, options = {}, resources, resourceWidth = 250, startHour = 5, style, utils }) => {

    const container = useRef(null);
    const creatingProps = useRef(null);
    const deafultSlotBlockWidth = 50;
    const hourTargets = useRef({ end: endHour, start: startHour });
    const resizeProps = useRef(null);
    const sizeTimeout = useRef(null);
    const target = useRef(null);
    const timeline = useRef(null);

    const [creating, setCreating] = useState(false);
    const [createdItems, setCreatedItems] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endingHour, setEndingHour] = useState(endHour);
    const [eventsMap, setEventsMap] = useState({});
    const [headers, setHeaders] = useState([]);
    const [resizing, setResizing] = useState(false);
    const [resizeLeft, setResizeLeft] = useState(null);
    const [resizeWidth, setResizeWidth] = useState(0);
    const [showResize, setShowResize] = useState(false);
    const [slotBlocks, setSlotBlocks] = useState([]);
    const [slotBlockWidth, setSlotBlockWidth] = useState(50);
    const [startDate, setStartDate] = useState(null);
    const [startingHour, setStartingHour] = useState(startHour);
    const [targetDate, setTargetDate] = useState(date || moment());
    const [timeslots, setTimeslots] = useState([]);
    const [timeslotWidth, setTimeslotWidth] = useState(0);
    const [unassignedEvents, setUnassignedEvents] = useState([]);

    const onEventClick = (evt, mouseEvent) => {
        if(resizing === false && typeof(onClick) === 'function') {
            onClick(evt, mouseEvent);
        }
    };

    const onEventMove = ({ evt, is_resource, offset, resource_id }) => {

        // events that have been dropped onto a resource
        // start date and end date are not changed
        if(is_resource) {
            if(typeof(onUpdate) === 'function') {
                onUpdate({
                    prev_evt: evt,
                    next_evt: {
                        ...evt,
                        resource_id: resource_id
                    }
                });
            }
            return;
        }

        // events that have been dropped from outside of the timeline
        // start date and end date inherit the start and end times from the timeline
        if(!evt.resource_id) {
            let rect = timeline.current.getBoundingClientRect();
            offset = {
                x: offset.x - rect.x,
                y: offset.y - rect.y
            }

            let prev_duration = moment(evt.end).unix() - moment(evt.start).unix();
            let duration_offset = ((offset.x / slotBlockWidth) * minuteSteps) * 60;
            let start = Utils.conformDate(moment(evt.start).startOf('day').add(startingHour, 'hours').add(duration_offset, 'seconds'), minuteSteps, true);
            let end = Utils.conformDate(moment(start).add(prev_duration, 'seconds'), minuteSteps, true);

            if(typeof(onUpdate) === 'function') {
                onUpdate({
                    prev_evt: evt,
                    next_evt: {
                        ...evt,
                        start: start.format('YYYY-MM-DD HH:mm:ss'),
                        end: end.format('YYYY-MM-DD HH:mm:ss'),
                        resource_id: resource_id
                    }
                });
            }
            return;
        }

        // calculate timing change
        let prev_duration = moment(evt.end).unix() - moment(evt.start).unix();
        let duration_offset = ((offset.x / slotBlockWidth) * minuteSteps) * 60;

        // generate next dates and conform dates to minute steps
        let start = Utils.conformDate(moment(evt.start).add(duration_offset, 'seconds'), minuteSteps, true);
        let end = Utils.conformDate(moment(evt.end).add(duration_offset, 'seconds'), minuteSteps, true);
        if(typeof(onUpdate) === 'function') {
            onUpdate({
                prev_evt: evt,
                next_evt: {
                    ...evt,
                    start: start.format('YYYY-MM-DD HH:mm:ss'),
                    end: end.format('YYYY-MM-DD HH:mm:ss'),
                    resource_id: resource_id
                }
            });
        }
    }

    const onMouseMove = evt => {

        // get rect size and offset within rect
        let rect = evt.target.getBoundingClientRect();
        let x = evt.clientX - rect.left;

        // set flag for cursor style if cursor is within the resize zone
        let offset = Math.abs(rect.width - x);
        setShowResize(x < 5 || offset < 5);
    }

    const onRenderEventComponent = (evt, props = {}) => {
        if(typeof(onRenderEvent) === 'function') {
            return onRenderEvent(evt, props);
        }
        return (
            <div
            {...props}
            style={{
                backgroundColor: evt.color || Appearance.colors.grey(),
                borderRadius: 25,
                overflow: 'hidden',
                width: evt.width
            }}>
                <span style={{
                    ...Appearance.textStyles.title(),
                    color: 'white',
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{evt.title || 'Title Not Available'}</span>
            </div>
        )
    }

    const onRenderResourceComponent = (resource, index, resources) => {
        if(typeof(onRenderResource) === 'function') {
            return onRenderResource(resource, index, resources)
        }
        return (
            <span style={{
                ...Appearance.textStyles.title(),
                flexGrow: 1
            }}>{resource.title}</span>
        )
    }

    const onResizeEnd = async evt => {

        // prevent default click event
        evt.preventDefault();
        evt.stopPropagation();

        // set ref and document listeners
        target.current = null;
        document.body.removeEventListener('mousemove', onResizeMouseMove);
        document.body.removeEventListener('mouseup', onResizeEnd);

        // calculate duration change and notify listeners of change
        if(typeof(onUpdate) === 'function') {

            let { direction, end, end_width, evt, ratio, start, start_width } = resizeProps.current || {};
            let seconds = (end_width * ratio) - (start_width * ratio);

            // add seconds offset to the ending date if direction is set to "right"
            if(direction === 'right') {

                // prepare new date using requested offset
                let offset = moment(end).add(seconds, 'seconds');
                let date = Utils.conformDate(offset, minuteSteps, true);

                // notify subsribers that data has changed
                onUpdate({
                    prev_evt: evt,
                    next_evt: {
                        ...evt,
                        end: date.format('YYYY-MM-DD HH:mm:ss')
                    }
                });
            }

            // subtract seconds offset to the starting date if direction is set to "left"
            if(direction === 'left') {

                // prepare new date using requested offset
                let offset = moment(start).subtract(seconds, 'seconds');
                let date = Utils.conformDate(offset, minuteSteps, true);

                // notify subsribers that data has changed
                onUpdate({
                    prev_evt: evt,
                    next_evt: {
                        ...evt,
                        start: date.format('YYYY-MM-DD HH:mm:ss')
                    }
                });
            }
        }

        // reset resizing props with timeout for standard click callback
        try {
            await Utils.sleep(0.25);
            setResizing(false);
            setShowResize(false);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onResizeMouseMove = evt => {

        // prevent default click event
        evt.preventDefault();
        evt.stopPropagation();

        // get redizing props and rect size
        let { direction, start_left, start_width } = resizeProps.current || {};
        let rect = target.current.getBoundingClientRect();

        // set width if right side of the event is being dragged
        if(direction === 'right') {
            let x = evt.clientX - rect.left;
            setResizeWidth(x);
        }

        // set left offset and width if left side of the event is being dragged
        if(direction === 'left') {
            let x = evt.clientX - (rect.right - start_width);
            setResizeLeft(start_left + x);
            setResizeWidth(start_width - x);
        }
    }

    const onResizingStart = (entry, evt) => {

        // get rect size and offset within rect
        let rect = evt.target.getBoundingClientRect();
        let x = evt.clientX - rect.left;

        // prevent default click event if mouse is within resize zone
        if(x < 5 || (rect.width - x) < 5) {
            evt.preventDefault();
            evt.stopPropagation();

            // set resizing props
            setResizeLeft(entry.style.left);
            setResizeWidth(entry.style.width);
            setResizing({
                ...entry,
                direction: x < 5 ? 'left' : 'right'
            });

            // set ref and document listeners
            target.current = evt.target;
            document.body.addEventListener('mousemove', onResizeMouseMove);
            document.body.addEventListener('mouseup', onResizeEnd);
        }
    }

    const onTargetDateChange = date => {
        setTargetDate(date);
        if(typeof(onDateChange) === 'function') {
            onDateChange(date);
        }
    }

    const onTimeslotMouseDown = (rowIndex, blockIndex, evt) => {
        if(options.creatable === false) {
            return;
        }

        // store block indexes in array for better performance
        setCreating(rowIndex);
        setCreatedItems({
            min: blockIndex,
            max: blockIndex,
            indexes: [ blockIndex ]
        });

        // set ref and document listeners
        document.body.addEventListener('mouseup', onTimeslotMouseUp);
    }

    const onTimeslotMouseEnter = (rowIndex, blockIndex, evt) => {

        let { row_index, selection } = creatingProps.current || {};

        // prevent moving foward if row indexes dont match
        if(rowIndex !== row_index) {
            return;
        }

        // update selected blocks
        setCreatedItems(() => {

            let next_indexes = selection.indexes.filter(index => index !== blockIndex);
            if(!next_indexes.includes(blockIndex)) {
                next_indexes.push(blockIndex);
            }
            return update(selection, {
                items: {
                    $set: next_indexes
                },
                min: {
                    $set: Math.min(...next_indexes)
                },
                max: {
                    $set: Math.max(...next_indexes)
                }
            });
        });
    }

    const onTimeslotMouseUp = evt => {

        // remove document listeners
        document.body.removeEventListener('mouseup', onTimeslotMouseUp);

        // find duration, start, and end date of new creation
        let { row_index, selection } = creatingProps.current || {};
        if(selection && selection.min >= 0 && selection.max >= 0) {
            let duration = (((selection.max - selection.min) + 1) * minuteSteps) * 60;
            let start_offset = (selection.min * minuteSteps) * 60;
            let start = moment.unix(startDate).add(start_offset, 'seconds');
            let end = moment(start).add(duration, 'seconds');

            if(typeof(onNew) === 'function') {
                onNew({
                    id: resources[row_index].user_id,
                    end: end.format('YYYY-MM-DD HH:mm:ss'),
                    start: start.format('YYYY-MM-DD HH:mm:ss')
                });
            }
        }

        // reset creating props
        setCreating(null);
        setCreatedItems(null);
    }

    const onUpdateAutoSizing = () => {

        if(!container.current) {
            return;
        }
        // calculate size of each block based on the visible size of the scheduler if applicable
        let { end, start } = hourTargets.current;
        let containerWidth = container.current.clientWidth;
        let operatingWidth = ((end - start) + 1) * ((60 / minuteSteps) * deafultSlotBlockWidth);
        if(operatingWidth < containerWidth) {
            let blockWidth = ((containerWidth - resourceWidth) / ((end - start) + 1)) / (60 / minuteSteps);
            setSlotBlockWidth(blockWidth);
            return;
        }

        setSlotBlockWidth(deafultSlotBlockWidth);
        setupTimelines();
    }

    const onUpdateEndHour = () => {
        if(!operatingHours || !operatingHours.end) {
            setStartingHour(endHour || 24);
            return;
        }
        // subtract one hour to ensure that an overflow column is not rendered
        // otherwise setting 10:00pm as the end time renders a column starting at 10:00pm and ending at 11:00pm
        let hour = parseInt(moment(operatingHours.end, 'HH:mm:ss').format('H'));
        setEndingHour(hour === 0 ? 23 : hour - 1);
    }

    const onUpdateStartHour = () => {
        if(!operatingHours || !operatingHours.start) {
            setStartingHour(startHour || 5);
            return;
        }
        setStartingHour(parseInt(moment(operatingHours.start, 'HH:mm:ss').format('H')));
    }

    const onWindowSizeChange = () => {
        if(sizeTimeout.current) {
            clearTimeout(sizeTimeout.current);
        }
        sizeTimeout.current = setTimeout(onUpdateAutoSizing, 250);
    }

    const getCalendarComponent = () => {
        return (
            <div
            ref={container}
            style={{
                ...Appearance.styles.unstyledPanel(),
                border: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                width: '100%'
            }}>
                {getResources()}
                {getTimelines()}
            </div>
        )
    }

    const getDateSelector = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 12
            }}>
                <DatePickerField
                onDateChange={onTargetDateChange}
                selected={targetDate}
                utils={utils}
                style={{
                    flexGrow: 1
                }}/>
            </div>
        )
    }

    const getEvents = resource => {

        let targets = eventsMap[resource.user_id] || [];
        return targets.map((entry, index) => {
            let isResizing = resizing && resizing.evt.id === entry.evt.id;
            return (
                <SchedulerEvent
                key={index}
                evt={entry.evt}
                draggable={options.moveable !== false}
                onEventMove={onEventMove}>
                    {onRenderEventComponent(entry.evt, {
                        key: index,
                        className: showResize ? 'cursor-ewresize' : 'text-button',
                        onMouseDown: onResizingStart.bind(this, entry),
                        onMouseMove: onMouseMove,
                        onClick: onEventClick.bind(this, entry.evt),
                        style: {
                            position: 'absolute',
                            zIndex: index + 1,
                            top: 0,
                            paddingLeft: 0,
                            ...entry.style,
                            ...isResizing && {
                                width: resizeWidth,
                                left: resizeLeft
                            }
                        }
                    })}
                </SchedulerEvent>
            )
        })
    }

    const getResources = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    borderLeft: `0.5px solid ${Appearance.colors.divider()}`,
                    borderRight: `0.5px solid ${Appearance.colors.divider()}`,
                    borderTop: `0.5px solid ${Appearance.colors.divider()}`,
                    maxWidth: resourceWidth,
                    minWidth: resourceWidth,
                    padding: '8px 12px 8px 12px',
                    textAlign: 'center'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.subText()
                    }}>{options.headerLabel || 'Resource'}</span>
                </div>
                {resources.map((resource, index, resources) => {
                    return (
                        <SchedulerRow
                        key={index}
                        resource={resource}
                        isResource={true}
                        style={{
                            alignItems: 'center',
                            borderBottom: index !== resources.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                            display: 'flex',
                            flexDirection: 'row',
                            flexGrow: 1,
                            height: SchedulerEventHeight,
                            padding: '0px 2px 0px 2px',
                            maxHeight: SchedulerEventHeight,
                            maxWidth: resourceWidth,
                            minWidth: resourceWidth,
                            overflow: 'hidden',
                            width: '100%'
                        }}>
                            {onRenderResourceComponent(resource, index, resources)}
                        </SchedulerRow>
                    )
                })}
            </div>
        )
    }

    const getTimelines = () => {
        return (
            <div
            className={`scheduler-view ${window.theme}`}
            style={{
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'scroll',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                }}>
                    {headers.map((header, index) => {
                        return (
                            <div
                            key={index}
                            style={{
                                border: `0.5px solid ${Appearance.colors.divider()}`,
                                maxWidth: timeslotWidth,
                                minWidth: timeslotWidth,
                                padding: '8px 12px 8px 12px',
                                textAlign: 'center'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    color: Appearance.colors.subText()
                                }}>{header}</span>
                            </div>
                        )
                    })}
                </div>
                <div ref={timeline}>
                    {resources.map((resource, rowIndex) => {
                        return (
                            <div
                            key={rowIndex}
                            style={{
                                flexGrow: 1,
                                position: 'relative'
                            }}>
                                <SchedulerRow
                                resource={resource}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: SchedulerEventHeight,
                                    width: '100%'
                                }}>
                                    {timeslots.map((_, index) => {
                                        return (
                                            <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                height: SchedulerEventHeight,
                                                maxWidth: timeslotWidth,
                                                minWidth: timeslotWidth
                                            }}>
                                                {getTimeslotZones(rowIndex, index)}
                                            </div>
                                        )
                                    })}
                                </SchedulerRow>
                                {getEvents(resource)}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const getTimeslotZones = (rowIndex, slotIndex) => {
        return slotBlocks.map((_, index) => {
            let blockIndex = ((60 / minuteSteps) * slotIndex) + index;
            return (
                <div
                blockindex={blockIndex}
                key={index}
                onMouseDown={onTimeslotMouseDown.bind(this, rowIndex, blockIndex)}
                onMouseEnter={creating === rowIndex ? onTimeslotMouseEnter.bind(this, rowIndex, blockIndex) : null}
                rowindex={rowIndex}
                style={{
                    borderBottom: `0.5px solid ${Appearance.colors.divider()}`,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderLeft: `0.5px solid ${Appearance.colors.divider()}`,
                    borderRight: `0.5px solid ${Appearance.colors.divider()}`,
                    borderTop: `0.5px solid ${Appearance.colors.divider()}`,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    height: '100%',
                    maxWidth: slotBlockWidth,
                    minWidth: slotBlockWidth,
                    ...creating === rowIndex && blockIndex >= createdItems.min && blockIndex <= createdItems.max && {
                        backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                        borderBottom: `2px solid ${Appearance.colors.primary()}`,
                        borderBottomLeftRadius: blockIndex === createdItems.min ? 10 : 0,
                        borderBottomRightRadius: blockIndex === createdItems.max ? 10 : 0,
                        borderLeft: blockIndex === createdItems.min ? `2px solid ${Appearance.colors.primary()}` : 'none',
                        borderRight: blockIndex === createdItems.max ? `2px solid ${Appearance.colors.primary()}` : 'none',
                        borderTop: `2px solid ${Appearance.colors.primary()}`,
                        borderTopLeftRadius: blockIndex === createdItems.min ? 10 : 0,
                        borderTopRightRadius: blockIndex === createdItems.max ? 10 : 0,
                    }
                }} />
            )
        });
    }

    const getUnassignedEvents = () => {
        if(!unassignedEvents || unassignedEvents.length === 0) {
            return null;
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                padding: 10,
                marginBottom: 12
            }}>
                {unassignedEvents.map((entry, index) => {
                    return (
                        <SchedulerEvent
                        key={index}
                        evt={entry.evt}
                        draggable={true}
                        onEventMove={onEventMove}>
                            <div
                            onClick={onEventClick.bind(this, entry.evt)}
                            className={'cursor-grab'}
                            style={{
                                padding: 5
                            }}>
                                {onRenderEventComponent(entry.evt, { })}
                            </div>
                        </SchedulerEvent>
                    )
                })}
            </div>
        )
    }

    const setupEvents = () => {

        // no need to setup events if resources are not available
        if(!resources) {
            console.log('no resources')
            return;
        }

        // attach events to their resource and calculate position and offset
        let targets = resources.reduce((object, resource) => {
            object[resource.user_id] = events.filter(evt => {
                return evt.resource_id === resource.user_id && moment(evt.start).isSame(targetDate, 'day');
            }).map(evt => {
                let evtStart = moment(evt.start).unix();
                let timelineStart = moment(evt.start).startOf('day').add(startingHour, 'hours').unix();
                let evtDuration = moment(evt.end).unix() - evtStart;
                let offset = evtStart - timelineStart;
                return {
                    duration: evtDuration,
                    evt: evt,
                    style: {
                        left: (timeslotWidth / 3600) * offset,
                        width: (timeslotWidth / 3600) * evtDuration
                    }
                }
            });
            return object;
        }, {});
        setEventsMap(targets);

        // setup array for unassigned events
        setUnassignedEvents(events.filter(evt => {
            return !evt.resource_id && moment(evt.start).isSame(targetDate, 'day');
        }).map(evt => {
            let evtStart = moment(evt.start).unix();
            let timelineStart = moment(evt.start).startOf('day').add(startingHour, 'hours').unix();
            let evtDuration = moment(evt.end).unix() - evtStart;
            let offset = evtStart - timelineStart;
            return {
                duration: evtDuration,
                evt: {
                    ...evt,
                    show_dates: true
                },
                style: {
                    width: (timeslotWidth / 3600) * evtDuration,
                    left: (timeslotWidth / 3600) * offset
                }
            }
        }).sort((a,b) => {
            return a.start < b.start ? 1 : -1;
        }));
    }

    const setupTimelines = () => {

        if(endingHour === null || startingHour === null) {
            return;
        }

        // setup start and end date for timeline
        let start = moment().startOf('day').add(startingHour, 'hours').unix();
        let end = moment().startOf('day').add(endingHour, 'hours').unix();
        setStartDate(start);
        setEndDate(end);

        // create timeslots and set width
        let index = 0;
        let control = start;
        let timeslots = [];
        while(control < end) {
            control = moment.unix(start).add(index, 'hours').unix();
            timeslots.push(control);
            index++;
        }
        setTimeslots(timeslots);
        setTimeslotWidth((60 / minuteSteps) * slotBlockWidth);

        // set header labels from unix timestamps
        setHeaders(timeslots.map(timeslot => {
            return moment.unix(timeslot).format('h:mma');
        }));

        // set slot blocks
        let val = (60 / minuteSteps);
        setSlotBlocks([ ...Array(val) ].map((_, index) => index));
    }

    useEffect(() => {
        setupEvents();
    }, [endingHour, events, resources, startingHour, targetDate, timeslots]);

    useEffect(() => {
        onUpdateEndHour();
    }, [endHour, operatingHours]);

    useEffect(() => {
        onUpdateStartHour();
    }, [operatingHours, startHour]);

    useEffect(() => {
        if(isNaN(creating)) {
            creatingProps.current = null;
            return;
        }
        creatingProps.current = {
            row_index: creating,
            selection: createdItems
        }
    }, [creating, createdItems]);

    useEffect(() => {
        if(!resizing) {
            resizeProps.current = null;
            return;
        }
        resizeProps.current = {
            direction: resizing.direction,
            end: resizing.evt.end,
            end_width: resizeWidth,
            evt: resizing.evt,
            ratio: resizing.duration / resizing.style.width,
            start: resizing.evt.start,
            start_left: resizing.style.left,
            start_width: resizing.style.width
        }
    }, [resizing, resizeWidth]);

    useEffect(() => {
        hourTargets.current = {
            end: endingHour,
            start: startingHour
        }
        onUpdateAutoSizing();
    }, [endingHour, startingHour]);

    useEffect(() => {
        setupTimelines();
    }, [slotBlockWidth]);

    useEffect(() => {
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...style
        }}>
            {getDateSelector()}
            {getUnassignedEvents()}
            {getCalendarComponent()}
        </div>
    )
}
export default SchedulerComponent;

const schedulerDropTarget = {
    canDrop(props, monitor) {
        return props.droppable !== false;
    },
    hover(props, monitor, component) {
        monitor.isOver({ shallow: true });
    },
    drop(props, monitor, component) {
        if(monitor.didDrop()) {
            return;
        }

        let item = monitor.getItem();
        let offset = item.resource_id ? monitor.getDifferenceFromInitialOffset() : monitor.getClientOffset();
        return {
            moved: true,
            offset: offset,
            resource: props.resource,
            is_resource: props.isResource ? true : false
        }
    }
}

const schedulerEventTarget = {
    canDrag(props) {
        return props.draggable !== false;
    },
    beginDrag(props, monitor, component) {
        monitor.isDragging();
        return props.evt;
    },
    endDrag(props, monitor, component) {
        if(!monitor.didDrop()) {
            return;
        }

        // get item and drop result
        let item = monitor.getItem();
        let result = monitor.getDropResult();

        // return early if item was dropped onto a resource row
        if(result.is_resource) {
            if(typeof(props.onEventMove) === 'function') {
                props.onEventMove({
                    evt: props.evt,
                    is_resource: result.is_resource,
                    resource_id: result.resource.user_id
                });
            }
            return;
        }

        // calculate current rect
        let el = findDOMNode(component).childNodes[0];
        let rect = el.getBoundingClientRect();

        // update offset if no resource id is found
        if(!props.evt.resource_id) {
            result.offset = {
                x: result.offset.x - (rect.width / 2),
                y: result.offset.y - (rect.height / 2)
            }
        }

        // update event
        if(typeof(props.onEventMove) === 'function') {
            props.onEventMove({
                offset: result.offset,
                evt: props.evt,
                resource_id: result.resource.user_id
            });
        }
    }
}

const collectDrop = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType()
    }
}

const collectDrag = (connect, monitor) => {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

class SchedulerEventClass extends React.Component {
    render() {
        let { children, className, connectDragSource, isDragging, style } = this.props;
        return connectDragSource(
            <div
            className={className}
            style={{
                ...style,
                opacity: isDragging ? 0 : 1
            }}>
                {children}
            </div>
        )
    }
}
const SchedulerEvent = DragSource(SchedulerTypes.standard, schedulerEventTarget, collectDrag)(SchedulerEventClass)

class SchedulerRowClass extends React.Component {
    render() {
        let { children, className, connectDropTarget, isOver, isResource, style } = this.props;
        return connectDropTarget(
            <div
            className={className}
            style={{
                ...style,
                ...isOver && {
                    backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                    ...isResource !== true && {
                        border: `2px solid ${Appearance.colors.primary()}`
                    }
                }
            }}>
                {children}
            </div>
        )
    }
}

const SchedulerRow = DropTarget(SchedulerTypes.standard, schedulerDropTarget, collectDrop)(SchedulerRowClass)
