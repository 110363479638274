import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import Program from 'classes/Program.js';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';

const QuestionEditor = ({ onAdd, onClose, onUpdate, style, utils, value }) => {

    const [question, setQuestion] = useState(null);
    const [title, setTitle] = useState(value && value.title);
    const [type, setType] = useState(value && value.type);

    const types = [{
        id: Program.Template.Question.type.file_picker,
        title: 'Document Selector'
    },{
        id: Program.Template.Question.type.list,
        title: 'Dropdown Menu',
        item: 'Dropdown Item'
    },{
        id: Program.Template.Question.type.picker,
        title: 'List of Options',
        item: 'Option'
    },{
        id: Program.Template.Question.type.textview,
        title: 'Long Text Response'
    },{
        id: Program.Template.Question.type.checkboxes,
        title: 'Multiple Checkboxes',
        item: 'Checkbox'
    },{
        id: Program.Template.Question.type.textfield,
        title: 'Short Text Response'
    }];

    const generateID = () => {
        return `${moment().unix()}${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`;
    }
    const generateItemID = () => {
        return moment().unix() + (Math.floor(Math.random() * Math.floor(999999)));
    }

    const onListItemClick = item => {
        utils.sheet.show({
            items: [{
                key: 'edit',
                title: 'Edit',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'edit') {
                onEditListItem(item);
                return;
            }
            if(key === 'remove') {
                onRemoveListItem(item);
                return;
            }
        })
    }

    const onNewListItem = () => {
        utils.alert.show({
            title: 'New Item',
            message: 'What would you like to call this item?',
            textFields: [{
                key: 'title',
                placeholder: 'Title for item...'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: ({ title }) => {
                if(!title) {
                    return;
                }
                let items = question.props.items ? question.props.items : [];
                items.push({
                    id: generateItemID(),
                    title: title
                });

                setQuestion(question => update(question, {
                    props: {
                        $set: {
                            ...question.props,
                            items: items
                        }
                    }
                }));
            }
        })
    }

    const onTextInputChange = text => {
        setQuestion(question => {
            question.props.placeholder = text;
            return question;
        });
    }

    const onEditListItem = item => {
        utils.alert.show({
            title: 'Edit Item',
            message: 'What would you like to call this item?',
            textFields: [{
                key: 'title',
                value: item.title,
                placeholder: 'Title for item...'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: ({ title }) => {
                if(!title) {
                    return;
                }
                let items = question.props.items ? question.props.items : [];
                let index = items.findIndex(prevItem => {
                    return prevItem.id === item.id;
                });
                if(index >= 0) {
                    items[index].title = title;
                }
                setQuestion(question => update(question, {
                    props: {
                        $set: {
                            ...question.props,
                            items: items
                        }
                    }
                }));
            }
        })
    }

    const onRemoveListItem = item => {
        utils.alert.show({
            title: 'Remove Item',
            message: 'Are you sure that you want to remove this item?',
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key !== 'confirm') {
                    return;
                }
                let items = question.props.items ? question.props.items : [];
                setQuestion(question => update(question, {
                    props: {
                        $set: {
                            ...question.props,
                            items: items.filter(prevItem => {
                                return prevItem.id !== item.id;
                            })
                        }
                    }
                }));
            }
        })
    }

    const didStructureChange = () => {
        if(!question || !question.type) {
            return false;
        }
        let { list, checkboxes, picker } = Program.Template.Question.type;
        let lists = [ list, checkboxes, picker ];
        return lists.includes(type) !== lists.includes(question.type)
    }

    const onUpdateQuestion = () => {
        if(question && typeof(onUpdate) === 'function') {
            question.props = { ...question.props }; // force an update for submission
            onUpdate(question);
        }
    }

    const onUpdateTitle = text => {
        setTitle(text);
        setQuestion(question => {
            question.title = text;
            return question;
        });
    }

    const onTypeChange = () => {
        setQuestion(question => update(question, {
            type: {
                $set: type
            },
            props: {
                $set: didStructureChange(question) === false ? question.props : {}
            }
        }))
    }

    const getComponents = () => {

        // no additional logic is required if a type or question are not available
        if(!type || !question) {
            return;
        }

        // use question type to determine which accessory components are required
        switch(type) {
            case Program.Template.Question.type.list:
            case Program.Template.Question.type.checkboxes:
            case Program.Template.Question.type.picker:
            let item = types.find(entry => entry.id === type).item;
            return (
                <LayerItem 
                collapsed={false}
                title={type === Program.Template.Question.type.checkboxes ? 'Checkboxes' : `${item}s`}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {question.props.items && (
                            question.props.items.map((item, index) => {
                                return (
                                    <div
                                    key={index}
                                    className={`view-entry ${window.theme}`}
                                    onClick={onListItemClick.bind(this, item)}
                                    style={{
                                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                                        padding: '8px 12px',
                                        width: '100%'
                                    }}>
                                        <span style={{
                                            ...Appearance.textStyles.subTitle(),
                                            color: Appearance.colors.text()
                                        }}>{item.title}</span>
                                    </div>
                                )
                            })
                        )}
                        <div
                        className={`view-entry ${window.theme}`}
                        onClick={onNewListItem}
                        style={{
                            padding: '8px 12px',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.primary()
                            }}>{`Add New ${types.find(prevType => prevType.id === type).item}`}</span>
                        </div>
                    </div>
                </LayerItem>
            );

            case Program.Template.Question.type.textfield:
            return (
                <LayerItem 
                collapsed={false}
                title={'Placeholder'}>
                    <TextField
                    onChange={onTextInputChange}
                    placeholder={'Type a placeholder for this question...'}/>
                </LayerItem>
            );

            case Program.Template.Question.type.textview:
            return (
                <LayerItem 
                collapsed={false}
                title={'Placeholder'}>
                    <TextView
                    onChange={onTextInputChange}
                    placeholder={'Type a placeholder for this question...'}/>
                </LayerItem>
            );

            default:
            return null;
        }
    }

    const getQuestionType = () => {
        let result = type && types.find(prevType => prevType.id === type);
        return result && result.title;
    }

    const setupQuestion = async () => {
        try {

            // no additional logic is required if a value was provided
            if(value) {
                setQuestion(value);
                return;
            }

            // create new template question and attach requried values
            let question = Program.Template.Question.new();
            question.dealership_id = utils.dealership.get().id;
            question.id = generateID();
            question.required = false;
            question.title = 'New Question';
            question.type = Program.Template.Question.type.list;

            // update local state with question values
            setQuestion(question);
            setTitle(question.title);
            setType(question.type);

            // notify subscribers that a new question has been created
            if(typeof(onAdd) === 'function') {
                onAdd(question);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your question. ${e.message || 'An unknown error occurred'}`,
                onClick: onClose
            });
        }
    }

    useEffect(() => {
        setupQuestion();
    }, [value]);

    useEffect(() => {
        onUpdateQuestion();
    }, [question]);

    useEffect(() => {
        onTypeChange();
    }, [type]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            width: '100%',
            ...style
        }}>
            <LayerItem
            collapsed={false}
            title={'Title'}>
                <TextField
                onChange={onUpdateTitle}
                placeholder={'Type your title here...'}
                value={title}/>
            </LayerItem>
            <LayerItem
            collapsed={false}
            title={'Type'}>
                <ListField
                items={types}
                onChange={item => setType(item && item.id)}
                placeholder={'Choose an question type from the list...'}
                value={getQuestionType()}/>
            </LayerItem>
            <LayerItem
            collapsed={false}
            title={'Require Answer'}>
                <ListField
                items={[{
                    id: 'yes',
                    title: 'Yes'
                },{
                    id: 'no',
                    title: 'No'
                }]}
                onChange={item => {
                    setQuestion(question => {
                        question.required = item && item.id === 'yes' ? true : false;
                        return question;
                    });
                }}
                value={question && question.required ? 'Yes' : 'No'} />
            </LayerItem>
            {getComponents()}
        </div>
    )
}

export default QuestionEditor;

export const QuestionsContainer = SortableContainer(({ onRenderQuestion, questions = [] }) => {
    return (
        <ul style={{
            width: '100%',
            borderRadius: 8,
            overflow: 'hidden',
            margin: 0,
            marginBlock: 0,
            padding: 0
        }}>
            {questions.map((question, index, questions) => (
                <SortableQuestion
                index={index}
                key={`question_${question.id}`}>
                    {onRenderQuestion(question, index, questions)}
                </SortableQuestion>
            ))}
        </ul>
    );
});

const SortableQuestion = SortableElement(({ children }) => {
    return (
        <li style={{
            display: 'block',
            width: '100%',
            backgroundColor: Appearance.colors.layerBackground()
        }}>
            {children}
        </li>
    )
});
