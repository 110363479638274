import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { AddEditLeadScript } from 'managers/Dealerships.js';
import Lead from 'classes/Lead.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

const LeadScriptPickerField = ({ canAddNewTarget = true, containerStyle, defaultValue, onChange, onLoad, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const onNewLeadScript = () => {
        utils.layer.open({
            id: 'new_lead_script',
            abstract: Abstract.create({
                type: 'lead_script',
                object: Lead.Script.new()
            }),
            Component: AddEditLeadScript.bind(this, {
                isNewTarget: true,
                onAddLeadScript: script => {
                    if(typeof(onChange) === 'function') {
                        onChange(script);
                    }
                    setItems(items => update(items, {
                        $push: [ script ],
                        $apply: items => items.sort((a,b) => {
                            return a.text.localeCompare(b.text);
                        })
                    }))
                }
            })
        })
    }

    const getAccessoryButton = () => {
        if(loading === true) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 55,
                    height: 30,
                    minWidth: 55,
                    minHeight: 30,
                    marginLeft: 12,
                    backgroundColor: Appearance.colors.primary(),
                    borderRadius: 15,
                    overflow: 'hidden'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={require('files/lottie/dots-white.json')}
                    style={{
                        width: 25,
                        height: 20,
                        marginBottom: 2
                    }}/>
                </div>
            )
        }

        return canAddNewTarget && (
            <div style={{
                width: 25,
                height: 25,
                minWidth: 25,
                minHeight: 25,
                marginLeft: 8
            }}>
                <img
                className={'text-button'}
                src={'images/plus-button-blue-small.png'}
                onClick={onNewLeadScript}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                }} />
            </div>
        )
    }

    const fetchLeadScripts = async () => {
        try {
            let { lead_scripts } = await Request.get(utils, '/dealerships/', {
                show_inactive: false,
                type: 'lead_scripts'
            });
            setItems(lead_scripts.map(script => Lead.Script.create(script)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead scripts list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(items.length > 0 && typeof(onLoad) === 'function') {
            onLoad(items);
        }
    }, [items]);

    useEffect(() => {
        fetchLeadScripts();
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            ...containerStyle
        }}>
            <ListField
            utils={utils}
            value={value}
            defaultValue={defaultValue}
            items={items}
            onChange={onChange} />
            {getAccessoryButton()}
        </div>
    )
}
export default LeadScriptPickerField;
