import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import { CollapseArrow } from 'structure/Layer';
import { FloatingLayerMenuIndex } from 'structure/Layer.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';

const CustomListField = ({ activePlaceholder, containerStyle, icon, inactivePlaceholder, insetLabel, items, onChange, onRemoveItem, placeholder, utils, value }) => {

    const itemHeight = 35;
    const nonce = useRef(`${moment().unix()}.${Utils.random.string(10)}`);

    const [selected, setSelected] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [text, setText] = useState(null);

    const onBodyClick = evt => {
        if(!evt.target.className || evt.target.className.includes(nonce.current) === false) {
            setShowOptions(false);
        }
    }

    const onFieldClick = evt => {
        if(evt && typeof(evt.stopPropagation) === 'function') {
            evt.stopPropagation();
        }
        setShowOptions(showOptions => !showOptions);
    }

    const onItemClick = option => {

        // declare next selection and determine how to update the state
        let next = selected && selected.id === option.id ? null : option;
        setSelected(next);
        setShowOptions(false);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onRemoveItemClick = (index, evt) => {

        // stop event from propagating to root element
        evt.stopPropagation();

        // close options menu
        setShowOptions(false);

        // notify subscribers of item removal
        if(typeof(onRemoveItem) === 'function') {
            onRemoveItem(items[index]);
        }
    }

    const getAccessoryIcon = (item, index) => {
        if(item.removable) {
            return (
                <img
                className={'text-button'}
                onClick={onRemoveItemClick.bind(this, index)}
                src={'images/red-x-icon.png'}
                style={{
                    height: 15,
                    marginLeft: 8,
                    objectFit: 'contain',
                    width: 15
                }} />
            )
        }
        return (
            <img
            src={'images/next-arrow-grey-small.png'}
            style={{
                height: 12,
                marginLeft: 8,
                objectFit: 'contain',
                opacity: 0.75,
                width: 12
            }} />
        )
    }

    const getPlaceholder = () => {
        if(insetLabel) {
            return null;
        }
        if(showOptions && activePlaceholder) {
            return activePlaceholder;
        }
        if(!showOptions && inactivePlaceholder) {
            return inactivePlaceholder;
        }
        if(placeholder) {
            return placeholder;
        }
        return showOptions ? 'Tap to hide options...' : 'Tap to show options...';
    }

    const getPickerContent = () => {
        if(items.length === 0) {
            return null;
        }
        return showOptions && (
            <div 
            className={nonce.current}
            style={{
                backgroundColor: Appearance.colors.layerBackground(),
                border: `1px solid ${Appearance.colors.softBorder()}`,
                borderRadius: 12,
                boxShadow: '0px 7px 20px rgba(0,0,0,0.1)', 
                marginTop: 8,
                maxHeight: window.innerHeight / 3,
                overflowY: 'scroll',
                position: 'absolute',
                zIndex: FloatingLayerMenuIndex,
                width: '100%'
            }}>
                {items.map((option, index) => {
                    return (
                        <div
                        className={`view-entry ${window.theme} ${nonce.current}`}
                        key={index}
                        onClick={onItemClick.bind(this, option)}>
                            <div style={{
                                alignItems: 'center',
                                borderBottom: index !== items.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: itemHeight,
                                minWidth: 0,
                                paddingLeft: 12,
                                paddingRight: 12,
                                width: '100%'
                            }}>
                                <span 
                                className={nonce.current}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: Appearance.colors.text(),
                                    flexGrow: 1,
                                    ...selected && selected.id === option.id && {
                                        color: Appearance.colors.primary()
                                    }
                                }}>{option.title}</span>
                                {getAccessoryIcon(option, index)}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    useEffect(() => {
        let item = items.find(item => value && item.id === value.id);
        setSelected(item);
    }, [value]);

    useEffect(() => {
        setText(selected ? selected.title || selected.text : null);
    }, [selected]);

    useEffect(() => {
        document.body.addEventListener('click', onBodyClick);
        return() => {
            document.body.removeEventListener('click', onBodyClick);
        }
    }, []);

    return (
        <div 
        className={nonce.current}
        style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            appendContent={(
                <CollapseArrow
                className={nonce.current}
                collapsed={showOptions ? false : true} 
                onClick={onFieldClick}
                style={{
                    height: 12,
                    marginLeft: 8,
                    width: 12 
                }} />
            )}
            className={nonce.current}
            icon={icon}
            insetLabel={insetLabel}
            onClick={onFieldClick}
            placeholder={getPlaceholder()}
            utils={utils}
            value={text}
            style={{
                flexGrow: 1,
                minHeight: 40,
                ...Appearance.textStyles.title()
            }} />
            {getPickerContent()}
        </div>
    )
}
export default CustomListField;
