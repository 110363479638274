import Request from 'files/Request.js';
import User from 'classes/User.js';

class DealershipClass {

    access_token = null;
    active = null;
    address = null;
    avatar = null;
    dealer = null;
    dealer_name = null; // optional list value
    gdl_active = null;
    id = null;
    location = null;
    name = null;
    omnishield = null;
    payments_account = null;
    phone_number = null;
    preferences = null;
    public_auth_token = null;
    subscriptions = null;
    timezone = null;

    cosntructor() {
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    close = async () => {
        return new Promise(resolve => {
            this.address = this.edits.address;
            this.dealer = this.edits.dealer;
            this.gdl_active = this.edits.gdl_active;
            this.location = this.edits.location;
            this.name = this.edits.name;
            this.omnishield = this.edits.omnishield || {};
            this.phone_number = this.edits.phone_number;
            this.timezone = this.edits.timezone;
            resolve();
        });
    }

    create = (props = {}) => {
        this.access_token = props.access_token;
        this.active = props.active;
        this.address = props.address;
        this.avatar = props.avatar;
        this.dealer = props.dealer && User.create(props.dealer);
        this.dealer_name = props.dealer_name;
        this.gdl_active = props.gdl_active;
        this.id = props.id;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.name = props.name;
        this.omnishield = props.omnishield || {};
        this.payments_account = props.payments_account;
        this.phone_number = props.phone_number;
        this.preferences = props.preferences || [];
        this.public_auth_token = props.public_auth_token;
        this.subscriptions = props.subscriptions;
        this.timezone = props.timezone;
        return this;
    }
}

export const fetchDealership = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { dealership } = await Request.get(utils, '/dealerships/', {
                type: 'details',
                id: id
            });
            resolve(new DealershipClass().create(dealership));
        } catch(e){
            reject(e);
        }
    });
}

export default {
    create: props => new DealershipClass().create(props),
    get: (utils, id) => fetchDealership(utils, id),
    new: () => new DealershipClass()
}
