import React, { useState } from 'react';
import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';

const ColorPickerField = ( { loading, onChange, style, value } ) => {

    const [selectedColor, setSelectedColor] = useState(value);
    const colors = [
        '#F25E9D',
        '#E02020',
        '#FA6400',
        '#F7B500',
        '#6DD400',
        '#44D7B6',
        '#32C5FF',
        '#0091FF',
        '#6236FF',
        '#B620E0',
        '#774625',
        '#6D7278',
        '#646464',
        '#000000'
    ];

    const onChangeColor = color => {
        let next = color && !color.includes('#') ? `#${color}` : color;
        setSelectedColor(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    return (
        <div
        key={'color-picker-container'}
        style={{
            width: '100%',
            ...style
        }}>
            <TextField
            loading={loading}
            placeholder={'#4A4A4A'}
            value={selectedColor}
            onChange={onChangeColor}
            prependContent={selectedColor && (
                <div style={{
                    width: 25,
                    height: 25,
                    minWidth: 25,
                    minHeight: 25,
                    borderRadius: 5,
                    marginRight: 12,
                    backgroundColor: selectedColor
                }} />
            )}/>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 1,
                marginTop: 8,
                padding: 12,
                ...Appearance.styles.unstyledPanel()
            }}>
                {colors.map((color, index) => {
                    return (
                        <div
                        key={index}
                        className={'text-button'}
                        onClick={onChangeColor.bind(this, color)}
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: 10,
                            overflow: 'hidden',
                            backgroundColor: color,
                            marginRight: 8
                        }} />
                    )
                })}
            </div>
        </div>
    )
};
export default ColorPickerField;
