import React, { useState, useEffect } from 'react';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { LayerItem } from 'structure/Layer.js';
import { Map } from 'views/MapElements.js';
import ProgressBar from 'views/ProgressBar.js';

export const formatFields = (utils, sections) => {

    // loop through sections and determine next steps
    return sections.filter(section => {

        // skip section if section is not visible
        if(section.visible === false) {
            return false;
        }

        // determine if permissions were providedand if the current user is unable to view this information
        if(section.permissions) {
            let match = section.permissions.find(key => utils.user.permissions.get(key) === false);
            return match ? false : true;
        }
        return true;

    }).map(section => {

        // filter down items to only visible items
        section.items = section.items.filter(item => item.visible !== false);
        return section;

    }).filter(section => {
        return section.items.length > 0;
    });
}

const FieldMapper = ({ editable, fields, group, onEditClick, utils }) => {

    const [sections, setSections] = useState(fields || []);
    const [shouldShowInteractiveMap, setShouldShowInteractiveMap] = useState(false);

    const onIncompleteAddressClick = () => {
        utils.alert.show({
            title: 'About this Map',
            message: 'The location provided is incomplete or invalid. Please provide a street address, city, state, and zipcode to take advantage of location based services.'
        });
    }

    const onLockClick = () => {
        utils.alert.show({
            title: 'Restricted Information',
            message: 'Your dealership has hidden this information from your account. Please speak with your dealer if you have questions.'
        });
    };

    const getChild = (key, child) => {
        if(group && utils.groups.check(group, key) === false) {
            return (
                <img
                key={key}
                className={'text-button'}
                onClick={onLockClick}
                src={'images/lock-icon-small-red.png'}
                style={{
                    width: 20,
                    height: 20,
                    objectFit: 'contain'
                }} />
            )
        }
        return child;
    }

    const getMapComponent = (item, index) => {

        // no additional logic is required if no value was provided
        if(!item.value) {
            return null;
        }

        // return a placeholder map image if location is not valid
        if(item.valid === false) {
            return getView(item.key, {
                backgroundColor: Appearance.colors.divider(),
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                height: 250,
                width: '100%'
            }, (
                <div
                key={index}
                className={'text-button'}
                onClick={onIncompleteAddressClick}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%'
                }}>
                    <img
                    src={window.theme === 'dark' ? 'images/map-placeholder-dark.jpg' : 'images/map-placeholder.jpg'}
                    style={{
                        height: 250,
                        width: '100%'
                    }}/>
                    <img
                    src={'images/red-x-icon.png'}
                    style={{
                        height: 40,
                        position: 'absolute',
                        width: 40
                    }} />
                </div>
            ));
        }

        // return a static map image if applicable
        if(item.static && shouldShowInteractiveMap === false) {
            return getView(item.key, {
                backgroundColor: Appearance.colors.divider(),
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                height: 250,
                position: 'relative',
                width: '100%'
            }, (
                <div 
                key={index}
                style={{
                    position: 'relative',
                    width: '100%'
                }}>
                    <img
                    src={item.static}
                    style={{
                        height: 250,
                        width: '100%'
                    }}/>
                    <AltBadge 
                    content={{
                        color: Appearance.colors.primary(),
                        text: 'Click to Unlock Map'
                    }} 
                    invert={true}
                    onClick={setShouldShowInteractiveMap.bind(this, true)}
                    style={{
                        bottom: 12,
                        padding: '5px 12px 5px 12px',
                        position: 'absolute',
                        right: 0  
                    }}/>
                </div>
            ));
        }

        // fallback to returning a rendered map component
        return getView(item.key, {
            backgroundColor: Appearance.colors.divider(),
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            height: 250,
            width: '100%'
        }, (
            <Map
            annotations={[{
                key: 'location',
                location: item.value
            }]}
            center={item.value}
            isRotationEnabled={true}
            isScrollEnabled={true}
            isZoomEnabled={true}
            key={index}
            useShadows={false}
            style={{
                height: 250,
                width: '100%'
            }}/>
        ));
    }

    const getRightContent = section => {
        return typeof(section.rightContent) === 'function' ? section.rightContent(section) : section.rightContent;
    }

    const getValue = item => {

        // determine if a user group was provided and if the current user is unable to view this information
        if(group && utils.groups.check(group, item.key) === false) {
            return (
                <img
                className={'text-button'}
                onClick={onLockClick}
                src={'images/lock-icon-small-red.png'}
                style={{
                    height: 20,
                    objectFit: 'contain',
                    width: 20
                }} />
            )
        }

        // fallback to returning provided value
        return item.value || 'Not Added';
    }

    const getView = (key, style, child) => {
        if(group && utils.groups.check(group, key) === false) {
            return (
                <div
                key={key}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    ...style
                }}>
                    <img
                    className={'text-button'}
                    onClick={onLockClick}
                    src={'images/lock-icon-small-red.png'}
                    style={{
                        height: 50,
                        objectFit: 'contain',
                        width: 50
                    }} />
                </div>
            )
        }
        return child;
    }

    useEffect(() => {
        setSections(formatFields(utils, fields));
    }, [fields]);

    return sections.map((section, index) => {
        return (
            <LayerItem
            {...section}
            key={index}
            rightContent={getRightContent(section)}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    borderRadius: 15
                }}>
                    {section.items.map((item, index, items) => {

                        // determine if a specialty component was requested
                        switch(item.component) {
                            case 'button':
                            return (
                                <div
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    borderBottom: index !== items.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                                    borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                                    borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                                    borderTopLeftRadius: index === 0 ? 6 : 0,
                                    borderTopRightRadius: index === 0 ? 6 : 0,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '8px 12px 8px 12px'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.key(),
                                        width: '50%'
                                    }}>{item.title}</span>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        width: '50%'
                                    }}>
                                        {getChild(item.key, (
                                            <Button {...item.button} />
                                        ))}
                                    </div>
                                </div>
                            )

                            case 'map':
                            return getMapComponent(item, index);
                        }

                        // prepare editable flag and click handler
                        let canEdit = editable || item.onClick;
                        let onClick = item.onClick || onEditClick;

                        return (
                            <div
                            key={index}
                            className={canEdit ? `view-entry ${window.theme}` : null}
                            onClick={onClick ? onClick.bind(this, item) : null}
                            style={{
                                borderBottom: index !== items.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                                borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                                borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                                borderTopLeftRadius: index === 0 ? 6 : 0,
                                borderTopRightRadius: index === 0 ? 6 : 0,
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                width: '100%'
                            }}>
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.key(),
                                    flexShrink: 1,
                                    ...item.style && item.style.key
                                }}>{item.title}</span>
                                <span style={{
                                    ...Appearance.textStyles.value(),
                                    flexGrow: 1,
                                    ...item.style && item.style.value,
                                    ...item.color && {
                                        color: item.color
                                    }
                                }}>{getValue(item)}</span>
                                {canEdit && (item.rightContent || (
                                    <img
                                    src={'images/next-arrow-grey-small.png'}
                                    style={{
                                        height: 12,
                                        marginLeft: 8,
                                        objectFit: 'contain',
                                        opacity: 0.75,
                                        width: 12
                                    }} />
                                ))}
                            </div>
                            {item.loading === true && (
                                <div style={{
                                    bottom: 0,
                                    height: 2,
                                    overflow: 'hidden',
                                    position: 'absolute',
                                    width: '100%'
                                }}>
                                    <ProgressBar />
                                </div>
                            )}
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    });
}
export default FieldMapper;
