import React, { useRef, useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import Layer from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';

const WebView = ({ buttons, id, onClick, onClose, title, url }, { index, options, utils }) => {

    const layerID = id || 'webview';
    const webView = useRef(null);

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [requestURL, setRequestURL] = useState(url);

    const onOptionPress = () => {
        utils.sheet.show({
            items: [{
                key: 'reload',
                title: `Reload Page`,
                style: 'default'
            },{
                key: 'open',
                title: `Open in New Window`,
                style: 'default'
            }]
        }, key => {
            if(key === 'reload') {
                webView.current.reload();
            } else if(key === 'open') {
                setLayerState('close');
                setTimeout(() => {
                    window.open(url);
                }, 500)
            }
        })
    }

    const getButtons = () => {
        if(!buttons) {
            return;
        }
        return buttons.map(button => {
            return {
                ...button,
                onClick: () => {
                    setLayerState('close');
                    if(typeof(onClick) === 'function') {
                        onClick(button.key);
                    }
                }
            }
        })
    }

    useEffect(() => {
        let request = url;
        request += `${request.includes('?') ? '&':'?'}theme=${window.theme}`;
        setRequestURL(request);
    }, [url]);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={title}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            onCloseLayer: onClose,
            removePadding: true,
            useScrollView: false
        }}>
            <iframe
            src={requestURL}
            style={{
                flex: 1,
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                borderWidth: 0,
                borderColor: Appearance.colors.transparent
            }} />
        </Layer>
    )
}
export default WebView;
