import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from '@react-spring/web';
import browserInfo from 'browser-info';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Checkbox from 'views/Checkbox.js';
import Cookies from 'js-cookie';
import Dealership from 'classes/Dealership.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

export const runLogin = (utils, props) => {

    return new Promise(async (resolve, reject) => {
        try {

            // verify the username and password have been provided
            let { auto_login, password, refresh_token, username } = props || {};
            if(!refresh_token && (!username || !password)) {
                throw new Error('Please enter your username and password to continue');
            }

            // prepare device name and operating system
            let { name, os } = browserInfo();
            
            // send request to server
            let { default_dealership, groups, request_agreement, user } = await Request.post(utils, '/users/', {
                browser: name,
                default_dealership_id: Cookies.get('default_dealership_id'),
                password: password,
                platform: os,
                type: 'login',
                refresh_token: refresh_token,
                username: username
            });

            // prevent non-eligible accounts from moving forward
            if(user.level > User.levels.get().safety_advisor) {
                throw new Error('It looks like your account is not able to use these services. Please speak with your dealer for more information');
            }

            // update cookies with access token for cross-platform logins
            let preferences = utils.graci.preferences.get();
            preferences.auto_login = typeof(auto_login) === 'boolean' ? auto_login : preferences.auto_login;
            preferences.last_login = moment().unix();
            preferences.refresh_token = user.refresh_token;
            utils.graci.preferences.set(preferences);

            // resolve default dealership object and user object
            resolve({
                default_dealership: default_dealership && Dealership.create(default_dealership),
                groups: groups ? groups.map(group => User.Group.create(group)) : [],
                request_agreement: request_agreement,
                user: User.create(user)
            });

        } catch(e) {
            reject(e);
        }
    });
}

const Login = ({ onLogin, utils }) => {

    const credentials = useRef({});

    const [autoLogin, setAutoLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState({
        opacity: 0,
        scale: 0.75
    });
    const [loginCredentials, setLoginCredentials] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 12 },
        maxHeight: 0,
        opacity: 0,
        transform: 'scale(0)'
    }));
    const [loginVisible, setLoginVisible] = useState(false);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const onAppStoreClick = () => {
        window.open(API.apps.ios);
    }

    const onButtonClick = () => {
        if(!loginVisible) {
            setLoginVisible(true);
            setLoginCredentials({
                opacity: 1,
                maxHeight: 250,
                transform: 'scale(1)'
            })
            return;
        }
        onRunLogin();
    }

    const onGooglePlayClick = () => {
        window.open(API.apps.android);
    }

    const onKeyDown = evt => {
        if(evt.keyCode === 13) {
            evt.preventDefault();
            onRunLogin();
        }
    }

    const onLoginClick = async () => {
        try {

            // set loading flag and start root loader
            setLoading(true);
            await utils.loader.show();

            // submit login request to login processor
            let result = await runLogin(utils, {
                ...credentials.current,
                auto_login: autoLogin
            });

            // redirect safety associates to the safety associates website
            if(result.user.level === User.levels.get().safety_associate) {
                setLoading(false);
                await utils.loader.hide();
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'It looks like your account is not able to use these services. Please login to the Safety Associate website to continue',
                    buttons: [{
                        key: 'confirm',
                        title: 'Take Me There',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            window.location.href = `${API.server}/associates`
                        }
                    }
                });
                return;
            }

            // present new window with agreement to terms and conditions if applicable
            if(result.request_agreement) {
                setLoading(false);
                await onRequestTermsAgreement(result.user.user_id);
            }

            // animate login out of view and remove keypress listener
            setLogin(login => {
                return update(login, {
                    opacity: {
                        $set: 0
                    },
                    scale: {
                        $set: 0.75
                    }
                });
            });

            // send account details back to root view
            setLoading(false);
            onLogin(result);

        } catch(e) {
            setLoading(false);
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRequestTermsAgreement = async userID => {
        return new Promise(async (resolve, reject) => {
            try {

                // inform user that agreement to terms and conditions is required before moving forward
                await utils.loader.hide();
                utils.alert.show({
                    title: 'Terms and Conditions',
                    message: 'Before we take you to your account, you must accept the terms and conditions for using this service',
                    buttons: [{
                        key: 'terms',
                        title: 'View And Accept',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Back to Login',
                        style: 'destructive'
                    }],
                    onClick: async key => {
                        try {
                            if(key !== 'terms') {
                                await Utils.sleep(0.5);
                                throw new Error('You must accept the terms and conditions to use this service');
                                return;
                            }

                            // present agreement layer
                            utils.agreement.request({
                                url: `${API.server}/privacy/global_data/care_and_share`,
                                onAccept: () => resolve(),
                                onDecline: () => {
                                    let error = new Error('You must accept the terms and conditions to use this service');
                                    reject(error);
                                }
                            });

                        } catch(e) {
                            reject(e);
                        }
                    }
                })
            } catch(e) {
                reject(e);
            }
        });
    }

    const onRunLogin = () => {
        setLoginVisible(false);
        setLoginCredentials({
            opacity: 0,
            maxHeight: 0,
            transform: 'scale(0)'
        })
        onLoginClick();
    }

    const onResetPasswordClick = () => {
        window.open(`${API.server}/reset-password/index.html`);
    }

    const getContent = () => {
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 25,
                    width: '100%'
                }}>
                    <img
                    className={'text-button'}
                    onClick={onAppStoreClick}
                    src={'images/app-store-badge.png'}
                    style={{
                        height: 40,
                        marginBottom: 8,
                        objectFit: 'contain',
                        width: 225
                    }} />
                    <img
                    className={'text-button'}
                    onClick={onGooglePlayClick}
                    src={'images/google-play-badge.png'}
                    style={{
                        height: 40,
                        objectFit: 'contain',
                        width: 225
                    }} />
                </div>
            )
        }
        return (
            <>
            <animated.div style={{
                paddingBottom: loginVisible ? 15 : 0,
                paddingTop: 20,
                width: '100%',
                ...loginCredentials
            }}>
                <div style={{
                    ...Appearance.styles.panel(),
                    padding: 12
                }}>
                    <TextField
                    icon={'user'}
                    autoCapitalize={'none'}
                    placeholder={'Username'}
                    value={username}
                    useDelay={false}
                    onChange={text => setUsername(text)}
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: '500',
                    }}/>
                    <TextField
                    icon={'lock'}
                    isSecure={true}
                    placeholder={'Password'}
                    value={password}
                    useDelay={false}
                    onChange={text => setPassword(text)}
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 500
                    }}
                    containerStyle={{
                        marginTop: 8,
                        paddingRight: 6,
                        width: '100%'
                    }} 
                    appendContent={(
                        <img
                        className={'image-button'}
                        onClick={onResetPasswordClick}
                        src={'images/help-button-grey.png'}
                        style={{
                            borderRadius: 10,
                            height: 20,
                            marginLeft: 8,
                            objectFit: 'contain',
                            width: 20
                        }}/>
                    )}/>
                    <div style={{
                        alignItems: 'center',
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 12,
                        paddingTop: 12,
                        textAlign: 'left'
                    }}>
                        <Checkbox
                        checked={autoLogin}
                        color={Appearance.colors.primary()}
                        onChange={setAutoLogin} />
                        <span 
                        className={'cursor-pointer'}
                        onClick={() => setAutoLogin(val => !val)}
                        style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            marginLeft: 8
                        }}>{'Keep me signed in'}</span>
                    </div>
                </div>
            </animated.div>
            <Button
            color={'primary'}
            label={loginVisible ? 'Done' : 'Login'}
            loading={loading}
            onClick={onButtonClick}
            transition={{
                icon: 'key-icon-white.png'
            }}
            type={'transition'}/>
            </>
        )
    }

    useEffect(() => {
        credentials.current = {
            username: username,
            password: password
        }
    }, [username, password]);

    useEffect(() => {
        if(loginVisible === true) {
            document.addEventListener('keydown', onKeyDown);
        }
        if(loginVisible === false) {
            document.removeEventListener('keydown', onKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [loginVisible]);

    useEffect(() => {
        setLogin(login => {
            return update(login, {
                scale: {
                    $set: 1
                },
                opacity: {
                    $set: 1
                }
            });
        });
        if(API.dev_marketing_director) {
            setTimeout(() => {
                credentials.current = {
                    username: 'flokicarpenter',
                    password: 'redhouse10'
                }
                onRunLogin();
            }, 250);
            return;
        }
        if(API.dev_safety_advisor) {
            setTimeout(() => {
                credentials.current = {
                    username: 'ecarpenter_advisor',
                    password: 'redhouse10'
                }
                onRunLogin();
            }, 250);
            return;
        }
    }, []);

    return (
        <VelocityComponent
        easing={[250, 20]}
        duration={750}
        animation={{
            scale: login.scale,
            opacity: login.opacity
        }}>
            <div style={{
                width: '100%',
                maxWidth: 400
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 30,
                    textAlign: 'center'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={require('files/lottie/logo-desktop.json')}
                    style={{
                        width: 250,
                        height: 250
                    }}/>
                    <span style={{
                        fontSize: 22,
                        fontWeight: 800,
                        color: Appearance.colors.text(),
                        lineHeight: 1.5
                    }}>{'Global Data'}</span>
                    <span style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: Appearance.colors.subText(),
                        lineHeight: 1.1
                    }}>{'Powered by Global Health and Safety'}</span>

                    {getContent()}
                </div>
            </div>
        </VelocityComponent>
    )
}

export default Login;
