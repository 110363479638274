import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from '@react-spring/web';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Demo from 'classes/Demo.js';
import DemoRequest from 'classes/DemoRequest.js';
import { DemoDetails, DemoRequestDetails } from 'managers/Demos.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const OverviewItem = ({ count, endDate, item, loading, onLoadingChange, startDate, utils }) => {

    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 12 },
        opacity: 0,
        transform: 'scale(0.8)'
    }))

    const onItemClick = async () => {
        try {
            if(count === 0) {
                utils.alert.show({
                    title: item.title,
                    message: `The ${utils.dealership.get().name} dealership has ${item.value} ${item.title.toLowerCase()}. No other additional information is available at this time`
                });
                return;
            }

            // fetch all matching demos from server
            onLoadingChange(true);
            let { demos } = await Request.get(utils, '/dealerships/', {
                end_date: endDate.format('YYYY-MM-DD'),
                key: item.key,
                start_date: startDate.format('YYYY-MM-DD'),
                type: 'overview_details'
            });

            // show details layer with demo results
            onLoadingChange(false);
            utils.layer.open({
                id: `overview_item_details_${item.key}`,
                Component: OverviewItemDetails.bind(this, {
                    item: item,
                    demos: demos.map(demo => {
                        return item.key === 'requested' ? DemoRequest.create(demo) : Demo.create(demo)
                    }).sort((a,b) => {
                        return a.lead.full_name.localeCompare(b.lead.full_name)
                    })
                })
            });

        } catch(e) {
            onLoadingChange(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the breakdown for your dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getGradient = () => {
        return `linear-gradient(25deg, ${Utils.hexToRGBA(item.color, 0.75)}, ${Utils.hexToRGBA(item.color, 0.55)})`
    }

    const getValue = item => {

        // return a loading component if loading flag is set
        if(loading) {
            return (
                <LottieView
                loop={true}
                autoPlay={true}
                source={require('files/lottie/dots-grey.json')}
                style={{
                    position: 'absolute',
                    width: 25,
                    height: 25
                }}/>
            )
        }

        /// prepare dynamic font size based on the character length of the value
        let fontSize = 23;
        let val = Utils.numberFormat(item.value, 1).toString();
        if(val.length <= 2) {
            fontSize = 23;
        } else if(val.length <= 3) {
            fontSize = 18;
        } else {
            fontSize = 14;
        }

        return (
            <span style={{
                color: Appearance.colors.darkGrey,
                fontSize: fontSize,
                fontWeight: 600,
                position: 'absolute',
                textShadow: '-5px 3px 10px rgba(0,0,0,0.3)'
            }}>{val}</span>
        )
    }

    const runAnimations = async () => {
        try {
            await Utils.sleep(0.5);
            setAnimations({
                opacity: 1,
                transform: 'scale(1)'
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        runAnimations();
    }, []);

    return (
        <animated.div
        className={'col-12 col-lg p-2 text-button'}
        onClick={onItemClick}
        style={animations}>
            <div style={{
                ...Appearance.styles.panel(),
                alignItems: 'center',
                background: getGradient(),
                backgroundColor: null,
                borderRadius: 15,
                borderWidth: 0,
                boxShadow: '-5px 15px 20px rgba(0,0,0,0.05)',
                display: 'flex',
                flexDirection: 'column',
                padding: 5,
                position: 'relative',
                overflow: 'hidden',
                textAlign: 'center'
            }}>
                <div
                className={'stripes-white'}
                style={{
                    alignItems: 'center',
                    backgroundColor: 'rgba(255,255,255,0.15)',
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 5,
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        height: 50,
                        marginBottom: 12,
                        marginTop: 12,
                        position: 'relative',
                        width: 50
                    }}>
                        <img
                        src={'images/badge-frame-white.png'}
                        style={{
                            height: '100%',
                            objectFit: 'contain',
                            width: '100%'
                        }}/>
                        {getValue(item)}
                    </div>
                </div>
                <div style={{
                    backgroundColor: item.color,
                    borderBottomLeftRadius: 12,
                    borderBottomRightRadius: 12,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    padding: '6px 12px 6px 12px',
                    textAlign: 'center',
                    width: '100%',
                }}>
                    <span style={{
                        color: 'white',
                        fontSize: 16,
                        fontWeight: 700
                    }}>{item.title}</span>
                </div>
            </div>
        </animated.div>
    )
}
export default OverviewItem;

export const OverviewItemDetails = ({ demos, item }, { index, options, utils }) => {

    const layerID = `overview_item_details_${item.key}`;
    const chart = useRef(null);
    const [chartData, setChartData] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [targets, setTargets] = useState(demos || []);

    const onDemoClick = async target => {
        try {
            if(item.key === 'requested') {
                let demo = await DemoRequest.get(utils, target.id);
                utils.layer.open({
                    abstract: Abstract.create({
                        object: demo,
                        type: 'demo_request'
                    }),
                    Component: DemoRequestDetails,
                    id: `demo_request_details_${demo.id}`,
                    permissions: ['demo_requests.details']
                });
                return;
            }

            let demo = await Demo.get(utils, target.id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo'
                }),
                Component: DemoDetails,
                id: `demo_details_${demo.id}`,
                permissions: ['demos.details']
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the information for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        if(!dateFilter) {
            return null;
        }
        return [{
            key: 'clear',
            text: 'Clear Date Filters',
            color: 'dark',
            onClick: () => setDateFilter(null)
        }]
    }

    const getChart = () => {

        let { labels } = chartData || {};
        if(!labels || labels.length < 3) {
            return null;
        }

        // generate total values for date values
        let data = labels.map(date => {
            return demos.filter(demo => {
                return date.isSame(moment(demo.start_date || demo.date), 'day');
            }).length;
        });

        // check if year value needs to be shown
        // min unix value is subtract from max unit value and compared against seconds in a year (31,536,000)
        let showYears = Math.max(...labels.map(date => date.unix())) - Math.min(...labels.map(date => date.unix())) > 31536000;

        return (
            <div style={{
                width: '100%',
                marginTop: 20,
                marginBottom: 20
            }}>
                <Line
                ref={chart}
                width={500}
                height={200}
                data={{
                    labels: labels.map(label => {
                        return label.format(showYears ? 'MM/DD/YYYY' : 'MMM Do')
                    }),
                    datasets: [{
                        label: item.title,
                        fill: false,
                        data: data,
                        pointStyle: 'circle',
                        borderDash: [5, 5],
                        borderWidth: 2,
                        borderColor: Appearance.colors.grey(),
                        pointRadius: 5,
                        pointBorderWidth: 3,
                        pointBackgroundColor: ({ dataIndex }) => {
                            if(!dateFilter) {
                                return item.color;
                            }
                            return labels[dataIndex].isSame(dateFilter, 'day') ? item.color : Appearance.colors.grey();
                        },
                        pointBorderColor: ({ dataIndex }) => {
                            if(!dateFilter) {
                                return item.color;
                            }
                            return labels[dataIndex].isSame(dateFilter, 'day') ? item.color : Appearance.colors.grey();
                        }
                    }]
                }}
                options={{
                    title: { display: false },
                    legend: { display: false },
                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        mode: 'dataset'
                    },
                    onClick: (evt, element) => {
                        if(element.length < 1) {
                            return;
                        }
                        let { _index } = element[0];
                        setDateFilter(labels[_index]);
                    },
                    tooltips: {
                        callbacks: {
                            label: tooltipItem => {
                                if(item.key === 'requested') {
                                    return `${tooltipItem.yLabel} ${parseInt(tooltipItem.yLabel) == 1 ? 'Demo Request':'Demo Requests'}`
                                }
                                return `${tooltipItem.yLabel} ${parseInt(tooltipItem.yLabel) == 1 ? 'Demo':'Demos'}`
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 20
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: (value, index, values) => {
                                    return Utils.softNumberFormat(value);
                                }
                            }
                        }]
                    }
                }} />
            </div>
        )
    }

    const getDemos = () => {
        return (
            <LayerItem title={`${item.title} List`}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {targets.map((demo, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.leads, demo.lead.full_name),
                                subTitle: utils.groups.apply('address', User.Group.categories.leads, Utils.formatAddress(demo.lead.address)) || 'Address not available',
                                badge: {
                                    text: Utils.formatDate(demo.start_date || demo.date),
                                    color: item.color
                                },
                                hideIcon: true,
                                firstItem: index === 0,
                                singleItem: targets.length === 1,
                                lastItem: index === targets.length - 1,
                                bottomBorder: index !== targets.length - 1,
                                onClick: onDemoClick.bind(this, demo)
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const setupChartData = () => {

        // do not show chart if there is insufficient data
        if(demos.length <= 1) {
            return;
        }

        // generate unique labels based on demo start date or demo request date
        let labels = demos.reduce((array, demo) => {
            let date = moment(demo.start_date || demo.date).format('YYYY-MM-DD');
            if(!array.includes(date)) {
                array.push(date);
            }
            return array;
        }, []).sort((a,b) => {
            return moment(a).unix() < moment(b).unix();
        }).map(date => {
            return moment(date);
        });

        // set chart labels
        // datasets are rendered each time to ensure proper colors are shown
        setChartData({ labels: labels })
    }

    const setupDemoTargets = () => {
        setTargets(demos.filter(demo => {
            if(!dateFilter) {
                return true;
            }
            return moment(demo.start_date || demo.date).isSame(dateFilter, 'day');
        }).sort((a,b) => {
            if(a.date || b.date) {
                return a.date.unix() < b.date.unix();
            }
            return a.start_date.unix() < b.start_date.unix();
        }))
    }

    useEffect(() => {
        setupDemoTargets();
    }, [dateFilter])

    useEffect(() => {
        setupChartData();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${item.title} Overview`}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            {getChart()}
            {getDemos()}
        </Layer>
    )
}
