import React, { useRef, useState, useEffect } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import { AddEditEvent, AddEditEventType, DealershipSelector, EventDetails, EventTypeDetails, getEventStatus } from 'managers/Dealerships.js';
import { AddEditCallLog, CallLogDetails, LeadDetails, SetLeadStatus } from 'managers/Leads.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BookDemo from 'views/BookDemo.js';
import BoolToggle from 'views/BoolToggle.js';
import CallLog from 'classes/CallLog.js';
import Cookies from 'js-cookie';
import Content from 'managers/Content.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import DatePickerField from 'views/DatePickerField.js';
import Demo from 'classes/Demo.js';
import DemoLookupField from 'views/DemoLookupField.js';
import DemoRequest from 'classes/DemoRequest.js';
import EditTarget from 'views/EditTarget.js';
import Event from 'classes/Event.js';
import Feedback from 'classes/Feedback.js';
import FieldMapper, { formatFields } from 'views/FieldMapper.js';
import FilePickerField from 'views/FilePickerField.js';
import ImagePickerField from 'views/ImagePickerField.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import LeadScriptEditor from 'views/LeadScriptEditor.js';
import LottieView from 'views/Lottie.js';
import MultipleListField from 'views/MultipleListField.js';
import Panel from 'structure/Panel.js';
import PatternPickerField, { convertPattern } from 'views/PatternPickerField.js';
import QuestionEditor from 'views/QuestionEditor.js';
import PageControl from 'views/PageControl.js';
import PermissionsContainer from 'views/PermissionsContainer.js';
import ProgressBar from 'views/ProgressBar.js';
import Request from 'files/Request.js';
import Scheduler, { SchedulerEventHeight } from 'views/Scheduler.js';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import StatusCodeFilters from 'views/StatusCodeFilters.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventsLayerItem, UserDetails, reassignTasks, unassignTasks } from 'managers/Users.js';
import TableListHeader from 'views/TableListHeader.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';
import { WeekCalendar } from 'views/Calendar.js';

export const CustomerFeedbackResponses = ({ index, options, utils }) => {

    const panelID = 'customer_feedback_responses';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [responses, setResponses] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [sorting, setSorting] = useState(null);

    const getFields = (response, index) => {

        let target = response || {};
        let fields = [{
            key: 'customer',
            title: 'Customer',
            value: target.full_name
        },{
            key: 'demo_date',
            title: 'Demo Date',
            value: target.start_date ? moment(target.start_date).format('MMM Do, YYYY [at] h:mma') : null
        },{
            key: 'feedback_date',
            title: 'Feedback Date',
            value: target.date ? moment(target.date).format('MMM Do, YYYY [at] h:mma') : null
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!response) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'customer',
                [Content.sorting.type.ascending]: 'feedback_date',
                [Content.sorting.type.descending]: 'feedback_date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== responses.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onResponseClick.bind(this, response)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintResponses,
            onRenderItem: (item, index, items) => {
                return {
                    customer: item.full_name,
                    demo_date: item.start_date ? moment(item.start_date).format('MMM Do, YYYY [at] h:mma') : null,
                    feedback_date: item.date ? moment(item.date).format('MMM Do, YYYY [at] h:mma') : null
                }
            },
            headers: [{
                key: 'customer',
                title: 'Customer'
            },{
                key: 'demo_date',
                title: 'Demo Date'
            },{
                key: 'feedback_date',
                title: 'Feedback Date'
            }]
        }
    }

    const onPrintResponses = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { responses } = await Request.get(utils, '/dealerships/', {
                    type: searchText ? 'lookup_feedback_responses' : 'feedback_responses',
                    search_text: searchText,
                    ...sorting,
                    ...props
                });

                setLoading(false);
                resolve(responses.map(response => Feedback.Response.create(response)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const fetchResponses = async () => {
        try {
            setLoading(true);
            let { paging, responses } = await Request.get(utils, '/dealerships/', {
                type: 'feedback_responses',
                limit: limit,
                offset: offset,
                search_text: searchText,
                ...sorting
            });

            setLoading(false);
            setPaging(paging);
            setResponses(responses.map(response => Feedback.Response.create(response)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the responses list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onResponseClick = response => {
        utils.sheet.show({
            items: [{
                key: 'demo',
                title: 'View Demo',
                style: 'default'
            },{
                key: 'lead',
                title: 'View Lead',
                style: 'default'
            },{
                key: 'response',
                title: 'View Customer Response',
                style: 'default'
            }]
        }, async key => {
            if(key === 'demo') {
                try {
                    let demo = await Demo.get(utils, response.demo_id);
                    utils.layer.open({
                        abstract: Abstract.create({
                            object: demo,
                            type: 'demo',
                        }),
                        id: `demo_details_${demo.id}`,
                        Component: DemoDetails,
                        permissions: ['demos.details']
                    })
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }

            if(key === 'lead') {
                try {
                    let demo = await Demo.get(utils, response.demo_id);
                    let lead = await Lead.get(utils, demo.lead.id);
                    utils.layer.open({
                        abstract: Abstract.create({
                            object: lead,
                            type: 'lead'
                        }),
                        Component: LeadDetails,
                        id: `lead_details_${lead.id}`,
                        permissions: ['leads.details']
                    })
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }

            if(key === 'response') {
                utils.layer.open({
                    id: `feedback_response_details_${response.id}`,
                    abstract: Abstract.create({
                        type: 'feedback_response',
                        object: response
                    }),
                    Component: FeedbackResponseDetails
                });
                return;
            }
        })
    }

    const getContent = () => {
        if(responses.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No customer responses were found in the system',
                    title: 'No Customer Responses Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {responses.map((response, index) => {
                        return getFields(response, index);
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchResponses();
    }, [offset, searchText, sorting]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchResponses);
        utils.events.on(panelID, 'dealership_preferences_update', fetchResponses);
        utils.content.subscribe(panelID, ['feedback_response'], {
            onFetch: fetchResponses,
            onUpdate: abstract => {
                setResponses(responses => {
                    return responses.map(response => {
                        return response.id === abstract.getID() ? abstract.object : response
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchResponses);
            utils.events.off(panelID, 'dealership_preferences_update', fetchResponses);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Customer Responses'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: setSearchText
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => setOffset(nextOffset)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const DemosCalendar = ({ index, options, utils }) => {

    const panelID = 'demos_calendar';
    const limit = 15;

    const contentContainer = useRef(null);
    const offset = useRef(0);
    const showInactive = useRef(false);

    const [calendarStyle, setCalendarStyle] = useState('timeGrid');
    const [categories, setCategories] = useState([]);
    const [categoryItems, setCategoryItems] = useState([]);
    const [dealership, setDealership] = useState(utils.dealership.get().id);
    const [events, setEvents] = useState([]);
    const [filters, setFilters] = useState(Object.values(Demo.status.get()));
    const [hiddenUsers, setHiddenUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [managingUsers, setManagingUsers] = useState(false);
    const [operatingHours, setOperatingHours] = useState(Utils.getDefaultOperatingHours());
    const [paging, setPaging] = useState(null);
    const [rescheduleContext, setRescheduleContext] = useState(null);
    const [targetDate, setTargetDate] = useState(moment());
    const [timelineEvents, setTimelineEvents] = useState([]);
    const [users, setUsers] = useState([]);
    const [userStates, setUserStates] = useState([]);

    const onAddNewEventType = () => {

        // prepare new event type
        let target = Event.Type.new();
        target.dealership_id = utils.dealership.get().id;

        // show new event type editing layer
        utils.layer.open({
            id: 'new_event_type',
            abstract: Abstract.create({
                object: target,
                type: 'event_type'
            }),
            Component: AddEditEventType.bind(this, { 
                isNewTarget: true,
                onChange: type => {

                    // prepare list of ids for local storage cache
                    let ids = categoryItems.map(selection => selection.id).concat([type.id]);
                    let key = getLocalStorageKey();
                    localStorage.setItem(key, JSON.stringify(ids));

                    // update list of selected event types
                    setCategories(ids);
                }
            })
        });
    }

    const setEventLoading = abstract => {
        setLoading(`${abstract.type}_${abstract.object.id}`);
    }

    const onCategoriesChange = items => {

        // prepare formatted list of selected categories
        let selections = categoryItems.filter(category => {
            return items.find(item => item.id === category.id) ? true : false;
        });

        // prepare list of ids for local storage cache
        let ids = selections.map(selection => selection.id);
        let key = getLocalStorageKey();
        localStorage.setItem(key, JSON.stringify(ids));

        // update state with new selections
        setCategories(ids);
    }

    const onContentUpdate = abstract => {
        setEvents(events => {
            return events.map(evt => {

                // determine if a status change was posted
                if(['demo_status', 'demo_request_status'].includes(abstract.type) === true) {
                    switch(abstract.type) {
                        case 'demo_status':
                        if(evt.type === 'demo' && evt.id === abstract.object.id) {
                            return update(evt, {
                                extendedProps: {
                                    event: {
                                        object: {
                                            $apply: object => {
                                                object.status = abstract.object.status;
                                                return object;
                                            }
                                        }
                                    }
                                },
                                status: {
                                    $set: abstract.object.status
                                }
                            });
                        }
                        break;

                        case 'demo_request_status':
                        if(evt.type === 'demo_request' && evt.id === abstract.getID()) {
                            return update(evt, {
                                extendedProps: {
                                    event: {
                                        object: {
                                            $apply: object => {
                                                object.status = abstract.object.status;
                                                return object;
                                            }
                                        }
                                    }
                                },
                                status: {
                                    $set: abstract.object.status
                                }
                            });
                        }
                        break;
                    }
                }

                // no additional logic is required for event non-matches
                if(`${evt.type}-${evt.id}` !== abstract.getTag()) {
                    return evt;
                }

                // format event data based on abstract type
                switch(abstract.type) {
                    case 'demo':
                    case 'event':
                    return {
                        end: moment(abstract.object.end_date).format('YYYY-MM-DD HH:mm:ss'),
                        eventResourceEditable: true,
                        eventStartEditable: true,
                        extendedProps: {
                            event: formatAbstractEvent(abstract)
                        },
                        id: abstract.object.id,
                        start: moment(abstract.object.start_date).format('YYYY-MM-DD HH:mm:ss'),
                        status: abstract.object.status,
                        type: abstract.type
                    }

                    case 'demo_request':
                    return {
                        end: moment(abstract.object.date).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                        eventResourceEditable: true,
                        eventStartEditable: true,
                        extendedProps: {
                            event: formatAbstractEvent(abstract)
                        },
                        id: abstract.object.id,
                        start: moment(abstract.object.date).format('YYYY-MM-DD HH:mm:ss'),
                        status: abstract.object.status,
                        type: abstract.type
                    }

                    default:
                    return evt;
                }
            });
        });
    }

    const onCustomEventRender = target => {

        // prepare event variables
        let { demo, end_date, lead, start_date, status, title, type } = target.object;

        // prepare quick look items
        let quickLook = [{
            key: 'time',
            value: `${moment(start_date).format('h:mma')} to ${moment(end_date).format('h:mma')}`
        },{
            key: 'demo',
            value: demo && (
                <>
                <span>{`Demo: `}</span>
                <span style={{
                    textDecoration: 'underline'
                }}>{demo.full_name}</span>
                </>
            )
        },{
            key: 'lead',
            value: lead && (
                <>
                <span>{`Lead: `}</span>
                <span style={{
                    textDecoration: 'underline'
                }}>{lead.full_name}</span>
                </>
            )
        }];

        // prepare name label for event, status code color, and abstract loading tag
        let color = status && status.color || Appearance.colors.grey();
        let tag = `${target.type}_${target.object.id}`;

        return (
            <div
            className={`text-button mb-1`}
            onClick={onEventClick.bind(this, target)}
            title={title}
            style={{
                background: Appearance.colors.softGradient(color),
                border: `2px solid ${color}`,
                borderRadius: 5,
                height: '100%',
                overflow: 'hidden',
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    borderBottom: `${loading === tag ? 0 : 2}px solid ${color}`,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '8px 12px 8px 12px',
                    position: 'relative'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minWidth: 0
                    }}>
                        <span
                        className={'d-block w-100'}
                        style={{
                            color: 'white',
                            fontSize: 12,
                            fontWeight: 700,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{title}</span>
                        <span
                        className={'d-block w-100'}
                        style={{
                            color: 'white',
                            fontSize: 12,
                            fontWeight: 600,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textDecoration: 'underline',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{status.text}</span>
                    </div>
                    <img
                    src={type.icon.url}
                    style={{
                        border: '2px solid white',
                        borderRadius: 12.5,
                        height: 25,
                        marginLeft: 8,
                        minHeight: 25,
                        minWidth: 25,
                        overflow: 'hidden',
                        width: 25
                    }} />
                </div>
                {loading === tag && (
                    <div style={{
                        height: 2,
                        overflow: 'hidden',
                        width: '100%'
                    }}>
                        <ProgressBar 
                        barColor={'#FFFFFF'} 
                        trackColor={status.color}/>
                    </div>
                )}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px 12px 8px 12px'
                }}>
                    {quickLook.map(item => {
                        return (
                            <span
                            key={item.key}
                            className={'d-block w-100'}
                            style={{
                                color: 'white',
                                fontSize: 11,
                                fontWeight: 600,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{item.value}</span>
                        )
                    })}
                </div>
            </div>
        )
    }

    const onDealershipChange = () => {
        setDealership(utils.dealership.get().id);
        fetchEvents();
    }

    const onDemoEventRender = target => {

        // prepare event variables
        let { address, end_date, first_name, full_name, last_name, lead_type, spouse_first_name, start_date, status } = target.object;

        // prepare quick look items
        let quickLook = [{
            key: 'time',
            value: `${moment(start_date).format('h:mma')} to ${moment(end_date).format('h:mma')}`
        },{
            key: 'address',
            value: address && address.locality && address.administrative_area_level_1 ? `${address.locality}, ${address.administrative_area_level_1}` : null
        }];

        // add assigned user to quick look if applicable
        let user = target.object.user_id && users.find(user => user.user_id === target.object.user_id);
        if(user) {
            quickLook.push({
                key: 'assigned',
                value: `Assigned to ${user.full_name}`
            });
        }

        // prepare name label for event, status code color, optional reschedule context, and abstract loading tag
        let color = status && status.color || Appearance.colors.grey();
        let name = full_name ? `${first_name}${spouse_first_name ? ` & ${spouse_first_name}` : ''} ${last_name || ''}` : 'Customer name not available';
        let rescheduleContext = getRescheduleContext(target);
        let tag = `${target.type}_${target.object.id}`;

        return (
            <div
            className={`text-button mb-1`}
            onClick={onEventClick.bind(this, target)}
            title={name}
            style={{
                background: Appearance.colors.softGradient(color),
                border: `2px solid ${color}`,
                borderRadius: 5,
                height: '100%',
                overflow: 'hidden',
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    borderBottom: `${loading === tag ? 0 : 2}px solid ${color}`,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '8px 12px 8px 12px',
                    position: 'relative'
                }}>
                    {lead_type && lead_type.icon && (
                        <img
                        src={lead_type.icon.url}
                        style={{
                            border: '2px solid white',
                            borderRadius: 15,
                            height: 30,
                            marginRight: 8,
                            minHeight: 30,
                            minWidth: 30,
                            overflow: 'hidden',
                            width: 30
                        }} />
                    )}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minWidth: 0
                    }}>
                        <span style={{
                            color: 'white',
                            fontSize: 12,
                            fontWeight: 700,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{utils.groups.apply(['first_name', 'last_name', 'spouse_first_name'], User.Group.categories.leads, name)}</span>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            maxWidth: '100%',
                            minWidth: 0
                        }}>
                            <span
                            className={'cursor-pointer'}
                            onClick={onStatusClick.bind(this, target)}
                            style={{
                                color: 'white',
                                fontSize: 12,
                                fontWeight: 600,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textDecoration: 'underline',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{status.text}</span>
                        </div>
                    </div>
                </div>
                {loading === tag && (
                    <div style={{
                        height: 2,
                        overflow: 'hidden',
                        width: '100%'
                    }}>
                        <ProgressBar barColor={'#FFFFFF'} trackColor={status.color}/>
                    </div>
                )}
                {rescheduleContext && status.code === Demo.status.get().rescheduled && (
                    <div style={{
                        borderBottom: `2px solid ${color}`,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span 
                        className={'d-block w-100'}
                        style={{
                            color: 'white',
                            fontSize: 11,
                            fontWeight: 600,
                            whiteSpace: 'normal'
                        }}>{rescheduleContext.message}</span>
                    </div>
                )}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px 12px 8px 12px'
                }}>
                    {quickLook.map(item => {
                        return (
                            <span
                            key={item.key}
                            className={'d-block w-100'}
                            style={{
                                color: 'white',
                                fontSize: 11,
                                fontWeight: 600,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{item.value}</span>
                        )
                    })}
                </div>
            </div>
        )
    }

    const onDemoRequestEventRender = target => {

        // prepare event variables
        let { date, first_name, last_name, lead_type, spouse_first_name, status } = target.object;

        // prepare quick look items
        let quickLook = [{
            key: 'time',
            value: `${moment(date).format('h:mma')} to ${moment(date).add(3, 'hours').format('h:mma')}`
        }];

        // prepare name label for event, status code color, and abstract loading tag
        let color = status && status.color || Appearance.colors.grey();
        let name = `${first_name}${spouse_first_name ? ` & ${spouse_first_name}` : ''} ${last_name || ''}`;
        let tag = `${target.type}_${target.object.id}`;

        return (
            <div
            className={`text-button mb-1`}
            onClick={onEventClick.bind(this, target)}
            title={name}
            style={{
                background: Appearance.colors.softGradient(color),
                border: `2px solid ${color}`,
                borderRadius: 5,
                height: '100%',
                overflow: 'hidden',
                width: '100%'
            }}>
                <div className={'pinstripes'}>
                    <div style={{
                        alignItems: 'center',
                        borderBottom: `${loading === tag ? 0 : 2}px solid ${color}`,
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '8px 12px 8px 12px',
                        position: 'relative'
                    }}>
                        {lead_type && lead_type.icon && (
                            <img
                            src={lead_type.icon.url}
                            style={{
                                border: '2px solid white',
                                borderRadius: 15,
                                height: 30,
                                marginRight: 8,
                                minHeight: 30,
                                minWidth: 30,
                                overflow: 'hidden',
                                width: 30
                            }} />
                        )}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            minWidth: 0
                        }}>
                            <span
                            className={'d-block w-100'}
                            style={{
                                color: 'white',
                                fontSize: 12,
                                fontWeight: 700,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{utils.groups.apply(['first_name', 'last_name', 'spouse_first_name'], User.Group.categories.leads, name)}</span>
                            <span
                            className={'d-block w-100'}
                            style={{
                                color: 'white',
                                fontSize: 12,
                                fontWeight: 600,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{status.text}</span>
                        </div>
                    </div>
                    {loading === tag && (
                        <div style={{
                            height: 2,
                            overflow: 'hidden',
                            width: '100%'
                        }}>
                            <ProgressBar barColor={'#FFFFFF'} trackColor={status.color}/>
                        </div>
                    )}
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px 12px 8px 12px'
                }}>
                    {quickLook.map(item => {
                        return (
                            <span
                            key={item.key}
                            className={'d-block w-100'}
                            style={{
                                color: 'white',
                                fontSize: 11,
                                fontWeight: 600,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{item.value}</span>
                        )
                    })}
                </div>
            </div>
        )
    }

    const onDownloadCalendar = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let { url } = await Request.get(utils, '/demos/', {
                    type: 'download_calendar'
                });
                resolve(url);
            } catch(e) {
                reject(e);
            }
        });
    }

    const onEventClick = async abstract => {
        try {

            // start loading for abstract target
            setEventLoading(abstract);

            // fetch details for demo if applicable
            if(abstract.type === 'demo') {
                let demo = await Demo.get(utils, abstract.object.id);
                setLoading(false);
                utils.layer.open({
                    abstract: Abstract.create({
                        object: demo,
                        type: 'demo'
                    }),
                    Component: DemoDetails,
                    id: `demo_details_${demo.id}`,
                    permissions: ['demos.details']
                });
                return;
            }

            // fetch details for demo if applicable
            if(abstract.type === 'demo_request') {
                let request = await DemoRequest.get(utils, abstract.object.id);
                setLoading(false);
                utils.layer.open({
                    abstract: Abstract.create({
                        object: request,
                        type: 'demo_request'
                    }),
                    Component: DemoRequestDetails,
                    id: `demo_request_details_${request.id}`,
                    permissions: ['demo_requests.details']
                });
                return;
            }

            // fetch details for event if applicable
            if(abstract.type === 'event') {
                let event = await Event.get(utils, abstract.object.id);
                setLoading(false);
                utils.layer.open({
                    id: `event_details_${event.id}`,
                    abstract: Abstract.create({
                        object: event,
                        type: 'event'
                    }),
                    Component: EventDetails
                });
                return;
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this event. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEventDrop = props => {

        // prevent demo rescheduling for user accounts not found in the array
        let { admin, dealer, division_director, marketing_director, region_director } = User.levels.get();
        if([admin, dealer, division_director, marketing_director, region_director].includes(utils.user.get().level) === false) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like your account is unable to reschedule demos. Please speak with your dealer or marketing director if you need to make changes to the time and date for this demo.'
            });
            return;
        }

        // prevent reschduling if the selected day has not changed
        let { delta, oldEvent } = props;
        if(delta.days == 0) {
            console.log('no scheduling changes have been made');
            return;
        }

        // prepare new event start date
        let endDate = moment(oldEvent.end).add(delta.days, 'days');
        let startDate = moment(oldEvent.start).add(delta.days, 'days');

        // temporarily move event to new timeslot while user inputs are being requested
        setEvents(events => events.map(evt => {
            if(evt.extendedProps.event.object.id === oldEvent.extendedProps.event.object.id && evt.extendedProps.event.type === oldEvent.extendedProps.event.type) {
                evt.restore = { ...evt };
                evt.start = startDate.format('YYYY-MM-DD HH:mm:ss');
                evt.end = endDate.format('YYYY-MM-DD HH:mm:ss');
            }
            return evt;
        }));
        
        // request confirmation for rescheduling if target is a custom event
        if(oldEvent.extendedProps.type === 'event') {
            utils.alert.show({
                title: 'Reschedule Demo',
                message: `Are you sure that you want to reschedule this event for ${Utils.formatDate(startDate)}?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
    
                    // determine if the event can be rescheduled
                    if(key === 'confirm') {
                        onRescheduleEvent({
                            end_date: endDate,
                            evt: oldEvent,
                            start_date: startDate
                        });
                        return;
                    }
    
                    // revert temporary event changes if the above logic was not triggered
                    setEvents(events => events.map(evt => evt.restore || evt));
                }
            });
            return;
        }

        // request confirmation for rescheduling with reason if target is a demo
        let reason = null;
        utils.alert.show({
            title: 'Reschedule Demo',
            message: `Are you sure that you want to reschedule this demo for ${moment(startDate).format('dddd MMMM Do [at] h:mma')}? Please provide your reason for rescheduling below.`,
            content: (
                <div style={{
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: '100%'
                }}>
                    <TextView
                    placeholder={'Reason for Rescheduling'}
                    onChange={text => reason = text} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {

                // determine if the demo can be rescheduled
                if(reason && key === 'confirm') {
                    onRescheduleDemo({
                        end_date: endDate,
                        evt: oldEvent,
                        reason: reason,
                        start_date: startDate
                    });
                    return;
                }

                // revert temporary event changes if the above logic was not triggered
                setEvents(events => events.map(evt => evt.restore || evt));
            }
        });
    }

    const onEventRender = target => {

        // determine which type of event needs to be rendered
        switch(target.type) {
            case 'demo':
            return onDemoEventRender(target);

            case 'demo_request':
            return onDemoRequestEventRender(target);

            case 'event':
            return onCustomEventRender(target);

            default:
            return null;
        }
    }

    const onEventTypeClick = index => {
        let type = categoryItems[index];
        utils.layer.open({
            id: `event_type_details_${type.id}`,
            abstract: Abstract.create({
                object: type,
                type: 'event_type'
            }),
            Component: EventTypeDetails
        });
    }

    const onNewEventClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: Event.new(),
                type: 'event'
            }),
            Component: AddEditEvent.bind(this, { isNewTarget: true }),
            id: 'new_event',
            permissions: ['events.actions.new']
        });
    }

    const onRenderCallout = ({ evt }) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 350
            }}>
                {utils.groups.apply([ 'date', 'start_date', 'end_date' ], User.Group.categories[`${evt.abstract.type}s`], (
                    <span style={{
                        ...Appearance.textStyles.title()
                    }}>{`${moment(evt.start).format('h:mma')} to ${moment(evt.end).format('h:mma')}`}</span>
                ))}
                {utils.groups.apply('address', User.Group.categories.leads, (
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{Utils.formatAddress(evt.abstract.object.lead.address)}</span>
                ))}
            </div>
        )
    }

    const onRenderEvent = (evt, props = {}) => {

        // return a loading component if abstract target is currently loading
        let { abstract, color, end, show_dates, start } = evt;
        if(loading === `${abstract.type}_${abstract.object.id}`) {
            return (
                <div
                {...props}
                style={{
                    alignItems: 'center',
                    background: Appearance.colors.softGradient(color),
                    border: `2px solid ${color}`,
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    height: SchedulerEventHeight,
                    justifyContent: 'center',
                    overflow: 'hidden',
                    paddingLeft: 24,
                    paddingRight: 24,
                    ...props.style
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={require('files/lottie/dots-white.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )   
        }

        // fallback to returning a standard abstract component
        return (
            <div
            {...props}
            style={{
                alignItems: 'center',
                background: Appearance.colors.softGradient(color),
                border: `2px solid ${color}`,
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                overflow: 'hidden',
                position: 'relative',
                ...props.style
            }}>
                <div
                className={abstract.type === 'demo_request' ? 'pinstripes' : ''}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: SchedulerEventHeight - 4, // subtract top and bottom border width
                    justifyContent: 'center',
                    width: '100%',
                }}>
                    {getEventTitle(evt, props)}
                    {utils.groups.apply('status', User.Group.categories.leads, (
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: 'white',
                            display: 'block',
                            overflow: 'hidden',
                            paddingLeft: 12,
                            paddingRight: 12,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{`${abstract.object.status.text}${show_dates ? `: ${moment(start).format('h:mma')} to ${moment(end).format('h:mma')}` : ''}`}</span>
                    ))}
                </div>
                {getRescheduledAccessoryIcon()}
            </div>
        )
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'download',
                permissions: ['events.actions.download'],
                style: 'default',
                title: 'Download'
            },{
                key: 'manage_users',
                style: 'default',
                title: 'Manage Users'
            },{
                key: 'new_event',
                permissions: ['events.actions.new'],
                style: 'default',
                title: 'New Event'
            },{
                key: 'active_users',
                style: 'default',
                title: `${showInactive.current ? 'Hide' : 'Show'} Inactive Users`,
                visible: calendarStyle === 'timeGrid'
            },{
                key: 'sync',
                style: 'default',
                title: 'Sync with External Calendar'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key === 'active_users') {
                offset.current = 0;
                showInactive.current = !showInactive.current;
                fetchEvents();;
                return;
            }
            if(key === 'download') {
                onDownloadCalendar();
                return;
            }
            if(key === 'manage_users') {
                offset.current = 0;
                setManagingUsers(true);
                return;
            }
            if(key === 'new_event') {
                onNewEventClick();
                return;
            }
            if(key === 'sync') {
                onSyncWithExternalCalendar();
                return;
            }
        })
    }

    const onRemoveEventAssignment = async evt => {
        utils.alert.show({
            title: 'Remove Assignment',
            message: 'Are you sure you want to unassign this event?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'canel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveEventAssignmentConfirm(evt);
                    return;
                }
            }
        });
    }

    const onRemoveEventAssignmentConfirm = async evt => {
        try {

            setEventLoading(evt.abstract);
            await Request.post(utils, '/events/', {
                id: evt.abstract.object.id,
                primary_user_id: null,
                type: 'set_assignment'
            });

            // end loading and update events
            setLoading(false);
            setEvents(events => {
                return events.map(prev_evt => {
                    if(prev_evt.id === evt.id) {
                        prev_evt.extendedProps.event.object.primary_user_id = null;
                    }
                    return prev_evt;
                });
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing the assignment from this event. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRenderResource = resource => {
        return (
            <div 
            className={isUserInDemo(resource.user_id) ? 'pinstripes in_demo' : ''}
            style={{
                width: '100%'
            }}>
                {Views.entry({
                    bottomBorder: false,
                    hoverClassName: isUserInDemo(resource.user_id) ? 'view-entry in_demo' : null,
                    icon: {
                        path: resource.avatar,
                        imageStyle: {
                            boxShadow: null
                        }
                    },
                    loading: loading === `user_${resource.user_id}`,
                    onClick: onUserClick.bind(this, resource.user_id),
                    rightContent: managingUsers === true && (
                        <img 
                        className={'text-button'}
                        onClick={onUserVisibilityClick.bind(this, resource.user_id)}
                        src={hiddenUsers.includes(resource.user_id) ? 'images/non-visible-icon-grey.png' : 'images/visible-icon-grey.png'}
                        style={{
                            height: 25,
                            marginLeft: 8,
                            objectFit: 'contain',
                            width: 25
                        }} />
                    ),
                    subTitle: utils.groups.apply('phone_number', User.Group.categories.users, resource.phone_number),
                    title: utils.groups.apply(['first_name', 'last_name'], User.Group.categories.users, resource.full_name)
                })}
            </div>
        )
    }

    const onRescheduleContextClick = (text, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Reschedule Reason',
            message: text
        });
    }

    const onRescheduleDemo = async ({ end_date, evt, reason, start_date }) => {
        try {
            setEventLoading(evt.extendedProps.event);
            let { context } = await Request.post(utils, '/demos/', {
                end_date: end_date.format('YYYY-MM-DD HH:mm:ss'),
                id: evt.id,
                reason: reason,
                start_date: start_date.format('YYYY-MM-DD HH:mm:ss'),
                type: 'reschedule'
            });

            // end loading and update reschedule context
            setLoading(false);
            setRescheduleContext(entries => {
                let tag = `${evt.extendedProps.event.type}_${evt.extendedProps.event.object.id}`;
                return update(entries, {
                    [tag]: {
                        $set: context
                    }
                });
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue rescheduling this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRescheduleEvent = async ({ end_date, evt, start_date }) => {
        try {
            setEventLoading(evt.extendedProps.event);
            await Request.post(utils, '/events/', {
                end_date: end_date.format('YYYY-MM-DD HH:mm:ss'),
                id: evt.id,
                primary_user_id: evt.resource_id,
                start_date: start_date.format('YYYY-MM-DD HH:mm:ss'),
                type: 'reschedule'
            });

            // end loading
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue rescheduling this event. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetUserDemoState = async (userID, val) => {
        try {
            setLoading(`user_${userID}`);
            let { state } = await Request.post(utils, '/demos/', {
                state: val,
                type: 'set_user_state',
                user_id: userID
            });

            setLoading(false);
            setUserStates(states => {
                states[userID] = state;
                return states;
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the demo state for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowUserDetails = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${userID}`,
                permissions: ['users.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onStatusClick = async (target, evt) => {
        try {

            // stop click propagation from triggering parent click
            evt.stopPropagation();

            // fetch details for demo
            setEventLoading(target);
            let demo = await Demo.get(utils, target.object.id);

            // end loading and show set status layer
            setLoading(false);
            utils.layer.open({
                id: `set_demo_status_${demo.id}`,
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo'
                }),
                Component: SetDemoStatus
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSyncWithExternalCalendar = () => {

        // determine if dealership has permissions to use this feature
        if(utils.subscriptions.capabilities.get('global_data.calendars.external_sync') === false) {
            utils.subscriptions.capabilities.reject();
            return;
        }

        // show options to prepare external calendar documentation
        utils.alert.show({
            title: 'Sync with External Calendar',
            message: 'When enabled, we can automatically sync your demos, demo requests, and custom calendar events with a nubmer of external calendar apps. Select a calendar app below to get started.',
            buttons: [{
                key: 'ical',
                title: 'Apple iCal',
                style: 'default'
            },{
                key: 'google',
                title: 'Google Calendar',
                style: 'default'
            },{
                key: 'outlook',
                title: 'Microsoft Outlook',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key !== 'cancel') {
                    onSyncWithExternalCalendarConfirm(key);
                    return;
                }
            }
        });
    }

    const onSyncWithExternalCalendarConfirm = app => {

        let dealership = utils.dealership.get();

        // create temporary text area and add to body 
        let textArea = document.createElement('textarea');
        textArea.value = `${API.server}/system/calendars/feeds/${dealership.id}/${utils.user.get().user_id}/${dealership.public_auth_token}`;
        textArea.style.opacity = 0;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);

        // focus and select textarea
        textArea.focus();
        textArea.select();

        // copy contents of textarea to clipboard
        navigator.clipboard.writeText(textArea.value);
        document.body.removeChild(textArea);
        
        // show confirmation alert with details for setting up subscription
        setTimeout(() => {
            utils.alert.show({
                title: 'All Done!',
                message: `We've copied a link to your clipboard that you can use to setup a calendar subscription. Click the "Learn More" button below to see the steps involved for setting up your calendar app subscription.`,
                buttons: [{
                    key: 'learn_more',
                    title: 'Learn More',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Done',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'learn_more') {
                        switch(app) {
                            case 'ical':
                            window.open('https://support.apple.com/guide/iphone/use-multiple-calendars-iph3d1110d4/ios');
                            break;
    
                            case 'google':
                            window.open('https://support.google.com/calendar/answer/37100');
                            break;
    
                            case 'outlook':
                            window.open('https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-or-outlook-on-the-web-cff1429c-5af6-41ec-a5b4-74f2c278e98c');
                            break;
                        }
                    }
                }
            });
        }, 250);
    }

    const onTimelineEventClick = (evt, mouseEvent) => {

        // determine if an assignent has been made for a custom event
        if(evt.abstract.type === 'event' && evt.abstract.object.primary_user_id) {
            utils.sheet.show({
                items: [{
                    key: 'assignment',
                    title: 'Remove Assignment',
                    style: 'destructive'
                },{
                    key: 'details',
                    title: 'View Details',
                    style: 'default'
                }],
                position: 'bottom',
                target: mouseEvent.target
            }, key => {
                if(key === 'assignment') {
                    onRemoveEventAssignment(evt);
                    return;
                }
                if(key === 'details') {
                    onEventClick(evt.abstract);
                    return;
                }
            });
            return;
        }

        // trigger an event click for demos and demo requests
        onEventClick(evt.abstract);
    }

    const onUpdateTimelineEvent = ({ next_evt, prev_evt }) => {

        // prevent demo updates for user accounts not found in the array
        let { admin, dealer, division_director, marketing_director, region_director } = User.levels.get();
        if([admin, dealer, division_director, marketing_director, region_director].includes(utils.user.get().level) === false) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like your account is unable to update demos. Please speak with your dealer or marketing director if you need to make changes to this demo.'
            });
            return;
        }

        // declare rescheduling flag and loading flag
        setLoading(prev_evt.id);
        let isRescheduling = next_evt.start !== prev_evt.start || next_evt.end !== prev_evt.end;

        // temporarily move event to new timeslot while user inputs are being requested
        if(isRescheduling) {
            setEvents(events => events.map(evt => {
                if(evt.id === prev_evt.id) {
                    return {
                        ...evt,
                        end: moment(next_evt.end).format('YYYY-MM-DD HH:mm:ss'),
                        resource_id: next_evt.resource_id,
                        restore: { ...evt },
                        start: moment(next_evt.start).format('YYYY-MM-DD HH:mm:ss'),
                    }
                }
                return evt;
            }));
        }

        // request confirmation if changing assignment
        if(next_evt.resource_id !== prev_evt.resource_id) {

            // declare user for resource
            let resource = users.find(resource => resource.user_id === next_evt.resource_id);

            // derermine if a custom event is being assigned and request confirmation to assign
            if(prev_evt.abstract.type === 'event') {
                utils.alert.show({
                    title: 'Assign Event',
                    message: `Are you sure that you want to assign this event to ${resource.full_name}? This event will be scheduled for ${moment(next_evt.start).format('h:mma')} to ${moment(next_evt.end).format('h:mma')}.`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'default'
                    },{
                        key: 'abort',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
    
                        // determine if a standard event update is needed
                        if(key === 'confirm') {
                            onUpdateEventConfirm(next_evt);
                            return;
                        }
    
                        // revert event back to pre-move event if above logic was not executed
                        setLoading(false);
                        setEvents(events => events.map(evt => evt.restore || evt));
                    }
                });
                return;
            }

            // request confirmation to assign demo
            utils.alert.show({
                title: 'Assign Demo',
                message: `Are you sure that you want to assign this demo to ${resource.full_name}? ${isRescheduling ? `This demo will be scheduled for ${moment(next_evt.start).format('h:mma')} to ${moment(next_evt.end).format('h:mma')}. Please explain your reason for rescheduling below since you have chosen a new timeframe for this demo.` : ''}`,
                ...isRescheduling && {
                    textFields: [{
                        key: 'reason',
                        placeholder: 'Reason for Rescheduling',
                        type: 'textview'
                    }],
                },
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'abort',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: response => {

                    // determine if a rescheduling update is needed
                    if(isRescheduling) {
                        if(response.reason && response.key === 'confirm') {
                            onUpdateDemoConfirm(next_evt, response.reason);
                        }
                    }

                    // determine if a standard event update is needed
                    if(response === 'confirm') {
                        onUpdateDemoConfirm(next_evt);
                        return;
                    }

                    // revert event back to pre-move event if above logic was not executed
                    setLoading(false);
                    setEvents(events => events.map(evt => evt.restore || evt));
                }
            });
            return;
        }

        // request confirmation for reschedule if start or end date have changed
        if(isRescheduling) {
            utils.alert.show({
                title: 'Reschedule Demo',
                message: `Are you sure that you want to reschedule this demo for ${moment(next_evt.start).format('h:mma')} to ${moment(next_evt.end).format('h:mma')}? Please provide your reason for rescheduling below.`,
                textFields: [{
                    key: 'reason',
                    placeholder: 'Reason for Rescheduling',
                    type: 'textview'
                }],
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'abort',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: ({ key, reason }) => {

                    // update event with new rescheduled data if applicable
                    if(reason && key === 'confirm') {
                        onUpdateDemoConfirm(next_evt, reason);
                        return;
                    }

                    // revert event back to pre-move event if above logic was not executed
                    setLoading(false);
                    setEvents(events => events.map(evt => evt.restore || evt));
                }
            });
            return;
        }

        // fallback to updating the event with data that does not need additional user input
        onUpdateDemoConfirm(next_evt);
    }

    const onUpdateDemoConfirm = async (evt, reason) => {
        try {

            // send request to server to update demo
            setEventLoading(evt.abstract);
            let { status } = await Request.post(utils, '/demos/', {
                end_date: moment(evt.end).format('YYYY-MM-DD HH:mm:ss'),
                id: evt.abstract.object.id,
                primary_user_id: evt.resource_id,
                reason: reason,
                start_date: moment(evt.start).format('YYYY-MM-DD HH:mm:ss'),
                type: 'update_calendar_event'
            });

            // update local events state
            setLoading(false);
            setEvents(events => {
                return events.map(prev_evt => {
                    if(prev_evt.id === evt.id) {

                        // update status object for the event and the target object
                        prev_evt.status = status;
                        prev_evt.extendedProps.event.object.status = status;

                        // update the end date for the event and the target object
                        prev_evt.end = moment(evt.end).format('YYYY-MM-DD HH:mm:ss');
                        prev_evt.extendedProps.event.object.end_date = prev_evt.end;

                        // update the end date for the event and the target object
                        prev_evt.start = moment(evt.start).format('YYYY-MM-DD HH:mm:ss');
                        prev_evt.extendedProps.event.object.start_date = prev_evt.start;

                        // update the user assignment for the event and the target object
                        prev_evt.extendedProps.event.object.user_id = evt.resource_id;
                        prev_evt.resource_id = evt.resource_id;
                    }
                    return prev_evt;
                });
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateEventConfirm = async evt => {
        try {

            // send request to server to update demo
            setEventLoading(evt.abstract);
            await Request.post(utils, '/events/', {
                end_date: evt.end,
                id: evt.abstract.object.id,
                primary_user_id: evt.resource_id,
                start_date: evt.start,
                type: 'reschedule'
            });

            // update local events state
            setLoading(false);
            setEvents(events => events.map(prev_evt => {
                if(prev_evt.id === evt.id) {
                    prev_evt.end = moment(evt.end).format('YYYY-MM-DD HH:mm:ss');
                    prev_evt.extendedProps.event.object.primary_user_id = evt.resource_id;
                    prev_evt.resource_id = evt.resource_id;
                    prev_evt.start = moment(evt.start).format('YYYY-MM-DD HH:mm:ss');
                    return prev_evt;
                }
                return prev_evt;
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this event. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTimelineEvents = () => {

        // filter out events that do not have a visible status code
        let targets = events.filter(evt => {
            return filters.includes(evt.status.code);
        }).map(evt => {

            // prepare variable for event
            let target = evt.extendedProps.event.object;
            let color = getColor(target.status ? target.status.code : null);

            // determine if a custom event type object should be returned
            if(evt.type === 'event') {
                return {
                    abstract: {
                        object: target,
                        type: evt.type
                    },
                    color: color,
                    end: evt.end,
                    id: evt.id,
                    movable: true,
                    resource_id: target.primary_user_id,
                    start: evt.start,
                    title: target.title
                }
            }

            // fallback to rendering event for demo or demo request
            return {
                abstract: {
                    object: target,
                    type: evt.type
                },
                color: color,
                end: evt.end,
                id: evt.id,
                movable: true,
                resource_id: target.user_id,
                start: evt.start,
                title: target.full_name
            }
        });

        // update local state with timeline events
        setTimelineEvents(targets);
    }

    const onUpdateUserPaging = next => {
        offset.current = next;
        setPaging({
            current_page: (offset.current / limit) + 1,
            number_of_pages: users.length > limit ? Math.ceil(users.length / limit) : 1
        });
    }

    const onUserClick = (userID, evt) => {
        let user = utils.user.get();
        utils.sheet.show({
            items: [{
                key: 'in_demo',
                title: 'Flag as "In Demo"',
                style: 'default',
                visible: isUserInDemo(userID) === false && user.level <= User.levels.get().dealer
            },{
                key: 'out_demo',
                title: 'Flag as "Out of Demo"',
                style: 'default',
                visible: isUserInDemo(userID) === true && user.level <= User.levels.get().dealer
            },{
                key: 'reassign',
                title: 'Reassign Tasks',
                style: 'default'
            },{
                key: 'unassign',
                title: 'Unassign Tasks',
                style: 'destructive'
            },{
                key: 'view',
                title: 'View Account Details',
                style: 'default'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            if(['in_demo', 'out_demo'].includes(key) === true) {
                onSetUserDemoState(userID, key);
                return;
            }
            if(key === 'reassign') {
                reassignTasks(utils, userID, ['demo'], setLoading);
                return;
            }
            if(key === 'unassign') {
                unassignTasks(utils, userID, ['demo'], setLoading);
                return;
            }
            if(key === 'view') {
                onShowUserDetails(userID);
                return;
            }
        });
    }

    const onUserVisibilityClick = (userID, evt) => {

        // stop event from propagating to parent element
        evt.stopPropagation();

        // update local state of hidden users
        let next = hiddenUsers.includes(userID) === true ? hiddenUsers.filter(id => id !== userID) : hiddenUsers.concat([userID]);
        setHiddenUsers(next);

        // update cookie with hidden users list
        Cookies.set('demo_calendar.hidden_user_ids', JSON.stringify(next));
    }

    const getButtons = () => {

        // determine if user managment is enabled
        if(managingUsers === true) {
            return [{
                key: 'managing_users',
                onClick: setManagingUsers.bind(this, false),
                style: 'default',
                title: 'Done'
            }];
        }

        // return list of default button options
        return [{
            key: 'day_view',
            onClick: setCalendarStyle.bind(this, 'dayGrid'),
            style: Appearance.colors.tertiary(),
            title: 'Day View'
        },{
            key: 'time_view',
            onClick: setCalendarStyle.bind(this, 'timeGrid'),
            style: Appearance.colors.primary(),
            title: 'Time View'
        },{
            key: 'week_view',
            onClick: setCalendarStyle.bind(this, 'weekGrid'),
            style: Appearance.colors.secondary(),
            title: 'Week View'
        },{
            key: 'options',
            onClick: onOptionsClick,
            style: 'grey',
            title: 'Options'
        }];
    }

    const getCalendar = () => {
        if(calendarStyle === 'dayGrid') {

            let events = getTimelineEvents();
            return (
                <div 
                className={'pr-lg-3'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 12
                    }}>
                        <DatePickerField
                        onDateChange={setTargetDate}
                        selected={targetDate}
                        utils={utils}
                        style={{
                            flexGrow: 1
                        }}/>
                    </div>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        width: '100%'
                    }}>
                        {events.length === 0 && (
                            Views.entry({
                                bottomBorder: false,
                                hideIcon: true,
                                subTitle: `No events were found for ${Utils.formatDate(targetDate, true)}`,
                                title: 'No Events Found'
                            })
                        )}
                        {events.length > 0 && (
                            <table
                            className={'px-3 py-2 m-0'}
                            style={{
                                width: '100%'
                            }}>
                                <thead style={{
                                    width: '100%'
                                }}>
                                    <TableListHeader fields={getCalendarDayGridFields()} />
                                </thead>
                                <tbody style={{
                                    width: '100%'
                                }}>
                                    {events.sort((a,b) => {
                                        return a.start < b.start ? -1 : 1;
                                    }).map((evt, index, events) => {
                                        let fields = getCalendarDayGridFields(evt);
                                        return (
                                            <tr
                                            key={index}
                                            className={`view-entry ${window.theme}`}
                                            style={{
                                                borderBottom: index !== events.length -1 && `1px solid ${Appearance.colors.divider()}`
                                            }}>
                                                {fields.map((field, index) => {
                                                    return (
                                                        <td
                                                        key={index}
                                                        className={'px-3 py-2 flexible-table-column'}
                                                        onClick={onEventClick.bind(this, evt.abstract)}>
                                                            <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            )
        }
        if(calendarStyle === 'timeGrid') {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                    <Scheduler
                    date={targetDate}
                    events={getTimelineEvents()}
                    loading={loading}
                    minuteSteps={30}
                    onClick={onTimelineEventClick}
                    onDateChange={setTargetDate}
                    onRenderCallout={onRenderCallout}
                    onRenderEvent={onRenderEvent}
                    onRenderResource={onRenderResource}
                    onUpdate={onUpdateTimelineEvent}
                    operatingHours={operatingHours}
                    resources={getUsers()}
                    utils={utils}
                    options={{
                        creatable: false,
                        crossResourceMove: false,
                        headerLabel: 'First and Last Name',
                        moveable: false,
                        resizable: true
                    }}
                    style={{
                        flexGrow: 1,
                        paddingRight: 15
                    }}/>
                    <PageControl
                    data={paging}
                    limit={limit}
                    offset={offset.current}
                    onClick={onUpdateUserPaging}
                    style={{
                        borderTop: 'none',
                        paddingTop: 0
                    }}/>
                </div>
            )
        }
        return (
            <div style={{
                paddingRight: 15
            }}>
                <DatePickerField
                utils={utils}
                selected={targetDate}
                blockStyle={'week'}
                insetLabel={'The Week of'}
                insetLabelStyle={{
                    minWidth: 65
                }}
                highlightDates={date => {
                    return date.unix() >= moment(targetDate).startOf('week').add(1, 'days') && date.unix() <= moment(targetDate).endOf('week').add(1, 'days')
                }}
                onDateChange={setTargetDate}
                style={{
                    marginBottom: 8
                }}/>
                <WeekCalendar
                defaultDate={targetDate}
                draggable={true}
                events={getEvents()}
                onEventRender={onEventRender}
                onEventDrop={onEventDrop} 
                utils={utils}/>
            </div>
        )
    }

    const getCalendarDayGridFields = evt => {

        // prepare optional primary assignment user
        let assignment = evt && evt.resource_id && users.find(user => user.user_id === evt.resource_id);

        // determine if target is a custom event
        if(evt && evt.abstract.type === 'event') {

            // prepare attachment target
            let target = evt.abstract.object.demo || evt.abstract.object.lead;

            return [{
                key: 'start',
                sortable: false,
                title: 'Scheduled Time',
                value: moment(evt.start).format('h:mma')
            },{
                key: 'type',
                sortable: false,
                title: 'Event Type',
                value: evt.title
            },{
                key: 'full_name',
                sortable: false,
                title: 'Customer',
                value: target && target.full_name
            },{
                key: 'phone_number',
                sortable: false,
                title: 'Phone Number',
                value: target && target.phone_number
            },{
                key: 'address',
                sortable: false,
                title: 'Address',
                value: target && target.address && Utils.formatAddress(target.address)
            },{
                key: 'assignment',
                sortable: false,
                title: 'Assigned To',
                value: assignment && assignment.full_name || 'Not available'
            },{
                key: 'status',
                sortable: false,
                title: 'Status',
                value: evt.abstract.object.status && getEventStatus(utils, evt.abstract.object)
            }];
        }

        // fallback to rendering a demo or demo request entry
        return [{
            key: 'start',
            sortable: false,
            title: 'Scheduled Time',
            value: evt && moment(evt.start).format('h:mma')
        },{
            key: 'type',
            sortable: false,
            title: 'Event Type',
            value: evt ? (evt.abstract.type === 'demo_request' ? 'Demo Request' : 'Demo') : null
        },{
            key: 'full_name',
            sortable: false,
            title: 'Customer',
            value: evt && evt.abstract.object.full_name
        },{
            key: 'phone_number',
            sortable: false,
            title: 'Phone Number',
            value: evt && evt.abstract.object.phone_number
        },{
            key: 'address',
            sortable: false,
            title: 'Address',
            value: evt && evt.abstract.object.address && Utils.formatAddress(evt.abstract.object.address)
        },{
            key: 'assignment',
            sortable: false,
            title: 'Assigned To',
            value: assignment && assignment.full_name || 'Not available'
        },{
            key: 'status',
            sortable: false,
            title: 'Status',
            value: evt && evt.abstract.object.status && getDemoStatus(utils, evt.abstract.object)
        }];
    }

    const getCategories = () => {
        let shouldSelectAll = categories.length !== categoryItems.length;
        return (
            <LayerItem 
            collapsed={false}
            title={'Categories'}>
                <MultipleListField
                items={getCategoryItems()}
                onChange={onCategoriesChange}
                onRenderResult={(item, index, items, onRemoveItem) => {
                    let isCustomType = ['demo', 'demo_request'].includes(item.id) === false;
                    return (
                        <div
                        className={isCustomType ? `view-entry ${window.theme}` : ''}
                        key={index}
                        onClick={isCustomType ? onEventTypeClick.bind(this, index) : null}
                        style={{
                            alignItems: 'center',
                            borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '8px 12px 8px 12px'
                        }}>
                            <img 
                            src={item.icon.url}
                            style={{
                                borderRadius: 10,
                                height: 20,
                                marginRight: 8,
                                overflow: 'hidden',
                                width: 20
                            }} />
                            <span style={{
                                ...Appearance.textStyles.key(),
                                flexGrow: 1,
                                textAlign: 'left',
                                whiteSpace: 'normal'
                            }}>{item.title}</span>
                            <img
                            src={`images/grey-close-icon-small.png`}
                            className={'text-button'}
                            onClick={onRemoveItem.bind(this, index)}
                            style={{
                                height: 15,
                                marginLeft: 8,
                                objectFit: 'contain',
                                opacity: 1,
                                width: 15
                            }} />
                        </div>
                    )
                }}
                onAddNew={onAddNewEventType}
                placeholder={'Choose a category from the list...'}
                value={categoryItems.filter(item => categories.includes(item.id))}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 8,
                    width: '100%'
                }}>
                    <AltBadge
                    onClick={setCategories.bind(this, [])}
                    content={{
                        color: Appearance.colors.grey(),
                        text: 'Deselect All'
                    }}
                    style={{
                        marginRight: shouldSelectAll ? 4 : 0,
                        padding: '5px 16px 5px 16px',
                        width: '100%'
                    }}/>
                    {shouldSelectAll && (
                        <AltBadge
                        onClick={onCategoriesChange.bind(this, categoryItems)}
                        content={{
                            text: 'Select All',
                            color: Appearance.colors.primary()
                        }}
                        style={{
                            marginLeft: 4,
                            marginRight: 0,
                            padding: '5px 16px 5px 16px',
                            width: '100%'
                        }}/>
                    )}
                </div>
            </LayerItem>
        )
    }

    const getCategoryItems = () => {
        return categoryItems.filter(item => item.active !== false);
    }

    const getColor = status => {
        if(!status) {
            return null;
        }
        if(status.color) {
            return status.color;
        }
        let field = Demo.styles.status.find(prevField => prevField.status === status);
        return field ? field.color : Appearance.colors.grey();
    }

    const getDefaultCategories = () => {
        return [{
            active: true,
            icon: {url: 'images/calendar-demos-icon.png'},
            id: 'demo',
            title: 'Demos'
        },{
            active: true,
            icon: {url: 'images/calendar-demo-requests-icon.png'},
            id: 'demo_request',
            title: 'Demo Requests'
        }];
    }

    const getEvents = userID => {
        return events.filter(calEvent => {

            // require that event status falls within the list of selected status codes
            if(filters.includes(calEvent.status.code) === false) {
                return false;
            }

            // restrict to a specific user id if applicable
            if(userID && calEvent.extendedProps.event.user_id !== userID) {
                return false;
            }

            // fallback to retricting event to event type
            let type = calEvent.type === 'event' ? calEvent.extendedProps.event.object.type.id : calEvent.extendedProps.event.type;
            return categories.includes(type);
        });
    }

    const getEventTitle = evt => {

        // determine if event represents a demo 
        if(evt.abstract.type === 'demo') {

            let { first_name, last_name, spouse_first_name } = evt.abstract.object;
            return utils.groups.apply([ 'first_name', 'last_name', 'spouse_first_name' ], User.Group.categories.leads, (
                <span style={{
                    ...Appearance.textStyles.title(),
                    color: 'white',
                    display: 'block',
                    overflow: 'hidden',
                    paddingLeft: 12,
                    paddingRight: 12,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>{`${first_name}${spouse_first_name ? ` & ${spouse_first_name}` : ''} ${last_name || ''}`}</span>
            ))
        }
        
        // fallback to returning title for custom event type
        return (
            <span style={{
                ...Appearance.textStyles.title(),
                color: 'white',
                display: 'block',
                overflow: 'hidden',
                paddingLeft: 12,
                paddingRight: 12,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{evt.abstract.object.title}</span>
        )
    }

    const getFilters = () => {
        return (
            <LayerItem 
            collapsed={false}
            title={'Status Codes'}>
                <StatusCodeFilters
                categories={['demos','demo_requests','events']}
                dealership={dealership}
                onChange={setFilters} 
                storage={'demo'}
                utils={utils}/>
            </LayerItem>
        )
    }

    const getLocalStorageKey = () => {
        let dealership = utils.dealership.get();
        return `${dealership.id}.categories.selected.calendar`;
    }

    const getRescheduledAccessoryIcon = () => {
        if(!rescheduleContext || typeof(rescheduleContext.text) !== 'string' || rescheduleContext.text.trim().length === 0) {
            return null;
        }
        return (
            <img
            className={'text-button'}
            onClick={onRescheduleContextClick.bind(this, rescheduleContext.text)}
            src={'images/event-context-icon.png'}
            style={{
                height: 25,
                marginLeft: 12,
                marginRight: 12,
                width: 25
            }} />
        )
    }

    const getRescheduleContext = calEvent => {
        let entry = rescheduleContext && rescheduleContext[`${calEvent.type}_${calEvent.object.id}`];
        return entry && entry.message ? entry : null;
    }

    const getTimelineEvents = () => {
        return timelineEvents.filter(calEvent => {
            if(filters.includes(calEvent.abstract.object.status.code) === false) {
                return false;
            }
            if(calendarStyle === 'dayGrid') {
                return moment(calEvent.start).isSame(targetDate, 'day');
            }
            return true;
        });
    }

    const getUsers = () => {
        let levels = User.levels.get();
        return users.filter(user => {

            // filter out users who are not advisors or dealers
            if([levels.dealer, levels.safety_advisor].includes(user.level) === false) {
                return false;
            }

            // hidden users have to be filtered out before paging index props are applied
            return managingUsers === false && hiddenUsers.includes(user.user_id) === true ? false : true;

        }).filter((_, index) => {

            // restrict users down to current page if paging props were provided
            if(paging) {
                return index >= offset.current && index < offset.current + limit;
            }

            // return user without any additional logic 
            return true;
        });
    }

    const fetchEvents = async () => {
        try {

            // fetch calendar events from server
            setLoading(true);
            let { events, event_types, operating_hours, reschedule_context, users, user_states } = await Request.get(utils, '/events/', {
                end_date: moment(targetDate).endOf('week').add(1, 'days').format('YYYY-MM-DD'),
                reschedule_context: true,
                start_date: moment(targetDate).startOf('week').add(1, 'days').format('YYYY-MM-DD'),
                type: 'calendar',
                users_list: true
            });

            // set operating hours and reschedule context object, both are nullable
            setLoading(false);
            setOperatingHours(operating_hours);
            setRescheduleContext(reschedule_context);

            // prepare list of calendar event types
            setupEventTypes(event_types);

            // update local state with dealership users and user states
            setUsers(users);
            setUserStates(user_states);

            // loop through events and format for calendar
            setEvents(events.map(target => {
                switch(target.type) {
                    case 'demo':
                    case 'event':
                    return {
                        end: target.object.end_date,
                        eventResourceEditable: true,
                        eventStartEditable: true,
                        extendedProps: {
                            event: target
                        },
                        id: target.object.id,
                        start: target.object.start_date,
                        status: target.object.status,
                        type: target.type
                    }

                    case 'demo_request':
                    return {
                        end: moment(target.object.date).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                        eventResourceEditable: true,
                        eventStartEditable: true,
                        extendedProps: {
                            event: target
                        },
                        id: target.object.id,
                        start: target.object.date,
                        status: target.object.status,
                        type: target.type
                    }
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the demos calendar. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchHiddenUsers = () => {
        let hiddenUsers = Cookies.get('demo_calendar.hidden_user_ids') || [];
        if(typeof(hiddenUsers) === 'string') {
            hiddenUsers = JSON.parse(hiddenUsers);
        }
        setHiddenUsers(hiddenUsers);
    }

    const formatAbstractEvent = abstract => {

        // demos and custom events share a similar structure but no additional formatting is required for custom events
        if(abstract.type === 'event') {
            return { 
                object: {
                    ...abstract.object,
                    primary_user_id: abstract.object.primary_user ? abstract.object.primary_user.user_id : abstract.object.primary_user_id
                },
                type: abstract.type
            };
        }

        return {
            object: {
                created: abstract.object.created,
                date: abstract.object.date,
                end_date: abstract.object.end_date,
                first_name:abstract.object.lead.first_name,
                full_name: abstract.object.lead.full_name,
                last_name: abstract.object.lead.last_name,
                lead_type: abstract.object.lead.lead_type,
                id: abstract.object.id,
                phone_number: abstract.object.lead.phone_number,
                spouse_first_name: abstract.object.lead.spouse_first_name,
                start_date: abstract.object.start_date,
                status: abstract.object.status,
                street_address_1: abstract.object.address && abstract.object.address.street_address_1,
                ...abstract.type === 'demo' && {
                    user_id: abstract.object.primary ? abstract.object.primary.user_id : abstract.object.primary_user_id
                }, 
                ...abstract.type === 'demo_request' && {
                    user_id: abstract.object.requested_by_user ? abstract.object.requested_by_user.user_id : abstract.object.requested_by_user_id
                }
            },
            type: abstract.type
        }
    }

    const isUserInDemo = userID => {
        return userStates && userStates[userID] && userStates[userID].state === 'in_demo' ? true : false;
    }

    const setupEventTypes = async types => {
        try {
            
            // format custom categories using response event types
            let eventTypes = types.map(type => Event.Type.create(type)).filter(type => type.active);
    
            // sort categories alphabetically and update state
            let selections = getDefaultCategories().concat(eventTypes).sort((a,b) => a.title.localeCompare(b.title));
            setCategoryItems(selections);

            // fetch cached selection for filters from local storage
            let key = getLocalStorageKey();
            let state = localStorage.getItem(key);

            // filter out non-selection ids if applicable and update values state
            if(state) {

                // parse cached selections and prepare new list of selected codes
                let ids = JSON.parse(state);
                selections = selections.filter(entry => ids.includes(entry.id));
            }

            // set state for selections
            setCategories(selections.map(entry => entry.id));

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        onUpdateTimelineEvents();
    }, [calendarStyle, events]);

    useEffect(() => {
        fetchEvents();
    }, [targetDate]);

    useEffect(() => {
        let targets = users.filter(user => {
            if([User.levels.get().dealer, User.levels.get().safety_advisor].includes(user.level) === false) {
                return false;
            }
            return managingUsers === false && hiddenUsers.includes(user.user_id) === true ? false : true
        });
        setPaging({
            current_page: (offset.current / limit) + 1,
            number_of_pages: targets.length > limit ? Math.ceil(targets.length / limit) : 1
        });
    }, [managingUsers, users]);

    useEffect(() => {

        fetchHiddenUsers();
        utils.events.on(panelID, 'dealership_change', onDealershipChange);
        utils.events.on(panelID, 'dealership_preferences_update', fetchEvents);
        utils.events.on(panelID, 'slot_change', fetchEvents);
        
        utils.content.subscribe(panelID, ['demo', 'demo_request', 'demo_status', 'demo_request_status', 'event', 'event_type'], {
            onFetch: fetchEvents,
            onUpdate: onContentUpdate
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', onDealershipChange);
            utils.events.off(panelID, 'dealership_preferences_update', fetchEvents);
            utils.events.off(panelID, 'slot_change', fetchEvents);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        index={index}
        utils={utils}
        name={'Calendar'}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true
        }}>
            <PermissionsContainer
            permission={calendarStyle === 'timeGrid' ? 'calendar.time_view' : 'calendar.week_view'} 
            utils={utils}>
                <div className={'row p-0 m-0'}>
                    <div
                    ref={contentContainer}
                    className={'col-12 col-md-8 col-lg-9 col-xl-10 p-0'}
                    style={{
                        height: '100%'
                    }}>
                        {getCalendar()}
                    </div>
                    <div
                    className={'col-12 col-md-4 col-lg-3 col-xl-2 p-0 pl-md-2 pr-md-3 pt-md-0 pb-md-2 custom-scrollbars'}
                    style={{
                        position: 'relative',
                        borderLeft: `1px solid ${Appearance.colors.divider()}`,
                        maxHeight: 940,
                        overflowY: 'scroll'
                    }}>
                        {getCategories()}
                        {getFilters()}
                    </div>
                </div>
            </PermissionsContainer>
        </Panel>
    )
}

export const DemosList = ({ category, cache_key }, { index, options, utils }) => {

    const limit = 15;
    const offset = useRef(0);

    const sorting = useRef({ 
        sort_key: category === 'demos' ? 'start_date' : 'created' ,
        sort_type: Content.sorting.type.descending
    });

    const [demos, setDemos] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [showExtendedFields, setShowExtendedFields] = useState(false);
    const [updateAvailable, setUpdateAvailable] = useState(false);

    const onDemoClick = async id => {
        try {

            // start loading and fetch demo details
            setLoading(id);
            let demo = await Demo.get(utils, id);

            // end loading and show demo details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo'
                }),
                Component: DemoDetails,
                id: `demo_details_${demo.id}`,
                permissions: ['demos.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDemoListDataChange = evt => {
        try {

            // no additional logic is needed if the update is not meant for this panel
            let { initiated_by_user_id, key } = evt;
            if(!cache_key || key !== `list.${cache_key}`) {
                return;
            }

            // automatically update panel contents if the change was made by the current user
            // otherwise show a button on the panel that allows the user to manually refresh the contents of the panel
            if(initiated_by_user_id === utils.user.get().user_id) {
                console.log(`[demos.list.${cache_key}]: automatically fetching for user who initiated data change`);
                fetchDemos();
            } else {
                console.log(`[demos.list.${cache_key}]: update available`);
                setUpdateAvailable(true);
            }

        } catch(e) {
            console.error(`[on_demo_list_data_change]: ${e.message}`);
        }
    }

    const onDemoRequestListDataChange = evt => {
        try {

            // no additional logic is needed if the update is not meant for this panel
            let { initiated_by_user_id, key } = evt;
            if(!cache_key || key !== `list.${cache_key}`) {
                return;
            }

            // automatically update panel contents if the change was made by the current user
            // otherwise show a button on the panel that allows the user to manually refresh the contents of the panel
            if(initiated_by_user_id === utils.user.get().user_id) {
                console.log(`[demo_requests.list.${cache_key}]: automatically fetching for user who initiated data change`);
                fetchDemos();
            } else {
                console.log(`[demo_requests.list.${cache_key}]: update available`);
                setUpdateAvailable(true);
            }

        } catch(e) {
            console.error(`[on_demo_request_list_data_change]: ${e.message}`);
        }
    }

    const onDemoRequestClick = async id => {
        try {

            // start loading and fetch demo request details
            setLoading(id);
            let demo = await DemoRequest.get(utils, id);

            // end loading and show demo request details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo_request'
                }),
                Component: DemoRequestDetails,
                id: `demo_request_details_${demo.id}`,
                permissions: ['demo_requests.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this demo request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPrintDemos = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { demos } = await Request.get(utils, '/demos/', {
                    category: category,
                    search_text: searchText,
                    type: 'all',
                    ...sorting.current,
                    ...props
                });

                setLoading(false);
                resolve(demos);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    const onToggleExtendedFields = () => {
        setShowExtendedFields(status => !status);
    }

    const getContent = () => {
        if(demos.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: `No ${category === 'demos' ? 'demos' : 'demo requests'} were found in the system`,
                    title: `No ${category === 'demos' ? 'Demos' : 'Demo Requests'} Found`
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {demos.map((demo, index) => {
                        return getFields(demo, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (demo, index) => {

        let target = demo || {};
        let fields = [{
            key: 'full_name',
            title: 'Full Name',
            value: target.full_name ? utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.leads, target.full_name) : 'Customer name not available'
        },{
            key: 'address',
            title: 'Address',
            value: target.address ? utils.groups.apply('address', User.Group.categories.leads, target.address.street_address_1) : null
        },{
            key: 'start_date',
            title: 'Scheduled Date',
            value: target.start_date ? utils.groups.apply('start_date', User.Group.categories.demos, Utils.formatDate(target.start_date)) : null,
            visible: category === 'demos'
        },{
            key: 'created',
            title: 'Created',
            value: target.created ? utils.groups.apply('created', User.Group.categories.demo_requests, Utils.formatDate(target.created)) : null,
            visible: category === 'demo_requests'
        },{
            key: 'user',
            title: 'Primary Assignment',
            value: target.user ? target.user.full_name : null,
            visible: showExtendedFields
        },{
            key: 'status',
            title: 'Status',
            sortable: false,
            value: utils.groups.apply('status', User.Group.categories.demos, category === 'demo_requests' ? getDemoRequestStatus(utils, target) : getDemoStatus(utils, target))
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!demo) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchDemos();
                }}
                value={sorting.current} />
            )
        }

        // loop through result rows and prepare table rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                if(field.visible === false) {
                    return null
                }
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={category === 'demos' ? onDemoClick.bind(this, demo.id) : onDemoRequestClick.bind(this, demo.id)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            headers: [{
                key: 'full_name',
                title: 'Full Name'
            },{
                key: 'address',
                title: 'Address'
            },{
                key: 'start_date',
                title: 'Date',
                visible: category === 'demos'
            },{
                key: 'user',
                title: 'Assigned To'
            },{
                key: 'status',
                title: 'Status'
            }],
            onFetch: onPrintDemos,
            onRenderItem: item => ({
                address: item.address ? utils.groups.apply('address', User.Group.categories.leads, Utils.formatAddress(item.address)) : null,
                full_name: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.leads, item.full_name),
                start_date: item.start_date ? utils.groups.apply('start_date', User.Group.categories.demos, moment(item.start_date).format('MM/DD/YYYY [at] h:mma')) : null,
                status: utils.groups.apply('status', User.Group.categories.demos, getDemoStatus(utils, item)),
                user: item.user ? item.user.full_name : null
            }),
            permissions: ['demos.actions.print']
        }
    }

    const getSubTitleProps = () => {
        if(updateAvailable) {
            return {
                onClick: fetchDemos,
                style: {
                    ...Appearance.textStyles.subTitle(),
                    color: Appearance.colors.green,
                    fontWeight: 700
                },
                text: 'New Content Available'
            }
        }
        return {
            text: lastUpdated ? `Last Updated: ${Utils.formatDate(lastUpdated)}` : 'Awaiting content...'
        }
    }

    const connectToSockets = async () => {
        try {
            switch(category) {
                case 'demos':
                await utils.sockets.persistOn('demos', 'on_demo_list_data_change', onDemoListDataChange);
                break;


                case 'demo_requests':
                await utils.sockets.persistOn('demos', 'on_demo_request_list_data_change', onDemoRequestListDataChange);
                break;

                default:
                console.error('unsupported list subscription category');
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            switch(category) {
                case 'demos':
                await utils.sockets.off('demos', 'on_demo_list_data_change', onDemoListDataChange);
                break;


                case 'demo_requests':
                await utils.sockets.off('demos', 'on_demo_request_list_data_change', onDemoRequestListDataChange);
                break;

                default:
                console.error('unsupported list subscription category');
            }
            
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchDemos = async () => {
        try {
            setLoading(true);
            let { demos, last_updated, paging } = await Request.get(utils, '/demos/', {
                cache_key: 'general',
                category: category,
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'all',
                ...sorting.current
            });

            setLoading(false);
            setLastUpdated(last_updated && moment.utc(last_updated).local());
            setPaging(paging);
            setDemos(demos.map(demo => ({
                ...demo,
                start_date: demo.start_date && moment(demo.start_date)
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${category === 'demos' ? 'demos' : 'demo requests'} list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchDemos();
    }, [searchText]);

    useEffect(() => {

        connectToSockets();

        // subscribe to dealership event changes
        utils.events.on(category, 'dealership_change', fetchDemos);
        utils.events.on(category, 'dealership_preferences_update', fetchDemos);
        utils.events.on(category, 'slot_change', fetchDemos);

        // subscribe to abstract target changes
        utils.content.subscribe(category, ['demo', 'demo_status'], {
            onFetch: fetchDemos,
            onUpdate: abstract => {
                setDemos(demos => {
                    return demos.map(demo => {
                        if(demo.id !== abstract.getID()) {
                            return demo;
                        }
                        switch(abstract.type) {
                            case 'demo':
                            return {
                                address: abstract.object.lead.address,
                                created: abstract.object.created,
                                date: abstract.object.date,
                                first_name: abstract.object.lead.first_name,
                                full_name: abstract.object.lead.full_name,
                                id: abstract.object.id,
                                last_name: abstract.object.lead.last_name,
                                start_date: abstract.object.start_date,
                                status: abstract.object.status,
                                user_id: abstract.object.primary && abstract.object.primary.user_id
                            }

                            case 'demo_status':
                            return {
                                ...demo,
                                status: abstract.object.status
                            }
                        }
                    })
                });
            }
        });

        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(category);
            utils.events.off(category, 'dealership_change');
            utils.events.off(category, 'dealership_preferences_update', fetchDemos);
            utils.events.off(category, 'slot_change', fetchDemos);
        }
    }, []);

    return (
        <Panel
        panelID={category}
        name={category === 'demos' ? 'Demos' : 'Demo Requests'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: [{
                key: 'toggle_extended',
                onClick: onToggleExtendedFields,
                style: showExtendedFields ? 'default' : 'secondary',
                title: `${showExtendedFields ? 'Hide' : 'Show'} Assignment`
            }],
            loading: loading,
            onRefreshContent: {
                pulse: {
                    color: Appearance.colors.green,
                    enabled: updateAvailable
                },
                onClick: fetchDemos
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => {
                    offset.current = next;
                    fetchDemos();
                }
            },
            search: {
                onChange: setSearchText,
                placeholder: 'Search by demo id or lead first and last name...'
            },
            subTitle: getSubTitleProps()
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const FeedbackTemplates = ({ index, options, utils }) => {

    const panelID = 'feedback_templates';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sorting, setSorting] = useState(null);
    const [templates, setTemplates] = useState([]);

    const getActiveStatus = template => {
        if(!template) {
            return;
        }
        let color = template.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, template)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{template.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const onChangeActiveStatus = async (template, e) => {
        try {
            e.stopPropagation();
            if(!template.dealership_id) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'This is a standard template meant for all Dealerships and can not be edited'
                });
                return;
            }
            await Request.post(utils, '/dealerships/', {
                type: 'set_feedback_template_active_status',
                id: template.id,
                active: !template.active
            });
            setTemplates(templates => {
                return templates.map(prevTemplate => {
                    if(prevTemplate.id === template.id) {
                        prevTemplate.active = !prevTemplate.active;
                    }
                    return prevTemplate;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewTemplate = () => {

        let template = Feedback.Template.new();
        template.dealership = utils.dealership.get();
        template.dealership_id = template.dealership.id;

        utils.layer.open({
            id: 'new_feedback_template',
            abstract: Abstract.create({
                type: 'feedback_template',
                object: template
            }),
            Component: AddEditFeedbackTemplate.bind(this, {
                isNewTarget: true
            })
        })
    }

    const onPrintTemplates = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { templates } = await Request.get(utils, '/dealerships/', {
                    type: searchText ? 'lookup_feedback_templates' : 'feedback_templates',
                    search_text: searchText,
                    ...sorting,
                    ...props
                });

                setLoading(false);
                resolve(templates.map(template => Feedback.Template.create(template)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onTemplateClick = template => {
        utils.layer.open({
            id: `feedback_template_details_${template.id}`,
            abstract: Abstract.create({
                type: 'feedback_template',
                object: template
            }),
            Component: FeedbackTemplateDetails
        })
    }

    const getContent = () => {
        if(templates.length === 0) {
            return (
                Views.entry({
                    title: 'No Templates Found',
                    subTitle: 'No templates were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {templates.map((template, index) => {
                        return getFields(template, index);
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (template, index) => {

        let target = template || {};
        let fields = [{
            key: 'title',
            title: 'Title',
            value: target.title
        },{
            key: 'description',
            title: 'Description',
            value: target.description
        },{
            key: 'is_default',
            title: 'Default for Dealership',
            value: target.is_default ? 'Yes' : 'No'
        },{
            key: 'status',
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!template) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'title',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== templates.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onTemplateClick.bind(this, template)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintTemplates,
            onRenderItem: (item, index, items) => {
                return {
                    title: item.title,
                    description: item.description,
                    is_default: item.is_default ? 'Yes' : 'No',
                    status: getActiveStatus(item)
                }
            },
            headers: [{
                key: 'title',
                title: 'Title'
            },{
                key: 'description',
                title: 'Description'
            },{
                key: 'is_default',
                title: 'Default for Dealership'
            },{
                key: 'status',
                title: 'Status'
            }]
        }
    }

    const fetchTemplates = async () => {
        try {
            setLoading(true);
            let { templates, paging } = await Request.get(utils, '/dealerships/', {
                type: 'feedback_templates',
                limit: limit,
                offset: offset,
                search_text: searchText,
                ...sorting
            });

            setLoading(false);
            setPaging(paging);
            setTemplates(templates.map(template => Feedback.Template.create(template)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the templates list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTemplates();
    }, [offset, searchText, sorting]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchTemplates);
        utils.events.on(panelID, 'dealership_preferences_update', fetchTemplates);
        utils.content.subscribe(panelID, ['feedback_template'], {
            onFetch: fetchTemplates,
            onUpdate: abstract => {
                setTemplates(templates => {
                    return templates.map(template => {
                        return template.id === abstract.getID() ? abstract.object : template
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchTemplates);
            utils.events.off(panelID, 'dealership_preferences_update', fetchTemplates);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Templates'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: [{
                key: 'new',
                title: 'New Template',
                style: 'default',
                onClick: onNewTemplate
            }],
            loading: loading,
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: setOffset
            },
            search: {
                placeholder: 'Search by template title...',
                onChange: setSearchText
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

// Layers
export const AddEditDemo = ({ abstract, index, options, utils }) => {

    const layerID = `edit_demo_${abstract.getID()}`;
    const [demo, setDemo] = useState(null);
    const [feedbackTemplates, setFeedbackTemplates] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(null);

    const canSetAssignments = () => {

        // prevent demo assignment changes for user accounts not found in the array
        return [
            User.levels.get().admin,
            User.levels.get().dealer,
            User.levels.get().division_director,
            User.levels.get().marketing_director,
            User.levels.get().region_director
        ].includes(utils.user.get().level);
    }

    const canShowQualifiedDemoToggle = () => {
        let { lead } = abstract.object;
        return lead.user && lead.user.level === User.levels.get().safety_associate;
    }

    const shouldRequireRescheduleReason = () => {
        if(!demo) {
            return false;
        }
        return moment(abstract.object.start_date).unix() !== moment(demo.start_date).unix() || moment(abstract.object.end_date).unix() !== moment(demo.end_date).unix();
    }

    const onDoneClick = async () => {
        try {

            // prevent moving forward if start and end dates are conflicting
            if(demo.start_date > demo.end_date) {
                throw new Error('It looks like your end date is before your start date. Please check your dates before moving on');
            }

            // set loading flag and verify that required variables were provided
            setLoading('done');
            await validateRequiredFields(getFields);
            
            // sleep briefly and submit request to server
            await Utils.sleep(0.25);
            await abstract.object.update(utils, { reason: reason });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The demo for ${abstract.object.lead.full_name} has been updated`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props)
        setDemo(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: 'Save Changes'
        }];
    }

    const getFields = () => {

        if(!demo) {
            return [];
        }

        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'primary',
                required: false,
                visible: canSetAssignments(),
                title: 'Primary Assignment',
                description: 'You can assign this demo to someone in your dealership if know who will be performing this demo. Assigning someone to this demo is not required',
                component: 'user_lookup',
                value: demo.primary,
                onChange: user => onUpdateTarget({ primary: user })
            },{
                key: 'start_date',
                title: 'Start Date',
                description: 'The start date is used to show when a demo is scheduled to start.',
                component: 'date_duration_picker',
                value: demo.start_date,
                onChange: date => onUpdateTarget({ start_date: date })
            },{
                key: 'end_date',
                title: 'End Date',
                description: 'The start date is used to show when a demo is scheduled to end.',
                component: 'date_duration_picker',
                value: demo.end_date,
                onChange: date => onUpdateTarget({ end_date: date })
            },{
                key: 'reason',
                required: shouldRequireRescheduleReason(),
                title: 'Reason for Rescheduling',
                description: `Please provide a reason for rescheduling this demo. We'll list this information in the history events for this demo`,
                component: 'textview',
                value: reason,
                visible: shouldRequireRescheduleReason(),
                onChange: text => setReason(text)
            }]
        },{
            key: 'details',
            title: 'Optional Details',
            items: [{
                key: 'partner',
                required: false,
                visible: canSetAssignments(),
                title: 'Partner',
                description: 'Assigning a partner will allow this user to interact with this demo.',
                component: 'user_lookup',
                value: demo.partner,
                onChange: user => onUpdateTarget({ partner: user })
            },{
                key: 'ride_along',
                required: false,
                visible: canSetAssignments(),
                title: 'Safety Associate',
                description: 'Assigning a safety associate will allow this user to interact with this demo.',
                component: 'user_lookup',
                value: demo.ride_along,
                onChange: user => onUpdateTarget({ ride_along: user }),
                props: {
                    levels: [ User.levels.get().safety_associate ]
                }
            },{
                key: 'trainee',
                required: false,
                visible: canSetAssignments(),
                title: 'Trainee',
                description: 'Assigning a trainee will allow this user to interact with this demo.',
                component: 'user_lookup',
                value: demo.trainee,
                onChange: user => onUpdateTarget({ trainee: user })
            },{
                key: 'feedback_template',
                required: false,
                title: 'Feedback Template',
                description: 'Would you like to send the customer a feedback link when this demo is completed? If so, choose a customer feedback template from the list below.',
                component: 'list',
                value: demo.feedback_template ? demo.feedback_template.title : null,
                items: feedbackTemplates,
                visible: feedbackTemplates.length > 0,
                onChange: template => onUpdateTarget({ feedback_template: template })
            },{
                key: 'qualified',
                required: false,
                title: 'Qualified',
                description: 'Does this demo meet the criteria to be considered a qualified demo?',
                component: 'bool_list',
                value: demo.qualified,
                visible: canShowQualifiedDemoToggle(),
                onChange: val => onUpdateTarget({ qualified: val })
            }]
        }];
    }

    const setupTarget = async () => {
        try {

            // fetch feedback template options from server
            let { templates } = await Request.get(utils, '/dealerships/', {
                type: 'feedback_templates',
                paging: false
            });
            setFeedbackTemplates(templates.map(template => Feedback.Template.create(template)));

            // open editing for abstract target
            let edits = abstract.object.open();
            setDemo(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this demo. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const AddEditFeedbackTemplate = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_feedback_template` : `edit_feedback_template_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [template, setTemplate] = useState(null);

    const onDoneClick = async () => {

        // Valdiate fields
        let items = getFields().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please fill out the "${required.title}" before moving on`
            });
            return;
        }

        // Submit program
        if(isNewTarget) {
            try {
                setLoading('done');
                await Utils.sleep(0.25);
                await abstract.object.submit(utils);

                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your new template has been created`,
                    onClick: () => setLayerState('close')
                });

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue creating this template. ${e.message || 'An unknown error occurred'}`
                })
            }
            return;
        }

        // Update program
        try {
            setLoading('done');
            await Utils.sleep(0.25);
            await abstract.object.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This template has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this template. ${e.message || 'An unknown error occurred'}`
            })
        }
        return;
    }

    const onEditClick = item => {

        if(typeof(item.prompt) === 'function') {
            item.prompt();
            return;
        }

        let props = {
            title: item.title,
            description: item.description
        };

        let tmpValue = item.value;
        switch(item.component) {

            case 'textview':
            props.children = (
                <TextView
                value={tmpValue}
                useDelay={false}
                onChange={text => {
                    if(item.nesting) {
                        tmpValue = {
                            ...template[item.nesting],
                            [item.key]: text
                        };
                        return;
                    }
                    tmpValue = text;
                }}
                containerStyle={{
                    width: '100%'
                }}/>
            )
            break;

            case 'image_picker':
            tmpValue = item.nesting ? template[item.nesting] : item.selected;
            props.children = (
                <ImagePickerField
                utils={utils}
                value={item.selected}
                onChange={image => {
                    if(item.nesting) {
                        tmpValue = {
                            ...template[item.nesting],
                            [item.key]: image
                        };
                        return;
                    }
                    tmpValue = image;
                }} />
            );
            break;

            case 'pattern_picker':
            tmpValue = item.selected;
            props.children = (
                <PatternPickerField
                utils={utils}
                collapsible={false}
                selected={item.selected}
                onChange={pattern => {
                    tmpValue = {
                        ...template.props,
                        pattern: pattern
                    };
                }}/>
            )
            break;

            case 'question_editor':
            tmpValue = item.selected;
            props.children = (
                <QuestionEditor
                utils={utils}
                value={item.selected}
                onClose={() => utils.layer.requestClose('edit_target')}
                onUpdate={question => tmpValue = question}
                onAdd={question => {
                    question.order_index = template.questions.length + 1;
                    tmpValue = question;
                }} />
            )
            break;

            case 'bool_toggle':
            props.children = (
                <BoolToggle
                enabled={'Yes'}
                disabled={'No'}
                isEnabled={item.enabled}
                onChange={enabled => tmpValue = enabled}
                containerStyle={{
                    width: '100%'
                }}/>
            )
            break;
        }

        utils.layer.open({
            id: 'edit_target',
            Component: EditTarget.bind(this, {
                ...props,
                ...item.layerProps,
                onCommit: () => onValueChange(item.nesting || item.key, tmpValue)
            })
        })
    }

    const onImageClick = itemKey => {
        utils.sheet.show({
            items: [{
                key: 'change',
                title: 'Change',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'change') {
                let item = getCustomizations()[0].items.find(prevItem => prevItem.key === itemKey);
                if(!item) {
                    return;
                }
                item.prompt = false;
                onEditClick(item);
                return;
            }
            if(key === 'remove') {
                utils.alert.show({
                    title: 'Remove Image',
                    message: 'Are you sure that you want to remove this image?',
                    buttons: [{
                        key: 'confirm',
                        title: 'Remove',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Remove',
                        style: 'default'
                    }],
                    onClick: async key => {
                        if(key !== 'confirm') {
                            return;
                        }
                        onUpdateTarget({
                            props: {
                                ...template.props,
                                header_image: null
                            }
                        })
                    }
                })
                return;
            }
        })
    }

    const onSortChange = ({  newIndex, oldIndex }) => {
        let question = template.questions[oldIndex];
        let edits = abstract.object.set({
            questions: update(template.questions, {
                $splice: [[ oldIndex, 1 ], [ newIndex, 0, question ]]
            }).map((question, index) => {
                question.order_index = index + 1;
                return question;
            })
        });
        setTemplate({ ...edits });
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setTemplate(edits);
    }

    const onValueChange = async (key, value) => {
        if([ 'new_question', 'questions' ].includes(key)) {
            let edits = abstract.object.set({
                questions: update(abstract.object.edits.questions, {
                    $apply: questions => {
                        let nextQuestions = questions.filter(prevQuestion => {
                            return prevQuestion.id !== value.id;
                        });
                        nextQuestions.push(value);
                        return nextQuestions;
                    }
                })
            })
            setTemplate(edits);
            return;
        }

        let edits = abstract.object.set({ [key]: value })
        setTemplate(edits);
    }

    const getCustomizations = () => {

        if(!template) {
            return null;
        }
        return [{
            key: 'customize',
            title: 'Customize',
            items: [{
                key: 'header_image',
                nesting: 'props',
                required: false,
                title: 'Hero Image',
                description: 'Would you like to add an image at the top of your template? We will show your dealership name, city, and state if no image is added.',
                value: template.props.header_image ? 'Image Added' : null,
                selected: template.props.header_image,
                component: 'image_picker',
                prompt: template.props.header_image ? onImageClick.bind(this, 'header_image') : null
            },{
                key: 'welcome_message',
                nesting: 'props',
                required: false,
                title: 'Welcome Message',
                description: 'Would you like your welcome message to say? This message is shown at the top of your template.',
                value: template.props.welcome_message,
                component: 'textview',
            },{
                key: 'pattern',
                nesting: 'props',
                required: false,
                title: 'Background Pattern',
                description: 'Would you like to add a pattern to the background of this template?',
                selected: template.props ? template.props.pattern : null,
                value: template.props ? convertPattern(template.props.pattern) : null,
                component: 'pattern_picker',
                layerProps: { sizing: 'medium' }
            }]
        }]
    }

    const getFields = () => {

        if(!template) {
            return [];
        }
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'What is the title of this Template? This title will appear when a customer visits a feedback form that uses this template',
                value: template.title,
                required: true,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'description',
                title: 'Description',
                description: 'What is the description for this Template? This description will not be shown to a customer when they visit a feedback form that uses this template',
                value: template.description,
                required: true,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            },{
                key: 'dealership',
                title: 'Dealership',
                description: 'What dealership do you want to assign to this template?',
                value: template.dealership,
                required: utils.user.get().level <= User.levels.get().admin,
                visible: utils.user.get().level <= User.levels.get().admin,
                component: 'dealership_lookup',
                onChange: text => onUpdateTarget({ dealership: text })
            }]
        }]
        return items;
    }

    const getLockedQuestions = () => {
        return getQuestions().filter(question => {
            return question.locked;
        })
    }

    const getQuestions = () => {
        if(!template || !template.questions) {
            return [];
        }
        return template.questions.sort((a,b) => {
            return a.order_index > b.order_index;
        }).map(question => {

            let value = null;
            switch(question.type) {
                case Feedback.Template.Question.type.list:
                value = 'Dropdown Menu';
                break;

                case Feedback.Template.Question.type.checkboxes:
                value = 'Multiple Checkboxes';
                break;

                case Feedback.Template.Question.type.picker:
                value = 'List of Options';
                break;

                case Feedback.Template.Question.type.textfield:
                value = 'Short Text Response';
                break;

                case Feedback.Template.Question.type.textview:
                value = 'Long Text Response';
                break;

                default:
                value = 'Unknown Question Type';
            }

            return {
                key: 'questions',
                id: question.id,
                title: question.title || 'New Question',
                description: 'What would you like to ask for this question? Choose a question type from the list below to get started.',
                value: value,
                selected: question,
                locked: question.dealership_id ? false : true,
                component: 'question_editor',
                layerProps: { sizing: 'medium' }
            }
        });
    }

    const getQuestionComponent = (question, index, locked) => {
        let { title, required, value } = question;
        return (
            <div
            key={index}
            className={locked ? '' : `view-entry ${window.theme}`}
            onClick={locked ? null : onEditClick.bind(this, question)}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '8px 12px 8px 12px',
                width: '100%',
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {locked && (
                    <img
                    src={'images/lock-icon-small.png'}
                    style={{
                        width: 15,
                        height: 15,
                        objectFit: 'contain',
                        marginRight: 8
                    }} />
                )}
                <span style={{
                    ...Appearance.textStyles.key(),
                    flexGrow: 1,
                    paddingRight: 20
                }}>{title}</span>
                <span style={{
                    ...Appearance.textStyles.value()
                }}>{value || 'Not added'}</span>
                <img
                src={'images/next-arrow-grey-small.png'}
                style={{
                    width: 12,
                    height: 12,
                    objectFit: 'contain',
                    marginLeft: 8,
                    opacity: 0.75
                }} />
            </div>
        )
    }

    const getSortableQuestions = () => {
        return getQuestions().filter(question => {
            return question.locked ? false : true;
        })
    }

    const QuestionsList = props => {
        if(!template) {
            return null;
        }
        const Container = SortableContainer(({ questions }) => {
            if(!questions) {
                return null;
            }
            return (
                <ul style={{
                    width: '100%',
                    borderRadius: 8,
                    overflow: 'hidden',
                    margin: 0,
                    marginBlock: 0,
                    padding: 0
                }}>
                    {questions.map((question, index) => (
                        <SortableQuestion
                        index={index}
                        key={`question_${question.id}`}
                        question={question} />
                    ))}
                </ul>
            );
        });
        return (
            <div style={{
                width: '100%'
            }}>
                <Container
                {...props}
                helperClass={'sortable-container'}
                pressDelay={200} />

                <div style={{
                    padding: 12,
                    textAlign: 'center'
                }}>
                    <span
                    className={'text-button'}
                    onClick={onEditClick.bind(this, {
                        key: 'new_question',
                        title: 'Add a New Question',
                        description: 'What would you like to ask for this question? Choose a question type from the list below to get started.',
                        value: 'Click to add a question...',
                        component: 'question_editor',
                        required: !template.questions || template.questions.length === 0,
                        layerProps: { sizing: 'medium' }
                    })}
                    style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.primary()
                    }}>{'Add a New Question'}</span>
                </div>
            </div>
        );
    };

    const SortableQuestion = SortableElement(({ index, question }) => {
        if(!template) {
            return null;
        }
        return (
            <li style={{
                width: '100%'
            }}>
                {getQuestionComponent(question, index, false)}
            </li>
        )
    });

    const setupTarget = async () => {
        try {

            let edits = abstract.object.open();
            if(isNewTarget) {
                let { questions } = await Request.get(utils, '/dealerships/', {
                    type: 'default_feedback_template_questions'
                });
                edits = abstract.object.set({
                    questions: questions.map(question => {
                        return Feedback.Template.Question.create(question);
                    })
                })
            }
            setTemplate(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this template. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New Feedback Template` : `Editing ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerItem
            title={'Questions'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getLockedQuestions().map((question, index) => {
                        return getQuestionComponent(question, index, true);
                    })}
                    <QuestionsList
                    questions={getSortableQuestions()}
                    onSortEnd={onSortChange} />
                </div>
            </LayerItem>

            <FieldMapper
            editable={true}
            fields={getCustomizations()}
            onEditClick={onEditClick} />
        </Layer>
    )
}

export const BookDemoFromLead = ({ onCloseLayer }, { abstract, index, options, utils }) => {

    const layerID = `book_demo_${abstract.getID()}`;
    const [date, setDate] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    const onBookDemo = ({ lead_status, start_date }) => {
        utils.alert.show({
            title: 'All Done!',
            message: `The demo for ${abstract.object.full_name} has been ${user ? ` assigned to ${user.full_name}` : 'set'} for ${moment(start_date).format('MMMM Do, YYYY [at] h:mma')}`,
            onClick: () => setLayerState('close')
        });

        abstract.object.status = lead_status;
        utils.content.update(abstract);
    }

    const getPresetUsers = () => {
        return Lead.assignments.guess(abstract.object);
    }

    return (
        <Layer
        id={layerID}
        title={`Set Demo for ${abstract.object.full_name}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState,
            onCloseLayer: onCloseLayer
        }}>
            <BookDemo
            utils={utils}
            lead={abstract.object}
            onBookDemo={onBookDemo}
            onDateChange={date => setDate(date)}
            onLoad={loading => setLoading(loading)}
            onUserChange={user => setUser(user)}
            presetUsers={getPresetUsers()} />
        </Layer>
    )
}

export const BookDemoFromRequest = ({ abstract, index, options, utils }) => {

    const layerID = `book_demo_${abstract.getID()}`;
    const [date, setDate] = useState(moment(abstract.object.date));
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    const onBookDemo = ({ lead_status, start_date }) => {

        // show confirmation alert
        utils.alert.show({
            title: 'All Done!',
            message: `The demo for ${abstract.object.lead.full_name} has been ${user ? ` assigned to ${user.full_name}` : 'set'} for ${moment(start_date).format('MMMM Do, YYYY [at] h:mma')}`,
            onClick: setLayerState.bind(this, 'close')
        });

        // update target with new status and notify demo request subscribers
        abstract.object.lead.status = lead_status;
        utils.content.update(abstract);

        // notify lead subscribers of data change
        utils.content.update({
            object: abstract.object.lead,
            type: 'lead'
        });
    }

    const getPresetUsers = () => {
        return DemoRequest.assignments.guess(abstract.object);
    }

    return (
        <Layer
        id={layerID}
        title={`Set Demo for ${abstract.object.lead.full_name}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}>
            <BookDemo
            utils={utils}
            onBookDemo={onBookDemo}
            request={abstract.object}
            lead={abstract.object.lead}
            defaultDate={date}
            onLoad={loading => setLoading(loading)}
            onDateChange={date => setDate(date)}
            onUserChange={user => setUser(user)}
            presetUsers={getPresetUsers()} />
        </Layer>
    )
}

export const DemoDetails = ({ abstract, index, options, utils }) => {

    const layerID = `demo_details_${abstract.getID()}`;
    const eventsLimit = 5;

    const [bookedBy, setBookedBy] = useState(null);
    const [callLogs, setCallLogs] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventsOffset, setEventsOffset] = useState(0);
    const [eventsPaging, setEventsPaging] = useState(null);
    const [feedbackTemplate, setFeedbackTemplate] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [leadAttributionUser, setLeadAttributionUser] = useState(null);
    const [loading, setLoading] = useState('init');
    const [partner, setPartner] = useState(null);
    const [primary, setPrimary] = useState(null);
    const [rideAlong, setRideAlong] = useState(null);
    const [trainee, setTrainee] = useState(null);

    const onCallLogClick = log => {
        utils.layer.open({
            abstract: Abstract.create({
                type: 'call_log',
                object: log
            }),
            Component: CallLogDetails,
            id: `call_log_details_${log.id}`,
            permissions: ['calls.details']
        })
    }

    const onCancelDemo = () => {
        utils.alert.show({
            title: 'Cancel Demo',
            message: `Are you sure that you want to cancel this demo?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelDemoConfirm();
                    return;
                }
            }
        });
    }

    const onCancelDemoConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { status } = await Request.post(utils, '/demos/', {
                id: abstract.getID(),
                type: 'cancel_demo'
            });

            abstract.object.status = status;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This demo has been cancelled',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteDemo = () => {
        utils.alert.show({
            title: 'Delete Demo',
            message: `Are you sure that you want to delete this demo? This will remove the demo from your dealership and can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteDemoConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteDemoConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/demos/', {
                id: abstract.getID(),
                type: 'delete_demo'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This demo has been deleted from your dealership',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }


    const onEditClick = () => {
        utils.layer.open({
            id: `edit_demo_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditDemo
        })
    }

    const onLeadClick = async () => {
        try {

            // fetch lead details from server
            setLoading(true);
            let lead = await Lead.get(utils, abstract.object.lead.id);

            // end loading and show lead details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            })
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadScriptClick = () => {
        utils.layer.open({
            id: `lead_script_editor_${abstract.object.lead.id}`,
            Component: LeadScriptEditor.bind(this, {
                utils: utils,
                lead: abstract.object.lead,
                value: abstract.object.lead.lead_script.text
            })
        });
    }

    const onNewSystemEvent = data => {
        try {
            setEvents(events => {
                return update(events, {
                    $unshift: [SystemEvent.create(data)]
                });
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'cancel_demo',
                permissions: ['demos.actions.cancel'],
                title: 'Cancel Demo',
                style: 'destructive',
                visible: abstract.object.status && abstract.object.status.code !== Demo.status.get().cancelled
            },{
                key: 'assignment',
                permissions: ['demos.actions.edit'],
                title: `${abstract.object.primary ? 'Change' : 'Set'} Primary Assignment`,
                style: 'default'
            },{
                key: 'delete_demo',
                permissions: ['demos.actions.delete'],
                title: 'Delete Demo',
                style: 'destructive'
            },{
                key: 'reschedule_demo',
                permissions: ['demos.actions.reschedule'],
                title: 'Reschedule Demo',
                style: 'default'
            },{
                key: 'demo_status',
                permissions: ['demos.actions.status'],
                title: 'Set Demo Status',
                style: 'default'
            },{
                key: 'lead_status',
                permissions: ['leads.actions.status'],
                title: 'Set Lead Status',
                style: 'default'
            }].sort((a,b) => {
                return a.title.localeCompare(b.title)
            }),
            target: evt.target
        }, key => {

            if(key === 'assignment') {

                // prevent demo rescheduling for user accounts not found in the array
                if(![
                    User.levels.get().admin,
                    User.levels.get().region_director,
                    User.levels.get().division_director,
                    User.levels.get().area_director,
                    User.levels.get().dealer,
                    User.levels.get().marketing_director
                ].includes(utils.user.get().level)) {
                    utils.alert.show({
                        title: 'Just a Second',
                        message: 'It looks like your account is unable to create or update demo assignments. Please speak with your dealer or marketing director if you need to make changes to the assignment for this demo.'
                    });
                    return;
                }

                // present options to choose new assignment user
                utils.layer.open({
                    abstract: abstract,
                    Component: SetDemoAssignment,
                    id: `set_demo_assignment_${abstract.getID()}`
                })
                return;
            }

            if(key === 'cancel_demo') {
                onCancelDemo();
                return;
            }

            if(key === 'delete_demo') {
                onDeleteDemo();
            }

            if(key === 'demo_status') {
                utils.layer.open({
                    id: `set_demo_status_${abstract.getID()}`,
                    abstract: abstract,
                    Component: SetDemoStatus
                });
                return;
            }

            if(key === 'lead_status') {
                onSetLeadStatus();
                return;
            }

            if(key === 'reschedule_demo') {
                utils.layer.open({
                    abstract: abstract,
                    Component: RescheduleDemo,
                    id: `reschedule_demo_${abstract.getID()}`
                });
                return;
            }
        });
    }

    const onSetLeadStatus = async () => {
        try {
            setLoading('options');
            let lead = await Lead.get(utils, abstract.object.lead.id);

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    type: 'lead',
                    object: lead
                }),
                id: `set_lead_status_${abstract.getID()}`,
                Component: SetLeadStatus
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = async userID => {
        try {
            setLoading(true);
            let user = await User.get(utils, userID);

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${user.user_id}`,
                permissions: ['users.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            permissions: ['demos.actions.edit'],
            text: 'Edit'
        }];
    }

    const getCallLogs = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('demos.details.calls') === false) {
            return null;
        }

        // render a placeholder if the first fetch for call logs is active
        if(loading === 'init') {
            return (
                <LayerItem title={'Calls and Emails'}>
                    <div style={Appearance.styles.unstyledPanel()}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: 15
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                            style={{
                                height: 50,
                                width: 50
                            }}/>
                        </div>
                    </div>
                </LayerItem>
            )
        }
        return (
            <LayerItem title={'Calls and Emails'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {callLogs.length === 0 && (
                        Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            title: 'No calls found'
                        })
                    )}
                    {callLogs.map((log, index) => {
                        return (
                            Views.entry({
                                badge: [{
                                    text: log.direction,
                                    color: log.direction === 'outbound' ? Appearance.colors.grey() : Appearance.colors.primary()
                                },{
                                    text: log.method,
                                    color: Appearance.colors.secondary()
                                }],
                                bottomBorder: index !== callLogs.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onCallLogClick.bind(this, log),
                                subTitle: moment(log.start_date).format('MMMM Do, YYYY [at] h:mma'),
                                title: log.author ? log.author.full_name : 'Name not available'
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getFields = () => {

        // prevent moving forward if initial loading is in progress
        if(loading === 'init') {
            return null;
        }

        // prepare demo detail items
        let demo = abstract.object;
        let items = [{
            key: 'details',
            permissions: ['demos.details.general'],
            title: 'About this Demo',
            items: [{
                key: 'created',
                title: 'Booking Date',
                value: demo.created ? Utils.formatDate(demo.created) : null
            },{
                key: 'end_date',
                title: 'End Date',
                value: demo.end_date && Utils.formatDate(demo.end_date)
            },{
                key: 'feedback_template',
                title: 'Feedback Template',
                value: feedbackTemplate ? feedbackTemplate.title : 'Disabled',
                style: {
                    value: {
                        color: feedbackTemplate ? Appearance.colors.subText() : Appearance.colors.red
                    }
                }
            },{
                key: 'id',
                title: 'ID',
                value: demo.id
            },{
                key: 'qualified',
                title: 'Qualified',
                value: demo.qualified ? 'Yes' : 'No',
                visible: leadAttributionUser && leadAttributionUser.level === User.levels.get().safety_associate
            },{
                key: 'start_date',
                title: 'Start Date',
                value: demo.start_date && Utils.formatDate(demo.start_date)
            },{
                color: demo.status ? demo.status.color : null,
                key: 'status',
                title: 'Status',
                value: demo.status ? demo.status.text : null
            }]
        },{
            key: 'users',
            permissions: ['demos.details.users'],
            title: 'Assignments and Credit',
            items: [{
                key: 'booked_by',
                onClick: bookedBy ? onUserClick.bind(this, bookedBy.user_id) : null,
                title: 'Booked By',
                value: bookedBy ? bookedBy.full_name : null
            },{
                key: 'user',
                onClick: leadAttributionUser ? onUserClick.bind(this, leadAttributionUser.user_id) : null,
                title: 'Lead Attribution',
                value: leadAttributionUser ? leadAttributionUser.full_name : 'Not Added'
            },{
                key: 'partner',
                onClick: partner ? onUserClick.bind(this, partner.user_id) : null,
                title: 'Partner',
                value: partner ? partner.full_name : 'Not Added'
            },{
                key: 'user',
                onClick: primary ? onUserClick.bind(this, primary.user_id) : null,
                title: 'Primary Assignment',
                value: primary ? primary.full_name : null
            },{
                key: 'ride_along',
                onClick: rideAlong ? onUserClick.bind(this, rideAlong.user_id) : null,
                title: 'Safety Associate',
                value: rideAlong ? rideAlong.full_name : 'Not Added'
            },{
                key: 'trainee',
                onClick: trainee ? onUserClick.bind(this, trainee.user_id) : null,
                title: 'Trainee',
                value: trainee ? trainee.full_name : 'Not Added'
            }]
        }];

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.demos} 
            utils={utils}/>
        )
    }

    const getLeadFields = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('demos.details.lead') === false) {
            return null;
        }

        // prepare items for lead fields
        let demo = abstract.object;
        let items = [{
            key: 'location',
            title: 'Location',
            visible: demo.lead.address && demo.lead.location ? true : false,
            items: [{
                component: 'map',
                key: 'location',
                title: 'Location',
                value: demo.lead.location
            },{
                key: 'address',
                title: 'Address',
                value: Utils.formatAddress(demo.lead.address)
            },{
                key: 'maps',
                onClick: () => {
                    let address = Utils.formatAddress(demo.lead.address);
                    window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                },
                title: 'Directions',
                value: 'Click to View'
            }]
        }];

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.leads}
            utils={utils} />
        )
    }

    const getLead = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('demos.details.lead') === false) {
            return null;
        }

        // render lead component if a lead is available
        return abstract.object.lead && (
            <LayerItem 
            collapsed={false}
            title={'Lead'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: abstract.object.lead.lead_type && abstract.object.lead.lead_type.icon.url,
                            imageStyle: {
                                backgroundColor: null
                            }
                        },
                        onClick: onLeadClick,
                        subTitle: abstract.object.lead.phone_number || abstract.object.lead.email_address || 'No contact information provided',
                        title: abstract.object.lead.full_name || 'Customer name not available',
                    })}
                </div>
            </LayerItem>
        )
    }

    const getSystemEvents = () => {
        return loading === false && (
            <SystemEventsLayerItem 
            abstract={abstract} 
            permissions={['demos.details.system_events']}
            utils={utils} />
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('system', 'on_new_event', onNewSystemEvent);
            await utils.sockets.emit('system', 'join_events', { tag: abstract.getTag()} );
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('system', 'on_new_event', onNewSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchDetails = async () => {
        try {

            // set loading flag if flag is not set to init
            if(loading !== 'init') {
                setLoading(true);
            }

            // fetch demo details from the server
            let {  booked_by, call_logs, feedback_template, lead_attribution_user, partner, primary, ride_along, trainee } = await Request.get(utils, '/demos/', {
                id: abstract.getID(),
                type: 'ext_details'
            })

            // set booked_by user and update state
            abstract.object.booked_by = booked_by && User.create(booked_by);
            setBookedBy(abstract.object.booked_by);

            // set partner user and update state
            abstract.object.partner = partner && User.create(partner);
            setPartner(abstract.object.partner);

            // set primary user and update state
            abstract.object.primary = primary && User.create(primary);
            setPrimary(abstract.object.primary);

            // set ride_along user and update state
            abstract.object.ride_along = ride_along && User.create(ride_along);
            setRideAlong(abstract.object.ride_along);

            // set trainee user and update state
            abstract.object.trainee = trainee && User.create(trainee);
            setTrainee(abstract.object.trainee);

            // set feedback template and update state
            abstract.object.feedback_template = feedback_template && Feedback.Template.create(feedback_template);
            setFeedbackTemplate(abstract.object.feedback_template);

            // set lead attribution user and update state
            abstract.object.lead.user = lead_attribution_user && User.create(lead_attribution_user);
            setLeadAttributionUser(abstract.object.lead.user);

            // update state with formatted call logs
            setCallLogs(call_logs.map(log => CallLog.create(log)));
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the additional information for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchEvents = async () => {
        try {
            let { events, paging } = await Request.get(utils, '/utils/', {
                limit: eventsLimit,
                offset: eventsOffset,
                target_id: abstract.getID(),
                target_type: abstract.type,
                type: 'system_events'
            });

            if(loading === 'system_events') {
                setLoading(false);
            }
            setEventsPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [eventsOffset]);

    useEffect(() => {

        connectToSockets();
        setTimeout(fetchDetails, 250);

        utils.content.subscribe(layerID, ['call_log', 'demo', 'user'], {
            onFetch: fetchDetails,
            onUpdate: next => {
                if(next.getTag() === abstract.getTag()) {
                    fetchDetails();
                    fetchEvents();
                }
            }
        })

        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.getTitle()} Details`}
        utils={utils}
        options={{
            ...options,
            extension: {
                abstract: abstract,
                permissions: {
                    delete: ['demos.notes.actions.delete'],
                    edit: ['demos.notes.actions.edit'],
                    new: ['demos.notes.actions.new'],
                    view: ['demos.notes']
                },
                type: 'notes'
            },
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getLead()}
            {getLeadFields()}
            {getFields()}
            {getCallLogs()}
            {getSystemEvents()}
        </Layer>
    )
}

export const DemoRequestDetails = ({ abstract, index, options, utils }) => {

    const layerID = `demo_request_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(abstract.object);

    const onAutoBookFromRequest = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);

            let { lead_status } = await Request.post(utils, '/demos/', {
                end_date: moment(request.date).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                request_id: request.id,
                requested_by_user_id: request.requested_by_user && request.requested_by_user.user_id,
                start_date: moment(request.date).format('YYYY-MM-DD HH:mm:ss'),
                type: 'new'
            });

            request.lead.status = lead_status;
            utils.content.update({
                type: 'lead',
                object: request.lead
            });

            setLoading(false);
            utils.content.fetch('demo');
            utils.alert.show({
                title: 'All Done!',
                message: `This demo has been set for ${moment(request.date).format('MMMM Do, YYYY [at] h:mma')} to ${moment(request.date).add(3, 'hours').format('MMMM Do, YYYY [at] h:mma')} and assigned to ${request.requested_by_user.full_name}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue booking this demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onBookDemoClick = () => {
        try {

            // prevent moving forward if feature is disabled for current user
            if(utils.user.permissions.get('demo_requests.actions.set') === false) {
                return utils.user.permissions.reject();
            }

            // prevent booking for archived
            if(abstract.object.lead && abstract.object.lead.active === false) {
                throw new Error('This lead is currently on the archived list and is not available for a demo')
            }

            // prevent booking for do not call
            if(abstract.object.lead && abstract.object.lead.status.code === Lead.status.get().do_not_call) {
                throw new Error('This lead is currently on the "Do Not Call" list and is not available for a demo')
            }

            // prompt user to customize request if no requested_by_user was found
            if(!request.requested_by_user) {
                utils.layer.open({
                    id: `book_demo_${abstract.getID()}`,
                    abstract: abstract,
                    Component: BookDemoFromRequest
                });
                return;
            }

            utils.alert.show({
                title: 'Set Demo',
                message: `This demo request will be converted to a demo, scheduled for ${Utils.formatDate(request.date)} to ${Utils.formatDate(moment(request.date).add(3, 'hours'))}, and assigned to ${request.requested_by_user.full_name}. If needed, you can change this information by selecting the "Customize Demo" option below.`,
                buttons: [{
                    key: 'confirm',
                    title: 'Okay',
                    style: 'default'
                },{
                    key: 'customize',
                    title: 'Customize Demo'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'customize') {
                        utils.layer.open({
                            id: `book_demo_${abstract.getID()}`,
                            abstract: abstract,
                            Component: BookDemoFromRequest
                        })
                        return;
                    }
                    if(key === 'confirm') {
                        onAutoBookFromRequest();
                        return;
                    }
                }
            })

        } catch(e) {
            utils.alert.show({
                title: 'Just a Second',
                message: e.message || 'An unknown error occurred'
            });
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'cancel_request',
                permissions: ['demo_requests.actions.cancel'],
                title: 'Cancel Demo Request',
                style: 'destructive'
            },{
                key: 'reschedule_request',
                permissions: ['demo_requests.actions.reschedule'],
                title: 'Reschedule Demo Request',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'cancel_request') {
                onCancelDemoRequest();
                return;
            }
            if(key === 'reschedule_request') {
                utils.layer.open({
                    id: `reschedule_demo_request_${abstract.getID()}`,
                    abstract: Abstract.create({
                        type: 'demo_request',
                        object: abstract.object
                    }),
                    Component: RescheduleDemoRequest
                });
                return;
            }
        });
    }

    const onCancelDemoRequest = () => {
        utils.alert.show({
            title: 'Cancel Demo Request',
            message: `Are you sure that you want to cancel this demo request?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelDemoRequestConfirm();
                    return;
                }
            }
        });
    }

    const onCancelDemoRequestConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let { status } = await Request.post(utils, '/demos/', {
                type: 'cancel_demo_request',
                id: abstract.getID()
            });

            setLoading(false);
            abstract.object.status = status;
            abstract.object.cancelled = true;
            abstract.object.rescheduled = false;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: 'This demo Request has been cancelled'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this demo request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadClick = async () => {
        try {
            let lead = await Lead.get(utils, abstract.object.lead.id);
            utils.layer.open({
                abstract: Abstract.create({
                    type: 'lead',
                    object: lead
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'book',
            text: 'Set Demo',
            color: 'primary',
            onClick: onBookDemoClick
        }];
    }

    const getFields = () => {

        // prepare field mapper items
        let demo = request || {};
        let items = [{
            key: 'request',
            permissions: ['demo_requests.details.general'],
            title: 'About this Request',
            items: [{
                key: 'created',
                title: 'Created',
                value: Utils.formatDate(demo.created)
            },{
                key: 'id',
                title: 'ID',
                value: demo.id
            },{
                key: 'requested_by_user',
                title: 'Requested By',
                value: demo.requested_by_user ? demo.requested_by_user.full_name : 'Not Available'
            },{
                key: 'date',
                title: 'Scheduled For',
                value: Utils.formatDate(demo.date)
            },{
                color: demo.status && demo.status.color,
                key: 'status',
                title: 'Status',
                value: demo.status && demo.status.text
            }]
        }];

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.demo_requests}
            utils={utils}/>
        )
    }

    const getLead = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('demo_requests.details.lead') === false) {
            return null;
        }

        return abstract.object.lead && (
            <LayerItem 
            collapsed={false}
            title={'Lead'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: abstract.object.lead.lead_type && abstract.object.lead.lead_type.icon.url,
                            imageStyle: {
                                backgroundColor: null
                            }
                        },
                        onClick: onLeadClick,
                        subTitle: abstract.object.lead.phone_number || abstract.object.lead.email_address || 'No contact information provided',
                        title: abstract.object.lead.full_name || 'Customer name not available',
                    })}
                </div>
            </LayerItem>
        )
    }

    const getLeadFields = () => {

        // prepare field mapper items
        let demo = request || {};
        let items = [{
            key: 'details',
            permissions: ['demo_requests.details.quick_look'],
            title: 'Quick Look',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                value: demo.lead ? demo.lead.email_address : null
            },{
                key: 'full_name',
                title: 'Full Name',
                value: demo.lead ? `${demo.lead.first_name || ''} ${demo.lead.last_name || ''}` : null
            },{
                color: demo.lead && demo.lead.status ? demo.lead.status.color : null,
                key: 'lead_status',
                title: 'Lead Status',
                value: demo.lead && demo.lead.status ? demo.lead.status.text : null
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: demo.lead ? demo.lead.phone_number : null
            },{
                key: 'spouse_full_name',
                title: 'Spouse',
                value: demo.lead && demo.lead.spouse_first_name && demo.lead.spouse_last_name ? `${demo.lead.spouse_first_name} ${demo.lead.spouse_last_name}` : null
            }]
        },{
            key: 'location',
            permissions: ['demo_requests.details.location'],
            title: 'Location',
            visible: demo.lead && demo.lead.address && demo.lead.location ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: demo.lead ? demo.lead.location : null
            },{
                key: 'address',
                title: 'Address',
                value: demo.lead ? Utils.formatAddress(demo.lead.address) : null
            },{
                button: {
                    color: 'primary',
                    label: 'Click to View',
                    onClick: () => {
                        let address = Utils.formatAddress(demo.lead.address);
                        window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                    }
                },
                component: 'button',
                key: 'maps',
                title: 'Directions'
            }]
        }];

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.leads}
            utils={utils}/>
        )
    }

    useEffect(() => {
        utils.content.subscribe(layerID, ['lead', 'lead_status'], {
            onUpdate: next => {
                setRequest(request => {
                    if(request.lead.id === next.getID()) {
                        switch(next.type) {
                            case 'lead':
                            abstract.object.lead = next.object;
                            request.lead = next.object;
                            break;

                            case 'lead_status':
                            abstract.object.lead.status = next.object.status;
                            request.lead.status = next.object.status;
                        }
                    }
                    return request;
                });
            }
        });

        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.getTitle()} Details`}
        utils={utils}
        options={{
            ...options,
            extension: {
                abstract: abstract,
                permissions: {
                    delete: ['events.notes.actions.delete'],
                    edit: ['events.notes.actions.edit'],
                    new: ['events.notes.actions.new'],
                    view: ['events.notes']
                },
                type: 'notes'
            },
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getLead()}
            {getLeadFields()}
            {getFields()}
        </Layer>
    )
}

export const FeedbackResponseDetails = ({ abstract, index, options, utils }) => {

    const layerID = `feedback_response_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [demo, setDemo] = useState(null);
    const [responses, setResponses] = useState(null);

    const getAnswers = () => {
        if(!responses) {
            return null;
        }
        return (
            <LayerItem
            title={'Questionnaire'}
            style={{
                overflow: 'visible'
            }}>
                {responses.map((item, index) => {
                    return (
                        <div
                        key={index}
                        style={{
                            ...Appearance.styles.unstyledPanel(),
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            marginBottom: index !== responses.length - 1 ? 8 : 0
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{item.title}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                whiteSpace: 'normal'
                            }}>{item.answer}</span>
                        </div>
                    )
                })}
            </LayerItem>
        )
    }

    const getFields = () => {
        let items = [{
            key: 'details',
            title: 'About this Response',
            items: [{
                key: 'id',
                title: 'ID',
                value: abstract.getID()
            },{
                key: 'full_name',
                title: 'Customer',
                value: abstract.object.full_name
            },{
                key: 'start_date',
                title: 'Demo Date',
                value: abstract.object.start_date ? moment(abstract.object.start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'date',
                title: 'Feedback Date',
                value: abstract.object.date ? moment(abstract.object.date).format('MMMM Do, YYYY [at] h:mma') : null
            }]
        }]
        return items;
    }

    const getDemo = () => {
        if(!demo) {
            return null;
        }
        return (
            <div style={{
                marginBottom: 20
            }}>
                <span style={{
                    ...Appearance.textStyles.subHeader(),
                    display: 'block',
                    marginBottom: 8
                }}>{'Demo'}</span>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        title: demo.lead.full_name,
                        subTitle: demo.lead.phone_number || demo.lead.email_address || 'No contact information provided',
                        hideIcon: true,
                        bottomBorder: false,
                        bottomBorder: false,
                        onClick: () => {
                            utils.layer.open({
                                abstract: Abstract.create({
                                    object: demo,
                                    type: 'demo',
                                }),
                                Component: DemoDetails,
                                id: `demo_details_${demo.id}`,
                                permissions: ['demos.details']
                            })
                        }
                    })}
                </div>
            </div>
        )
    }

    const fetchDetails = async () => {
        try {
            let { demo, responses } = await Request.get(utils, '/dealerships/', {
                type: 'feedback_response_details',
                id: abstract.getID()
            });
            setDemo(Demo.create(demo));
            setResponses(responses);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this customer response. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        fetchDetails();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`${abstract.getTitle()} Details`}
        index={index}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getDemo()}
            <FieldMapper fields={getFields()} />
            {getAnswers()}
        </Layer>
    )
}

export const FeedbackTemplateDetails = ({ abstract, index, options, utils }) => {

    const layerID = `feedback_template_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [url, setURL] = useState(null);
    const [layerState, setLayerState] = useState(null);

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set "${abstract.object.title}" as ${abstract.object.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/dealerships/', {
                type: 'set_feedback_template_active_status',
                active: !abstract.object.active,
                id: abstract.getID()
            });

            abstract.object.active = !abstract.object.active;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This template has been set as ${abstract.object.active ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCopyURL = async url => {

        let textArea = document.createElement('textarea');
        textArea.value = url;
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.position = 'fixed';
        textArea.style.opacity = 0;
        document.body.appendChild(textArea);

        textArea.focus();
        textArea.select();

        try {
            let copied = document.execCommand('copy');
            document.body.removeChild(textArea);
            if(!copied) {
                throw new Error('Your browser denied the copy request');
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your feedback link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            })
        }
    }

    const onDownloadQRCode = async url => {
        try {

            // prepare a download link
            let link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = 'qr_code.png';
            document.body.appendChild(link);
            link.click();

            await Utils.sleep(0.25);
            document.body.removeChild(link);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading your feedback QR Code. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        if(!abstract.object.dealership_id) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This is a standard template meant for all Dealerships. You can customize your own template by creating a new template for your Dealership',
                buttons: [{
                    key: 'new',
                    title: 'New Template',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {

                    if(key === 'new') {

                        let template = Feedback.Template.new();
                        template.dealership = utils.dealership.get();
                        template.dealership_id = template.dealership.id;

                        utils.layer.open({
                            id: `new_feedback_template`,
                            abstract: Abstract.create({
                                type: 'feedback_template',
                                object: template
                            }),
                            Component: AddEditFeedbackTemplate.bind(this, {
                                isNewTarget: true
                            })
                        });
                        return;
                    }
                }
            });
            return;
        }
        utils.layer.open({
            id: `edit_feedback_template_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditFeedbackTemplate.bind(this, {
                isNewTarget: false
            })
        })
    }

    const onGeneratePublicURL = () => {

        let tmpValue = null;
        utils.alert.show({
            title: 'Generate Feedback Link',
            message: 'Every feedback link is tied to a specific Demo in your Dealership. This allows us to give the proper credit when feedback is submitted with the link. Search for a Demo in your Dealership below to generate your feedback link.',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <DemoLookupField
                    utils={utils}
                    placeholder={'Search by first or last name...'}
                    onChange={demo => tmpValue = demo} />
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Create Link',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: async key => {
                if(key === 'done' && tmpValue) {
                    onGeneratePublicURLConfirm(tmpValue);
                    return;
                }
            }
        })
    }


    const onGeneratePublicURLConfirm = async demo => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { qr_code, url } = await Request.get(utils, '/dealerships/', {
                type: 'public_feedback_url',
                template_id: abstract.getID(),
                demo_id: demo.id
            })

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `We have created the public url for "${abstract.object.title}". You can scan the QR Code below to open the feedback form or you can copy the url and share it manually`,
                content: (
                    <div style={{
                        padding: 12,
                        paddingTop: 0
                    }}>
                        <img
                        src={qr_code}
                        style={{
                            width: 250,
                            height: 250,
                            objectFit: 'contain',
                            borderRadius: 10,
                            overflow: 'hidden'
                        }} />
                    </div>
                ),
                buttons: [{
                    key: 'copy',
                    title: 'Copy URL',
                    style: 'default'
                },{
                    key: 'download',
                    title: 'Download QR Code',
                    style: 'default'
                }],
                onClick: key => {
                    if(key === 'copy') {
                        onCopyURL(url);
                        return;
                    }
                    if(key === 'download') {
                        onDownloadQRCode(qr_code);
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your link for this feedback form. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'public_url',
                title: 'Generate Public URL',
                style: 'default'
            },{
                key: 'set_default',
                title: 'Set as Dealership Default',
                style: 'default',
                visible: abstract.object.is_default ? false : true
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
            if(key === 'public_url') {
                onGeneratePublicURL();
                return;
            }
            if(key === 'set_default') {
                onSetAsDefault();
                return;
            }
        })
    }

    const onSetAsDefault = () => {
        utils.alert.show({
            title: 'Set as Dealership Default',
            message: `Are you sure that you want to set this template as the default Dealership feedback form?`,
            buttons: [{
                key: 'confirm',
                title: 'Set as Deafult',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetAsDefaultConfirm();
                    return;
                }
            }
        });
    }

    const onSetAsDefaultConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/dealerships/', {
                type: 'set_feedback_template_default',
                id: abstract.getID()
            });

            setLoading(false);
            abstract.object.is_default = true;

            utils.content.fetch('feedback_template');
            utils.alert.show({
                title: 'All Done!',
                message: `This template has been set as the default feedback template for the Dealership`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const getFields = () => {

        let template = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: template.id
            },{
                key: 'title',
                title: 'Title',
                value: template.title
            },{
                key: 'description',
                title: 'Description',
                value: template.description
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: template.dealership ? template.dealership.name : 'All Dealerships'
            },{
                key: 'is_default',
                title: 'Default for Dealership',
                value: template.is_default ? 'Yes' : 'No'
            },{
                key: 'active',
                title: 'Active',
                value: template.active ? 'Yes' : 'No'
            },{
                button: {
                    color: 'primary',
                    label: 'Generate',
                    onClick: onGeneratePublicURL
                },
                component: 'button',
                key: 'url',
                title: 'Public URL'
            }]
        }]

        return items;
    }

    const getURL = () => {
        return `${API.server}/feedback/index.html?template_id=${abstract.getID()}&preview=1&v=${moment().unix()}&dealership_id=${utils.dealership.get().id}`
    }

    useEffect(() => {
        setURL(getURL());
        utils.content.subscribe(layerID, ['feedback_template'], {
            onFetch: () => setURL(getURL()),
            onUpdate: () => setURL(getURL())
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 20
            }}>
                <div style={{
                    height: 350
                }}>
                    <iframe
                    className={'template-preview'}
                    src={url}
                    style={{
                        width: '100%',
                        height: 350,
                        border: 'none',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottom: `1px solid ${Appearance.colors.divider()}`
                    }} />
                </div>
                <div style={{
                    padding: 12,
                    textAlign: 'center'
                }}>
                    <span
                    className={'text-button'}
                    onClick={() => window.open(getURL())}
                    style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.primary()
                    }}>{'Open Preview in New Window'}</span>
                </div>
            </div>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const ImportDemos = ({ abstract, index, options, utils }) => {

    const layerID = 'import_demos';
    const [attributionUser, setAttributionUser] = useState(utils.user.get());
    const [demos, setDemos] = useState(null);
    const [file, setFile] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onBackToUpload = () => {
        utils.alert.show({
            title: 'Just a Second',
            message: 'Are you sure that you want to return to the upload screen? Any unsaved changes will be lost.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Go Back',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setFile(null);
                    setDemos(null);
                    return;
                }
            }
        })
    }

    const onConfirmDemos = () => {

        let match = demos.find(entry => {
            for(var i in entry.valid) {
                if(entry.valid[i] === false) {
                    return true;
                }
            }
            return false;
        })
        if(match) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like one or more Demos may be missing some required information. Please fill out all fields that are marked in red.'
            });
            return;
        }

        let dealership = utils.dealership.get();
        let user = utils.user.get();
        utils.alert.show({
            title: 'Confirm Demos',
            message: `Uploading ${demos.length === 1 ? 'this':'these'} ${demos.length} ${demos.length === 1 ? 'Demo':'Demos'} will add them to ${dealership.name} and may take a few minutes depending on the number of Demos. Do you want to continue with adding ${demos.length === 1 ? 'this Demo':'these Demos'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'change_dealership',
                title: 'Change Dealership',
                visible: user.level < User.levels.get().dealer,
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Upload',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'cancel') {
                    return;
                }
                if(key === 'change_dealership') {

                    const onDealershipChange = async () => {
                        try {
                            utils.events.off('selector', 'dealership_change', onDealershipChange);
                            let updatedDemos = update(demos, {
                                $apply: demos => {
                                    return demos.map(entry => {
                                        entry.demo.dealership_id = utils.dealership.get().id;
                                        return entry;
                                    });
                                }
                            });
                            setDemos(updatedDemos);
                            onSubmitDemosToServer(updatedDemos);

                        } catch(e) {
                            console.error(e.message)
                        }
                    }

                    utils.events.on('selector', 'dealership_change', onDealershipChange);
                    utils.layer.open({
                        id: 'dealership_selector',
                        Component: DealershipSelector
                    });
                    return;
                }
                onSubmitDemosToServer();
            }
        });
    }

    const onSubmitDemosToServer = async updatedDemos => {
        try {
            setLoading('confirm');
            await Utils.sleep(0.25);

            let dealership = utils.dealership.get();
            let target = updatedDemos || demos;
            let { count } = await Request.post(utils, '/demos/', {
                type: 'import',
                attribution_user_id: attributionUser.user_id,
                demos: target.map(entry => ({
                    ...entry.demo,
                    start_date: moment(entry.demo.start_date).format('YYYY-MM-DD HH:mm:ss'),
                    end_date: moment(entry.demo.end_date).format('YYYY-MM-DD HH:mm:ss'),
                    status: entry.demo.status ? entry.demo.status.key : null
                }))
            });

            setLoading(false);
            utils.content.fetch('demo');
            utils.alert.show({
                title: 'All Done!',
                message: `We have added ${count} ${count === 1 ? 'Demo':'Demos'} to ${dealership.name}`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your Demos. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDownloadTemplate = async () => {
        try {
            setLoading('download');
            await Utils.sleep(0.25);

            let { url } = await Request.get(utils, '/demos/', {
                type: 'get_import_template'
            });

            setLoading(false);
            window.open(url);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading the Demos template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onFieldClick = (field, index) => {

        utils.alert.show({
            title: field.title,
            message: `Please type the ${field.title.toLowerCase()} for this demo below`,
            textFields: [{
                ...field,
                placeholder: field.title
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: response => {
                if(response[field.key]) {
                    setDemos(demos => update(demos, {
                        [index]: {
                            valid: {
                                [field.key]: {
                                    $set: true
                                }
                            },
                            demo: {
                                [field.key]: {
                                    $set: response[field.key]
                                }
                            }
                        }
                    }))
                }
            }
        })
    }

    const onUploadDocument = async () => {
        try {
            setLoading('next');
            await Utils.sleep(0.25);

            let { demos } = await Request.post(utils, '/demos/', {
                type: 'import',
                file: file
            });
            setLoading(false);
            setDemos(demos);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading the Demos template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const entryHasMissingValues = entry => {
        for(var i in entry.valid) {
            if(entry.valid[i] === false) {
                return true;
            }
        }
        return false;
    }

    const getButtons = () => {
        if(demos) {
            return [{
                key: 'back',
                text: 'Back',
                color: 'dark',
                onClick: onBackToUpload
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                loading: loading === 'confirm',
                onClick: onConfirmDemos
            }]
        }
        if(file) {
            return [{
                key: 'download',
                text: 'Download Template',
                color: 'dark',
                loading: loading === 'download',
                onClick: onDownloadTemplate
            },{
                key: 'next',
                text: 'Continue',
                color: 'primary',
                loading: loading === 'next',
                onClick: onUploadDocument
            }]
        }
        return [{
            key: 'download',
            text: 'Download Template',
            color: 'dark',
            loading: loading === 'download',
            onClick: onDownloadTemplate
        }];
    }

    const getContent = () => {
        if(demos) {
            let missingValues = getDemosMissingValuesCount();
            let color = missingValues > 0 ? Appearance.colors.grey() : Appearance.colors.primary();
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        marginBottom: 4
                    }}>{'Demos Preview'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        marginBottom: 20,
                        whiteSpace: 'normal'
                    }}>{'Listed below are all the Demos that we were able to find in your spreadsheet. Review the Demos and check that all the information looks correct before submitting. Areas that are missing required information will be highlighted in red. We will automatically attribute the creation of these Demos and Leads to you unless you choose another user under the "Lead Credit" section.'}</span>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 20,
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: 10,
                            overflow: 'hidden',
                            border: `2px solid ${color}`,
                            background: Appearance.colors.softGradient(color),
                            marginLeft: 4,
                            marginRight: 4,
                            padding: '6px 10px 6px 10px',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: 'white',
                                fontWeight: 600
                            }}>{`${demos.length} New ${demos.length === 1 ? 'Demo':'Demos'}`}</span>
                        </div>
                        {missingValues > 0 && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRadius: 10,
                                overflow: 'hidden',
                                border: `2px solid ${Appearance.colors.red}`,
                                background: Appearance.colors.softGradient(Appearance.colors.red),
                                marginLeft: 4,
                                marginRight: 4,
                                padding: '6px 10px 6px 10px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    color: 'white',
                                    fontWeight: 600
                                }}>{`${missingValues} ${missingValues === 1 ? 'Issue':'Issues'}`}</span>
                            </div>
                        )}
                    </div>

                    <LayerItem
                    title={'Lead Credit'}
                    collapsed={false}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            padding: 12
                        }}>
                            <UserLookupField
                            utils={utils}
                            user={attributionUser}
                            icon={'search'}
                            placeholder={'Search by first or last name...'}
                            onChange={user => setAttributionUser(user)}
                            style={{
                                width: '100%'
                            }} />
                        </div>
                    </LayerItem>

                    {demos.map((entry, index) => {
                        let fields = [{
                            key: 'first_name',
                            title: 'First Name',
                            value: entry.demo.first_name,
                            valid: entry.valid.first_name
                        },{
                            key: 'last_name',
                            title: 'Last Name',
                            value: entry.demo.last_name,
                            valid: entry.valid.last_name
                        },{
                            key: 'phone_number',
                            title: 'Phone Number',
                            value: entry.demo.phone_number,
                            valid: entry.valid.phone_number
                        },{
                            key: 'email_address',
                            title: 'Email Address',
                            value: entry.demo.email_address,
                            valid: entry.valid.email_address
                        },{
                            key: 'address',
                            title: 'Address',
                            type: 'address_lookup',
                            value: Utils.formatAddress(entry.demo.address, true),
                            valid: entry.valid.address
                        },{
                            key: 'start_date',
                            title: 'Start Date',
                            type: 'date_picker',
                            value: entry.demo.start_date ? moment(entry.demo.start_date, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY [at] h:mma') : null,
                            valid: entry.valid.start_date
                        },{
                            key: 'end_date',
                            title: 'End Date',
                            type: 'date_picker',
                            value: entry.demo.end_date ? moment(entry.demo.end_date, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY [at] h:mma') : null,
                            valid: entry.valid.end_date
                        },{
                            key: 'status',
                            title: 'Status',
                            type: 'list',
                            items: Demo.styles.status.map(entry =>( {
                                key: entry.status,
                                title: entry.title
                            })).sort((a,b) => {
                                return a.title.localeCompare(b.title);
                            }),
                            value: entry.demo.status ? entry.demo.status.title : null,
                            valid: entry.valid.status
                        },{
                            key: 'notes',
                            title: 'Notes',
                            value: entry.demo.notes || 'No notes added',
                            valid: entry.valid.notes
                        }];

                        let hasMissingValues = entryHasMissingValues(entry);
                        return (
                            <div
                            key={index}
                            style={{
                                width: '100%'
                            }}>
                                <LayerItem
                                title={`Demo #${index + 1}`}
                                collapsed={false}
                                headerStyle={{
                                    ...hasMissingValues && ({
                                        color: Appearance.colors.red
                                    })
                                }}>
                                    <div style={{
                                        ...Appearance.styles.unstyledPanel(),
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        overflow: 'hidden',
                                        ...hasMissingValues && ({
                                            borderWidth: 2,
                                            borderColor: Appearance.colors.red,
                                        })
                                    }}>
                                        {fields.map((field, i) => {
                                            return (
                                                <div
                                                key={i}
                                                className={`view-entry ${window.theme}`}
                                                onClick={onFieldClick.bind(this, field, index)}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    padding: '6px 10px 6px 10px',
                                                    borderBottom: i !== fields.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                                                }}>
                                                    <span style={{
                                                        ...Appearance.textStyles.title(),
                                                        ...(field.valid ? null : { color: Appearance.colors.red })
                                                    }}>{field.title}</span>
                                                    <span style={{
                                                        ...Appearance.textStyles.subTitle()
                                                    }}>{field.value}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </LayerItem>
                            </div>
                        )
                    })}
                </div>
            )
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 15,
                    whiteSpace: 'normal'
                }}>{'Please choose a document to upload that contains the demos from your past CRM. The document needs to be a spreadsheet that follows our spreadsheet template. You can download the spreadsheet template by clicking the "Download Template" button below'}</span>
                <FilePickerField
                utils={utils}
                fileTypes={[ 'xls', 'xlsx' ]}
                onChange={file => setFile(file)} />
            </div>
        )
    }

    const getDemosMissingValuesCount = () => {
        let matches = demos.filter(entry => {
            for(var i in entry.valid) {
                if(entry.valid[i] === false) {
                    return true;
                }
            }
            return false;
        })
        return matches.length;
    }

    return (
        <Layer
        id={layerID}
        title={'Import Demos'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading === true,
            layerState: layerState
        }}
        buttons={getButtons()}>
            {getContent()}
        </Layer>
    )
}

export const RescheduleDemo = ({ abstract, index, options, utils }) => {

    const layerID = `reschedule_demo_${abstract.getID()}`;
    const [endDate, setEndDate] = useState(moment(abstract.object.end_date));
    const [layerState, setLayerState]= useState(null);
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(null);
    const [startDate, setStartDate] = useState(moment(abstract.object.start_date));

    const onReschedule = async () => {
        try {

            if(!startDate || !endDate) {
                throw new Error('Please choose a start date and end date before moving on');
            }
            if(startDate > endDate) {
                throw new Error('Please check that the start date is before the end date');
            }
            if(!startDate.isSame(endDate, 'day')) {
                throw new Error('Demos can not be scheduled for multiple days. Pleaase choose a start and end date that are on the same day');
            }
            if(!reason) {
                throw new Error('Please provide a reason for rescheduling this Demo');
            }

            setLoading('done');
            await Utils.sleep(0.25);

            let { status } = await Request.post(utils, '/demos/', {
                end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
                id: abstract.getID(),
                reason: reason,
                start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                type: 'reschedule'
            });

            setLoading(false);
            abstract.object.status = status;
            abstract.object.start_date = startDate;
            abstract.object.end_date = endDate;
            //utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `This demo has been rescheduled for ${moment(startDate).format('MMMM Do, YYYY [at] h:mma')} to ${moment(endDate).format('MMMM Do, YYYY [at] h:mma')}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: startDate && endDate ? 'primary' : 'dark',
            key: 'done',
            loading: loading === 'done',
            onClick: onReschedule,
            text: 'Save Changes'
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Reschedule Demo`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <LayerItem title={'Start Date and Time'}>
                <DateDurationPickerField
                utils={utils}
                selected={startDate}
                placeholder={'Choose a start date...'}
                onChange={date => setStartDate(date)}
                style={{
                    width: '100%',
                    marginBottom: 8
                }}/>
            </LayerItem>

            <LayerItem
            lastItem={true}
            title={'End Date and Time'}>
                <DateDurationPickerField
                utils={utils}
                selected={endDate}
                placeholder={'Choose an end date...'}
                onChange={date => setEndDate(date)}
                style={{
                    width: '100%'
                }}/>
            </LayerItem>

            <LayerItem
            lastItem={true}
            title={'Reason for Rescheduling'}>
                <TextView
                utils={utils}
                onChange={text => setReason(text)}
                style={{
                    width: '100%'
                }}/>
            </LayerItem>
        </Layer>
    )
}

export const RescheduleDemoRequest = ({ abstract, index, options, utils }) => {

    const layerID = `reschedule_demo_request_${abstract.getID()}`;
    const [layerState, setLayerState]= useState(null);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment(abstract.object.date));

    const onReschedule = async () => {
        if(!date) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please choose a date before moving on'
            });
            return;
        }

        try {
            setLoading('done');
            await Utils.sleep(0.25);

            let { status } = await Request.post(utils, '/demos/', {
                type: 'reschedule_request',
                id: abstract.getID(),
                date: moment(date).format('YYYY-MM-DD HH:mm:ss')
            });

            setLoading(false);
            abstract.object.status = status;
            abstract.object.cancelled = false;
            abstract.object.rescheduled = true;
            abstract.object.date = date;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `This demo request has been rescheduled for ${moment(date).format('MMMM Do, YYYY [at] h:mma')}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this Demo Request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={`Reschedule Demo Request`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Save Changes',
            color: date ? 'primary' : 'dark',
            loading: loading === 'done',
            onClick: onReschedule
        }]}>
            <DateDurationPickerField
            utils={utils}
            selected={date}
            placeholder={'Choose a date...'}
            onChange={date => setDate(date)}
            style={{
                width: '100%'
            }}/>
        </Layer>
    )
}

export const SetDemoAssignment = ({ abstract, index, options, utils }) => {

    const layerID = `set_demo_assignment_${abstract.getID()}`;
    const [layerState, setLayerState]= useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    const onChangeUser = async () => {
        if(!user) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please choose a user before moving on'
            });
            return;
        }

        try {
            setLoading('done');
            await Utils.sleep(0.25);

            let { status } = await Request.post(utils, '/demos/', {
                type: 'set_assignment',
                id: abstract.getID(),
                user_id: user.user_id
            });

            setLoading(false);
            abstract.object.primary = user;
            abstract.object.status = status;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `The primary assignment for this demo has been changed to ${user.full_name}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            color: user ? 'primary' : 'dark',
            key: 'done',
            loading: loading === 'done',
            onClick: onChangeUser,
            text: 'Done'
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.object.primary ? 'Change':'Set'} Primary Demo Assignment`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'small'
        }}>
            <UserLookupField
            onChange={setUser}
            placeholder={'Search for a user...'}
            value={user}
            utils={utils} />
        </Layer>
    )
}

export const SetDemoStatus = ({ abstract, index, options, utils }) => {

    const layerID = `set_demo_status_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [items, setItems] = useState([]);

    const onPromptNewCallLog = () => {
        utils.alert.show({
            title: 'All Done!',
            message: 'The status for this demo has been updated. Would you like to setup a time and date to call the lead?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: async key => {
                try {
                    setLayerState('close');
                    if(key === 'confirm') {
                        await Utils.sleep(0.25);
                        utils.layer.open({
                            abstract: Abstract.create({
                                object: CallLog.new(),
                                type: 'call_log'
                            }),
                            Component: AddEditCallLog.bind(this, {
                                isNewTarget: true,
                                lead: abstract.object.lead
                            }),
                            id: `new_call_log_${abstract.object.lead.id}`,
                            permissions: ['calls.actions.create']
                        })
                    }
                } catch(e) {
                    console.error(e.message);
                }
            }
        });
    }

    const onSetStatus = () => {

        // check for matching status codes for Demos with customer feedback templates
        // request confirmation before sending customer feedback email
        
        let codes = Demo.status.get();
        if(abstract.object.feedback_template && [codes.did_not_sell, codes.sale, codes.turndown].includes(selectedStatus)) {

            utils.alert.show({
                title: 'Confirm New Status',
                message: `Would you like to send the "${abstract.object.feedback_template.title}" questionnaire to the customer after updating the status?`,
                buttons: [{
                    key: 'do_not_send',
                    title: 'Only Update Status',
                    style: 'default'
                },{
                    key: 'confirm',
                    title: 'Update Status and Send Questionnaire',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key !== 'cancel') {
                        onSetStatusConfirm(key === 'confirm');
                        return;
                    }
                }
            });
            return;
        }
        onSetStatusConfirm();
    }

    const onSetStatusConfirm = async questionnaire => {
        try {
            setLoading('done');
            await Utils.sleep(0.25);

            // send request to server
            let { status } = await Request.post(utils, '/demos/', {
                type: 'set_status',
                id: abstract.getID(),
                status: selectedStatus,
                send_questionnaire: questionnaire
            })

            // end loading and update status for abstract target
            setLoading(false);
            abstract.object.status = status;

            // noptify subscribers that status has changed
            utils.content.update({
                object: {
                    id: abstract.getID(),
                    status: status
                },
                type: 'demo_status'
            });

            // prompt call creation if status is "recall"
            if(selectedStatus === Demo.status.get().recall) {
                onPromptNewCallLog();
                return;
            }

            // show user confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'The status for this demo has been updated',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this demo. ${e.message || 'An unknown error occurred'}`,
            })
        }
    }

    const getButtons = () => {
        return [{
            color: selectedStatus ? 'primary' : 'dark',
            key: 'done',
            loading: loading === 'done',
            onClick: onSetStatus,
            text: 'Save Changes'
        }];
    }

    const setupStatusCodes = () => {
        let codes = utils.dealership.status_codes.get().filter(status => {
            return status.capabilities.demos;
        }).map(status => ({
            id: status.code,
            title: status.text
        }));
        setItems(codes);
    }

    useEffect(() => {
        setupStatusCodes();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Set Demo Status'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'small'
        }}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={'Choose a status...'}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                setSelectedStatus(parseInt(id));
            }}
            style={{
                width: '100%'
            }}>
                <option disabled={true}>{'Choose a status...'}</option>
                {items.map((item, index) => {
                    return (
                        <option key={index} id={item.id}>{item.title}</option>
                    )
                })}
            </select>
        </Layer>
    )
}

export const SetDemoRequestStatus = ({ abstract, index, options, utils }) => {

    const layerID = `set_demo_status_${abstract.getID()}`;
    const [items, setItems] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const onSetStatus = async () => {
        try {
            setLoading('done');
            await Utils.sleep(0.25);

            // send request to server
            let { status } = await Request.post(utils, '/demos/', {
                id: abstract.getID(),
                status: selectedStatus,
                type: 'set_status'
            })

            // end loading and update status for abstract target
            setLoading(false);
            abstract.object.status = status;

            // noptify subscribers that status has changed
            utils.content.update({
                object: {
                    id: abstract.getID(),
                    status: status
                },
                type: 'demo_request_status'
            });

            // show user confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'The status for this demo request has been updated',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this demo request. ${e.message || 'An unknown error occurred'}`,
            })
        }
    }

    const getButtons = () => {
        return [{
            color: selectedStatus ? 'primary' : 'dark',
            key: 'done',
            loading: loading === 'done',
            onClick: onSetStatus,
            text: 'Save Changes'
        }];
    }

    const setupStatusCodes = () => {
        let codes = utils.dealership.status_codes.get().filter(status => {
            return status.capabilities.demo_requests;
        }).map(status => ({
            id: status.code,
            title: status.text
        }));
        setItems(codes);
    }

    useEffect(() => {
        setupStatusCodes();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Set Demo Request Status'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'small'
        }}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={'Choose a status...'}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                setSelectedStatus(parseInt(id));
            }}
            style={{
                width: '100%'
            }}>
                <option disabled={true}>{'Choose a status...'}</option>
                {items.map((item, index) => {
                    return (
                        <option key={index} id={item.id}>{item.title}</option>
                    )
                })}
            </select>
        </Layer>
    )
}

// Components
export const getDemoStatus = (utils, demo, options = {}) => {

    // prevent moving forward if no demo status was provided
    if(!demo || !demo.status) {
        return null;
    }

    // prepare status click handler
    const onStatusClick = evt => {

        // prevent root component from triggering click event
        evt.stopPropagation();

        // only allow editing if options object does not contain a false editing flag
        if(options.editable !== false) {
            utils.layer.open({
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo'
                }),
                Component: SetDemoStatus,
                id: `set_demo_status_${demo.id}`,
                permissions: ['demos.actions.edit']
            });
        }
    }

    return (
        <div
        className={options.editable === false ? '' : 'text-button'}
        onClick={onStatusClick}
        style={{
            alignItems: 'center',
            background: Appearance.colors.softGradient(demo.status.color),
            border: `1px solid ${demo.status.color}`,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            maxWidth: 95,
            overflow: 'hidden',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
            width: '100%'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{demo.status.text}</span>
        </div>
    )
}

export const getDemoRequestStatus = (utils, request, options = {}) => {

    // prevent moving forward if no request status was provided
    if(!request || !request.status) {
        return null;
    }

    // prepare status click handler
    const onStatusClick = evt => {

        // prevent root component from triggering click event
        evt.stopPropagation();

        // only allow editing if options object does not contain a false editing flag
        if(options.editable !== false) {
            utils.layer.open({
                abstract: Abstract.create({
                    object: request,
                    type: 'demo_request'
                }),
                Component: SetDemoRequestStatus,
                id: `set_demo_request_status_${request.id}`,
                permissions: ['demo_requests.actions.edit']
            });
        }
    }

    return (
        <div
        className={options.editable === false ? '' : 'text-button'}
        onClick={onStatusClick}
        style={{
            alignItems: 'center',
            background: Appearance.colors.softGradient(request.status.color),
            border: `1px solid ${request.status.color}`,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            maxWidth: 95,
            overflow: 'hidden',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
            width: '100%'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{request.status.text}</span>
        </div>
    )
}
