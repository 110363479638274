import React, { useState, useEffect } from 'react';
import Utils from 'files/Utils.js';

const ListField = ({ defaultValue, disablePlaceholder, items, onAddNew, onChange, placeholder, placeholderItem, preserveResult, returnKey, style, value }) => {

    const [selected, setSelected] = useState(value);

    const onListChange = e => {
        let id = Utils.attributeForKey.select(e, 'id');
        let val = isNaN(id) ? id : parseInt(id);
        let match = items.find(item => item.id === val || item.code === val);
        setSelected(preserveResult !== false && match ? (match.title || match.text) : null);
        if(typeof(onChange) === 'function') {
            if(!match) {
                onChange(null); // used for set edits
                return;
            }
            onChange(returnKey === false ? (match.title || match.text) : match);
        }
    }

    const getAccessoryButton = () => {
        if(typeof(onAddNew) === 'function') {
            return (
                <div style={{
                    width: 25,
                    height: 25,
                    minWidth: 25,
                    minHeight: 25,
                    marginLeft: 8
                }}>
                    <img
                    className={'text-button'}
                    src={'images/plus-button-blue-small.png'}
                    onClick={onAddNew}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }} />
                </div>
            )
        }
        return null;
    }

    const getItems = () => {
        if(!items || !Array.isArray(items)) {
            return null;
        }
        return items.map((item, index) => {
            return (
                <option
                key={index}
                id={item.id || item.code}
                disabled={item.disabled || false}>{item.title || item.text}</option>
            )
        })
    }

    useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            ...style
        }}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={defaultValue}
            value={selected || placeholder || ''}
            onChange={onListChange}
            style={{
                flexGrow: 1,
                height: 35
            }}>
                {placeholderItem !== false && (
                    <option disabled={disablePlaceholder}>{placeholder || 'Choose from the list below...'}</option>
                )}
                {getItems()}
            </select>
            {getAccessoryButton()}
        </div>
    )
}

export default ListField;
