import React from 'react';
import moment from 'moment-timezone';
import TimePicker from 'views/TimePicker.js';

const TimeRangePicker = ({ end, increments, onEndChange, onStartChange, start, utils }) => {

    const onEndTimeChange = date => {
        if(typeof(onEndChange) === 'function') {
            onEndChange(moment(date.format('HH:mm:ss'), 'HH:mm:ss'))
        }
    }

    const onStartTimeChange = date => {
        if(typeof(onStartChange) === 'function') {
            onStartChange(moment(date.format('HH:mm:ss'), 'HH:mm:ss'))
        }
    }

    const getEnd = () => {
        if(!end) {
            return  null;
        }
        if(typeof(end) === 'string') {
            return moment(end, 'HH:mm:ss');
        }
        return end;
    }

    const getStart = () => {
        if(!start) {
            return  null;
        }
        if(typeof(start) === 'string') {
            return moment(start, 'HH:mm:ss');
        }
        return start;
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
        }}>
            <div style={{
                width: '50%'
            }}>
                <TimePicker
                utils={utils}
                selected={getStart()}
                conformDate={false}
                increments={increments}
                onChange={onStartTimeChange} />
            </div>
            <img
            src={`images/${window.theme === 'dark' ? 'white' : 'grey'}-arrow-right-small.png`}
            style={{
                width: 15,
                height: 15,
                objectFit: 'contain',
                marginLeft: 8,
                marginRight: 8
            }} />
            <div style={{
                width: '50%'
            }}>
                <TimePicker
                utils={utils}
                selected={getEnd()}
                conformDate={false}
                increments={increments}
                onChange={onEndTimeChange} />
            </div>
        </div>
    )
}
export default TimeRangePicker;
