import React, { useEffect, useRef, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import PageControl from 'views/PageControl.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

const DealershipLookupField = ({ containerStyle, globalVisibility, icon, onChange, placeholder, utils, value }) => {

    const limit = 5;
    const offset = useRef(0);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [searchText, setSearchText] = useState(value && value.name);
    const [selected, setSelected] = useState(value);

    const onClick = result => {

        // empty resutls array, update selected item state, and update textfield label
        setResults([]);
        setSelected(result);
        setSearchText(result.name);

        // notify subscribers that data has changed
        if(typeof(onChange) === 'function') {
            onChange(result);
        }
    }

    const fetchResults = async () => {
        try {

            // prevent moving forward if no search text was provided
            if(!searchText || searchText.length === 0) {
                setResults([]);
                return;
            }

            // no additional logic is required is search text is the same as the selected target text
            if(selected && selected.name === searchText) {
                return;
            }

            // clear selected item from local state
            setSelected(false);

            // no additional logic is required if less than 3 characters were provided
            if(searchText.length < 3) {

                // empty results array and paging object 
                setResults([]);
                setPaging(null);

                // notify subscribers that selected item has changed
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            // submit request to server
            setLoading(true);
            let { dealerships, paging } = await Request.get(utils, '/dealerships/', {
                global_visibility: globalVisibility,
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'all'
            });

            // end loading and update results array
            setLoading(false);
            setPaging(paging);
            setResults(dealerships);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setSelected(value);
    }, [value]);

    useEffect(() => {
        offset.current = 0;
        fetchResults();
    }, [searchText]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            autoCapitalize={'sentences'}
            autoComplete={false}
            autoCorrect={false}
            icon={icon}
            loading={loading}
            onChange={setSearchText}
            placeholder={placeholder || 'Search by dealership name...'}
            useDelay={true}
            value={searchText}
            style={{
                flexGrow: 1
            }}/>
            {results.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                    marginBottom: 12,
                    marginTop: 8,
                    overflow: 'hidden',
                    padding: 0,
                    textAlign: 'left'
                }}>
                    {results.map((dealership, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onClick.bind(this, dealership),
                                subTitle: dealership.dealer_name,
                                title: dealership.name,
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        loading={loading === 'paging'}
                        offset={offset.current}
                        onClick={next => {
                            offset.current = next;
                            setLoading('paging');
                            fetchResults();
                        }}/>
                    )}
                </div>
            )}
        </div>
    )
}
export default DealershipLookupField;
