import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Checkbox from 'views/Checkbox.js';
import Content from 'managers/Content.js';
import DatePickerField from 'views/DatePickerField.js';
import Demo from 'classes/Demo.js';
import { DemoDetails, getDemoStatus } from 'managers/Demos.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import Layer from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import { LeadDetails, getLeadType, getLeadStatus } from 'managers/Leads.js';
import LeadLookupField from 'views/LeadLookupField.js';
import { Line } from 'react-chartjs-2';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import Panel from 'structure/Panel.js';
import Program from 'classes/Program.js';
import { ProgramDetails } from 'managers/Programs.js';
import Request from 'files/Request.js';
import StatusCodeFilters from 'views/StatusCodeFilters.js';
import TableListHeader from 'views/TableListHeader.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';
import ListField from 'views/ListField';

export const AppointmentsOverview = ({ index, options, utils }) => {

    const panelID = 'appointments_overview';
    const [date, setDate] = useState(moment());
    const [demos, setDemos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState(null);
    const [stats, setStats] = useState(null);

    const onDemoClick = demo => {
        utils.layer.open({
            abstract: Abstract.create({
                object: demo,
                type: 'demo'
            }),
            Component: DemoDetails,
            id: `demo_details_${demo.id}`,
            permissions: ['demos.details']
        });
    }

    const getContent = () => {
        if(demos.length === 0) {
            return (
                Views.entry({
                    title: 'No Demos Found',
                    subTitle: 'No Demos were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {demos.map((demo, index) => {
                        return getFields(demo, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getDateSelector = () => {
        return (
            <DatePickerField
            utils={utils}
            selected={date}
            onDateChange={date => setDate(date)}
            style={{
                maxWidth: 250
            }}/>
        )
    }

    const getFields = (demo, index) => {

        let target = demo || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.lead ? target.lead.full_name : null,
                    subTitle: target.user ? `Representative: ${target.user.full_name}` : null,
                    badge: {
                        text: target.start_date ? moment(target.start_date).format('h:mma') : null,
                        color: Appearance.colors.primary()
                    },
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onDemoClick.bind(this, target)
                })
            )
        }

        let fields = [{
            key: 'time',
            title: 'Time',
            value: target.start_date ? moment(target.start_date).format('h:mma') : null
        },{
            key: 'customer',
            title: 'Customer',
            value: target.lead ? target.lead.full_name : null
        },{
            key: 'user',
            title: 'Representative',
            value: target.user ? target.user.full_name : null
        },{
            key: 'locality',
            title: 'City',
            value: target.lead && target.lead.address ? target.lead.address.locality : null
        },{
            key: 'status',
            title: 'Status',
            value: target.status ? getDemoStatus({ status: target.status }) : null
        }];

        // create table headers with custom sorting options
        if(!demo) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== demos.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onDemoClick.bind(this, demo)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getStats = () => {
        if(!stats) {
            return null;
        }
        let items = [{
            key: 'total',
            title: 'On Board',
            color: Appearance.colors.grey()
        },{
            key: 'dispatched',
            title: 'Dispatched',
            color: Appearance.colors.blue
        },{
            key: 'sold',
            title: 'Sales',
            color: Demo.styles.status.find(entry => entry.status === Demo.status.get().sale).color
        },{
            key: 'dns',
            title: 'Did Not Sell',
            color: Demo.styles.status.find(entry => entry.status === Demo.status.get().did_not_sell).color
        }];
        return (
            <div
            className={'row m-0 px-0 pb-0 pt-3'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {items.map(getStatItem)}
            </div>
        )
    }

    const getStatItem = (item, index, items) => {
        return (
            <div
            key={index}
            className={`col-12 col-lg pt-0 pb-3 ${index === 0 ? 'pl-lg-3' : 'pl-lg-2'} ${index === items.length - 1 ? 'pr-lg-3' : 'pr-lg-2'}`}>
                <div style={{
                    ...Appearance.styles.panel(),
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: `linear-gradient(25deg, ${Utils.hexToRGBA(item.color, 0.75)}, ${Utils.hexToRGBA(item.color, 0.55)})`,
                    borderRadius: 15,
                    padding: 5,
                    border: 'none',
                    overflow: 'hidden',
                    boxShadow: '-15px 15px 20px rgba(0,0,0,0.05)'
                }}>
                    <div style={{
                        padding: '6px 12px 6px 12px',
                        backgroundColor: item.color,
                        textAlign: 'center',
                        width: '100%',
                        borderRadius: 12
                    }}>
                        <span style={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: 'white'
                        }}>{`${item.title}: ${stats[item.key] || 0}`}</span>
                    </div>
                </div>
            </div>
        )
    }

    const getTitle = () => {
        return `Appointment Overview for ${moment().isSame(date, 'year') ? date.format('MMMM Do') : date.format('MMMM Do, YYYY')}`;
    }

    const fetchDemos = async () => {
        try {
            setLoading(true);
            let { demos, stats } = await Request.get(utils, '/reports/', {
                type: 'appointments_overview',
                date: date && date.format('YYYY-MM-DD'),
                ...sorting
            });

            setLoading(false);
            setStats(stats);
            setDemos(demos.map(demo => Demo.create(demo)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the demos list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchDemos();
    }, [date, sorting]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['demo', 'lead'], {
            onFetch: fetchDemos,
            onUpdate: fetchDemos
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={getTitle()}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            rightContent: getDateSelector()
        }}>
            {getStats()}
            {getContent()}
        </Panel>
    )
}

const DemosList = ({ demos, status, user }, { index, options, utils }) => {

    const layerID = `demos_list_${user.user_id}_${status.code}`;
    const [loading, setLoading] = useState(false);

    const onDemoClick = async id => {
        try {

            // start loading and fetch demo details
            setLoading(true);
            let demo = await Demo.get(utils, id);

            // end loading and show demo details layer
            setLoading(false);
            utils.layer.open({
                id: `demo_details_${demo.id}`,
                abstract: Abstract.create({
                    type: 'demo',
                    object: demo
                }),
                Component: DemoDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = async () => {
        try {

            // start loading and fetch user details
            setLoading(true);
            let result = await User.get(utils, user.user_id);

            // end loading and show user details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: result,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${result.user_id}`,
                permissions: ['users.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    return (
        <Layer
        id={layerID}
        index={index}
        title={`${status.text} Demos for ${user.full_name}`}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 12,
                width: '100%'
            }}> 
                {Views.entry({
                    bottomBorder: false,
                    icon: {
                        path: user.avatar
                    },
                    onClick: onUserClick,
                    subTitle: user.email_address || 'No email address provided',
                    title: user.full_name
                })}
            </div>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {demos.map((demo, index) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== demos.length - 1,
                            hideIcon: true,
                            key: index,
                            onClick: onDemoClick.bind(this, demo.id),
                            subTitle: Utils.formatDate(demo.start_date),
                            title: demo.full_name
                        })
                    )
                })}
            </div>
        </Layer>
    )
}

export const MarketingAverageCallTime = ({ index, options, utils }) => {

    const panelID = 'marketing_average_call_time';
    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [marketingDirectors, setMarketingDirectors] = useState([]);
    const [sorting, setSorting] = useState(null);
    const [stats, setStats] = useState(null);

    const onMarketerClick = async userID => {
        try {
            setLoading(true);
            let user = await User.get(utils, userID);

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${user.user_id}`,
                permissions: ['users.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this marketing director. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(marketingDirectors.length === 0) {
            return (
                Views.entry({
                    title: 'No Call Data Found',
                    subTitle: 'No information is available at this time',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {marketingDirectors.map(getFields)}
                </tbody>
            </table>
        )
    }

    const getFields = (marketingDirector, index) => {

        let target = marketingDirector || {};
        let fields = [{
            key: 'marketing_director_user',
            title: 'Marketing Director',
            value: target.full_name
        },{
            key: 'set',
            title: 'Appointments',
            value: target.set
        },{
            key: 'not_interested',
            title: 'Not Interested',
            value: target.not_interested || '0'
        },{
            key: 'not_home',
            title: 'Not Home',
            value: target.not_home || '0'
        },{
            key: 'called',
            title: 'Total',
            value: target.called || '0',
            color: Appearance.colors.grey()
        }];

        // create table headers with custom sorting options
        if(!marketingDirector) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== marketingDirectors.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                let color = field.color || getStatusColor(field.key);
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onMarketerClick.bind(this, target.user_id)}>
                        {color
                            ?
                            <div style={{
                                display: 'inline-block'
                            }}>
                                <AltBadge content={{
                                    color: color,
                                    text: field.value
                                }} />
                            </div>
                            :
                            <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                        }
                    </td>
                )
            })}
            </tr>
        )
    }

    const getStats = () => {
        if(!stats) {
            return null;
        }
        let items = [{
            key: 'called',
            title: 'Called',
            color: Appearance.colors.grey()
        },{
            key: 'set',
            title: 'Set',
            color: getStatusColor('set')
        },{
            key: 'not_home',
            title: 'Not Home',
            color: getStatusColor('not_home')
        },{
            key: 'not_interested',
            title: 'Not Interested',
            color: getStatusColor('not_interested')
        }];
        return (
            <div
            className={'row m-0 px-0 pb-0 pt-3'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {items.map(getStatItem)}
            </div>
        )
    }

    const getStatItem = (item, index, items) => {
        return (
            <div
            key={index}
            className={`col-12 col-lg pt-0 pb-3 ${index === 0 ? 'pl-lg-3' : 'pl-lg-2'} ${index === items.length - 1 ? 'pr-lg-3' : 'pr-lg-2'}`}>
                <div style={{
                    ...Appearance.styles.panel(),
                    alignItems: 'center',
                    background: `linear-gradient(25deg, ${Utils.hexToRGBA(item.color, 0.75)}, ${Utils.hexToRGBA(item.color, 0.55)})`,
                    border: 'none',
                    borderRadius: 15,
                    boxShadow: '-15px 15px 20px rgba(0,0,0,0.05)',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    padding: 5,
                    position: 'relative',
                    textAlign: 'center'
                }}>
                    <div style={{
                        backgroundColor: item.color,
                        borderRadius: 12,
                        padding: '6px 12px 6px 12px',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <span style={{
                            color: 'white',
                            fontSize: 16,
                            fontWeight: 700
                        }}>{`${item.title}: ${stats[item.key] || 0}`}</span>
                    </div>
                </div>
            </div>
        )
    }

    const getStatusColor = key => {
        let entry = Demo.styles.status.find(entry => entry.status === Demo.status.get()[key]);
        return entry && entry.color;
    }

    const fetchLeads = async () => {
        try {
            setLoading(true);
            let { marketing_directors, stats } = await Request.get(utils, '/reports/', {
                date: date && date.format('YYYY-MM-DD'),
                type: 'marketing_average_call_time',
                ...sorting
            });

            setLoading(false);
            setStats(stats);
            setMarketingDirectors(marketing_directors);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the marketing call times list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLeads();
    }, [sorting]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['lead', 'user'], {
            onFetch: fetchLeads,
            onUpdate: fetchLeads
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'Average Call Times'}
        options={{
            ...options,
            loading: loading,
            removePadding: true
        }}>
            {getStats()}
            {getContent()}
        </Panel>
    )
}

export const MarketingDirectorStats = ({ index, options, utils }) => {

    const panelID = 'marketing_director_stats';
    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [marketingDirectors, setMarketingDirectors] = useState([]);
    const [sorting, setSorting] = useState(null);
    const [stats, setStats] = useState(null);

    const onMarketerClick = async userID => {
        try {
            setLoading(true);
            let user = await User.get(utils, userID);

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${user.user_id}`,
                permissions: ['users.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this marketing director. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(marketingDirectors.length === 0) {
            return (
                Views.entry({
                    title: 'No Statistics Found',
                    subTitle: 'No information is available at this time',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <div
            className={`custom-scrollbars ${window.theme}`}
            style={{
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                paddingBottom: 15
            }}>
                <table
                className={'px-3 py-2 m-0'}
                style={{
                    width: '100%'
                }}>
                    <thead style={{
                        width: '100%'
                    }}>
                        {getFields()}
                    </thead>
                    <tbody style={{
                        width: '100%'
                    }}>
                        {marketingDirectors.map(getFields)}
                    </tbody>
                </table>
            </div>
        )
    }

    const getFields = (marketingDirector, index) => {

        let target = marketingDirector || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.marketingDirector ? target.marketingDirector.full_name : null,
                    subTitle: `${target.called} ${target.called === 1 ? 'Call' : 'Calls'} Made`,
                    badge: {
                        text: `${target.set} ${target.set === 1 ? 'demo' : 'demos'} Set`,
                        color: target.set > 0 ? Appearance.colors.primary() : Appearance.colors.grey()
                    },
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onMarketerClick.bind(this, target.user_id)
                })
            )
        }

        let fields = [{
            key: 'marketing_director_user',
            title: 'Marketing Director',
            value: target.full_name
        },{
            key: 'bph',
            title: 'BPH',
            value: target.bph || '0'
        },{
            key: 'set',
            title: 'Set',
            value: target.set || '0'
        },{
            key: 'not_interested',
            title: 'Not Interested',
            value: target.not_interested || '0'
        },{
            key: 'not_home',
            title: 'Not Home',
            value: target.not_home || '0'
        },{
            key: 'unqualified',
            title: 'Unqualified',
            value: target.not_qualified || '0'
        },{
            key: 'do_not_call',
            title: 'Do Not Call',
            value: target.do_not_call || '0'
        },{
            key: 'wrong_number',
            title: 'Wrong Number',
            value: target.wrong_number || '0'
        },{
            key: 'recall',
            title: 'Recalls',
            value: target.recall || '0'
        },{
            key: 'contact',
            title: 'Contact %',
            value: target.contact || '0%'
        },{
            key: 'hours',
            title: 'Hours',
            value: target.hours || '0',
            component: 'textfield'
        },{
            key: 'pay_rate',
            title: 'Pay Rate',
            value: target.pay_rate
        },{
            key: 'set_percentage',
            title: 'Set %',
            value: target.set_percentage
        },{
            key: 'cost_per_demo',
            title: 'Cost Per Demo',
            value: target.cost_per_demo
        },{
            key: 'called',
            title: 'Total',
            value: target.called || '0'
        }];

        // create table headers with custom sorting options
        if(!marketingDirector) {
            return (
                <TableListHeader
                fields={fields}
                flexible={false}
                onChange={props => setSorting(props)} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== marketingDirectors.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                let color = getStatusColor(field.key);
                return (
                    <td
                    key={index}
                    className={'px-3 py-2'}
                    onClick={onMarketerClick.bind(this, target.user_id)}>
                        {color
                            ?
                            <div style={{
                                display: 'inline-block'
                            }}>
                                <AltBadge content={{
                                    color: color,
                                    text: field.value
                                }} />
                            </div>
                            :
                            <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                        }
                    </td>
                )
            })}
            </tr>
        )
    }

    const getStats = () => {
        if(!stats) {
            return null;
        }
        let items = [{
            key: 'contact',
            title: 'Contact',
            color: Appearance.colors.primary()
        },{
            key: 'called',
            title: 'Called',
            color: Appearance.colors.grey()
        },{
            key: 'set',
            title: 'Set',
            color: Demo.styles.status.find(entry => entry.status === Demo.status.get().set).color
        },{
            key: 'not_interested',
            title: 'Not Interested',
            color: Demo.styles.status.find(entry => entry.status === Demo.status.get().not_interested).color
        },{
            key: 'recalls',
            title: 'Recalls',
            color: Demo.styles.status.find(entry => entry.status === Demo.status.get().recall).color
        }];
        return (
            <div
            className={'row m-0 px-0 pb-0 pt-3'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {items.map(getStatItem)}
            </div>
        )
    }

    const getStatusColor = key => {
        let entry = Demo.styles.status.find(entry => entry.status === Demo.status.get()[key]);
        return entry && entry.color;
    }

    const getStatItem = (item, index, items) => {
        return (
            <div
            key={index}
            className={`col-12 col-lg pt-0 pb-3 ${index === 0 ? 'pl-lg-3' : 'pl-lg-2'} ${index === items.length - 1 ? 'pr-lg-3' : 'pr-lg-2'}`}>
                <div style={{
                    ...Appearance.styles.panel(),
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: `linear-gradient(25deg, ${Utils.hexToRGBA(item.color, 0.75)}, ${Utils.hexToRGBA(item.color, 0.55)})`,
                    borderRadius: 15,
                    padding: 5,
                    border: 'none',
                    overflow: 'hidden',
                    boxShadow: '-15px 15px 20px rgba(0,0,0,0.05)'
                }}>
                    <div style={{
                        padding: '6px 12px 6px 12px',
                        backgroundColor: item.color,
                        textAlign: 'center',
                        width: '100%',
                        borderRadius: 12
                    }}>
                        <span style={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: 'white'
                        }}>{`${item.title}: ${stats[item.key] || 0}`}</span>
                    </div>
                </div>
            </div>
        )
    }

    const fetchLeads = async () => {
        try {
            setLoading(true);
            let { marketing_directors, stats } = await Request.get(utils, '/reports/', {
                type: 'marketing_director_stats',
                date: date && date.format('YYYY-MM-DD'),
                ...sorting
            });

            setLoading(false);
            setStats(stats);
            setMarketingDirectors(marketing_directors);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the marketing directors list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLeads();
    }, [sorting]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['lead', 'user'], {
            onFetch: fetchLeads,
            onUpdate: fetchLeads
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        name={'Statistics'}
        options={{
            ...options,
            loading: loading,
            removePadding: true
        }}>
            {getStats()}
            {getContent()}
        </Panel>
    )
}

export const ReportPanel = ({ id }, { index, options, utils }) => {

    const panelID = id;
    const dealership = useRef(utils.dealership.get());

    const [endDate, setEndDate] = useState(null);
    const [filters, setFilters] = useState(null);
    const [fieldChecks, setFieldChecks] = useState([]);
    const [limit, setLimit] = useState(15);
    const [loading, setLoading] = useState(null);
    const [months, setMonths] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [showInactive, setShowInactive] = useState(false);
    const [sorting, setSorting] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [totals, setTotals] = useState(null);
    const [years, setYears] = useState([]);

    const isFieldChecked = key => {
        return fieldChecks.includes(key) ? false : true;
    }

    const onDemoClick = async demoID => {
        try {
            let demo = await Demo.get(utils, demoID);
            utils.layer.open({
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo'
                }),
                Component: DemoDetails,
                id: `demo_details_${demo.id}`,
                permissions: ['demos.details']
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }
    
    const onFormatFieldChecks = () => {
        if(fieldChecks.length === 0) {
            return null;
        }
        return {
            exclude_ids: fieldChecks
        }
    }

    const onLeadClick = async leadID => {
        try {
            let lead = await Lead.get(utils, leadID);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onPrintReport = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { results } = await Request.get(utils, '/reports/', {
                    end_date: endDate.format('YYYY-MM-DD'),
                    limit: limit,
                    offset: offset,
                    start_date: startDate.format('YYYY-MM-DD'),
                    type: panelID,
                    ...formatFilters(),
                    ...props
                });

                setLoading(false);
                resolve(results);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onProgramClick = async programID => {
        try {
            let program = await Program.get(utils, programID);
            utils.layer.open({
                abstract: Abstract.create({
                    object: program,
                    type: 'program'
                }),
                Component: ProgramDetails,
                id: `program_details_${program.id}`,
                permissions: ['programs.details']
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this Program. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onResultClick = result => {
        switch(panelID) {
            case 'just_the_facts':
            case 'marketing_director_progress_overview':
            case 'safety_advisor_progress_overview':
            if(result.user) {
                onUserClick(result.user.user_id);
            }
            break;

            case 'lead_affiliate_leads':
            case 'lead_pending':
            onLeadClick(result.id);
            break;

            case 'lead_daily_follow_up':
            onDemoClick(result.id);
            break;

            case 'program_registrations':
            if(result.program) {
                onProgramClick(result.program.id);
            }
            break;

            case 'user_program_registrations':
            if(result.enrollment && result.enrollment.user) {
                onUserClick(result.enrollment.user.user_id);
            }
            break;
        }
    }

    const onSetFieldCheck = (key, enabled) => {
        setFieldChecks(checks => {
            if(enabled === false) {
                checks.push(key);
                return [ ...checks ];
            }
            checks = checks.filter(prev_key => prev_key !== key);
            return [ ...checks ];
        });
    }

    const onShowSafetyAdvisorDemos = async (user, item) => {
        try {
            setLoading(true);
            let { demos } = await Request.get(utils, '/demos/', {
                category: 'demos',
                ids: item.ids,
                sort_key: 'start_date',
                sort_type: Content.sorting.type.descending,
                type: 'all'
            });

            setLoading(false);
            utils.layer.open({
                Component: DemosList.bind(this, { 
                    demos: demos, 
                    status: {
                        code: item.key,
                        text: item.title
                    },
                    user: user
                }),
                id: `demos_list_${user.user_id}_${item.key}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the demos for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onTableValueClick = (result, item, evt) => {

        // prevent event from propagating to parent element
        evt.stopPropagation();

        // determine next steps based on panel id
        switch(panelID) {
            case 'safety_advisor_progress_overview':
            onShowSafetyAdvisorDemos(result.user, item)
            break;
        }
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${user.user_id}`,
                permissions: ['users.details']
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this user. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        if(['just_the_facts', 'marketing_director_progress_overview', 'safety_advisor_progress_overview'].includes(panelID)) {
            return [{
                key: 'active',
                title: `${showInactive ? 'Hide' : 'Show'} Inactive`,
                style: showInactive ? 'default' : 'grey',
                onClick: () => {
                    setOffset(0);
                    setShowInactive(status => !status);
                }
            }];
        }
        return null;
    }

    const getContentTargets = () => {
        switch(panelID) {
            case 'just_the_facts':
            case 'marketing_director_progress_overview':
            case 'safety_advisor_progress_overview':
            case 'user_program_registrations':
            return 'user';

            case 'lead_affiliate_leads':
            case 'lead_credit':
            case 'lead_daily_follow_up':
            case 'lead_pending':
            return 'lead';

            case 'program_registrations':
            return 'program';

            default:
            return 'Unknown';
        }
    }

    const getDatePicker = () => {

        if([ 'just_the_facts' ].includes(panelID)) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <select
                    className={`custom-select ${window.theme}`}
                    value={endDate ? endDate.format('MMMM') : 'Month'}
                    onChange={evt => {
                        let index = Utils.attributeForKey.select(evt, 'index');
                        let month = months[parseInt(index)];
                        if(!month) {
                            return;
                        }
                        let year = endDate.format('YYYY');
                        let date = moment(`${year}-${month}-01`, 'YYYY-MMMM-DD');
                        setStartDate(date);
                        setEndDate(moment(date).endOf('month'));
                    }}
                    style={{
                        width: 150,
                        marginRight: 8
                    }}>
                        <option>{'Month'}</option>
                        {months.map((month, index) => (
                            <option key={index} index={index}>{month}</option>
                        ))}
                    </select>

                    <select
                    className={`custom-select ${window.theme}`}
                    value={endDate ? endDate.format('YYYY') : 'Year'}
                    onChange={evt => {
                        let index = Utils.attributeForKey.select(evt, 'index');
                        let year = years[parseInt(index)];
                        if(!year) {
                            return;
                        }
                        let month = endDate.format('MMMM');
                        let date = moment(`${year}-${month}-01`, 'YYYY-MMMM-DD');
                        setStartDate(date);
                        setEndDate(moment(date).endOf('month'));
                    }}
                    style={{
                        width: 100
                    }}>
                        <option>{'Year'}</option>
                        {years.map((year, index) => (
                            <option key={index} index={index}>{year}</option>
                        ))}
                    </select>
                </div>
            )
        }
        return (
            <DualDatePickerField
            endDate={endDate}
            onEndDateChange={setEndDate} 
            onStartDateChange={setStartDate}
            startDate={startDate}
            utils={utils}/>
        )
    }

    const getFields = (result, index) => {

        let target = result || {};
        let fields = [];

        switch(panelID) {
            case 'just_the_facts':
            fields = [{
                key: 'user',
                title: 'User',
                value: target.user ? target.user.full_name : null,
                ...target.user && {
                    selectable: {
                        enabled: true,
                        key: target.user.user_id
                    }
                }
            },{
                key: 'appointments',
                title: 'Appointments',
                value: target.appointments
            },{
                key: 'demos_ran',
                title: 'Demos',
                value: target.demos_ran
            },{
                key: 'hold_up',
                title: 'Hold Up %',
                value: `${parseInt(target.hold_up * 100)}%`
            },{
                key: 'demos_pace',
                title: 'Demos Pace',
                value: target.demos_pace
            },{
                key: 'sales',
                title: 'Sales',
                value: target.sales
            },{
                key: 'sales_pace',
                title: 'Sales Pace',
                value: target.sales_pace
            },{
                key: 'closing',
                title: 'Closing',
                value: `${parseInt(target.closing * 100)}%`
            }];
            break;

            case 'lead_affiliate_leads':
            fields = [{
                key: 'full_name',
                title: 'Name',
                value: target ? target.full_name : null
            },{
                key: 'lead_type',
                title: 'Lead Type',
                value: getLeadType(target)
            },{
                key: 'status',
                title: 'Status',
                value: getLeadStatus(utils, target)
            },{
                key: 'priority',
                title: 'Priority',
                value: target.priority ? 'Yes' : 'No'
            },{
                key: 'locality',
                title: 'City',
                value: target.address ? target.address.locality : null
            },{
                key: 'Phone Number',
                title: 'Phone Number',
                value: target.phone_number
            },{
                key: 'affiliate',
                title: 'Affiliate',
                value: target.affiliate ? target.affiliate.full_name : null
            }];
            break;

            case 'lead_credit':
            fields = [{
                key: 'user',
                title: 'User',
                value: target.user ? target.user.full_name : null
            },{
                key: 'total',
                title: 'Created',
                value: target.total
            },{
                key: 'assigned',
                title: 'Assigned',
                value: target.assigned
            },{
                key: 'unqualified',
                title: 'Unqualified',
                value: target.unqualified
            },{
                key: 'sold',
                title: 'Sold',
                value: target.sold
            }];
            break;

            case 'lead_daily_follow_up':
            fields = [{
                key: 'full_name',
                title: 'Name',
                value: target.full_name
            },{
                key: 'phone_number',
                title: 'Phone Number',
                sortable: false,
                value: target.phone_number ? Utils.formatPhoneNumber(target.phone_number) : null
            },{
                key: 'locality',
                title: 'City',
                value: target.locality
            },{
                key: 'status',
                title: 'Status',
                sortable: false,
                value: getLeadStatus(utils, target)
            }];
            break;

            case 'lead_pending':
            fields = [{
                key: 'date',
                title: 'Date',
                value: target.created && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title()
                        }}>{moment(target.created).format('MMM Do, YYYY')}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{moment(target.created).format('h:mma')}</span>
                    </div>
                )
            },{
                key: 'full_name',
                title: 'Name',
                value: target.full_name
            },{
                key: 'lead_type',
                title: 'Lead Type',
                value: typeof(target.lead_type) === 'object' ? target.lead_type.text : null
            },{
                key: 'phone_number',
                title: 'Phone Number',
                sortable: false,
                value: target.phone_number ? Utils.formatPhoneNumber(target.phone_number) : null
            },{
                key: 'address',
                title: 'Address',
                value: target.street_address_1
            }];
            break;

            case 'marketing_director_progress_overview':
            fields = [{
                key: 'user',
                title: 'User',
                value: target.user ? target.user.full_name : 'Name not available'
            }];
            let leadStatusCodes = Lead.status.get();
            fields = fields.concat(utils.dealership.status_codes.get().filter(entry => {
                return [
                    leadStatusCodes.set,
                    leadStatusCodes.assigned,
                    leadStatusCodes.confirmed,
                    leadStatusCodes.rescheduled,
                    leadStatusCodes.sale
                ].includes(entry.code);
            }).map(entry => {
                let key = Object.keys(leadStatusCodes).find(key => leadStatusCodes[key] === entry.code);
                return {
                    ...entry,
                    key: key,
                    value: target[key],
                    title: `${entry.code === leadStatusCodes.sale ? 'Sold' : entry.text} Demos`
                }
            }));
            fields = fields.concat([{
                key: 'calls',
                title: 'Calls/Emails',
                value: target.calls
            },{
                key: 'follow_up_calls',
                title: 'Follow Up Calls/Emails',
                value: target.follow_up_calls
            }]);
            break;

            case 'program_registrations':
            fields = [{
                key: 'start_date',
                title: 'Registered',
                value: target.start_date && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title()
                        }}>{moment(target.start_date).format('MMM Do')}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{moment(target.start_date).format('h:mma')}</span>
                    </div>
                )
            },{
                key: 'program',
                title: 'Program',
                value: target.program ? target.program.name : null
            },{
                key: 'user',
                title: 'Safety Associate',
                value: target.user && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        maxWidth: 250
                    }}>
                        <AltBadge
                        content={{
                            text: target.user.full_name,
                            color: Appearance.colors.secondary()
                        }}
                        style={{
                            marginRight: 8
                        }}
                        onClick={async e => {
                            try {
                                e.stopPropagation();
                                onUserClick(target.user.user_id);
                            } catch(e) {
                                console.error(e.message);
                            }
                        }} />
                    </div>
                )
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: target.program && target.program.dealership ? target.program.dealership.name : 'All Dealerships'
            },{
                key: 'end_date',
                title: 'End Date',
                value: target.end_date ? moment(target.end_date).format('MMM Do, YYYY') : 'Open Ended'
            }];
            break;
            
            case 'safety_advisor_progress_overview':

            // allow user to pass in status codes that are shown on the report
            // demo status changes need to be kept in a log that can be referenced for each requested code
            // set minimum date for report so legacy data prior to the log isnt referenced
            fields = [{
                key: 'user',
                title: 'User',
                value: target.user && target.user.full_name
            }];
            if(target.status_codes) {
                fields = fields.concat(target.status_codes.sort((a,b) => {
                    return a.text.localeCompare(b.text);
                }).map(entry => ({
                    color: entry.color,
                    ids: entry.ids,
                    key: entry.code,
                    title: entry.text,
                    value: entry.value
                })));
            } else {
                fields = fields.concat(filters.status_codes.sort((a,b) => {
                    return a.text.localeCompare(b.text);
                }).map(entry => ({
                    key: entry.code,
                    sortable: true,
                    title: entry.text
                })));
            }
            break;
            
            case 'user_program_registrations':
            fields = [{
                key: 'user',
                title: 'Safety Associate',
                value: target.user ? target.user.full_name : null
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: target.dealership ? target.dealership.name : null
            },{
                key: 'start_date',
                title: 'Start Date',
                value: target.enrollment && target.enrollment.start_date ? Utils.formatDate(target.enrollment.start_date) : null
            },{
                key: 'days_remaining',
                title: 'Days Remaining',
                value: 'Open Ended',
                ...target.enrollment && target.enrollment.days_remaining && {
                    value: `${target.enrollment.days_remaining} ${target.enrollment.days_remaining === 1 ? 'days' : 'days'}`
                },
                ...target.enrollment && target.enrollment.days_remaining === false && {
                    value: 'Expired'
                }
            },{
                key: 'total_demos',
                title: 'Total Demos',
                value: target.total || 0
            },{
                key: 'qualified_demos',
                title: 'Qualified Demos',
                value: target.qualified || 0
            },{
                key: 'sold_demos',
                title: 'Sold Demos',
                value: target.sold || 0
            }];
            break;
        }

        /// create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!result) {
            return (
                <TableListHeader
                fields={fields}
                flexible={hasFlexibleTableHeader()}
                onChange={setSorting} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {fields.map(getTableValue.bind(this, result))}
            </tr>
        )
    }

    const getFooter = () => {
        if(!totals) {
            return null;
        }
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}>
                <td
                key={index}
                className={'px-3 py-2 flexible-table-column'}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        fontWeight: 700
                    }}>{'Total'}</span>
                </td>
                {totals.map((total, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.text(),
                                fontWeight: 700
                            }}>{total}</span>
                        </td>
                    )
                })}
            </tr>
        )
    }

    const getNoResultsText = () => {

        switch(panelID) {
            case 'lead_affiliate_leads':
            if(!filters || !filters.lead_id) {
                return {
                    title: 'No Results Found',
                    subTitle: 'Please search for a Lead to continue',
                }
            }

            default:
            return {
                title: 'No Results Found',
                subTitle: 'No results were found for this report',
            }
        }
    }

    const getPrintProps = () => {

        let headers = [];
        let onRenderItem = null;

        switch(panelID) {
            case 'just_the_facts':
            onRenderItem = item => ({
                user: item.user ? item.user.full_name : null,
                appointments: item.appointments,
                demos_ran: item.demos_ran,
                hold_up: `${parseInt(item.hold_up * 100)}%`,
                demos_pace: item.demos_pace,
                sales: item.sales,
                sales_pace: item.sales_pace,
                closing: `${parseInt(item.closing * 100)}%`
            });
            headers = [{
                key: 'user',
                title: 'User'
            },{
                key: 'appointments',
                title: 'Appointments'
            },{
                key: 'demos_ran',
                title: 'Demos'
            },{
                key: 'hold_up',
                title: 'Hold Up %'
            },{
                key: 'demos_pace',
                title: 'Demos Pace'
            },{
                key: 'sales',
                title: 'Sales'
            },{
                key: 'sales_pace',
                title: 'Sales Pace'
            },{
                key: 'closing',
                title: 'Closing'
            }];
            break;

            case 'lead_affiliate_leads':
            onRenderItem = item => ({
                full_name: item ? item.full_name : null,
                lead_type: getLeadType(item),
                status: getLeadStatus(utils, item),
                priority: item.priority ? 'Yes' : 'No',
                locality: item.address ? item.address.locality : null,
                phone_number: item.phone_number,
                affiliate: item.affiliate ? item.affiliate.full_name : null
            });
            headers = [{
                key: 'full_name',
                title: 'Name'
            },{
                key: 'lead_type',
                title: 'Lead Type'
            },{
                key: 'status',
                title: 'Status'
            },{
                key: 'priority',
                title: 'Priority'
            },{
                key: 'locality',
                title: 'City'
            },{
                key: 'phone_number',
                title: 'Phone Number'
            },{
                key: 'affiliate',
                title: 'Affiliate'
            }];
            break;

            case 'lead_daily_follow_up':
            onRenderItem = item => {
                let statusItem = item.status ? Demo.styles.status.find(prevItem => prevItem.status === item.status.code) : null;
                return {
                    full_name: item.full_name,
                    phone_number: item.phone_number,
                    locality: item.locality,
                    status: statusItem ? statusItem.title : null
                }
            };
            headers = [{
                key: 'name',
                title: 'Name'
            },{
                key: 'phone_number',
                title: 'Phone Number'
            },{
                key: 'locality',
                title: 'City'
            },{
                key: 'status',
                title: 'Status'
            }]
            break;

            case 'lead_pending':
            onRenderItem = item => ({
                date: item.created ? `${moment(item.created).format('MMM Do')} to ${moment(item.created).format('h:mma')}` : null,
                full_name: item.full_name,
                phone_number: item.phone_number,
                address: item.street_address_1,
                lead_type: typeof(item.lead_type) === 'object' ? item.lead_type.text : null
            });
            headers = [{
                key: 'date',
                title: 'Date'
            },{
                key: 'full_name',
                title: 'Name'
            },{
                key: 'phone_number',
                title: 'Phone Number'
            },{
                key: 'lead_type',
                title: 'Lead Type'
            },{
                key: 'address',
                title: 'Address'
            }];
            break;

            case 'marketing_director_progress_overview':
            let leadStatusCodes = Lead.status.get();
            onRenderItem = item => ({
                user: item.user && item.user.full_name,
                ...Object.keys(leadStatusCodes).reduce((object, key) => {
                    object[key] = item[key] || 0;
                    return object;
                }, {})
            });

            let statusCodesValues = [leadStatusCodes.assigned, leadStatusCodes.confirmed, leadStatusCodes.rescheduled, leadStatusCodes.sale, leadStatusCodes.set];
            let statusCodes = utils.dealership.status_codes.get().filter(entry => statusCodesValues.includes(entry.code)).map(entry => ({
                ...entry,
                key: entry.code,
                title: entry.text
            }));

            headers = [{
                key: 'user',
                title: 'User'
            }].concat(statusCodes.sort((a,b) => {
                return a.title.localeCompare(b.title);
            })).concat([{
                key: 'calls',
                title: 'Calls and Emails'
            },{
                key: 'follow_up_calls',
                title: 'Follow Up Calls and Emails'
            }])
            break;

            case 'program_interactions':
            onRenderItem = item => ({
                date: item.date,
                total: item.total
            });
            headers = [{
                key: 'date',
                title: 'Date'
            },{
                key: 'total',
                title: 'Total'
            }];
            break;
            
            case 'program_registrations':
            onRenderItem = item => ({
                start_date: moment(item.start_date).format('MMM Do, YYYY [at] h:mma'),
                program: item.program ? item.program.name : null,
                lead: item.lead ? item.lead.full_name : null,
                user: item.user ? item.user.full_name : null,
                dealership: item.program && item.program.dealership ? item.program.dealership.name : 'All Dealerships',
                end_date: item.end_date ? moment(item.end_date).format('MMM Do, YYYY') : 'Open Ended'
            })
            headers = [{
                key: 'start_date',
                title: 'Registered'
            },{
                key: 'program',
                title: 'Program'
            },{
                key: 'lead',
                title: 'Lead'
            },{
                key: 'user',
                title: 'Safety Associate'
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'end_date',
                title: 'End Date'
            }];
            break;

            case 'safety_advisor_progress_overview':
            onRenderItem = item => ({
                user: item.user && item.user.full_name,
                ...item.status_codes.reduce((object, entry) => {
                    object[entry.code] = entry.value || '0';
                    return object;
                }, {})
            });
            headers = [{
                key: 'user',
                title: 'User'
            }].concat((filters && filters.status_codes || []).map(entry => ({
                key: entry.code,
                title: entry.text
            })));
            break;

            case 'schedules':
            onRenderItem = item => ({
                date: item.date ? `${moment(item.date).format('MMM Do')} to ${moment(item.date).format('h:mma')}` : null,
                name: item.full_name,
                phone_number: item.phone_number,
                address: item.address ? item.address.address : null,
                type: item.lead_type,
                affiliate: item.affiliate,
                status: item.status ? item.status.text : null
            });
            headers = [{
                key: 'date',
                title: 'Date'
            },{
                key: 'name',
                title: 'Name'
            },{
                key: 'phone_number',
                title: 'Phone Number'
            },{
                key: 'address',
                title: 'Address'
            },{
                key: 'type',
                title: 'Lead Type'
            },{
                key: 'affiliate',
                title: 'Affiliate'
            },{
                key: 'status',
                title: 'Status'
            }];
            break;
            
            case 'user_program_registrations':
            onRenderItem = item => ({
                user: item.user ? item.user.full_name : null,
                dealership: item.dealership ? item.dealership.name : null,
                start_date: item.enrollment && item.enrollment.start_date ? Utils.formatDate(item.enrollment.start_date) : null,
                total: item.total || 0,
                qualified: item.qualified || 0,
                sold: item.sold || 0,
                days_remaining: 'Open Ended',
                ...item.enrollment && item.enrollment.days_remaining && {
                    days_remaining: `${item.enrollment.days_remaining} ${item.enrollment.days_remaining === 1 ? 'days' : 'days'}`
                },
                ...item.enrollment && item.enrollment.days_remaining === false && {
                    days_remaining: 'Expired'
                }
            })
            headers = [{
                key: 'user',
                title: 'Safety Associate'
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'start_date',
                title: 'Start Date'
            },{
                key: 'days_remaining',
                title: 'Days Remaining'
            },{
                key: 'total',
                title: 'Total Demos'
            },{
                key: 'qualified',
                title: 'Qualified Demos'
            },{
                key: 'sold',
                title: 'Sold Demos'
            }];
            break;

            default:
            return null;
        }

        return {
            headers: headers,
            onFetch: onPrintReport,
            onRenderItem: onRenderItem
        }
    }

    const getTableValue = (result, field, index) => {

        switch(panelID) {
            case 'marketing_director_progress_overview':
            return (
                <td
                key={index}
                className={'px-3 py-2'}
                onClick={() => onResultClick(result)}>
                    {isNaN(field.value) && (
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    )}
                    {field.value >= 0 && (
                        <AltBadge
                        content={{
                            text: field.value.toString(),
                            color: field.color && field.value > 0 ? field.color : Appearance.colors.grey()
                        }}
                        style={{
                            display: 'inline-block',
                            opacity: field.value > 0 ? 1 : 0.5
                        }}
                        labelStyle={{
                            fontSize: Appearance.textStyles.subTitle().fontSize
                        }}/>
                    )}
                </td>
            )
            case 'safety_advisor_progress_overview':
            return (
                <td
                key={index}
                className={'px-3 py-2'}
                onClick={() => onResultClick(result)}>
                    {isNaN(field.value) && (
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    )}
                    {field.value >= 0 && (
                        <AltBadge
                        content={{
                            text: field.value.toString(),
                            color: field.color && field.value > 0 ? field.color : Appearance.colors.grey()
                        }}
                        onClick={field.value > 0 ? onTableValueClick.bind(this, result, field) : null}
                        style={{
                            display: 'inline-block',
                            opacity: field.value > 0 ? 1 : 0.5
                        }}
                        labelStyle={{
                            fontSize: Appearance.textStyles.subTitle().fontSize
                        }}/>
                    )}
                </td>
            )
        }

        return (
            <td
            key={index}
            className={'px-3 py-2 flexible-table-column'}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {field.selectable && field.selectable.enabled && (
                        <Checkbox
                        checked={isFieldChecked(field.selectable.key)}
                        onChange={onSetFieldCheck.bind(this, field.selectable.key)}
                        style={{
                            marginRight: 8
                        }}/>
                    )}
                    <span
                    onClick={() => onResultClick(result)}
                    style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{field.value}</span>
                </div>
            </td>
        );
    }

    const getRightColumn = () => {
        switch(panelID) {
            case 'lead_daily_follow_up':
            return (
                <div className={'col-12 col-md-3 col-lg-2 p-0 pl-md-2'}>
                    <StatusCodeFilters
                    categories={['leads']}
                    onChange={codes => setFilters({ status_codes: codes })} 
                    storage={'lead_daily_follow_up'}
                    utils={utils} />
                </div>
            )

            case 'safety_advisor_progress_overview':
            return (
                <div className={'col-12 col-md-3 col-lg-2 p-0 pl-md-2'}>
                    <StatusCodeFilters
                    categories={['demos']}
                    defaultCodes={[
                        Demo.status.get().did_not_sell,
                        Demo.status.get().not_interested,
                        Demo.status.get().sale,
                        Demo.status.get().turndown
                    ]}
                    onChange={codes => {
                        setFilters({ status_codes: utils.dealership.status_codes.get().filter(entry => codes.includes(entry.code)) });
                    }}
                    storage={'safety_advisor_progress_overview'}
                    useIds={false}
                    utils={utils}/>
                </div>
            );  
        }
    }

    const getRightHeaderColumn = () => {
        switch(panelID) {
            case 'lead_affiliate_leads':
            return (
                <LeadLookupField
                utils={utils}
                inline={false}
                placeholder={'Search for a Lead...'}
                onChange={lead => {
                    setFilters({ lead_id: lead.id })
                }}
                containerStyle={{
                    marginLeft: Utils.isMobile() ? 0 : 8,
                    maxWidth: Utils.isMobile() ? '100%' : 225
                }}/>
            );
        }
        return null;
    }

    const getTableLayout = () => {
        return ['safety_advisor_progress_overview'].includes(panelID) ? 'auto' : 'fixed';
    }

    const getTitle = () => {
        switch(panelID) {
            case 'just_the_facts':
            return 'Just the Facts';

            case 'marketing_director_progress_overview':
            return 'Marketing Director Progress Overview';

            case 'lead_affiliate_leads':
            return 'Lead Affiliates';

            case 'lead_credit':
            return 'Lead Credit';

            case 'lead_daily_follow_up':
            return 'Lead Daily Follow Up';

            case 'lead_pending':
            return 'Lead Pending';

            case 'program_registrations':
            return 'Program Registrations';

            case 'safety_advisor_progress_overview':
            return 'Safety Advisor Progress Overview';

            case 'user_program_registrations':
            return 'Safety Associate Program Registrations';
        }
    }

    const hasFilters = () => {
        return [
            'lead_affiliate_leads',
            'lead_daily_follow_up'
        ].includes(panelID);
    }

    const hasFlexibleTableHeader = () => {
        return ['safety_advisor_progress_overview'].includes(panelID) ? false : true;
    }

    const hasRightColumn = () => {
        return ['lead_daily_follow_up', 'safety_advisor_progress_overview'].includes(panelID);
    }

    const fetchReport = async () => {
        if(hasFilters() && !filters) {
            return;
        }
        try {
            setLoading(true);
            let { results, paging, totals } = await Request.get(utils, '/reports/', {
                type: panelID,
                limit: limit,
                offset: offset,
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                show_inactive: showInactive,
                ...formatFilters(),
                ...sorting,
                ...onFormatFieldChecks()
            });

            setLoading(false);
            setPaging(paging);
            setTotals(totals);
            setResults(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${getTitle().toLowerCase()} report. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const formatFilters = () => {
        if(panelID === 'safety_advisor_progress_overview') {
            return {
                ...filters,
                status_codes: filters.status_codes && filters.status_codes.map(entry => entry.code)
            }
        }
        return filters;
    }

    const setupFilters = () => {
        switch(panelID) {
            case 'just_the_facts':
            let date = moment().startOf('year');
            setStartDate(moment().startOf('month'));
            setEndDate(moment().endOf('month'));
            setYears([ ...Array(50) ].map((_, index) => moment(date).subtract(index, 'years').format('YYYY') ));
            setMonths([ ...Array(12) ].map((_, index) => moment(date).add(index, 'months').format('MMMM') ));
            break;

            case 'lead_daily_follow_up':
            setLimit(23);
            setFilters({ status_codes: Object.values(Demo.status.get()) });
            setStartDate(moment().subtract(1, 'months'));
            setEndDate(moment());
            break;

            case 'safety_advisor_progress_overview':
            let codes = [
                Demo.status.get().did_not_sell,
                Demo.status.get().not_interested,
                Demo.status.get().sale,
                Demo.status.get().turndown
            ];
            setFilters({ 
                status_codes: codes.map(code => ({
                    code: code,
                    text: Demo.status.toText(code)
                })) 
            });
            setStartDate(moment().subtract(1, 'months'));
            setEndDate(moment());
            break;

            default:
            setStartDate(moment().subtract(1, 'months'));
            setEndDate(moment());
        }
    }

    useEffect(() => {
        if(endDate && startDate) {
            fetchReport();
        }
    }, [endDate, fieldChecks, filters, offset, showInactive, sorting, startDate]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchReport);
        utils.content.subscribe(panelID, getContentTargets(), {
            onFetch: fetchReport,
            onUpdate: fetchReport
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchReport);
        }
    }, [endDate, startDate]);

    useEffect(() => {
        setupFilters();
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={getTitle()}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            print: getPrintProps(),
            buttons: getButtons(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: setOffset
            }
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 8
            }}>
                {getDatePicker()}
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {getRightHeaderColumn()}
                </div>
            </div>
            <div className={'row p-0 m-0'}>
                <div
                className={`${hasRightColumn() ? 'col-12 col-md-9 col-lg-10' : 'col-12'} p-0`}
                style={{
                    maxWidth: '100%',
                    overflowY: 'scroll',
                    ...results.length > 0 && {
                        border: `1px solid ${Appearance.colors.divider()}`,
                        borderRadius: 10
                    }
                }}>
                    {results.length === 0 && (
                        <div style={{
                            border: `1px solid ${Appearance.colors.divider()}`,
                            borderRadius: 10
                        }}>
                            {Views.entry({
                                bottomBorder: false,
                                hideIcon: true,
                                ...getNoResultsText()
                            })}
                        </div>
                    )}
                    {results.length > 0 && (
                        <table
                        className={'px-3 py-2 m-0 custom-scrollbars'}
                        style={{
                            tableLayout: getTableLayout(),
                            width: '100%'
                        }}>
                            <thead style={{
                                width: '100%'
                            }}>
                                {getFields()}
                            </thead>
                            <tbody style={{
                                width: '100%'
                            }}>
                                {results.map((result, index) => {
                                    return getFields(result, index)
                                })}
                            </tbody>
                            <tfoot style={{
                                width: '100%'
                            }}>
                                {getFooter()}
                            </tfoot>
                        </table>
                    )}
                </div>
                {getRightColumn()}
            </div>
        </Panel>
    )
}

export const ReportLeadLocations = ({ index, options, utils }) => {

    const panelID = 'report_lead_locations';
    const [loading, setLoading] = useState(false);
    const [annotations, setAnnotations] = useState(null);

    const fetchLeads = async () => {
        try {
            setLoading(true);
            let { leads } = await Request.get(utils, '/leads/', {
                type: 'locations'
            });

            setLoading(false);
            setAnnotations(leads.map(lead => {
                return {
                    id: lead.id,
                    title: lead.full_name,
                    subTitle: lead.phone_number,
                    location: lead.location ? {
                        latitude: lead.location.lat,
                        longitude: lead.location.long
                    } : null
                };
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the Lead map data. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onAnnotationClick = async id => {
        try {
            let lead = await Lead.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${id}`,
                permissions: ['leads.details']
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLeads();
    }, [])

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchLeads);
        utils.content.subscribe(panelID, ['lead'], {
            onFetch: fetchLeads,
            onUpdate: fetchLeads
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchLeads);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Lead Locations'}
        options={{
            ...options,
            loading: loading
        }}>
            <Map
            annotations={annotations}
            onAnnotationClick={onAnnotationClick}
            isScrollEnabled={true}
            isZoomEnabled={true}
            isRotationEnabled={true}
            style={{
                width: '100%',
                height: 350
            }}/>
        </Panel>
    )
}

export const ReportProgramInteractions = ({ index, options, utils }) => {

    const panelID = 'report_program_interactions';
    const [count, setCount] = useState(null);
    const [dates, setDates] = useState({
        end_date: moment(),
        start_date: moment().subtract(1, 'weeks')
    });
    const [datasets, setDatasets] = useState([]);
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState('init');
    const [program, setProgram] = useState(null);
    const [programs, setPrograms] = useState([]);

    const onDateChange = (key, date) => {
        setLoading(true);
        setDates(prev => ({
            ...prev,
            [key]: date
        }));
    }

    const onProgramChange = item => {
        setLoading(true);
        setProgram(item && programs.find(program => program.id === item.id));
    }

    const getContent = () => {
        if(loading === 'init' || datasets.length === 0) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        if(count === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No survey interactions were found within your requested date range',
                    title: 'No Interactions Found'
                })
            )
        }
        return (
            <Line
            data={{ datasets, labels }}
            options={{
                legend: { display: false },
                maintainAspectRatio: true,
                responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: Appearance.colors.transparent,
                            display: false
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 20
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: Appearance.colors.transparent,
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: value => {
                                return Utils.softNumberFormat(value);
                            }
                        }
                    }]
                },
                title: { display: false },
                tooltips: {
                    callbacks: {
                        label: tooltipItem => {
                            return `${tooltipItem.yLabel} ${parseInt(tooltipItem.yLabel) == 1 ? 'Interaction' : 'Interactions'}`
                        }
                    }
                }
            }}
            height={175}
            width={700}  />
        )
    }

    const getProgramListItems = () => {
        return programs.map(program => ({
            id: program.id,
            title: program.name
        }));
    }

    const fetchPrograms = async () => {
        try {
            setLoading('init');
            let { programs } = await Request.get(utils, '/dealerships/', {
                type: 'programs'
            });

            // filter out programs that are not sharable
            let targets = programs.filter(program => program.allow_sharing === true);
            setLoading(false);
            setPrograms(targets);
            setProgram(targets.length > 0 ? targets[0] : null);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the programs list for the programs interactions graph. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchReport = async () => {
        try {

            // no additional logic is needed if a program has not been selected
            if(!program) {
                return null;
            }

            // send request to server
            let { count, results } = await Request.get(utils, '/reports/', {
                end_date: dates.end_date && dates.end_date.utc().format('YYYY-MM-DD'),
                program_id: program.id,
                start_date: dates.start_date && dates.start_date.utc().format('YYYY-MM-DD'),
                type: 'program_interactions'
            });

            // end loading and update local state with results
            setLoading(false);
            setCount(count);
            setLabels(results.map(result => moment(result.date).format('MMM Do')));
            setDatasets([{
                borderColor: Appearance.colors.grey(),
                borderDash: [5, 5],
                borderWidth: 2,
                data: results.map(result => result.total),
                fill: false,
                label: 'Interactions',
                pointRadius: 5,
                pointStyle: 'circle',
                pointBackgroundColor: ({ dataIndex }) => {
                    return datasets[0] && datasets[0].data[dataIndex] > 0 ? Appearance.colors.primary() : Appearance.colors.grey();
                },
                pointBorderColor: ({ dataIndex }) => {
                    return datasets[0] && datasets[0].data[dataIndex] > 0 ? Appearance.colors.primary() : Appearance.colors.grey();
                },
                pointBorderWidth: 3
            }]);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the program interactions graph. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReport();
    }, [dates, program]);

    useEffect(() => {
        fetchPrograms();
        utils.events.on(panelID, 'dealership_change', fetchPrograms);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchPrograms);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Survey Interactions'}
        options={{
            ...options,
            loading: loading
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <DualDatePickerField
                containerStyle={{
                    maxWidth: 335
                }}
                endDate={dates.end_date}
                onEndDateChange={onDateChange.bind(this, 'end_date')} 
                onStartDateChange={onDateChange.bind(this, 'start_date')}
                startDate={dates.start_date}
                utils={utils}/>
                <ListField
                items={getProgramListItems()}
                onChange={onProgramChange}
                style={{
                    maxWidth: 335
                }}
                value={program && program.name} />
            </div>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginTop: 8,
                padding: count === 0 ? 0 : 12,
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}
