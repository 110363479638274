import React from 'react';
import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

export const MessageComponent = ({ message, style }) => {

    const getAvatar = () => {
        return message.sender && message.sender.avatar || 'images/avatar-placeholder.png';
    }

    const getContent = () => {

        // message color and orientation is set based on the presence of a sender
        // sender will be null if the message was sent from an external entity
        return (
            <div style={{
                backgroundColor: isExternalMessage() ? Appearance.colors.lighterGrey : Appearance.colors.blue,
                borderRadius: 20,
                maxWidth: '66%',
                overflow: 'hidden',
                padding: '6px 14px 6px 14px',
            }}>
                <span style={{
                    ...Appearance.textStyles.title(),
                    color: isExternalMessage() ? Appearance.colors.darkGrey : 'white',
                    whiteSpace: 'break-spaces'
                }}>
                    {message.text}
                </span>
            </div>
        )
    }

    const isExternalMessage = () => {
        return message.sender && message.sender.user_id ? false : true;
    }

    return (
        <div style={{
            width: '100%',
            ...style
        }}>
            {message.show_date && (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 15,
                    width: '100%'
                }}>
                    <span style={Appearance.textStyles.subTitle()}>{Utils.formatDate(message.date)}</span>
                </div>
            )}
            {message.show_name && (
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    display: 'block',
                    marginBottom: 4,
                    paddingLeft: isExternalMessage() ? 45 : 0,
                    paddingRight: isExternalMessage() ? 0 : 45,
                    textAlign: isExternalMessage() ? 'left' : 'right'
                }}>{message.sender ? message.sender.full_name : 'Name not available'}</span>
            )}
            <div style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isExternalMessage() ? 'flex-start' : 'flex-end',
                width: '100%'
            }}>
                {isExternalMessage() === true && (
                    <div style={{
                        borderRadius: 16,
                        height: 32,
                        marginRight: 8,
                        minHeight: 32,
                        minWidth: 32,
                        overflow: 'hidden',
                        width: 32
                    }}>
                        <img
                        src={getAvatar()}
                        style={{
                            height: '100%',
                            objectFit: 'cover',
                            width: '100%'
                        }}/>
                    </div>
                )}

                {getContent()}

                {isExternalMessage() === false && (
                    <div style={{
                        alignSelf: 'flex-end',
                        borderRadius: 16,
                        height: 32,
                        marginLeft: 8,
                        minHeight: 32,
                        minWidth: 32,
                        overflow: 'hidden',
                        width: 32,
                    }}>
                        <img
                        src={getAvatar()}
                        style={{
                            height: '100%',
                            objectFit: 'cover',
                            width: '100%'
                        }}/>
                    </div>
                )}
            </div>
        </div>
    )
}
