import React, { useRef, useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

const LeadTypePickerField = ({ canAddNewTarget = true, containerStyle, defaultValue, onChange, onLoad, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const onNewLeadType = () => {
        utils.alert.show({
            title: 'New Lead Type',
            message: 'What would you like to call this lead type?',
            textFields: [{
                key: 'title',
                placeholder: 'Title'
            }],
            buttons: [{
                key: 'save',
                title: 'Save',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: response => {
                if(response.title) {
                    onNewLeadTypeConfirm(response.title);
                    return;
                }
            }
        })
    }

    const onNewLeadTypeConfirm = async title => {
        try {

            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/dealerships/', {
                type: 'new_lead_type',
                text: title
            });

            setLoading(false);
            fetchLeadTypes();
            utils.content.fetch('lead_type');
            utils.alert.show({
                title: 'All Done!',
                message: `Your "${title}" Lead Type has been created and added to your list of Lead Types`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your lead type. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getAccessoryButton = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: 55,
                    height: 30,
                    minWidth: 55,
                    minHeight: 30,
                    marginLeft: 12,
                    backgroundColor: Appearance.colors.primary(),
                    borderRadius: 15,
                    overflow: 'hidden'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={require('files/lottie/dots-white.json')}
                    style={{
                        width: 25,
                        height: 20,
                        marginBottom: 2
                    }}/>
                </div>
            )
        }

        return canAddNewTarget && (
            <div style={{
                width: 25,
                height: 25,
                minWidth: 25,
                minHeight: 25,
                marginLeft: 8
            }}>
                <img
                className={'text-button'}
                src={'images/plus-button-blue-small.png'}
                onClick={onNewLeadType}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                }} />
            </div>
        )
    }

    const fetchLeadTypes = async () => {
        try {
            let { lead_types } = await Request.get(utils, '/dealerships/', {
                show_inactive: false,
                type: 'lead_types'
            });
            setItems(lead_types);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead types list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(items.length > 0 && typeof(onLoad) === 'function') {
            onLoad(items);
        }
    }, [items]);

    useEffect(() => {
        fetchLeadTypes();
    }, []);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            ...containerStyle
        }}>
            <ListField
            defaultValue={defaultValue}
            items={items}
            onChange={onChange} 
            utils={utils}
            value={value}/>
            {getAccessoryButton()}
        </div>
    )
}
export default LeadTypePickerField;
