import React, { useState, useEffect } from 'react';

import Abstract from 'classes/Abstract.js';
import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import MultipleListField from 'views/MultipleListField.js';
import { StatusDetails } from 'managers/Dealerships.js';

const StatusCodeFilters = React.forwardRef((props, ref) => {

    let { categories, dealership, defaultCodes, onChange, storage, utils } = props;
    const [filters, setFilters] = useState([]);
    const [selected, setSelected] = useState([]);

    const onListChange = items => {
        try {

            // prepare list of ids for local storage cache
            let ids = items.map(item => item.id);
            let key = getLocalStorageKey();
            localStorage.setItem(key, JSON.stringify(ids));

            // update selected state
            setSelected(items.map(item => item.id));

            // notify subscribers of data change
            if(typeof(onChange) === 'function') {
                onChange(items.map(item => item && item.id));
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onFilterModifierButtonClick = all => {

        // set selected status codes
        let next = all ? filters.map(item => item && item.id) : [];
        setSelected(next);

        // update local storage with selections
        let key = getLocalStorageKey();
        localStorage.setItem(key, JSON.stringify(next));

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onRenderResult = (item, index, items, onRemoveItem) => {
        return (
            <div
            className={`view-entry ${window.theme}`}
            key={index}
            onClick={onStatusClick.bind(this, item)}
            style={{
                alignItems: 'center',
                borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                display: 'flex',
                flexDirection: 'row',
                padding: '8px 12px 8px 12px'
            }}>
                <div style={{
                    backgroundColor: item.color,
                    borderRadius: 10,
                    height: 20,
                    marginRight: 8,
                    overflow: 'hidden',
                    width: 20
                }} />
                <span style={{
                    ...Appearance.textStyles.key(),
                    flexGrow: 1,
                    textAlign: 'left',
                    whiteSpace: 'normal'
                }}>{item.title}</span>
                <img
                src={`images/grey-close-icon-small.png`}
                className={'text-button'}
                onClick={onRemoveItem.bind(this, index)}
                style={{
                    height: 15,
                    marginLeft: 8,
                    objectFit: 'contain',
                    opacity: 1,
                    width: 15
                }} />
            </div>
        )
    }

    const onStatusClick = item => {

        let status = utils.dealership.status_codes.get().find(status => status.code === item.id);
        if(status) {
            utils.layer.open({
                id: `status_details_${status.code}`,
                abstract: Abstract.create({
                    object: status,
                    type: 'status'
                }),
                Component: StatusDetails
            });
        }
    }

    const getLocalStorageKey = () => {
        let dealership = utils.dealership.get();
        return `${dealership.id}.status_codes.selected.${storage}`;
    }

    const getSelectionButtons = () => {
        let shouldSelectAll = selected.length !== filters.length;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 8,
                width: '100%'
            }}>
                <AltBadge
                onClick={onFilterModifierButtonClick.bind(this, false)}
                content={{
                    color: Appearance.colors.grey(),
                    text: 'Deselect All'
                }}
                style={{
                    marginRight: shouldSelectAll ? 4 : 0,
                    padding: '5px 16px 5px 16px',
                    width: '100%'
                }}/>
                {shouldSelectAll && (
                    <AltBadge
                    onClick={onFilterModifierButtonClick.bind(this, true)}
                    content={{
                        text: 'Select All',
                        color: Appearance.colors.primary()
                    }}
                    style={{
                        marginLeft: 4,
                        marginRight: 0,
                        padding: '5px 16px 5px 16px',
                        width: '100%'
                    }}/>
                )}
            </div>
        )
    }

    const getStatusCodes = () => {
        return new Promise(resolve => {
            let interval = setInterval(() => {

                // fetch codes and prevent moving forward if no codes have been fetched at the root of the application
                let codes = utils.dealership.status_codes.get();
                if(codes.length === 0) {
                    return;
                }

                // clear interval and filter results by category if applicable
                clearInterval(interval);
                let results =  codes.filter(status => {
                    if(!categories || categories.length === 0) {
                        return true;
                    }
                    return categories.find(category => status.capabilities[category]) ? true : false;
                });
                resolve(results);
            }, 100);
        });
    }

    const getValues = () => {
        if(filters.length === 0) {
            return [];
        }
        return filters.filter(entry => selected && selected.includes(entry.id));
    }

    const setupFilters = async () => {
        try {
            
            // prepare status codes
            let statusCodes = await getStatusCodes();

            // set state for filters with all status codes options
            setFilters(statusCodes.map(status => ({
                color: status.color,
                id: status.code,
                title: status.text
            })));

            // fetch cached selection for filters from local storage
            let key = getLocalStorageKey();
            let state = localStorage.getItem(key);

            // determine if a default set of status codes were provided
            if(defaultCodes) {
                statusCodes = statusCodes.filter(entry => defaultCodes.includes(entry.code));
            }

            // filter out non-selection ids if applicable and update values state
            if(state) {

                // parse cached status codes and prepare new list of selected codes
                let ids = JSON.parse(state);
                statusCodes = statusCodes.filter(entry => ids.includes(entry.code));

                // notify subscribers of data change
                if(typeof(onChange) === 'function') {
                    onChange(statusCodes.map(item => item && item.code));
                }
            }

            // set state for selected status codes
            setSelected(statusCodes.map(entry => entry.code));

        } catch(e) {
            console.error(e.message);
        }
    }
    
    useEffect(() => {
        setupFilters();
        utils.content.subscribe(`status_code_filters_${storage}`, ['status'], {
            onUpdate: setupFilters
        });
        return () => {
            utils.content.unsubscribe(`status_code_filters_${storage}`);
        }
    }, [dealership]);

    return (
        <div
        ref={ref}
        style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <MultipleListField
            items={filters}
            onChange={onListChange}
            onRenderResult={onRenderResult}
            placeholder={'Choose a status from the list...'}
            value={getValues()}/>
            {getSelectionButtons()}
        </div>
    )
})

export default StatusCodeFilters;
