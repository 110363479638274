import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import { AddEditLeadScriptRebuttal } from 'managers/Leads.js';
import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import Layer from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import Request from 'files/Request.js';
import TextView from 'views/TextView.js';
import Utils from 'files/Utils.js';

export const config = {
    fontSize: 12
}

export const getKeywords = args => {

    const { editable, lead, utils } = args;
    
    const getDynamicResponseValue = key => {

        // no additional logic is needed if a lead was not provided
        if(!lead) {
            return null;
        }

        // determine if value exists on the lead class
        if(lead[key]) {
            return lead[key].text;
        }

        // prepare question ids for survey response
        let questions = {
            age: 1,
            marital_status: 2,
            home_ownership: 3,
            employement: 4
        }

        // return null if a lead, customer response, or question wasnt found
        if(!lead.customer_response || !questions[key]) {
            return null;
        }

        // look for an answer in the customer response
        let question = lead.customer_response.props.find(question => question.id === questions[key]);
        return question ? question.value : null;
    }

    return [{
        key: '[DEALERSHIP_NAME]',
        title: 'Dealership Name',
        category: 'dealership',
        description: `the dealership's name`,
        value: utils.dealership.get().name
    },{
        key: '[DEALERSHIP_ADDRESS]',
        title: 'Dealership Physical Address',
        category: 'dealership',
        description: `the dealership's physical address`,
        value: utils.dealership.get().address ? Utils.formatAddress(utils.dealership.get().address) : `${editable ? '':'Unknown '}Dealership Address`
    },{
        key: '[FIRST_NAME]',
        title: 'First Name',
        category: 'user',
        description: 'the first name for the presenter of the script',
        value: utils.user.get().first_name
    },{
        key: '[LAST_NAME]',
        title: 'Last Name',
        category: 'user',
        description: 'the last name for the presenter of the script',
        value: utils.user.get().last_name
    },{
        key: '[FULL_NAME]',
        title: 'Full Name',
        category: 'user',
        description: 'the first and last name for the presenter of the script',
        value: utils.user.get().full_name
    },{
        key: '[ADDRESS]',
        title: 'Physical Address',
        category: 'user',
        description: 'the physical address for the presenter of the script',
        value: Utils.formatAddress(utils.user.get().address) || `${editable ? '':'Unknown '}Address`
    },{
        key: '[EMAIL_ADDRESS]',
        title: 'Email Address',
        category: 'user',
        description: 'the email address for the presenter of the script',
        value: utils.user.get().email_address || `${editable ? '':'Unknown '}Email Address`
    },{
        key: '[PHONE_NUMBER]',
        title: 'Phone Number',
        category: 'user',
        description: 'the phone number for the presenter of the script',
        value: utils.user.get().phone_number || `${editable ? '':'Unknown '}Phone Number`
    },{
        key: '[LEAD_FIRST_NAME]',
        title: 'First Name',
        category: 'lead',
        description: 'the first name for the lead',
        value: lead ? lead.first_name : `${editable ? '':'Unknown '}Lead First Name`
    },{
        key: '[LEAD_LAST_NAME]',
        title: 'Last Name',
        category: 'lead',
        description: 'the last name for the lead',
        value: lead ? lead.last_name : `${editable ? '':'Unknown '}Lead Last Name`
    },{
        key: '[LEAD_FULL_NAME]',
        title: 'Full Name',
        category: 'lead',
        description: 'the first and last name for the lead',
        value: lead ? lead.full_name : `${editable ? '':'Unknown '}Lead Full Name`
    },{
        key: '[LEAD_SPOUSE_FIRST_NAME]',
        title: 'Spouse First Name',
        category: 'lead',
        description: 'the first name for the lead spouse if available',
        value: lead && lead.spouse_first_name ? lead.spouse_first_name : `${editable ? '':'Unknown '}Lead Spouse First Name`
    },{
        key: '[LEAD_SPOUSE_LAST_NAME]',
        title: 'Spouse Last Name',
        category: 'lead',
        description: 'the last name for the lead spouse if available',
        value: lead && lead.spouse_last_name ? lead.spouse_last_name : `${editable ? '':'Unknown '}Lead Spouse Last Name`
    },{
        key: '[LEAD_SPOUSE_FULL_NAME]',
        title: 'Spouse Full Name',
        category: 'lead',
        description: 'the first and last name for the lead spouse if available',
        value: lead && lead.spouse_first_name && lead.spouse_last_name ? `${lead.spouse_first_name} ${lead.spouse_last_name}` : `${editable ? '':'Unknown '}Lead Spouse Full Name`
    },{
        key: '[LEAD_ADDRESS]',
        title: 'Physical Address',
        category: 'lead',
        description: 'the physical address for the lead',
        value: lead ? Utils.formatAddress(lead.address) : `${editable ? '':'Unknown '}Lead Address`
    },{
        key: '[LEAD_EMAIL_ADDRESS]',
        title: 'Email Address',
        category: 'lead',
        description: 'the email address for the lead',
        value: lead ? lead.email_address : `${editable ? '':'Unknown '}Lead Email Address`
    },{
        key: '[LEAD_PHONE_NUMBER]',
        title: 'Phone Number',
        category: 'lead',
        description: 'the phone number for the lead',
        value: lead ? lead.phone_number : `${editable ? '':'Unknown '}Lead Phone Number`
    },{
        key: '[LEAD_TYPE]',
        title: 'Lead Type',
        category: 'lead',
        description: 'the type of Lead',
        value: lead ? lead.lead_type.text : `${editable ? '':'Unknown '}Lead Type`
    },{
        key: '[LEAD_SUBTYPE]',
        title: 'Lead Sub-Type',
        category: 'lead',
        description: 'the sub-type for the lead',
        value: lead && lead.lead_sub_type ? lead.lead_sub_type.text : `${editable ? '':'Unknown '}Lead Sub-Type`
    },{
        key: '[LEAD_AGE]',
        title: 'Age',
        category: 'lead',
        description: 'the age for the lead',
        value: getDynamicResponseValue('age') || `${editable ? '':'Unknown '}Lead Age`
    },{
        key: '[LEAD_MARITAL_STATUS]',
        title: 'Marital Status',
        category: 'lead',
        description: 'the marital status for the lead',
        value: getDynamicResponseValue('marital_status') || `${editable ? '':'Unknown '}Lead Marital Status`
    },{
        key: '[LEAD_HOME_OWNERSHIP]',
        title: 'Home Ownership',
        category: 'lead',
        description: 'the home ownership status for the lead',
        value: getDynamicResponseValue('homeowner_status') || `${editable ? '':'Unknown '}Lead Home Ownership`
    },{
        key: '[LEAD_EMPLOYEMENT_STATUS]',
        title: 'Employement',
        category: 'lead',
        description: 'the employement status for the lead',
        value: getDynamicResponseValue('occupational_status') || `${editable ? '':'Unknown '}Lead Employement`
    }]
};

export const formatLeadScriptContent = args => {

    const { text, utils } = args;
    const onKeywordClick = keyword => {
        utils.alert.show({
            title: 'Smart Keyword',
            message: `This smart keyword was activated using the term "${keyword.key}". This term automatically fills in ${keyword.description}. This information will be displayed in-place of the smart keyword when available.`
        });
    }

    let tag = null;
    let word = null;

    let target = text.replaceAll('\n', '<br>');
    return [ ...target ].reduce((array, character, index) => {

        // html tag detection
        if(character === '<') {
            tag = [character]
        }
        if(tag && ![ '<', '>' ].includes(character)) {
            tag.push(character);
        }
        if(tag && character === '>') {
            let term = tag.concat([character]).join('');
            tag = null;
            switch(term) {
                case '<br>':
                array.splice(array.length - (term.length - 1), term.length);
                array.push(
                    <br key={index} />
                );
                return array;
            }
        }

        // word detection
        if(character === '[') {
            word = [character];
        }
        if(word && ![ '[', ']' ].includes(character)) {
            word.push(character);
        }
        if(word && character === ']') {
            let term = word.concat([character]).join('');
            word = null;
            let keyword = getKeywords(args).find(keyword => {
                return keyword.key === term;
            })
            if(keyword) {
                array.splice(array.length - (term.length - 1), term.length);
                array.push((
                    <span
                    key={index}
                    className={'text-button'}
                    onClick={onKeywordClick.bind(this, keyword)}
                    style={{
                        whiteSpace: 'nowrap',
                        fontSize: config.fontSize,
                        fontWeight: 700,
                        color: Appearance.colors.primary(),
                    }}>{keyword.value}</span>
                ))
                return array;
            }
        }

        // Standard character
        array.push(
            <span key={index}>{character}</span>
        );
        return array;

    }, []);
}

const LeadScriptEditor = ({ lead, onChange, value, style }, { index, options, utils }) => {

    const layerID = lead ? `lead_script_editor_${lead.id}` : 'lead_script_editor';
    const controlsRef = useRef(null);
    const fieldRef = useRef(null);
    const editable = lead ? false : true;

    const [height, setHeight] = useState(window.innerHeight);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState(value || '');

    const onLearnMoreClick = () => {
        utils.alert.show({
            title: 'About Lead Scripts',
            message: 'Lead scripts are personalized to each Lead in your Dealership. Using Smart Keywords you can automatically insert information about yourself, the Lead, or your Dealership. You can choose a keyword to insert into your script by selecting a keyword from one of the dropdown menus or by typing in the keyword yourself'
        });
    }

    const onSelectKeyword = evt => {
        let id = Utils.attributeForKey.select(evt, 'id');
        let cursor = fieldRef.current.selectionStart || text.length;
        setText(text => {
            return `${text.slice(0, cursor)} ${id} ${text.slice(cursor)}`;
        });
    }

    const onDoneClick = () => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(text);
        }
    }

    const onWindowSizeChange = () => {
        setHeight(window.innerHeight);
    }

    const getButtons = () => {
        if(lead) {
            return null;
        }
        return [{
            key: 'about',
            text: 'Learn More',
            color: 'secondary',
            onClick: onLearnMoreClick
        },{
            key: 'about',
            text: 'Done',
            color: 'primary',
            onClick: onDoneClick
        }]
    }

    const getHeight = key => {
        if(editable === false) {
            return window.innerHeight / 1.5;
        }
        let controls = controlsRef.current ? controlsRef.current.clientHeight : 0;
        return ((height - 60 - controls) / 5) * (key === 'preview' ? 1.5 : 2)
    }

    const getSelectMenu = key => {
        let label = null;
        switch(key) {
            case 'dealership':
            label = 'Dealership';
            break;

            case 'lead':
            label = 'Leads';
            break;

            case 'user':
            label = 'Script Presenter';
            break;

            default:
            return null;
        }

        return (
            <select
            value={label}
            className={`custom-select ${window.theme}`}
            onChange={onSelectKeyword}
            style={{
                marginRight: 8
            }}>
                <option disabled={true}>{label}</option>
                {getKeywords({
                    utils: utils,
                    editable: editable,
                    lead: lead
                }).filter(keyword => {
                    return keyword.category === key;
                }).map((keyword, index) => {
                    return (
                        <option key={index} id={keyword.key}>{keyword.title}</option>
                    )
                })}
            </select>
        )
    }

    useEffect(() => {
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, [])

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={lead ? `Lead Script for ${lead.full_name}` : 'Lead Script Editor'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading
        }}>
            <RebuttalsCollection
            lead={lead}
            utils={utils} />

            {text.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: editable ? 12 : 0
                }}>
                    {editable && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            backgroundColor: Appearance.colors.primary()
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: 'white'
                            }}>{'Script Preview'}</span>
                        </div>
                    )}
                    <div style={{
                        maxHeight: getHeight('preview'),
                        overflowY: 'scroll',
                        padding: '8px 12px 8px 12px',
                        fontSize: config.fontSize,
                        fontWeight: 500,
                        color: Appearance.colors.text()
                    }}>
                        {formatLeadScriptContent({
                            editable: editable,
                            lead: lead,
                            text: text,
                            utils: utils
                        })}
                    </div>
                </div>
            )}

            {editable && (
                <>
                <div
                ref={controlsRef}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginBottom: 8
                }}>
                    {getSelectMenu('dealership')}
                    {getSelectMenu('lead')}
                    {getSelectMenu('user')}
                </div>
                <TextView
                ref={fieldRef}
                value={text}
                placeholder={'Type your script here...'}
                onChange={text => setText(text)}
                fieldStyle={{
                    height: getHeight('textview')
                }}/>
                </>
            )}
        </Layer>
    )
}

export default LeadScriptEditor;

const LeadScriptRebuttalContent = ({ lead }, { abstract, index, options, utils }) => {

    const layerID = `lead_script_rebuttal_content_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rebuttal, setRebuttal] = useState(abstract.object);

    const onDeleteClick = () => {
        utils.alert.show({
            title: 'Delete Rebuttal',
            message: 'Are you sure that you want to delete this rebuttal? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteClickConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteClickConfirm = async () => {
        try {
            setLoading('delete');
            await Utils.sleep(0.25);
            await Request.post(utils, '/dealerships/', {
                id: abstract.getID(),
                type: 'delete_lead_script_rebuttal'
            });

            setLoading(false);
            utils.content.fetch('lead_script_rebuttal');
            utils.alert.show({
                title: 'All Done!',
                message: `This lead script has been remove from your ${abstract.object.dealership_id ? 'dealership' : 'account'}.`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this lead script rebuttal. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditRebuttal = () => {
        utils.layer.open({
            id: `edit_lead_script_rebuttal_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditLeadScriptRebuttal.bind(this, {
                isNewTarget: false
            })
        });
    }

    const getButtons = () => {
        return [{
            key: 'delete',
            text: 'Delete',
            color: 'danger',
            loading: loading === 'delete',
            onClick: onDeleteClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditRebuttal,
            visible: abstract.object.dealership_id ? true : false
        }]
    }

    useEffect(() => {
        utils.content.subscribe(layerID, ['lead_script_rebuttal'], {
            onUpdate: abstract => {
                setRebuttal(prev => prev.id === abstract.getID() ? abstract.object : prev);
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={abstract.getTitle()}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                padding: 12
            }}>
                {rebuttal && formatLeadScriptContent({
                    editable: false,
                    lead: lead,
                    text: rebuttal.content,
                    utils: utils
                })}
            </div>
        </Layer>
    )
}

export const RebuttalsCollection = React.forwardRef((props, ref) => {

    const viewID = 'rebuttals_collection';
    const { lead, onHeightChange, style, title, utils } = props;
    const [rebuttals, setRebuttals] = useState([]);

    const onNewRebuttalClick = () => {
        let rebuttal = Lead.Script.Rebuttal.new();
        rebuttal.dealership = utils.dealership.get();
        utils.layer.open({
            id: 'new_lead_script_rebuttal',
            abstract: Abstract.create({
                type: 'lead_script_rebuttal',
                object: rebuttal
            }),
            Component: AddEditLeadScriptRebuttal.bind(this, {
                isNewTarget: true,
                onAddRebuttal: next => {
                    setRebuttals(prev => update(prev, {
                        $push: [next]
                    }));
                }
            })
        });
    }

    const onRebuttalClick = rebuttal => {
        utils.layer.open({
            id: `lead_script_rebuttal_content_${rebuttal.id}`,
            abstract: Abstract.create({
                type: 'lead_script_rebuttal',
                object: rebuttal
            }),
            Component: LeadScriptRebuttalContent.bind(this, {
                lead: lead
            })
        });
    }

    const getContent = () => {
        return (
            <div
            ref={ref}
            style={{
                ...Appearance.styles.unstyledPanel(),
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginBottom: 12,
                padding: 8,
                ...style
            }}>
                <div style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: title ? 'flex-start' : 'center',
                    width: '100%'
                }}>
                    {title === true && (
                        <span style={{
                            ...Appearance.textStyles.subHeader(),
                            marginLeft: 12,
                            marginRight: 12
                        }}>{'Rebuttals'}</span>
                    )}
                    {rebuttals.map((rebuttal, index) => {
                        return (
                            <AltBadge
                            key={index}
                            onClick={onRebuttalClick.bind(this, rebuttal)}
                            style={{
                                marginTop: 4,
                                marginBottom: 4,
                                marginLeft: 4,
                                marginRight: 4,
                                padding: '8px 15px 8px 15px'
                            }}
                            content={{
                                text: rebuttal.title,
                                color: Appearance.colors.primary()
                            }} />
                        )
                    })}
                </div>
                <img
                className={'text-button'}
                src={'images/plus-button-blue-small.png'}
                onClick={onNewRebuttalClick}
                style={{
                    width: 25,
                    height: 25,
                    marginTop: 5,
                    marginLeft: 8,
                    marginRight: 6
                }} />
            </div>
        )
    }

    const fetchRebuttals = async () => {
        try {
            let { rebuttals } = await Request.get(utils, '/dealerships/', {
                dealership_id: utils.dealership.get().id,
                type: 'lead_script_rebuttals'
            });
            setRebuttals(rebuttals.map(rebuttal => Lead.Script.Rebuttal.create(rebuttal)));

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchRebuttals();
        utils.content.subscribe(viewID, ['lead_script_rebuttal'], {
            onFetch: fetchRebuttals,
            onUpdate: abstract => {
                setRebuttals(rebuttals => {
                    return rebuttals.map(rebuttal => {
                        return rebuttal.id === abstract.getID() ? abstract.object : rebuttal;
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(viewID);
        }
    }, []);

    return getContent();
});
