import moment from 'moment-timezone';
import Dealership from 'classes/Dealership.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class PaymentClass {

    id = null;
    external_id = null;
    user = null;
    dealership = null;
    target_id = null;
    date = null;
    category = null;
    source_category = null;
    amount = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.external_id = props.external_id;
        this.user = props.user ? User.create(props.user) : null;
        this.dealership = props.dealership ? Dealership.create(props.dealership) : null;
        this.target_id = props.target_id;
        this.date = props.date ? moment(props.date) : null;
        this.category = props.category;
        this.source_category = props.source_category;
        this.amount = props.amount;
        this.status = props.status;
        return this;
    }
}

class MethodClass {

    brand = null;
    expiration = null;
    funding = null;
    id = null;
    is_default = false;
    last4 = null;
    source_category = false;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.brand = props.brand;
        this.expiration = props.expiration;
        this.funding = props.funding;
        this.id = props.id;
        this.is_default = props.is_default || false;
        this.last4 = props.last4;
        this.source_category = props.source_category;
        return this;
    }

    getTitle = () => {
        return `${this.brand} ${Utils.ucFirst(this.funding)} (${this.last4})`
    }

    getSubTitle = () => {
        return `Expires ${this.expiration.month}/${this.expiration.year}`;
    }

    getIcon = () => {
        switch(this.brand) {
            case 'Visa':
            return 'fab fa-cc-visa';

            case 'American Express':
            return 'fab fa-cc-amex';

            case 'Discover':
            return 'fab fa-cc-discover';

            case 'MasterCard':
            return 'fab fa-cc-mastercard';

            default:
            return 'far fa-credit-card';
        }
    }
}

const categories = {
    dealership: 2,
    user: 1
}

export default {
    new: () => new PaymentClass(),
    create: props => new PaymentClass().create(props),
    Method: {
        new: () => new MethodClass(),
        create: props => new MethodClass().create(props),
        source_categories: {
            get: () => categories
        }
    }
}
