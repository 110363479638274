import moment from 'moment-timezone';

import Dealership from 'classes/Dealership.js';
import Lead from 'classes/Lead.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

class ProgramClass {

    active = null;
    allow_sharing = false;
    avatar = null;
    category = null;
    created = null;
    dealership = null;
    dealership_id = null;
    description = null;
    duration = null;
    end_date = null;
    end_date_type = null;
    end_days = null;
    end_type = null;
    id = null;
    lead_script = null;
    lead_type = null;
    name = null;
    privacy = null;
    props = {};
    registered = null;
    template = null;
    text = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.active = props.active;
        this.allow_sharing = props.allow_sharing;
        this.avatar = props.avatar;
        this.category = props.category;
        this.created = props.created;
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.dealership_id = props.dealership_id;
        this.description = props.description;
        this.duration = props.duration;
        this.end_date = props.end_date && moment(props.end_date);
        this.end_days = props.end_days;
        this.end_type = props.end_type;
        this.id = props.id;
        this.lead_script = props.lead_script && Lead.Script.create(props.lead_script);
        this.lead_type = props.lead_type;
        this.name = props.name;
        this.props = props.props || {};
        this.privacy = props.privacy;
        this.registered = props.registered || 0;
        this.template = props.template && new ProgramTemplateClass().create(props.template);

        // set end date type to 'date' is an end_date was provided
        if(this.end_date) {
            this.end_date_type = 'date';
        }

        // set end date type to 'days' is end_days were provided
        if(this.end_days) {
            this.end_date_type = 'days';
        }
        return this;
    }

    open = () => {
        this.edits = {
            allow_sharing: this.allow_sharing,
            category: this.category || 'lead_generation',
            description: this.description,
            dealership: this.dealership,
            end_date: this.end_date,
            end_days: this.end_days,
            end_date_type: this.end_date_type,
            lead_script: this.lead_script,
            lead_type: this.lead_type,
            name: this.name,
            privacy: this.privacy,
            props: this.props || {},
            template: this.template
        }
        return this.edits;
    }

    set = props => {

        // set default edits
        this.edits = {
            ...this.edits,
            ...props
        }

        // determine if the end date type has changed
        if(this.edits.end_date_type) {
            switch(this.edits.end_date_type) {
                case 'date':
                this.edits.end_days = null;
                break;

                case 'days':
                this.edits.end_date = null;
                break;
            }
        }
        return this.edits;
    }

    close = () => {
        this.affiliate = this.edits.affiliate;
        this.allow_sharing = this.edits.allow_sharing;
        this.category = this.edits.category;
        this.description = this.edits.description;
        this.dealership = this.edits.dealership;
        this.end_date = this.edits.end_date;
        this.end_days = this.edits.end_days;
        this.end_date_type = this.edits.end_date_type;
        this.lead_script = this.edits.lead_script;
        this.lead_type = this.edits.lead_type;
        this.name = this.edits.name;
        this.privacy = this.edits.privacy;
        this.props = this.edits.props;
        this.template = this.edits.template;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                let { id } = await Request.post(utils, '/programs/', {
                    type: 'new',
                    ...this.toJSON(this.edits),
                    ...props
                });

                // close edits and set record id
                this.close();
                this.id = id;

                // notify subscribers of data change
                utils.content.fetch('program');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                await Request.post(utils, '/programs/', {
                    type: 'update',
                    ...this.toJSON(this.edits),
                    ...props
                });

                // close edits
                this.close();

                // notify subscribers of data change
                utils.content.update({
                    type: 'program',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = props => {
        let target = props || this;
        return {
            allow_sharing: target.allow_sharing,
            category: target.category,
            description: target.description,
            dealership_id: target.dealership && target.dealership.id,
            end_date: target.end_date,
            end_days: target.end_days,
            id: this.id,
            lead_script: target.lead_script && target.lead_script.id,
            lead_type: target.lead_type && target.lead_type.id,
            name: target.name,
            privacy: target.privacy,
            props: target.props,
            template: target.template && target.template.id
        }
    }
}

class ProgramTemplateClass {

    active = null;
    category = null;
    date = null;
    dealership = null;
    dealership_id = null;
    description = null;
    id = null;
    props = {};
    questions = [];
    request_demo = null;
    title = null;
    used_by = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.category = props.category;
        this.date = props.date && moment(props.date);
        this.dealership_id = props.dealership_id;
        this.description = props.description;
        this.id = props.id;
        this.props = props.props || {};
        this.questions = props.questions ? props.questions.map(question => new ProgramTemplateQuestionClass().create(question)) : [];
        this.request_demo = props.request_demo;
        this.title = props.title;
        this.used_by = props.used_by || 0;
        return this;
    }

    open = () => {
        this.edits = {
            category: this.category || 'lead_generation',
            dealership: this.dealership,
            description: this.description,
            props: this.props,
            questions: this.questions,
            request_demo: this.request_demo,
            title: this.title
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.category = this.edits.category;
        this.dealership = this.edits.dealership;
        this.title = this.edits.title;
        this.description = this.edits.description;
        this.props = this.edits.props;
        this.questions = this.edits.questions;
        this.request_demo = this.edits.request_demo;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, questions } = await Request.post(utils, '/programs/', {
                    type: 'new_template',
                    ...this.toJSON(this.edits)
                });
                this.close();
                this.id = id;
                this.questions = questions ? questions.map(question => new ProgramTemplateQuestionClass().create(question)) : [];

                utils.content.fetch('template');
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { questions } = await Request.post(utils, '/programs/', {
                    type: 'update_template',
                    id: this.id,
                    ...this.toJSON(this.edits)
                });
                this.close();
                this.questions = questions ? questions.map(question => new ProgramTemplateQuestionClass().create(question)) : [];

                utils.content.update({
                    type: 'template',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = props => {
        let target = props || this;
        return {
            category: target.category,
            dealership_id: target.dealership && target.dealership.id,
            description: target.description,
            props: target.props,
            request_demo: target.request_demo,
            title: target.title,
            ...target.questions && {
                questions: target.questions.map(question => {
                    return {
                        id: question.id,
                        order_index: question.order_index,
                        props: question.props,
                        required: question.required,
                        title: question.title,
                        type: question.type
                    }
                })
            }
        }
    }
}

class ProgramTemplateQuestionClass {

    dealership_id = null;
    editable = true;
    id = null;
    order_index = null;
    props = {};
    required = null;
    sortable = true;
    title = null;
    type = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.dealership_id = props.dealership_id;
        this.editable = props.editable;
        this.id = props.id;
        this.order_index = props.order_index;
        this.props = props.props || {};
        this.required = props.required;
        this.sortable = props.sortable;
        this.title = props.title;
        this.type = props.type;
        return this;
    }
}

class ProgramAgreementTemplateClass {

    dealership = null;
    date = null;
    id = null;
    options = [];
    props = null;
    title = null;
    used_by = 0;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.date = props.date && moment(props.date);
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.id = props.id;
        this.options = props.options;
        this.props = props.props;
        this.title = props.title;
        this.used_by = props.used_by || 0;
        return this;
    }

    open = () => {
        this.edits = {
            dealership: this.dealership,
            date: this.date,
            props: this.props,
            title: this.title
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.dealership = this.edits.dealership;
        this.date = this.edits.date;
        this.props = this.edits.props;
        this.title = this.edits.title;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/programs/', {
                    type: 'new_agreement_template',
                    ...this.toJSON(this.edits)
                })

                this.id = id;
                this.close();
                utils.content.fetch('program_agreement_template');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/programs/', {
                    type: 'update_agreement_template',
                    ...this.toJSON(this.edits)
                })

                this.close();
                utils.content.update({
                    type: 'program_agreement_template',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership && target.dealership.id,
            date: target.date,
            id: this.id,
            props: target.props,
            title: target.title
        }
    }
}

const fetchProgram = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { program } = await Request.get(utils, '/programs/', {
                type: 'details',
                id: id
            });
            let obj = new ProgramClass().create(program);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchProgramAgreementTemplate = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { template } = await Request.get(utils, '/programs/', {
                type: 'agreement_template_details',
                id: id
            });
            let obj = new ProgramAgreementTemplateClass().create(template);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

const templateQuestionTypes = {
    list: 1,
    checkboxes: 2,
    picker: 3,
    textfield: 4,
    textview: 5,
    file_picker: 6
}

const getQuestionInputDescription = question => {
    switch(question.type) {
        case templateQuestionTypes.list:
        if(question.props.items) {
            return Utils.oxfordImplode(question.props.items.map(item => item.title))
        }
        return 'Customer selects one item from a dropdown list';

        case templateQuestionTypes.checkboxes:
        if(question.props.items) {
            return Utils.oxfordImplode(question.props.items.map(item => item.title))
        }
        return 'Customer selects one or more items from a list';

        case templateQuestionTypes.picker:
        if(question.props.items) {
            return Utils.oxfordImplode(question.props.items.map(item => item.title))
        }
        return 'Customer selects one item from a list';

        case templateQuestionTypes.textfield:
        return 'Customer provides a short freeform answer';

        case templateQuestionTypes.textview:
        return 'Customer provides a long freeform answer';

        case templateQuestionTypes.file_picker:
        return 'Customer selects a document from their device';

        default:
        return 'Unknown Question Type';
    }
}

const getQuestionInputLabel = question => {
    switch(question.type) {
        case templateQuestionTypes.list:
        return 'Dropdown Menu';

        case templateQuestionTypes.checkboxes:
        return 'Multiple Checkboxes';

        case templateQuestionTypes.picker:
        return 'List of Options';

        case templateQuestionTypes.textfield:
        return 'Short Text Response';

        case templateQuestionTypes.textview:
        return 'Long Text Response';

        case templateQuestionTypes.file_picker:
        return 'Document Selector';

        default:
        return 'Unknown Question Type';
    }
}

export default {
    new: () => new ProgramClass(),
    get: (utils, id) => fetchProgram(utils, id),
    create: props => new ProgramClass().create(props),
    Agreement: {
        Template: {
            new: () => new ProgramAgreementTemplateClass(),
            get: (utils, id) => fetchProgramAgreementTemplate(utils, id),
            create: props => new ProgramAgreementTemplateClass().create(props),
        }
    },
    Template: {
        new: () => new ProgramTemplateClass(),
        create: props => new ProgramTemplateClass().create(props),
        Question: {
            new: () => new ProgramTemplateQuestionClass(),
            create: props => new ProgramTemplateQuestionClass().create(props),
            type: templateQuestionTypes,
            input: {
                getLabel: getQuestionInputLabel,
                getDescription: getQuestionInputDescription
            }
        }
    }
}
