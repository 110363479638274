import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import { AddEditEventType } from 'managers/Dealerships.js';
import Event from 'classes/Event.js';
import ListField from 'views/ListField.js';
import Request from 'files/Request.js';

const EventTypeLookupField = ({ containerStyle, icon, onChange, placeholder, utils, value }) => {

    const [eventTypes, setEventTypes] = useState([]);
    const [selected, setSelected] = useState(null);

    const onFieldChange = async item => {

        // declare result from list of event types
        let result = eventTypes.find(type => item && item.id === type.id);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(result);
        }
    }

    const onNewEventTypeClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: Event.Type.new(),
                type: 'event_type'
            }),
            Component: AddEditEventType.bind(this,{
                isNewTarget: true,
                onChange: result => {
                    setEventTypes(types => {
                        return update(types, {
                            $push: [result]
                        }).sort((a,b) => {
                            return a.title.localeCompare(b.title);
                        });
                    });
                }
            }),
            id: 'new_event_type',
            permissions: ['events.actions.new']
        });
    }

    const getListItems = () => {
        return eventTypes.map(evt => ({
            id: evt.id,
            title: evt.title
        }));
    }

    const fetchTypes = async () => {
        try {

            // fetch event types for dealership
            let { event_types } = await Request.get(utils, '/events/', {
                show_global: true,
                type: 'types'
            });
    
            // end loading and update state with event type options
            setEventTypes(event_types.map(type => Event.Type.create(type)));
    
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the event types list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setSelected(value);
    }, [value]);

    useEffect(() => {
        fetchTypes();
    }, []);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <ListField 
            icon={icon}
            items={getListItems()}
            onChange={onFieldChange}
            placeholder={placeholder || 'Select an event type from the list...'} 
            value={selected && selected.title}/>
            <img
            className={'text-button'}
            onClick={onNewEventTypeClick}
            src={'images/plus-button-blue-small.png'}
            style={{
                height: 25,
                marginLeft: 8,
                minWidth: 25,
                objectFit: 'contain',
                width: 25,
            }} />
        </div>
    )
}
export default EventTypeLookupField;
