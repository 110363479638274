import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from '@react-spring/web';
import { config, formatLeadScriptContent } from 'views/LeadScriptEditor.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Cookies from 'js-cookie';
import Dealership from 'classes/Dealership.js';
import Demo from 'classes/Demo.js';
import { DemoDetails } from 'managers/Demos.js';
import Event from 'classes/Event.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { EndIndex, LayerItem } from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import { LeadDetails } from 'managers/Leads.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Status from 'classes/Status.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import TimeRangePicker from 'views/TimeRangePicker.js';
import Utils from 'files/Utils.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Views, { AltBadge } from 'views/Main.js';
import UserLookupField from 'views/UserLookupField';
import LeadTypePickerField from 'views/LeadTypePickerField';
import LeadSubTypePickerField from 'views/LeadSubTypePickerField';

export const AddEditDemoSlot = ({ isNewTarget, onChange }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_demo_slot' : `edit_demo_slot_${abstract.getID()}`;
    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmitClick = async () => {
        try {

            // verify that all required fields were completed
            await validateRequiredFields(getFields);

            // verify that start hour is before the end hour
            if(edits.start_hour > edits.end_hour) {
                throw new Error('The start time must be before the end time');
            }

            // submit request to server
            await abstract.object.apply(utils, isNewTarget);

            // notify subscribers of data change
            if(typeof(onChange) === 'function') {
                onChange(abstract.object);
            }

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: isNewTarget ? `Your new demo slot has been created and is ready to use.` : `The '${abstract.object.name}' demo slot has been updated.`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} your demo slot. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setEdits(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmitClick,
            text: 'Submit'
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            lastItem: true,
            title: 'Details',
            items: [{
                component: 'textfield',
                key: 'name',
                onChange: text => onUpdateTarget({ name: text }),
                title: 'Name',
                value: edits.name
            },{
                component: 'list',
                items: getHourItems(),
                key: 'start_hour',
                onChange: item => onUpdateTarget({ start_hour: item && item.id }),
                title: 'Start Time',
                value: edits.start_hour && formatHour(edits.start_hour)
            },{
                component: 'list',
                items: getHourItems(),
                key: 'end_hour',
                onChange: item => onUpdateTarget({ end_hour: item && item.id }),
                title: 'End Time',
                value: edits.end_hour && formatHour(edits.end_hour)
            }]
        }];
    }

    const getHourItems = () => {
        return [...Array(24)].map((_, index) => {
            return {
                id: index,
                title: formatHour(index)
            }
        });
    }

    const formatHour = val => {
        let hour = val > 12 ? val - 12 : val;
        return `${hour === 0 ? 12 : hour}:00 ${val > 11 ? 'PM' : 'AM'}`;
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setEdits(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'New Demo Slot'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const AddEditEvent = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_event` : `edit_event_${abstract.getID()}`;
    const [evt, setEvent] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmitClick = async () => {
        try {

            // set loading flag and validate required fields
            setLoading('submit');
            await validateRequiredFields(getFields);

            // submit request to server
            await abstract.object.apply(utils, isNewTarget);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${evt.title}" ${evt.type.title} event has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this event. ${e.message || 'An unknown error occurred'}`
            });
        }
        return;
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setEvent(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            loading: loading === 'submit',
            key: 'done',
            onClick: onSubmitClick,
            text: isNewTarget ? 'Submit' : 'Save Changes'
        }];
    }

    const getFields = () => {
        if(!evt) {
            return [];
        }
        return [{
            key: 'details',
            title: 'About this Event',
            items: [{
                component: 'event_type_lookup',
                description: 'The event type helps categorize your events and allows you to easily filter the event on your calendar.',
                key: 'type',
                onChange: result => onUpdateTarget({ type: result }),
                title: 'Category',
                value: evt.type
            },{
                component: 'textfield',
                description: 'The title of the event is shown when representing the event in public and private settings.',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                title: 'Name',
                value: evt.title
            }]
        },{
            key: 'user_and_schedule',
            title: 'Assignments and Scheduling',
            items: [{
                component: 'user_lookup',
                description: 'The primary assignment will see this event on their calendar and be notified of the event 1 hour and 2 hours before the start date.',
                key: 'primary_user',
                onChange: user => onUpdateTarget({ primary_user: user }),
                required: false,
                title: 'Primary Assignment',
                value: evt.primary_user
            },{
                component: 'date_duration_picker',
                description: 'This date will be used to chart the start date on your calendar.',
                key: 'start_date',
                onChange: date => {
                    onUpdateTarget({ 
                        end_date: evt.end_date && date > evt.end_date ? moment(date).add(1, 'hours') : evt.end_date,
                        start_date: date 
                    })
                },
                title: 'Start Date',
                value: evt.start_date
            },{
                component: 'date_duration_picker',
                description: 'This date will be used to chart the end date on your calendar.',
                key: 'end_date',
                onChange: date => onUpdateTarget({ end_date: date }),
                title: 'End Date',
                value: evt.end_date
            }]
        },{
            key: 'attachments',
            title: 'Attachments',
            items: [{
                component: 'lead_lookup',
                description: 'Attaching a lead to this event can be helpful if the event is tied to a lead in some way, like an installation that resulted from a lead in the dealership.',
                key: 'lead',
                onChange: result => onUpdateTarget({ lead: result }),
                required: false,
                title: 'Lead',
                value: evt.lead
            },{
                component: 'demo_lookup',
                description: 'Attaching a demo to this event can be helpful if the event is tied to a demo in some way, like an installation that resulted from a successful demo.',
                key: 'demo',
                onChange: result => onUpdateTarget({ demo: result }),
                required: false,
                title: 'Demo',
                value: evt.demo
            }]
        }];
    }

    const setupTarget = async () => {
        let edits = abstract.object.open();
        setEvent(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New Event` : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const AddEditEventType = ({ isNewTarget, onChange }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_event_type` : `edit_event_type_${abstract.getID()}`;
    const [eventType, setEventType] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmitClick = async () => {
        try {

            // set loading flag and validate required fields
            setLoading('submit');
            await validateRequiredFields(getFields);

            // submit request to server
            await abstract.object.apply(utils, isNewTarget);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${eventType.title}" event type has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => {
                    setLayerState('close');
                    if(typeof(onChange) === 'function') {
                        onChange(abstract.object);
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this event type. ${e.message || 'An unknown error occurred'}`
            });
        }
        return;
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setEventType(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            loading: loading === 'submit',
            key: 'done',
            onClick: onSubmitClick,
            text: isNewTarget ? 'Submit' : 'Save Changes'
        }];
    }

    const getFields = () => {
        if(!eventType) {
            return [];
        }
        return [{
            key: 'details',
            title: 'About this Event Type',
            items: [{
                component: 'textfield',
                description: 'What do you want to call this event type? ',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                title: 'Name',
                value: eventType.title
            },{
                component: 'icon_picker',
                key: 'icon',
                onChange: icon => onUpdateTarget({ icon: icon }),
                props: { category: 'event_type' },
                title: 'Icon',
                value: eventType.icon
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setEventType(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New Event Type` : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const AddEditLeadScript = ({ isNewTarget, onAddLeadScript }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_lead_script' : `edit_lead_script_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [script, setScript] = useState(null);

    const onDoneClick = async () => {
        try {

            // set loading flag and verify that required fields were filled out
            setLoading('done');
            await validateRequiredFields(getFields);

            // determine if a new target needs to be created
            if(isNewTarget) {

                // submit request to server
                await abstract.object.submit(utils);

                // end loading and show confirmation alert
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The "${abstract.object.title}" lead script has been created`,
                    onClick: () => {
                        setLayerState('close');
                        if(typeof(onAddLeadScript) === 'function') {
                            onAddLeadScript(abstract.object);
                        }
                    }
                });
                return;
            }

            // submit request to server
            await abstract.object.update(utils);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" lead script has been updated`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this lead script. ${e.message || 'An unknown error occurred'}`
            });
        }
        return;
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setScript(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }];
    }

    const getFields = () => {
        if(!script) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Script Details',
            items: [{
                component: 'lead_script_editor',
                description: 'The script content will be shown to a marketing director during their conversation with a lead.',
                key: 'text',
                onChange: script => onUpdateTarget({ text: script }),
                required: true,
                title: 'Content',
                value: script.text,
            },{
                component: 'dealership_lookup',
                description: 'Which dealership does this script belong to?',
                key: 'dealership',
                onChange: dealership => onUpdateTarget({ dealership: dealership }),
                required: false,
                title: 'Dealership',
                value: script.dealership,
                visible: isNewTarget && utils.user.get().level < User.levels.get().dealer
            },{
                component: 'textfield',
                description: 'The title for a lead script is used to identify this content within your dealership.',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                required: true,
                title: 'Title',
                value: script.title
            }]
        }];
    }

    const setupTarget = async () => {
        try {

             // declare dealership and target edits
            let dealership = utils.dealership.get();
            let edits = abstract.object.open();

            // automatically set dealership to current dealership if user is not an administrator
            if(isNewTarget === true && utils.user.get().level > User.levels.get().admin) {
                edits = abstract.object.set({ dealership: dealership });
            }

            // determine how to set dealership object for existing targets
            if(isNewTarget === false) {
                if(abstract.object.dealership_id === dealership.id) {
                    edits = abstract.object.set({ dealership: dealership });
                } else {
                    let nextDealership = await Dealership.get(utils, dealership.id);
                    edits = abstract.object.set({ dealership: nextDealership });
                }
            }
            setScript(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this lead script. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Script' : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const AddEditStatus = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_status` : `edit_status_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);

    const onDoneClick = async () => {
        try {

            // set loading flag and validate required fields
            setLoading('submit');
            await validateRequiredFields(getFields);

            // submit changes to server
            await abstract.object.apply(utils, isNewTarget);

            // prepare list of capabilities for confirmation alert
            let capabilities = Object.keys(status.capabilities).filter(key => {
                return status.capabilities[key];
            }).map(key => {
                return key === 'demo_requests' ? 'demo requests' : key;
            });

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${status.text}" status has been ${isNewTarget ? 'created' : 'updated'}. This status will be available for ${Utils.oxfordImplode(capabilities)}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this status. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setStatus(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onDoneClick,
            text: isNewTarget ? 'Submit' : 'Save Changes'
        }]
    }

    const getFields = () => {
        if(!status) {
            return [];
        }
        return [{
            key: 'details',
            title: 'About this Status',
            items: [{
                component: 'textfield',
                description: 'What do you want to call this status? ',
                key: 'title',
                onChange: text => onUpdateTarget({ text: text }),
                title: 'Name',
                value: status.text
            },{
                component: 'color_picker',
                description: 'What color do you want to associate with this status? ',
                key: 'color',
                onChange: color => onUpdateTarget({ color: color }),
                title: 'Color',
                value: status.color && status.color.toUpperCase()
            }]
        },{
            key: 'capabilities',
            title: 'Capabilities',
            items: ['demos','demo_requests','events','leads'].map(key => {
                let title = key === 'demo_requests' ? 'Demo Requests' : Utils.ucFirst(key);
                return {
                    component: 'bool_list',
                    description: `Do you want to make this status available for ${title}?`,
                    key: key,
                    onChange: val => {
                        onUpdateTarget({ 
                            capabilities: update(status.capabilities, {
                                [key]: {
                                    $set: val
                                }
                            }) 
                        });
                    },
                    required: false,
                    title: Utils.ucFirst(title),
                    value: status.capabilities[key]
                }
            })
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setStatus(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New Status` : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const AddEditLeadType = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_lead_type` : `edit_lead_type_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [leadType, setLeadType] = useState(null);
    const [loading, setLoading] = useState(false);

    const onCreateLeadType = async props => {
        try {
            await abstract.object.submit(utils, props);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${leadType.text}" lead type has been created`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your lead type. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCreateSpecificLeadType = () => {
        utils.alert.show({
            title: 'New Lead Type',
            message: `Would you like to add this lead type to ${utils.dealership.get().name} or make it globally available for all dealerships?`,
            buttons: [{
                key: 'global',
                title: 'Add Globally',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'dealership',
                title: 'Add to Dealership',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: async key => {
                if(key === 'cancel') {
                    setLoading(false);
                    return;
                }
                try {
                    await Utils.sleep(0.25);
                    onCreateLeadType({ for_dealership: key !== 'global' });
                } catch(e) {
                    console.error(e.message);
                }
            }
        })
    }

    const onDoneClick = async () => {
        try {
            setLoading('done');
            await Utils.sleep(0.25);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                if(utils.user.get().level <= User.levels.get().admin) {
                    onCreateSpecificLeadType();
                    return;
                }
                onCreateLeadType();
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${leadType.text}" lead type has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this lead type. ${e.message || 'An unknown error occurred'}`
            })
        }
        return;
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setLeadType(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }]
    }

    const getFields = () => {

        if(!leadType) {
            return [];
        }

        let items = [{
            key: 'details',
            title: 'About this Lead Type',
            items: [{
                component: 'textfield',
                description: 'What do you want to call this lead type? ',
                key: 'text',
                onChange: text => onUpdateTarget({ text: text }),
                title: 'Name',
                value: leadType.text
            },{
                component: 'icon_picker',
                key: 'icon',
                onChange: icon => onUpdateTarget({ icon: icon }),
                props: { category: 'lead_type' },
                title: 'Icon',
                value: leadType.icon
            },{
                component: 'lead_script_picker',
                description: 'Attaching a script to a lead type will automatically present the script if no other script is found for a given lead.',
                key: 'lead_script',
                onChange: script => onUpdateTarget({ lead_script: script }),
                required: false,
                title: 'Lead Script',
                value: leadType.lead_script ? leadType.lead_script.title : null
            }]
        }]

        return items;
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setLeadType(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New Lead Type` : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            layerState: layerState,
            loading: loading === true,
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const Dealerships = ({ index, options, utils }) => {

    const panelID = 'dealerships';
    const limit = 15;
    const showInactiveRef = useRef(false);

    const [dealerships, setDealerships] = useState([]);
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [showInactive, setShowInactive] = useState(false);

    const onChangeActiveStatus = async (dealership, evt) => {

        // prevent parent click from triggering
        evt.stopPropagation();

        // confirmation not needed when activating a previously deactivated dealership
        let next = !dealership.gdl_active;
        if(next === true) {
            onChangeActiveStatusConfirm(dealership);
            return;
        }

        // request confirmation before deactivating a dealership
        utils.alert.show({
            title: `Deactivate Dealership`,
            message: `Are you sure that you want to deactivate this dealership? This will prevent "${dealership.name}" from using Global Data.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Deactivate',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm(dealership);
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async dealership => {
        try {

            // send status update request to server
            let next_status = !dealership.gdl_active;
            await Request.post(utils, '/dealerships/', {
                type: 'set_active_status',
                id: dealership.id,
                active: next_status
            });

            // update local dealership object with new status
            dealership.gdl_active = next_status;

            // notify subscribers of data change
            utils.content.fetch('dealership');
            utils.content.update({
                type: 'dealership',
                object: dealership
            });

            // update list of dealerships
            setDealerships(dealerships => {
                return dealerships.map(prevDealership => {
                    if(prevDealership.id === dealership.id) {
                        prevDealership.gdl_active = next_status;
                    }
                    return prevDealership;
                });
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this Dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDealershipClick = dealership => {
        utils.layer.open({
            id: `dealership_details_${dealership.id}`,
            abstract: Abstract.create({
                type: 'dealership',
                object: dealership
            }),
            Component: DealershipDetails
        })
    }

    const onPrintDealerships = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { dealerships } = await Request.get(utils, '/dealerships/', {
                    type: 'all',
                    search_text: searchText,
                    ...props
                });

                setLoading(false);
                resolve(dealerships.map(dealership => Dealership.create(dealership)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const getActiveStatus = dealership => {
        if(!dealership) {
            return;
        }
        let color = dealership.gdl_active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, dealership)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{dealership.gdl_active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const getButtons = () => {
        if(utils.user.get().level < User.levels.get().dealer) {
            return [{
                key: 'active',
                title: `${showInactiveRef.current ? 'Hide' : 'Show'} Inactive`,
                style: showInactiveRef.current ? 'default' : 'grey',
                onClick: () => {
                    setOffset(0);
                    showInactiveRef.current = showInactive ? false : true;
                    setShowInactive(showInactiveRef.current);
                }
            }];
        }
    }

    const getContent = () => {
        if(dealerships.length === 0) {
            return (
                Views.entry({
                    title: 'No Dealerships Found',
                    subTitle: 'No dealerships were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {dealerships.map((dealership, index) => {
                        return getFields(dealership, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (dealership, index) => {

        let target = dealership || {};
        let fields = [{
            key: 'name',
            title: 'Name',
            value: target.name
        },{
            key: 'locality',
            title: 'City',
            value: target.address ? target.address.locality : 'Not available'
        },{
            key: 'administrative_area_level_1',
            title: 'State',
            value: target.address ? target.address.administrative_area_level_1 : 'Not available'
        },{
            key: 'dealer',
            title: 'Dealer',
            value: target.dealer ? target.dealer.full_name : 'Not available'
        },{
            key: 'phone_number',
            title: 'Phone Number',
            value: target.dealer ? target.dealer.phone_number : null
        },{
            key: 'status',
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // Headers
        if(!dealership) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onDealershipClick.bind(this, dealership)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintDealerships,
            onRenderItem: (item, index, items) => ({
                name: item.name,
                city: item.address ? item.address.locality : null,
                administrative_area_level_1: item.address ? item.address.administrative_area_level_1 : null,
                dealer: item.dealer ? item.dealer.full_name : null,
                phone_number: item.dealer ? item.dealer.phone_number : null,
                status: item.gdl_active ? 'Yes' : 'No'
            }),
            headers: [{
                key: 'name',
                title: 'Name'
            },{
                key: 'locality',
                title: 'City'
            },{
                key: 'administrative_area_level_1',
                title: 'State'
            },{
                key: 'dealer',
                title: 'Dealer'
            },{
                key: 'phone_number',
                title: 'Phone Number'
            },{
                key: 'status',
                title: 'Status'
            }]
        }
    }

    const fetchDealerships = async () => {
        try {
            setLoading(true);
            let { dealerships, paging } = await Request.get(utils, '/dealerships/', {
                type: 'all',
                limit: limit,
                offset: offset,
                search_text: searchText,
                show_inactive: showInactiveRef.current
            });

            setLoading(false);
            setPaging(paging);
            setDealerships(dealerships.map(dealership => Dealership.create(dealership)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the dealerships list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchDealerships();
        showInactiveRef.current = showInactive;
    }, [offset, searchText, showInactive]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchDealerships);
        utils.content.subscribe(panelID, ['dealership'], {
            onFetch: fetchDealerships,
            onUpdate: abstract => {
                setDealerships(dealerships => {
                    return dealerships.map(dealership => {
                        return dealership.id === abstract.getID() ? abstract.object : dealership
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchDealerships);
        }
    }, []);

    return (
        <Panel
        index={index}
        name={'Dealerships'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            search: {
                placeholder: 'Search by name, dealer, city, state, or zipcode...',
                onChange: text => setSearchText(text)
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => setOffset(next)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const DealershipDetails = ({ abstract, index, options, utils }) => {

    const layerID = `dealership_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const onDealerClick = async () => {
        try {
            let user = await User.get(utils, abstract.object.dealer.user_id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${user.user_id}`,
                permissions: ['users.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'status',
                title: `Set as ${abstract.object.gdl_active ? 'Inactive' : 'Active'}`,
                style: abstract.object.gdl_active ? 'destructive' : 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'status') {
                onSetStatus();
                return;
            }
        })
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: 'Confirm New Status',
            message: `Are you sure that you want to set this Dealership as ${abstract.object.gdl_active ? 'inactive' : 'active'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.gdl_active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let next_status = !abstract.object.gdl_active;
            await Request.post(utils, '/dealerships/', {
                type: 'set_active_status',
                id: abstract.getID(),
                active: next_status
            })

            setLoading(false);
            abstract.object.gdl_active = next_status;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `This Dealership has been ${next_status ? 'activated' : 'deactivated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this Dealership. ${e.message || 'An unknown error occurred'}`,
            })
        }
    }

    const getButtons = () => {
        if(utils.user.get().level <= User.levels.get().admin) {
            return [{
                color: 'secondary',
                key: 'options',
                onClick: onOptionsClick,
                text: 'Options'
            }];
        }
    }

    const getFields = () => {

        let dealership = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: dealership.id
            },{
                key: 'name',
                title: 'Name',
                value: dealership.name
            },{
                key: 'dealer',
                title: 'Dealer',
                value: dealership.dealer ? dealership.dealer.full_name : null,
                onClick: dealership.dealer ? onDealerClick : null
            }]
        },{
            key: 'location',
            title: 'Location',
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                visible:  dealership.address && dealership.location ? true : false,
                value: dealership.location
            },{
                key: 'address',
                title: 'Physical Address',
                value: dealership.address ? Utils.formatAddress(dealership.address) : null
            },{
                key: 'timezone',
                title: 'Timezone',
                value: dealership.timezone
            }]
        }]

        return items;
    }

    return (
        <Layer
        id={layerID}
        title={'Dealership Details'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}
        buttons={getButtons()}>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const DealershipLocationsMap = ({ index, options, utils }) => {

    const panelID = 'dealership_locations_map';
    const [loading, setLoading] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [region, setRegion] = useState(null);
    const [showInactive, setShowInactive] = useState(false);

    const onDealershipClick = async props => {
        try {
            let dealership = await Dealership.get(utils, props.id);
            utils.layer.open({
                id: `dealership_details_${dealership.id}`,
                abstract: Abstract.create({
                    type: 'dealership',
                    object: dealership
                }),
                Component: DealershipDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this Dealership. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        if(utils.user.get().level < User.levels.get().dealer) {
            return [{
                key: 'active',
                title: `${showInactive ? 'Hide' : 'Show'} Inactive`,
                style: showInactive ? 'default' : 'grey',
                onClick: () => setShowInactive(status => !status)
            }]
        };
        return null;
    }

    const getFeatures = () => {
        if(!locationData) {
            return null;
        }
        return {
            id: 'dealership_locations',
            region: region,
            data: {
                ...locationData,
                features: locationData.features.filter(feature => {
                    if(showInactive) {
                        return true;
                    }
                    return feature.properties.active === true;
                })
            },
            onClick: onDealershipClick,
            icons: [{
                key: 'location-icon-grey',
                path: 'images/location-icon-grey.png'
            }],

            layer: {
                type: 'symbol',
                layout: {
                    'icon-size': 0.2,
                    'icon-anchor': 'center',
                    'icon-image': 'location-icon-grey',
                    'icon-allow-overlap': true
                },
                paint: {
                    'icon-color': [ 'get', 'color' ]
                }
            },
            onHover: feature => {
                try {
                    let { id, name, address } = feature.properties;
                    return {
                        title: name,
                        subTitle: address
                    }
                } catch(e) {
                    console.error(e.message);
                }
            }
        }
    }

    const fetchLocations = async () => {
        try {
            setLoading(true);
            let { data, region } = await Request.get(utils, '/dealerships/', {
                type: 'locations',
                show_inactive: showInactive
            });

            setLoading(false);
            setRegion(region);
            setLocationData(data);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the location map data. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLocations();
    }, [showInactive]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['dealership'], {
            onFetch: fetchLocations,
            onUpdate: abstract => {
                setLocationData(data => {
                    if(!data) {
                        return data;
                    }
                    // attempt to find dealership in feature collection
                    // may not be present if dealership was previously inactive
                    for(var i in data.features) {
                        if(abstract && data.features[i].properties.id) {

                            // update active status
                            data.features[i].properties.active = abstract.object.active;
                            data.features[i].properties.color = abstract.object.active ? Appearance.colors.primary() : Appearance.colors.lightGrey;
                            return { ...data };
                        }
                    }
                    // add to feature collection if not found above and location data is present
                    if(abstract.object.location) {
                        data.features.push({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [ abstract.object.location.longitude, abstract.object.location.latitude ]
                            },
                            properties: {
                                id: abstract.getID(),
                                name: abstract.object.name,
                                address: Utils.formatAddress(abstract.object.address),
                                active: abstract.object.active,
                                color: abstract.object.active ? Appearance.colors.primary() : Appearance.colors.lightGrey
                            }
                        })
                        return { ...data };
                    }
                    return data;
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Dealership Locations'}
        options={{
            ...options,
            loading: loading,
            buttons: getButtons()
        }}>
            <Map
            features={getFeatures()}
            isScrollEnabled={true}
            isZoomEnabled={true}
            isRotationEnabled={true}
            style={{
                border: `1px solid ${Appearance.colors.divider()}`,
                height: 350,
                width: '100%'
            }}/>
        </Panel>
    )
}

export const DealershipOverview = ({ onDateChange, onSlotChange, utils }) => {

    const offset = 150;
    const ref = useRef(null);
    const visible = useRef(false);
    
    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 22 },
        top: -offset
    }));
    const [endDate, setEndDate] = useState(moment().endOf('day'));
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [startDate, setStartDate] = useState(moment().startOf('day'));
    const [slots, setSlots] = useState([]);
    const [width, setWidth] = useState(0);
    
    const onAnimateIntoView = () => {

        // no additional logic is needed if components are already in view
        if(visible.current === true) {
            return;
        }

        // set width of fixed component using the relative component
        setWidth(ref.current.clientWidth);

        // update visible flag and animate components into view
        visible.current = true;
        setAnimations({ top: 0 });
    }

    const onAnimateOutOfView = () => {

        // no additional logic is needed if components are already out of view
        if(visible.current === false) {
            return;
        }

        // update visible flag and animate components out of view
        visible.current = false;
        setAnimations({ top: -offset });
    }

    const onDateChangePrivate = dates => {

        // update local state for end date and start date
        setEndDate(dates.end);
        setStartDate(dates.start);

        // notify subscribers that data has changed
        if(typeof(onDateChange) === 'function') {
            onDateChange(dates);
        }
    }

    const onLeadCounterClick = () => {

    }

    const onScrollEvent = () => {
        if(window.scrollY > offset) {
            onAnimateIntoView();
        } else {
            onAnimateOutOfView();
        }
    }

    const onSettingsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'dates',
                title: 'Change Overview Dates',
                style: 'default'
            },{
                key: 'new_slot',
                title: 'New Demo Slot',
                style: 'default'
            },{
                key: 'manage_slots',
                title: 'Manage Demo Slots',
                style: 'default'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key === 'dates') {
                utils.datePicker.showDual({
                    endDate: endDate,
                    onDateChange: onDateChangePrivate,
                    showTime: false,
                    startDate: startDate
                });
                return;
            }
            if(key === 'new_slot') {
                utils.layer.open({
                    abstract: Abstract.create({
                        object: Demo.Slot.new(),
                        type: 'demo_slot'
                    }),
                    Component: AddEditDemoSlot.bind(this, {
                        isNewTarget: true,
                        onChange: slot => {
                            setSlots(slots => {
                                return update(slots, {
                                    $push: [slot]
                                }).sort((a,b) => {
                                    return a.name.localeCompare(b.name);
                                });
                            });
                        }
                    }),
                    id: 'new_demo_slot'
                });
                return;
            }
            if(key === 'manage_slots') {
                utils.layer.open({
                    id: 'manage_demo_slots',
                    Component: ManageDemoSlots.bind(this, {
                        onChange: results => {
                            setSlots(results);
                            setSelectedSlot(slot => slot && results.find(s => s.id === slot.id) ? slot : null);
                        },
                        slots: slots
                    })
                });
                return;
            }
        });
    }

    const onSlotChangePrivate = slot => {

        // update local state with newly selected slot
        setSelectedSlot(slot);

        // notify subscribers that data has changed
        if(typeof(onSlotChange) === 'function') {
            onSlotChange(slot);
        }
    }

    const onSlotClick = evt => {
        utils.sheet.show({
            items: slots.map((slot, index) => ({
                key: index,
                title: slot.name,
                style: 'default'
            })).concat([{
                key: -1,
                title: 'No Slot',
                style: 'default'
            }]),
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key !== 'cancel') {
                onSlotChangePrivate(slots[key]);
                return;
            }
        });
    }

    const getContent = position => {
        let backgroundColor = Appearance.colors.background();
        return (
            <animated.div 
            className={position === 'fixed' ? 'offset-lg-2 offset-md-3' : ''}
            ref={position === 'relative' ? ref : null}
            style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
                position: position,
                width: '100%',
                ...position === 'fixed' && {
                    background: `linear-gradient(180deg, ${backgroundColor}, ${backgroundColor}, ${Utils.hexToRGBA(backgroundColor, 0)})`,
                    left: 0,
                    paddingBottom: 36,
                    width: width,
                    zIndex: EndIndex - 100,
                    ...animations
                }
            }}>
                <div style={{
                    ...Appearance.styles.panel(),
                    ...Appearance.styles.header(),
                    height: '100%'
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        position: 'relative',
                        width: '100%'
                    }}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            flexGrow: 1
                        }}>
                            <img
                            src={'images/global_data_system_user.png'}
                            style={{
                                height: 30,
                                marginRight: 12,
                                objectFit: 'contain',
                                width: 30
                            }} />
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <span style={{
                                    color: Appearance.colors.text(),
                                    fontSize: 16,
                                    fontWeight: 700,
                                    lineHeight: 1.1,
                                    marginBottom: 2,
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}>{`Dealership Overview`}</span>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    lineHeight: 1.1,
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}>{`${startDate.format('MMMM Do, YYYY')} to ${endDate.format('MMMM Do, YYYY')}`}</span>
                                
                            </div>
                        </div>
                        {getRightContent()}
                    </div>
                </div>
            </animated.div>
        )
    }

    const getRightContent = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                minWidth: 0
            }}>
                <AltBadge 
                content={{
                    color: selectedSlot ? Appearance.colors.primary() : Appearance.colors.grey(),
                    text: selectedSlot ? selectedSlot.name : 'No Slot Selected'
                }} 
                onClick={onSlotClick}
                style={{
                    padding: '5px 16px 5px 16px'
                }}/>
                <img 
                className={'text-button'}
                onClick={onSettingsClick}
                src={'images/settings-button-grey.png'}
                style={{
                    height: 25,
                    objectFit: 'contain',
                    width: 25
                }} />
            </div>
        )
    }

    const fetchSlots = async () => {
        try {

            // set loading flag and send request to server
            let { slots } = await Request.get(utils, '/dealerships/', {
                type: 'demo_slots'
            });

            // end loading and update local state with dealership slots
            setSlots(slots.map(slot => Demo.Slot.create(slot)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the slots for your dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {

        // fetch all slots for dealership
        fetchSlots();

        // add event listener for window scroll
        window.addEventListener('scroll', onScrollEvent);

        // add content listener for demo slot changes
        utils.content.subscribe('dealership_overview', ['demo_slot'], {
            onUpdate: abstract => {
                let slot = utils.dealership.slot.get();
                if(slot.id === abstract.getID()) {
                    onSlotChange(abstract.object);
                }
            }
        });

        return () => {
            utils.content.unsubscribe('dealership_overview');
            window.removeEventListener('scroll', onScrollEvent);
        }
    }, []);

    return (
        <div style={{
            width: '100%'
        }}>
            {getContent('relative')}
            {getContent('fixed')}
        </div>
    )
}

export const DealershipPreferences = ({ abstract, index, options, utils }) => {

    const layerID = `dealership_preferences_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [prefs, setPrefs] = useState(null);

    const onDoneClick = async () => {
        try {
            setLoading('done');
            await Utils.sleep(0.25);
            let { preferences } = await Request.post(utils, '/dealerships/', {
                type: 'update_preferences',
                id: abstract.getID(),
                prefs: prefs
            });

            setLoading(false);
            setPrefs(preferences);
            utils.alert.show({
                title: 'All Done!',
                message: `The preferences for ${abstract.object.name} have been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the preferences for ${abstract.object.name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditFirstRespondersContent = () => {

        // declare current message content
        let message = prefs.first_responders_sms_content || '';
        
        // show alert with textview to change message content
        utils.alert.show({
            title: 'First Responders Content',
            message: 'For OmniShield white label app subscribers, this is the content that will be sent to emergency contacts when a customer is inviting them to the First Responders program',
            content: (
                <div style={{
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: '100%'
                }}>
                    <TextView 
                    onChange={text => message = text}
                    placeholder={'Your message goes here...'}
                    value={message} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setPrefs(prefs => {
                        return update(prefs, {
                            first_responders_sms_content: {
                                $set: message
                            }
                        });
                    });
                    return;
                }
            }
        });
    }

    const onItemClick = item => {

        // determine if an item onClick function was provided
        if(typeof(item.onClick) === 'function') {
            item.onClick();
            return;
        }

        // fallback to showing alert with item description and component
        utils.alert.show({
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            }],
            component: item.component && (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    {item.component}
                </div>
            ),
            message: item.description,
            title: item.title
        });
    }

    const onUpdateTarget = async props => {
        try {
            setPrefs(prefs => ({
                ...prefs,
                ...props
            }));
        } catch(e) {
            console.error(e.message);
        }
    }

    const onVisibilityChange = item => {
        onUpdateTarget({ [item.key]: item.selected ? false : true });
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            permissions: ['settings.actions.edit'],
            text: 'Save Changes'
        }];
    }

    const getPropFields = () => {

        if(!prefs) {
            return [];
        }

        let items = [{
            key: 'demos',
            title: 'Demos',
            collapsed: false,
            items: [{
                key: 'default_demo_duration',
                title: 'Default Duration (Hours)',
                description: `This is the default amount of time that will be allocated for a newly set Demo. You'll still have the opportunity to change the duration of the Demo when booking.`,
                value: prefs.default_demo_duration ? `${prefs.default_demo_duration} ${prefs.default_demo_duration == 1 ? 'hour' : 'hours'}` : '3 hours',
                props: { format: 'number' },
                content: (
                    <TextField
                    format={'integer'}
                    placeholder={'3 hours'}
                    value={prefs.default_demo_duration || 3}
                    onChange={text => {
                        setPrefs(prefs => ({
                            ...prefs,
                            default_demo_duration: isNaN(text) ? 0 : parseInt(text)
                        }))
                    }} />
                )
            },{
                key: 'duplicate_rescheduled_demos',
                title: 'Duplicate Rescheduled Demos',
                description: 'Enabling this feature will preserve the original demo timeslot when rescheduling a demo for a new timeslot. The original timeslot will be listed as "Rescheduled" and the new timeslot will be listed as "Set".'
            },{
                key: 'global_demo_visibility',
                title: 'Global Visibility',
                description: 'Enabling this feature will provide Demo visibility to all accounts in your Dealership. Disabling this feature will only show Demos to accounts who hold an assignment, booking credit, ride along assignment, trainee assignment, or partner assignment.',
            },{
                key: 'demo_rescheduled_notification',
                title: 'Notification: Rescheduled ',
                description: 'Enabling this feature will tell the system to automatically send a push notification to the Dealer when a Demo has been rescheduled.',
            },{
                key: 'demo_notification',
                title: 'Notification: Set',
                description: 'Enabling this feature will tell the system to automatically send a push notification to the Dealer when a Demo has been set.',
            },{
                key: 'three_day_rule',
                title: 'Three Day Rule',
                description: 'Enabling this rule will allow the dealership to book a demo up to three days in advance. This means that any bookings that are further than three days out will not be accepted.',
            }]
        },{
            key: 'demo_requests',
            title: 'Demo Requests',
            collapsed: false,
            items: [{
                key: 'global_demo_request_visibility',
                title: 'Global Visibility',
                description: 'Enabling this feature will provide Demo Request visibility to all accounts in your Dealership. Disabling this feature will only show Demo Requests to accounts who hold an assignment or request credit.',
            },{
                key: 'demo_request_notification',
                title: 'Notification: Submitted',
                description: 'Enabling this feature will tell the system to automatically send a push notification to the Dealer when a new Demo Request is submitted.',
            }]
        },{
            key: 'leads',
            title: 'Leads',
            collapsed: false,
            items: [{
                component: 'textview',
                key: 'first_responders_sms_content',
                onClick: onEditFirstRespondersContent,
                title: 'First Responders Text Message Content',
                description: 'By default, new leads are made available to the dealership after they are submitted. If needed, you can automatically mark new leads as "Unreleased" when they are submitted. This will only allow dealers and directors to view this lead until the release it to the rest of the dealership.',
                value: 'Click to configure'
            },{
                key: 'global_lead_visibility',
                title: 'Global Visibility',
                description: 'Enabling this feature will provide Lead visibility to all accounts in your Dealership. Disabling this feature will only show Leads to accounts who hold lead credit, marketing director credit, or enrollment credit.',
            },{
                key: 'global_marketing',
                title: 'Global Marketing',
                description: 'By default, marketing directors are only able to interact with leads that have been assigned to them. If needed, you can let your marketing directors interact with all the leads in your dealership.',
            },{
                key: 'release_on_submission',
                title: 'Release on Submission',
                description: 'By default, new leads are made available to the dealership after they are submitted. If needed, you can automatically mark new leads as "Unreleased" when they are submitted. This will only allow dealers and directors to view this lead until the release it to the rest of the dealership.'
            }]
        },{
            key: 'operating_hours',
            title: 'Operating Hours',
            collapsed: false,
            items: [{
                key: 'default',
                title: 'Dealership',
                description: 'The start and end time for your operating hours dictates when a demo can be scheduled. These times also control the available timeslots for scheduling calendars.',
                value: prefs.operating_hours ? `${moment(prefs.operating_hours.default.start, 'HH:mm:ss').format('h:mma')} to ${moment(prefs.operating_hours.default.end, 'HH:mm:ss').format('h:mma')}` : null,
                content: (
                    <TimeRangePicker
                    utils={utils}
                    increments={60}
                    {...prefs.operating_hours && prefs.operating_hours.default}
                    onEndChange={time => {
                        setPrefs(prefs => ({
                            ...prefs,
                            operating_hours: {
                                ...prefs.operating_hours,
                                default: {
                                    ...prefs.operating_hours.default,
                                    end: time.format('HH:mm:ss')
                                }
                            }
                        }))
                    }}
                    onStartChange={time => {
                        setPrefs(prefs => ({
                            ...prefs,
                            operating_hours: {
                                ...prefs.operating_hours,
                                default: {
                                    ...prefs.operating_hours.default,
                                    start: time.format('HH:mm:ss')
                                }
                            }
                        }))
                    }} />
                )
            },{
                key: 'safety_associates',
                title: 'Safety Associates',
                description: 'The start and end time for your safety associate operating hours dictates when a safety associate can schedule a demo. These times also control the available timeslots for a safety associate\'s calendar.',
                value: prefs.operating_hours && prefs.operating_hours.safety_associates ? `${moment(prefs.operating_hours.safety_associates.start, 'HH:mm:ss').format('h:mma')} to ${moment(prefs.operating_hours.safety_associates.end, 'HH:mm:ss').format('h:mma')}` : null,
                content: (
                    <TimeRangePicker
                    utils={utils}
                    increments={60}
                    {...prefs.operating_hours && prefs.operating_hours.safety_associates}
                    onEndChange={time => {
                        setPrefs(prefs => ({
                            ...prefs,
                            operating_hours: {
                                ...prefs.operating_hours,
                                safety_associates: {
                                    ...prefs.operating_hours.safety_associates,
                                    end: time.format('HH:mm:ss')
                                }
                            }
                        }))
                    }}
                    onStartChange={time => {
                        setPrefs(prefs => ({
                            ...prefs,
                            operating_hours: {
                                ...prefs.operating_hours,
                                safety_associates: {
                                    ...prefs.operating_hours.safety_associates,
                                    start: time.format('HH:mm:ss')
                                }
                            }
                        }))
                    }} />
                )
            }]
        }];

        return items.map(section => {
            section.items = section.items.filter(item => {
                return item.visible !== false;
            }).map(item => {
                if(item.component) {
                    switch(item.component) {
                        case 'text':
                        return {
                            ...item,
                            onClick: onItemClick.bind(this, { ...item, component: null })
                        };

                        default:
                        return {
                            ...item,
                            onClick: onItemClick.bind(this, item)
                        };
                    }
                }
                return {
                    ...item,
                    component: 'auto_toggle',
                    selected: prefs[item.key] ? true : false,
                    value: (
                        <span style={{
                            ...Appearance.textStyles.value(),
                            color: prefs[item.key] ? Appearance.colors.green : Appearance.colors.red
                        }}>{prefs[item.key] ? 'Enabled' : 'Disabled'}</span>
                    ),
                    rightContent: (
                        <img
                        src={prefs[item.key] ? `images/checkmark-button-green-small.png` : `images/red-x-icon.png`}
                        style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginLeft: 8
                        }} />
                    )
                }
            })
            return section;
        });
    }

    const fetchPreferences = async () => {
        try {
            let { preferences } = await Request.get(utils, '/dealerships/', {
                type: 'preferences',
                id: abstract.getID()
            })
            setPrefs(preferences);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the preferences for ${abstract.object.name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchPreferences();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Settings for ${abstract.object.name}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            editable={true}
            fields={getPropFields()}
            onEditClick={onVisibilityChange} 
            utils={utils}/>
        </Layer>
    )
}

export const DealershipSelector = ({ index, options, utils }) => {

    const layerID = 'dealership_selector';
    const limit = 5;
    const offset = useRef(0);

    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [paging, setPaging] = useState(null);
    const [dealerships, setDealerships] = useState([]);
    const [defaultDealershipID, setDefaultDealershipID] = useState(null);

    const onDealershipClick = async dealership => {
        try {

            // fetch details for dealership
            setLoading(true);
            let result = await Dealership.get(utils, dealership.id);

            // end loading and update root with new dealership 
            setLoading(false);
            utils.dealership.set(result);

            // close layer
            setLayerState('close');
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getBadge = dealership => {
        let badges = [];
        if(dealership.id === defaultDealershipID) {
            badges.push({
                text: 'Selected',
                color: Appearance.colors.primary()
            });
        }
        if(!dealership.gdl_active) {
            badges.push({
                text: 'Not Active',
                color: Appearance.colors.grey()
            });
        }
        return badges.length > 0 ? badges : null;
    }

    const fetchDealerships = async () => {
        try {
            
            let { dealerships, paging } = await Request.get(utils, '/dealerships/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'all'
            });

            setLoading(false);
            setPaging(paging);
            setDealerships(dealerships.map(dealership => Dealership.create(dealership)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dealerships list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = async () => {
        try {
            let dealershipID = Cookies.get('default_dealership_id');
            if(dealershipID) {
                setDefaultDealershipID(parseInt(dealershipID));
                return;
            }
            setDefaultDealershipID(utils.user.get().dealership.id);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchDealerships();
    }, [searchText]);

    useEffect(() => {
        setupTarget();
    }, [])

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState,
            removePadding: true,
            sticky: {
                top: (
                    <div style={{
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        padding: 15
                    }}>
                        <TextField
                        icon={'search'}
                        value={searchText}
                        useDelay={true}
                        placeholder={'Search by name, dealer, city, state, or zipcode...'}
                        onChange={text => {
                            offset.current = 0;
                            setLoading(true);
                            setSearchText(text);
                        }}
                        containerStyle={{
                            width: '100%'
                        }}/>
                    </div>
                ),
                bottom: paging && (
                    <PageControl
                    data={paging}
                    limit={limit}
                    loading={loading === 'paging'}
                    offset={offset}
                    onClick={next => {
                        offset.current = next;
                        setLoading('paging');
                        fetchDealerships();
                    }}/>
                )
            }
        }}>
            <div style={{
                padding: 15
            }}>
                {dealerships.length > 0
                    ?
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {dealerships.map((dealership, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: dealership.name,
                                    subTitle: dealership.address ? Utils.formatAddress(dealership.address) : 'Address Not Available',
                                    badge: getBadge(dealership),
                                    hideIcon: true,
                                    bottomBorder: index !== dealerships.length - 1 ,
                                    onClick: onDealershipClick.bind(this, dealership)
                                })
                            )
                        })}
                    </div>
                    :
                    <div style={Appearance.styles.unstyledPanel()}>
                        {Views.entry({
                            title: 'No Dealerships Found',
                            subTitle: 'There are no dealerships available to view',
                            bottomBorder: false,
                            hideIcon: true
                        })}
                    </div>
                }
            </div>
        </Layer>
    )
}

export const EventDetails = ({ abstract, index, options, utils }) => {

    const layerID = `event_details_${abstract.getID()}`;
    const [evt, setEvent] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDeleteClick = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('events.actions.delete') === false) {
            return utils.user.permissions.reject();
        }

        // show alert requesting confirmation
        utils.alert.show({
            title: 'Delete Event',
            message: 'Are you sure that you want to delete this event? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onDeleteConfirm, 250);
                    return;
                }
            }
        });
    }

    const onDeleteConfirm = async () => {
        try {

            // set loading flag and submit request to server
            setLoading('options');
            await Request.post(utils, '/events/', {
                id: abstract.getID(),
                type: 'delete'
            });

            // notify subscribers that new data is available
            utils.content.fetch('event');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" ${abstract.object.type.title} event has been deleted`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this event. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDemoClick = async () => {
        try {

            // start loading and fetch demo details
            setLoading(true);
            let demo = await Demo.get(utils, evt.demo.id);

            // end loading and show demo details layer
            setLoading(false);
            utils.layer.open({
                id: `demo_details_${demo.id}`,
                abstract: Abstract.create({
                    type: 'demo',
                    object: demo
                }),
                Component: DemoDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('events.actions.edit') === false) {
            return utils.user.permissions.reject();
        }

        // open event editing layer
        utils.layer.open({
            id: `edit_event_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditEvent.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onLeadClick = async () => {
        try {

            // start loading and fetch lead details
            setLoading(true);
            let lead = await Lead.get(utils, evt.lead.id);

            // end loading and show lead details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                permissions: ['events.actions.delete'],
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'status',
                permissions: ['events.actions.status'],
                title: 'Set Status',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'delete') {
                onDeleteClick();
                return;
            }
            if(key === 'status') {
                utils.layer.open({
                    abstract: abstract,
                    Component: SetEventStatus,
                    id: `set_event_status_${abstract.getID()}`,
                    permissions: ['events.actions.edit']
                });
            }
        });
    }

    const onUserClick = async userID => {
        try {

            // start loading and fetch user details
            setLoading(true);
            let user = await User.get(utils, userID);

            // end loading and show user details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${user.user_id}`,
                permissions: ['users.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getAttachments = () => {

        // prepare list of attachments
        let { attachments = [] } = evt.props;

        // add demo to list of attachments if applicable
        if(evt.demo) {
            attachments.push({
                badge: evt.demo.status,
                bottomBorder: evt.lead ? true : false,
                icon: { path: evt.demo.lead_type && evt.demo.lead_type.icon.url },
                key: 'demo',
                onClick: onDemoClick,
                subTitle: evt.demo.phone_number || 'No phone number provided',
                title: `Demo for ${evt.demo.full_name}`
            });
        }

        // add lead to list of attachments if applicable
        if(evt.lead) {
            attachments.push({
                badge: evt.lead.status,
                bottomBorder: false,
                icon: { path: evt.lead.lead_type && evt.lead.lead_type.icon.url },
                key: 'lead',
                onClick: onLeadClick,
                subTitle: evt.lead.phone_number || 'No phone number provided',
                title: `Lead for ${evt.lead.full_name}`
            });
        }

        // prevent moving forward if no attachments were added
        if(attachments.length === 0) {
            return null;
        }

        return (
            <LayerItem 
            collapsed={false}
            title={'Attachments'}>
                {attachments.sort((a,b) => {
                    return a.title.localeCompare(b.title);
                }).map((attachment, index) => {
                    return (
                        <div 
                        key={index}
                        style={{
                            ...Appearance.styles.unstyledPanel(),
                            marginBottom: 8,
                            width: '100%'
                        }}>
                            {Views.entry(attachment)}
                        </div>
                    )
                })}
            </LayerItem>
        )
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit',
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'type',
                title: 'Category',
                value: evt.type && evt.type.title
            },{
                key: 'date',
                title: 'Created',
                value: evt.date && Utils.formatDate(evt.date)
            },{
                key: 'user_id',
                onClick: evt.user ? onUserClick.bind(this, evt.user.user_id) : null,
                title: 'Created By',
                value: evt.user && evt.user.full_name
            },{
                key: 'id',
                title: 'ID',
                value: evt.id
            },{
                key: 'title',
                title: 'Title',
                value: evt.title
            },{
                color: evt.status && evt.status.color,
                key: 'status',
                title: 'Status',
                value: evt.status && evt.status.text
            }]
        },{
            key: 'user_and_schedule',
            title: 'Assignments and Scheduling',
            items: [{
                key: 'primary_user',
                onClick: evt.primary_user ? onUserClick.bind(this, evt.primary_user.user_id) : null,
                title: 'Primary Assignment',
                value: evt.primary_user && evt.primary_user.full_name
            },{
                key: 'start_date',
                title: 'Start Date',
                value: evt.start_date && Utils.formatDate(evt.start_date)
            },{
                key: 'end_date',
                title: 'End Date',
                value: evt.end_date && Utils.formatDate(evt.end_date)
            }]
        }];
    }

    useEffect(() => {
        utils.content.subscribe(layerID, ['demo', 'demo_status', 'event', 'lead', 'lead_status', 'lead_type'], {
            onUpdate: next => {
                switch(next.type) {
                    case 'demo':
                    if(abstract.object.demo && abstract.object.demo.id === next.getID()) {
                        abstract.object.demo = {
                            ...abstract.object.demo,
                            end_date: next.object.end_date,
                            full_name: next.object.lead.full_name,
                            phone_number: next.object.lead.phone_number,
                            start_date: next.object.start_date,
                            status: next.object.status 
                        }
                        setEvent(abstract.object);
                    }
                    break;

                    case 'demo_status':
                    if(abstract.object.demo && abstract.object.demo.id === next.getID()) {
                        abstract.object.demo = {
                            ...abstract.object.demo,
                            status: next.object.status 
                        }
                        setEvent(abstract.object);
                    }
                    break;

                    case 'event':
                    if(abstract.getID() === next.getID()) {
                        abstract.object.end_date = next.object.end_date;
                        abstract.object.primary_user = next.object.primary_user;
                        abstract.object.start_date = next.object.start_date;
                        abstract.object.status = next.object.status;
                        abstract.object.title = next.object.title;
                        abstract.object.type = next.object.type;
                        abstract.object.user = next.object.user;
                        setEvent(abstract.object);
                    }
                    break;

                    case 'lead':
                    if(abstract.object.demo && abstract.object.demo.lead_id === next.getID()) {
                        abstract.object.demo = {
                            ...abstract.object.demo,
                            full_name: next.object.full_name,
                            lead_type: next.object.lead_type,
                            phone_number: next.object.phone_number
                        }
                        setEvent(abstract.object);
                    }
                    if(abstract.object.lead && abstract.object.lead.id === next.getID()) {
                        abstract.object.lead = {
                            ...abstract.object.lead,
                            full_name: next.object.full_name,
                            lead_type: next.object.lead_type,
                            phone_number: next.object.phone_number,
                            status: next.object.status 
                        }
                        setEvent(abstract.object);
                    }
                    break;

                    case 'lead_status':
                    if(abstract.object.lead && abstract.object.lead.id === next.getID()) {
                        abstract.object.lead = {
                            ...abstract.object.lead,
                            status: next.object.status 
                        }
                        setEvent(abstract.object);
                    }
                    break;

                    case 'lead_type':
                    if(abstract.object.demo && abstract.object.demo.lead_type.id === next.getID()) {
                        abstract.object.demo.lead_type = next.object;
                        setEvent(abstract.object);
                    }
                    if(abstract.object.lead && abstract.object.lead.lead_type.id === next.getID()) {
                        abstract.object.lead.lead_type = next.object;
                        setEvent(abstract.object);
                    }
                    break;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }

    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for "${abstract.getTitle()}"`}
        utils={utils}
        options={{
            ...options,
            extension: {
                abstract: abstract,
                permissions: {
                    delete: ['events.notes.actions.delete'],
                    edit: ['events.notes.actions.edit'],
                    new: ['events.notes.actions.new'],
                    view: ['events.notes']
                },
                type: 'notes'
            },
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
            {getAttachments()}
        </Layer>
    )
}

export const EventTypeDetails = ({ abstract, index, options, utils }) => {

    const layerID = `event_type_details_${abstract.getID()}`;
    const [eventType, setEventType] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `${abstract.object.active ? 'Deactivate' : 'Activate'} Event Type`,
            message: `Are you sure that you want to ${abstract.object.active ? 'deactivate' : 'activate'} this event type?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${abstract.object.active ? 'Deactivate' : 'Activate'}`,
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onChangeActiveStatusConfirm, 250);
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {

            // submit request to server
            setLoading(true);
            let next_status = !abstract.object.active;
            await Request.post(utils, '/events/', {
                active: next_status,
                id: abstract.getID(),
                type: 'set_type_active_status',
            });

            // update active status for target and notify subscribers of data change
            abstract.object.active = next_status;
            utils.content.update(abstract);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This event type has been ${next_status ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this event type. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteClick = () => {
        utils.alert.show({
            title: 'Delete Event Type',
            message: 'Are you sure that you want to delete this event type? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onDeleteLeadTypeConfirm, 250);
                    return;
                }
            }
        });
    }

    const onDeleteLeadTypeConfirm = async () => {
        try {

            // set loading flag and submit request to server
            setLoading(true);
            await Request.post(utils, '/events/', {
                id: abstract.getID(),
                type: 'delete_type',
            });

            // notify subscribers that new data is available
            utils.content.fetch('event_type');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" event type has been deleted`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this event type. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            abstract: abstract,
            Component: AddEditEventType.bind(this, {
                isNewTarget: false
            }),
            id: `edit_event_type_${abstract.getID()}`,
            permissions: ['events.actions.edit']
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                style: abstract.object.active ? 'destructive' : 'default',
                title: abstract.object.active ? 'Deactivate' : 'Activate'
            },{
                key: 'delete',
                visible: abstract.object.dealership_id ? true : false,
                style: 'destructive',
                title: 'Delete from Dealership'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
            if(key === 'delete') {
                onDeleteClick();
                return;
            }
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit',
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'icon',
                title: 'Icon',
                visible: eventType.icon ? true : false,
                value: eventType.icon && (
                    <img 
                    src={eventType.icon.url} 
                    style={{
                        borderRadius: 10,
                        height: 20,
                        objectFit: 'cover',
                        width: 20 
                    }}/>
                )
            },{
                key: 'id',
                title: 'ID',
                value: eventType.id
            },{
                key: 'title',
                title: 'Name',
                value: eventType.title
            },{
                key: 'active',
                title: 'Status',
                value: eventType.active ? 'Active' : 'Inactive'
            }]
        }];
    }

    useEffect(() => {
        utils.content.subscribe(layerID, ['event_type'], {
            onUpdate: next => {
                setEventType(next.getID() === abstract.getID() ? next.object : abstract.object);
            }
        })
        return () => {
            utils.content.unsubscribe(layerID);
        }

    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const EventTypesList = ({ index, options, utils }) => {

    const panelID = 'event_types_list';
    const limit = 15;
    const offset = useRef(0);

    const [eventTypes, setEventTypes] = useState([]);
    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onChangeActiveStatus = async (type, evt) => {
        try {
            evt.stopPropagation();
            let status = !type.active;
            await Request.post(utils, '/events/', {
                active: status,
                id: type.id,
                type: 'set_type_active_status'
            });

            setEventTypes(types => {
                return types.map(prev => {
                    if(prev.id === type.id) {
                        prev.active = status;
                    }
                    return prev;
                });
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the active status for this event type. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNewEventType = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: Event.Type.new(),
                type: 'event_type'
            }),
            Component: AddEditEventType.bind(this,{
                isNewTarget: true
            }),
            id: 'new_event_type',
            permissions: ['events.actions.new']
        });
    }

    const onEventTypeClick = type => {
        utils.layer.open({
            id: `event_type_details_${type.id}`,
            abstract: Abstract.create({
                object: type,
                type: 'event_type'
            }),
            Component: EventTypeDetails
        });
    }

    const getButtons = () => {
        return [{
            key: 'new',
            onClick: onNewEventType,
            style: 'default',
            title: 'New Event Type'
        }];
    }

    const fetchEventTypes = async () => {
        try {
            setLoading(true);
            let { event_types, paging } = await Request.get(utils, '/events/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                show_inactive: true,
                type: 'types'
            });

            setLoading(false);
            setPaging(paging);
            setEventTypes(event_types.map(type => Event.Type.create(type)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the event types list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEventTypes();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchEventTypes);
        utils.content.subscribe(panelID, ['event_type'], {
            onFetch: fetchEventTypes,
            onUpdate: abstract => {
                setEventTypes(types => {
                    return types.map(type => {
                        return type.id === abstract.getID() ? abstract.object : type;
                    })
                });
            }
        })
        return () => {
            utils.events.off(panelID, 'dealership_change');
            utils.content.unsubscribe(panelID);
        }

    }, []);

    return (
        <Panel
        index={index}
        name={'Event Types'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchEventTypes();
                }
            },
            search: {
                placeholder: 'Search by event type name...',
                onChange: setSearchText
            },
            removePadding: true
        }}>
            {eventTypes.length === 0 && (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No custom event types were found in the system',
                    title: 'No Event Types Found'
                })
            )}
            {eventTypes.map((type, index) => {
                return (
                    Views.entry({
                        bottomBorder: index !== eventTypes.length - 1,
                        key: index,
                        onClick: onEventTypeClick.bind(this, type),
                        rightContent: (
                            <div
                            className={'text-button'}
                            onClick={onChangeActiveStatus.bind(this, type)}
                            style={{
                                alignItems: 'center',
                                background: Appearance.colors.softGradient(type.active ? Appearance.colors.primary() : Appearance.colors.grey()),
                                border: `1px solid ${type.active ? Appearance.colors.primary() : Appearance.colors.grey()}`,
                                borderRadius: 5,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'center',
                                maxWidth: 75,
                                overflow: 'hidden',
                                textAlign: 'center',
                                width: 100
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: 'white',
                                    fontWeight: '600',
                                    width: '100%'
                                }}>{type.active ? 'Active' : 'Not Active'}</span>
                            </div>
                        ),
                        subTitle: `Created ${moment(type.date).format('MMMM Do, YYYY [at] h:mma')}`,
                        title: type.title,
                        icon: {
                            path: type.icon.url,
                            imageStyle: {
                                backgroundColor: Appearance.colors.transparent
                            }
                        },
                    })
                )
            })}
        </Panel>
    )
}

export const getEventStatus = (utils, event, options = {}) => {

    // prevent moving forward if no event status was provided
    if(!event || !event.status) {
        return null;
    }

    // prepare status click handler
    const onStatusClick = evt => {

        // prevent root component from triggering click event
        evt.stopPropagation();

        // only allow editing if options object does not contain a false editing flag
        if(options.editable !== false) {
            utils.layer.open({
                abstract: Abstract.create({
                    object: event,
                    type: 'event'
                }),
                Component: SetEventStatus,
                id: `set_event_status_${event.id}`,
                permissions: ['events.actions.edit']
            });
        }
    }

    return (
        <div
        className={options.editable === false ? '' : 'text-button'}
        onClick={onStatusClick}
        style={{
            alignItems: 'center',
            background: Appearance.colors.softGradient(event.status.color),
            border: `1px solid ${event.status.color}`,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            maxWidth: 95,
            overflow: 'hidden',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
            width: '100%'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{event.status.text}</span>
        </div>
    )
}

export const LeadScriptDetails = ({ abstract, index, options, utils }) => {

    const layerID = `lead_script_details_${abstract.getID()}`;
    const [dealership, setDealership] = useState(abstract.object.dealership);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getFields = () => {

        let script = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'dealership',
                title: 'Dealership',
                visible: dealership && utils.user.get().level < User.levels.get().dealer ? true : false,
                value: dealership ? dealership.name : null
            },{
                key: 'is_default',
                title: 'Deafult for Dealership',
                visible: dealership ? true : false,
                value: script.is_default ? 'Yes' : 'No'
            },{
                key: 'id',
                title: 'ID',
                value: script.id
            },{
                key: 'active',
                title: 'Status',
                value: script.active ? 'Active' : 'Inactive'
            },{
                key: 'title',
                title: 'Title',
                value: script.title
            },]
        }]

        return items;
    }

    const onEditClick = () => {

        // prevent editing if script is company-wide and user is not an admin
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This lead script is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new lead script if you need to change the information in this lead script.'
            });
            return;
        }

        // open layer for script editing
        utils.layer.open({
            abstract: abstract,
            Component: AddEditLeadScript.bind(this, {
                isNewTarget: false
            }),
            id: `edit_lead_script_${abstract.getID()}`,
            permissions: ['leads.scripts.actions.edit']
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                permissions: ['leads.scripts.actions.status'],
                title: abstract.object.active ? 'Deactivate' : 'Activate',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'default_status',
                permissions: ['leads.scripts.actions.default'],
                title: 'Set as Dealership Default',
                visible: abstract.object.is_default !== true,
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
            if(key === 'default_status') {
                onSetDefaultStatus();
                return;
            }
        });
    }

    const onChangeActiveStatus = () => {

        // prevent editing if script is company-wide and user is not an admin
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This lead script is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new lead script if you need to change the information in this lead script.'
            });
            return;
        }

        // request confirmation to change lead script status
        utils.alert.show({
            title: `${abstract.object.active ? 'Deactivate' : 'Activate'} Lead Script`,
            message: `Are you sure that you want to ${abstract.object.active ? 'deactivate' : 'activate'} this lead script?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${abstract.object.active ? 'Deactivate' : 'Activate'}`,
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            let next_status = !abstract.object.active;
            await Request.post(utils, '/dealerships/', {
                type: 'set_lead_script_active_status',
                id: abstract.getID(),
                active: next_status
            });

            abstract.object.active = next_status;
            utils.content.fetch(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This script has been set as ${next_status ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this script. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetDefaultStatus = () => {
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This lead script is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new lead script if you need to change the information in this lead script.'
            });
            return;
        }
        utils.alert.show({
            title: `Set as Default for Dealership`,
            message: `Are you sure that you want to set this lead script as the default lead script for your Dealership? This will automatically attach this script when a new Lead is created.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetDefaultStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetDefaultStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/dealerships/', {
                type: 'set_default_lead_script',
                id: abstract.getID()
            });

            setLoading(false);
            abstract.object.is_default = true;
            utils.content.update(abstract);

            utils.content.fetch('lead_script');
            utils.alert.show({
                title: 'All Done!',
                message: `This script has been set as the default lead script for ${utils.dealership.get().name}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the default status for this script. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchDealership = async () => {
        try {
            // globally offered lead scripts do not have a dealership
            if(!abstract.object.dealership_id) {
                return;
            }

            if(!abstract.object.dealership) {

                // attach local dealership if a match is found
                let dealership = utils.dealership.get();
                if(abstract.object.dealership_id === dealership.id) {
                    setDealership(dealership);
                    return;
                }

                // fetch dealership details
                let nextDealership = await Dealership.get(utils, abstract.object.dealership_id);
                abstract.object.dealership = nextDealership;
                setDealership(nextDealership);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchDealership();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 24
            }}>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.primary(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '8px 12px 8px 12px',
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        color: 'white'
                    }}>{'Script Preview'}</span>
                </div>
                <div style={{
                    color: Appearance.colors.text(),
                    fontSize: config.fontSize,
                    fontWeight: 500,
                    maxHeight: 300,
                    overflowY: 'scroll',
                    padding: '8px 12px 8px 12px',
                }}>
                    {formatLeadScriptContent({
                        editable: true,
                        text: abstract.object.text,
                        utils: utils
                    })}
                </div>
            </div>
            <FieldMapper 
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const LeadScriptsList = ({ index, options, utils }) => {

    const panelID = 'lead_scripts_list';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [scripts, setScripts] = useState([]);

    const onNewScript = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: Lead.Script.new(),
                type: 'lead_script'
            }),
            Component: AddEditLeadScript.bind(this, {
                isNewTarget: true
            }),
            id: 'new_lead_script',
            permissions: ['leads.scripts.actions.new']
        });
    }

    const onPrintScripts = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { scripts } = await Request.get(utils, '/dealerships/', {
                    type: searchText ? 'lookup' : 'all',
                    search_text: searchText,
                    ...props
                });

                setLoading(false);
                resolve(scripts.map(script => Lead.Script.create(script)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onScriptClick = script => {
        utils.layer.open({
            abstract: Abstract.create({
                object: script,
                type: 'lead_script'
            }),
            Component: LeadScriptDetails,
            id: `lead_script_details_${script.id}`,
            permissions: ['leads.scripts.details']
        })
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintScripts,
            onRenderItem: item => ({
                title: item.title,
                text: item.text
            }),
            headers: [{
                key: 'title',
                title: 'Title'
            },{
                key: 'text',
                title: 'Script'
            }]
        }
    }

    const getBadges = script => {
        let badges = [];
        if(script.is_default === true) {
            badges.push({
                text: 'Dealership Default',
                color: Appearance.colors.primary()
            })
        }
        if(script.active === false) {
            badges.push({
                text: 'Not Active',
                color: Appearance.colors.grey()
            })
        }
        return badges;
    }

    const fetchScripts = async () => {
        try {
            setLoading(true);
            let { lead_scripts, paging } = await Request.get(utils, '/dealerships/', {
                limit: limit,
                offset: offset,
                search_text: searchText,
                type: 'lead_scripts'
            });

            setLoading(false);
            setPaging(paging);
            setScripts(lead_scripts.map(script => Lead.Script.create(script)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead scripts list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchScripts();
    }, [searchText, offset]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchScripts);
        utils.content.subscribe(panelID, ['lead_script'], {
            onFetch: fetchScripts,
            onUpdate: abstract => {
                setScripts(scripts => {
                    if(!scripts) {
                        return scripts;
                    }
                    return scripts.map(script => {
                        return script.id === abstract.getID() ? abstract.object : script;
                    })
                })
            }
        })
        return () => {
            utils.events.off(panelID, 'dealership_change');
            utils.content.unsubscribe(panelID);
        }

    }, []);

    return (
        <Panel
        index={index}
        name={'Scripts'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            search: {
                onChange: setSearchText,
                placeholder: 'Search by script title or content...'
            },
            buttons: [{
                key: 'new',
                onClick: onNewScript,
                title: 'New Script',
                style: 'default',
            }],
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => setOffset(nextOffset)
            }
        }}>
            {scripts.length > 0
                ?
                scripts.map((script, index) => {
                    return (
                        Views.entry({
                            badge: getBadges(script),
                            bottomBorder: index !== scripts.length - 1,
                            icon: {
                                path: 'images/lead-script-icon-blue.png'
                            },
                            key: index,
                            onClick: onScriptClick.bind(this, script),
                            subTitle: `Created ${moment(script.date).format('MMMM Do, YYYY [at] h:mma')}`,
                            title: script.title
                        })
                    )
                })
                :
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No scripts were found in the system',
                    title: 'No Scripts Found'
                })
            }
        </Panel>
    )
}

export const LeadSubTypesList = ({ index, options, utils }) => {

    const panelID = 'lead_sub_types_list';
    const limit = 15;
    const offset = useRef(0);

    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [leadSubTypes, setLeadSubTypes] = useState([]);

    const onChangeActiveStatus = async (leadSubType, e) => {
        try {
            e.stopPropagation();
            let status = !leadSubType.active;
            await Request.post(utils, '/dealerships/', {
                active: status,
                id: leadSubType.id,
                type: 'set_lead_sub_type_active_status'
            });

            setLeadSubTypes(types => {
                return types.map(prev => {
                    if(prev.id === leadSubType.id) {
                        prev.active = status;
                    }
                    return prev;
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this lead sub-type. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDelete = leadSubType => {
        utils.alert.show({
            title: 'Delete Lead Sub-Type',
            message: `Are you sure that you want to delete this lead sub-type? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteConfirm(leadSubType);
                    return;
                }
            }
        });
    }

    const onDeleteConfirm = async leadSubType => {
        try {
            setLoading(true);
            await Request.post(utils, '/dealerships/', {
                id: leadSubType.id,
                type: 'delete_lead_sub_type'
            });

            setLoading(false);
            utils.content.fetch('lead_sub_type');
            utils.alert.show({
                title: 'All Done!',
                message: `The "${leadSubType.text}" has been removed from your dealership.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this lead sub-type from your dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEdit = leadSubType => {
        utils.alert.show({
            title: 'Editing Lead Sub-Type',
            message: 'What would you like to call this lead sub-type?',
            textFields: [{
                key: 'title',
                placeholder: leadSubType.title
            }],
            buttons: [{
                key: 'save',
                title: 'Save',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: response => {
                if(response.title) {
                    onUpdateLeadSubType(leadSubType, response.title);
                    return;
                }
            }
        });
    }

    const onLeadSubTypeClick = leadSubType => {
        utils.sheet.show({
            items: [{
                key: 'edit',
                permissions: ['leads.sub_types.actions.edit'],
                title: 'Edit',
                style: 'default'
            },{
                key: 'delete',
                permissions: ['leads.sub_types.actions.delete'],
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            switch(key) {
                case 'delete':
                onDelete(leadSubType);
                break;

                case 'edit':
                onEdit(leadSubType);
                break;
            }
        });
    }

    const onNewLeadSubType = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('leads.sub_types.actions.new') === false) {
            return utils.user.permissions.reject();
        }

        // show alert requesting lead sub-type information
        utils.alert.show({
            title: 'New Lead Sub-Type',
            message: 'What would you like to call this lead sub-type?',
            textFields: [{
                key: 'title',
                placeholder: 'Title'
            }],
            buttons: [{
                key: 'save',
                title: 'Save',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: response => {
                if(response.title) {
                    onNewLeadSubTypeConfirm(response.title);
                    return;
                }
            }
        });
    }

    const onNewLeadSubTypeConfirm = async title => {
        try {

            // set loading flag and sleep briefly so previous alert can close
            setLoading(true);
            await Utils.sleep(0.25);

            // send request to server
            await Request.post(utils, '/dealerships/', {
                text: title,
                type: 'new_lead_sub_type'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.content.fetch('lead_sub_type');
            utils.alert.show({
                title: 'All Done!',
                message: `The "${title}" lead sub-type has been created and added to your list of lead sub-types`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your lead sub-type. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateLeadSubType = async (leadSubType, title) => {
        try {

            // set loading flag and sleep briefly so previous alert can close
            setLoading(true);
            await Utils.sleep(0.25);

            // send request to server
            await Request.post(utils, '/dealerships/', {
                id: leadSubType.id,
                text: title,
                type: 'update_lead_sub_type'
            });

            // notify subscribers of data change
            leadSubType.text = title;
            utils.content.fetch('lead_sub_type');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${title}" lead sub-type has been updated`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your lead sub-type. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getActiveStatus = leadType => {
        if(!leadType) {
            return;
        }
        if(!leadType.dealership_id && utils.user.get().level > User.levels.get().admin) {
            return null;
        }
        let color = leadType.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, leadType)}
            style={{
                alignItems: 'center',
                background: Appearance.colors.softGradient(color),
                border: `1px solid ${color}`,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                maxWidth: 75,
                overflow: 'hidden',
                textAlign: 'center',
                width: 100
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{leadType.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const fetchLeadSubTypes = async () => {
        try {
            setLoading(true);
            let { lead_sub_types, paging } = await Request.get(utils, '/dealerships/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                show_inactive: true,
                type: 'lead_sub_types'
            });

            setLoading(false);
            setPaging(paging);
            setLeadSubTypes(lead_sub_types.map(type => Lead.SubType.create(type)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead sub-types list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLeadSubTypes();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchLeadSubTypes);
        utils.content.subscribe(panelID, ['lead_sub_type'], {
            onFetch: fetchLeadSubTypes,
            onUpdate: abstract => {
                setLeadSubTypes(leadSubTypes => {
                    return leadSubTypes.map(leadSubType => {
                        return leadSubType.id === abstract.getID() ? abstract.object : leadSubType;
                    });
                });
            }
        });
        return () => {
            utils.events.off(panelID, 'dealership_change');
            utils.content.unsubscribe(panelID);
        }

    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Lead Sub-Types'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            search: {
                placeholder: 'Search by lead sub-type name...',
                onChange: setSearchText
            },
            buttons: [{
                key: 'new',
                title: 'New Lead Sub-Type',
                style: 'default',
                onClick: onNewLeadSubType
            }],
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: props => {
                    offset.current = props;
                    fetchLeadSubTypes();
                }
            }
        }}>
            {leadSubTypes.length === 0 && (
                Views.entry({
                    title: 'No Lead Types Found',
                    subTitle: 'No lead types were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )}
            {leadSubTypes.map((leadSubType, index) => {
                return (
                    Views.entry({
                        badge: {
                            color: Appearance.colors.grey(),
                            text: leadSubType.is_default ? 'Default' : null
                        },
                        bottomBorder: index !== leadSubTypes.length - 1,
                        hideIcon: true,
                        key: index,
                        onClick: onLeadSubTypeClick.bind(this, leadSubType),
                        rightContent: getActiveStatus(leadSubType),
                        subTitle: `Created ${moment(leadSubType.date).format('MMMM Do, YYYY [at] h:mma')}`,
                        title: leadSubType.text
                    })
                )
            })}
        </Panel>
    )
}

export const LeadTypeDetails = ({ abstract, index, options, utils }) => {

    const layerID = `lead_type_details_${abstract.getID()}`;
    const [dealership, setDealership] = useState(abstract.object.dealership);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onChangeActiveStatus = () => {

        // prevent moving forward if the lead type is globally offered and the current user is not an administrator
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This lead type is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new lead type if you need to change the information in this lead type.'
            });
            return;
        }

        // request confirmation to change lead type status
        utils.alert.show({
            title: `${abstract.object.active ? 'Deactivate' : 'Activate'} Lead Type`,
            message: `Are you sure that you want to ${abstract.object.active ? 'deactivate' : 'activate'} this lead type?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${abstract.object.active ? 'Deactivate' : 'Activate'}`,
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let next_status = !abstract.object.active;
            await Request.post(utils, '/dealerships/', {
                type: 'set_lead_type_active_status',
                id: abstract.getID(),
                active: next_status
            });

            abstract.object.active = next_status;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This lead type has been set as ${next_status ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this lead type. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDeleteClick = () => {
        utils.alert.show({
            title: 'Delete Lead Type',
            message: 'Are you sure that you want to delete this lead type? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteLeadTypeConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteLeadTypeConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/dealerships/', {
                type: 'delete_lead_type',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('lead_type');
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.text}" lead type has been deleted`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this lead type. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {

        // prevent moving forward if the lead type is globally offered and the current user is not an administrator
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This lead type is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new lead type if you need to change the information in this lead type.'
            });
            return;
        }

        // open editing layer for lead type
        utils.layer.open({
            abstract: abstract,
            Component: AddEditLeadType.bind(this, {
                isNewTarget: false
            }),
            id: `edit_lead_type_${abstract.getID()}`,
            permissions: ['leads.types.actions.edit']
        })
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                permissions: ['leads.types.actions.status'],
                title: abstract.object.active ? 'Deactivate' : 'Activate',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'delete',
                permissions: ['leads.types.actions.delete'],
                title: 'Delete from Dealership',
                style: 'destructive'
            },{
                key: 'default_status',
                permissions: ['leads.types.actions.default'],
                title: 'Set as Dealership Default',
                visible: abstract.object.is_default !== true,
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
            if(key === 'default_status') {
                onSetDefaultStatus();
                return;
            }
            if(key === 'delete') {
                onDeleteClick();
                return;
            }
        })
    }

    const onScriptClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: abstract.object.lead_script,
                type: 'lead_script'
            }),
            Component: LeadScriptDetails,
            id: `lead_script_details_${abstract.object.lead_script.id}`,
            permissions: ['leads.scripts.details']
        });
    }

    const onSetDefaultStatus = () => {

        // prevent moving forward if the lead type is globally offered and the current user is not an administrator
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This lead type is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new lead type if you need to change the information in this lead type.'
            });
            return;
        }

        // request confirmation to set lead type as the dealership default
        utils.alert.show({
            title: `Set as Default for Dealership`,
            message: `Are you sure that you want to set this lead type as the default lead type for your Dealership? This will automatically attach this lead type when a new Lead is created.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetDefaultStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetDefaultStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/dealerships/', {
                type: 'set_default_lead_type',
                id: abstract.getID()
            });

            setLoading(false);
            abstract.object.is_default = true;
            utils.content.update(abstract);

            utils.content.fetch('lead_type');
            utils.alert.show({
                title: 'All Done!',
                message: `This lead type has been set as the default lead type for ${utils.dealership.get().name}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the default status for this lead type. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            permissions: ['leads.types.actions.edit'],
            text: 'Edit'
        }];
    }

    const getFields = () => {

        let leadType = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'dealership',
                title: 'Dealership',
                visible: dealership && utils.user.get().level < User.levels.get().dealer ? true : false,
                value: dealership ? dealership.name : null
            },{
                key: 'is_default',
                title: 'Deafult for Dealership',
                visible: dealership ? true : false,
                value: leadType.is_default ? 'Yes' : 'No'
            },{
                key: 'icon',
                title: 'Icon',
                visible: leadType.icon ? true : false,
                value: leadType.icon && (
                    <img 
                    src={leadType.icon.url} 
                    style={{
                        borderRadius: 10,
                        height: 20,
                        objectFit: 'cover',
                        width: 20 
                    }}/>
                )
            },{
                key: 'id',
                title: 'ID',
                value: leadType.id
            },{
                key: 'lead_script',
                title: 'Lead Script',
                ...leadType.lead_script && {
                    value: leadType.lead_script.title,
                    onClick: onScriptClick
                }
            },{
                key: 'text',
                title: 'Name',
                value: leadType.text
            },{
                key: 'active',
                title: 'Status',
                value: leadType.active ? 'Active' : 'Inactive'
            }]
        }]

        return items;
    }

    const fetchDealership = async () => {
        try {
            // globally offered lead scripts do not have a dealership
            if(!abstract.object.dealership_id) {
                return;
            }

            if(!abstract.object.dealership) {

                // attach local dealership if a match is found
                let dealership = utils.dealership.get();
                if(abstract.object.dealership_id === dealership.id) {
                    setDealership(dealership);
                    return;
                }

                // fetch dealership details
                let nextDealership = await Dealership.get(utils, abstract.object.dealership_id);
                abstract.object.dealership = nextDealership;
                setDealership(nextDealership);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchDealership();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for "${abstract.getTitle()}"`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const LeadTypesList = ({ index, options, utils }) => {

    const panelID = 'lead_types_list';
    const limit = 15;
    const offset = useRef(0);

    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [leadTypes, setLeadTypes] = useState([]);

    const onChangeActiveStatus = async (leadType, e) => {
        try {
            e.stopPropagation();
            let status = !leadType.active;
            await Request.post(utils, '/dealerships/', {
                type: 'set_lead_type_active_status',
                id: leadType.id,
                active: status
            });

            setLeadTypes(leadTypes => {
                return leadTypes.map(prevLeadType => {
                    if(prevLeadType.id === leadType.id) {
                        prevLeadType.active = status;
                    }
                    return prevLeadType;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this lead type. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLeadTypeClick = leadType => {
        utils.layer.open({
            abstract: Abstract.create({
                object: leadType,
                type: 'lead_type'
            }),
            Component: LeadTypeDetails,
            id: `lead_type_details_${leadType.id}`,
            permissions: ['leads.types.details']
        });
    }

    const onNewLeadType = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: Lead.Type.new(),
                type: 'lead_type'
            }),
            Component: AddEditLeadType.bind(this,{
                isNewTarget: true
            }),
            id: 'new_lead_type',
            permissions: ['leads.types.actions.new']
        })
    }

    const getActiveStatus = leadType => {
        if(!leadType) {
            return;
        }
        if(!leadType.dealership_id && utils.user.get().level > User.levels.get().admin) {
            return null;
        }
        let color = leadType.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, leadType)}
            style={{
                alignItems: 'center',
                background: Appearance.colors.softGradient(color),
                border: `1px solid ${color}`,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                maxWidth: 75,
                overflow: 'hidden',
                textAlign: 'center',
                width: 100,
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{leadType.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const getButtons = () => {
        return [{
            key: 'new',
            onClick: onNewLeadType,
            style: 'default',
            title: 'New Lead Type'
        }];
    }

    const fetchLeadTypes = async () => {
        try {
            setLoading(true);
            let { lead_types, paging } = await Request.get(utils, '/dealerships/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                show_inactive: true,
                type: 'lead_types'
            });

            setLoading(false);
            setPaging(paging);
            setLeadTypes(lead_types.map(type => Lead.Type.create(type)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead types list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLeadTypes();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchLeadTypes);
        utils.content.subscribe(panelID, ['lead_type'], {
            onFetch: fetchLeadTypes,
            onUpdate: abstract => {
                setLeadTypes(leadTypes => {
                    return leadTypes.map(leadType => {
                        return leadType.id === abstract.getID() ? abstract.object : leadType;
                    });
                });
            }
        });
        return () => {
            utils.events.off(panelID, 'dealership_change');
            utils.content.unsubscribe(panelID);
        }

    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Lead Types'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            removePadding: true,
            search: {
                placeholder: 'Search by lead type name...',
                onChange: setSearchText
            },
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => {
                    offset.current = next;
                    fetchLeadTypes();
                }
            }
        }}>
            {leadTypes.length === 0 && (
                Views.entry({
                    title: 'No Lead Types Found',
                    subTitle: 'No lead types were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )}
            {leadTypes.map((leadType, index) => {
                return (
                    Views.entry({
                        badge: [{
                            color: Appearance.colors.grey(),
                            text: leadType.is_default ? 'Default' : null
                        }],
                        bottomBorder: index !== leadTypes.length - 1,
                        icon: {
                            path: leadType.icon.url,
                            imageStyle: {
                                backgroundColor: Appearance.colors.transparent
                            }
                        },
                        key: index,
                        onClick: onLeadTypeClick.bind(this, leadType),
                        rightContent: getActiveStatus(leadType),
                        subTitle: `Created ${moment(leadType.date).format('MMMM Do, YYYY [at] h:mma')}`,
                        title: leadType.text
                    })
                )
            })}
        </Panel>
    )
}

export const ManageDemoSlots = ({ onChange, slots }, { index, options, utils }) => {

    const layerID = 'manage_demo_slots';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [targets, setTargets] = useState(slots || []);

    const onDeleteSlotClick = slot => {
        utils.alert.show({
            title: 'Delete Demo Slot',
            message: 'Are you sure that you want to delete this demo slot? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteSlotConfirm(slot);
                    return;
                }
            }
        });
    }

    const onDeleteSlotConfirm = async slot => {
        try {

            // set loading flag and submit request to server
            setLoading(true);
            await Request.post(utils, '/dealerships/', {
                id: slot.id,
                type: 'delete_demo_slot'
            });

            // filter requested slot out of targets list and update local state
            let next = targets.filter(target => target.id !== slot.id);
            setTargets(next);

            // end loading and notify subscribers of data change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange(next);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this demo slot. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditSlotClick = slot => {
        utils.layer.open({
            abstract: Abstract.create({
                object: slot,
                type: 'demo_slot'
            }),
            id: `edit_demo_slot_${slot.id}`,
            Component: AddEditDemoSlot.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onNewDemoSlotClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: Demo.Slot.new(),
                type: 'demo_slot'
            }),
            Component: AddEditDemoSlot.bind(this, {
                isNewTarget: true,
                onChange: result => {

                    // add new slot to local state of targets
                    let next = update(targets, {$push: [result]});
                    setTargets(next);

                    // notify subscribers that data has changed
                    if(typeof(onChange) === 'fucntion') {
                        onChange(next);
                    }
                }
            }),
            id: 'new_demo_slot'
        });
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onNewDemoSlotClick,
            text: 'New Demo Slot'
        }];
    }

    const getSlots = () => {
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {targets.length === 0 && (
                    Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'No demo slots were found for this dealership',
                        title: 'No Slots Found'
                    })
                )}
                {targets.sort((a,b) => {
                    return a.name.localeCompare(b.name);
                }).map((slot, index) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== targets.length - 1,
                            hideIcon: true,
                            key: index,
                            rightContent: (
                                <div style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <img
                                    className={'text-button'}
                                    onClick={onEditSlotClick.bind(this, slot)}
                                    title={'Edit'}
                                    src={'images/edit-button-yellow-small.png'}
                                    style={{
                                        height: 25,
                                        marginLeft: 8,
                                        objectFit: 'contain',
                                        width: 25
                                    }} />
                                    <img
                                    className={'text-button'}
                                    onClick={onDeleteSlotClick.bind(this, slot)}
                                    title={'Delete'}
                                    src={'images/red-x-icon.png'}
                                    style={{
                                        height: 25,
                                        marginLeft: 8,
                                        objectFit: 'contain',
                                        width: 25
                                    }} />
                                </div>
                            ),
                            subTitle: `${formatHour(slot.start_hour)} to ${formatHour(slot.end_hour)}`,
                            title: slot.name
                        })
                    )
                })}
            </div>
        );
    }

    const formatHour = val => {
        let hour = val > 12 ? val - 12 : val;
        return `${hour === 0 ? 12 : hour}:00 ${val > 11 ? 'PM' : 'AM'}`;
    }
    
    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Manage Demo Slots'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getSlots()}
        </Layer>
    )
}

export const ManageHiddenSurveyMonkeySurveys = ({ dealership, onChange }, { index, options, utils }) => {

    const layerID = `manage_hidden_survey_monkey_surveys_${dealership.id}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState('init');
    const [surveys, setSurveys] = useState([]);

    const onSurveyClick = survey => {
        utils.sheet.show({
            items: [{
                key: 'add',
                title: 'Add Back to Global Data',
                style: 'default'
            }]
        }, key => {
            if(key === 'add') {
                onAddSurveyBackToAccount(survey.id);
                return;
            }
        });
    }

    const onAddSurveyBackToAccount = async id => {
        utils.alert.show({
            title: 'Add Back to Global Data',
            message: 'Are you sure that you want to add this survey back to your Global Data account? This survey will become available for automatic lead generation.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onAddSurveyBackToAccountConfirm.bind(this, id), 250);
                    return;
                }
            }
        });
    }

    const onAddSurveyBackToAccountConfirm = async id => {
        try {

            // send request to server
            setLoading(true);
            await Request.post(utils, '/survey_monkey/', {
                id: id,
                type: 'undelete_survey'
            });

            // notify subscribers of data change
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This survey has been added back to your Global Data account',
                onClick: () => {
                    if(surveys.length === 1) {
                        setLayerState('close');
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding this survey back to your Global Data account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {

        // determine if a loading component is needed
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        
        return surveys.sort((a,b) => {
            return a.title.localeCompare(b.title);
        }).map((survey, index) => {
            return (
                Views.entry({
                    bottomBorder: index !== surveys.length - 1,
                    hideIcon: true,
                    key: index,
                    onClick: onSurveyClick.bind(this, survey),
                    title: survey.title
                })
            )
        });
    }

    const fetchSurveys = async () => {
        try {
            
            let { surveys } = await Request.get(utils, '/survey_monkey/', {
                dealership_id: dealership.id,
                type: 'hidden_surveys'
            });

            // determine if there were no hidden surveys found
            if(surveys.length === 0) {
                utils.alert.show({
                    title: 'No Surveys Found',
                    message: 'There were no deleted surveys found for your Survey Monkey account.',
                    onClick: setLayerState.bind(this, 'close')
                });
                return;
            }

            // end loading and update local state
            setLoading(false);
            setSurveys(surveys);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of deleted surveys. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSurveys();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={'Manage Deleted Survey Monkey Surveys'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Layer>
    )
}

export const SetEventStatus = ({ abstract, index, options, utils }) => {

    const layerID = `set_event_status_${abstract.getID()}`;
    const [items, setItems] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const onSetStatus = async () => {
        try {
            setLoading('done');
            await Utils.sleep(0.25);

            // submit request to the server
            let { status } = await Request.post(utils, '/events/', {
                id: abstract.getID(),
                status: selectedStatus,
                type: 'set_status'
            })

            // end loading and update abstract target
            setLoading(false);
            abstract.object.status = status;

            // notify subscribers that target has been updated
            utils.content.update(abstract);

            // show user confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `The status for this ${abstract.object.type.title} has been updated`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this ${abstract.object.type.title}. ${e.message || 'An unknown error occurred'}`,
            })
        }
    }

    const getButtons = () => {
        return [{
            color: selectedStatus ? 'primary' : 'dark',
            key: 'done',
            loading: loading === 'done',
            onClick: onSetStatus,
            text: 'Save Changes'
        }];
    }

    const setupStatusCodes = () => {
        let codes = utils.dealership.status_codes.get().filter(status => {
            return status.capabilities.events;
        }).map(status => ({
            id: status.code,
            title: status.text
        }));
        setItems(codes);
    }

    useEffect(() => {
        setupStatusCodes();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Set ${abstract.object.type.title} Status`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'small'
        }}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={'Choose a status...'}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                setSelectedStatus(parseInt(id));
            }}
            style={{
                width: '100%'
            }}>
                <option disabled={true}>{'Choose a status...'}</option>
                {items.map((item, index) => {
                    return (
                        <option key={index} id={item.id}>{item.title}</option>
                    )
                })}
            </select>
        </Layer>
    )
}

export const StatusCodesList = ({ index, options, utils }) => {

    const panelID = 'status_codes_list';
    const limit = 15;
    const offset = useRef(0);

    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [statusCodes, setStatusCodes] = useState([]);

    const onChangeActiveStatus = async (statusCode, evt) => {
        try {
            evt.stopPropagation();
            let status = !statusCode.active;
            await Request.post(utils, '/dealerships/', {
                active: status,
                id: statusCode.id,
                type: 'set_status_active_status'
            });

            setStatusCodes(statusCodes => {
                return statusCodes.map(prev => {
                    if(prev.id === statusCode.id) {
                        prev.active = status;
                    }
                    return prev;
                });
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the active status for this status code. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNewStatusCode = () => {

        // open editing layer for new status
        utils.layer.open({
            id: 'new_status',
            abstract: Abstract.create({
                type: 'status',
                object: Status.new()
            }),
            Component: AddEditStatus.bind(this,{
                isNewTarget: true
            })
        });
    }

    const onStatusCodeClick = status => {
        utils.layer.open({
            id: `status_details_${status.code}`,
            abstract: Abstract.create({
                object: status,
                type: 'status'
            }),
            Component: StatusDetails
        });
    }

    const fetchStatusCodes = async () => {
        try {
            setLoading(true);
            let { status_codes, paging } = await Request.get(utils, '/dealerships/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                show_inactive: true,
                type: 'status_codes'
            });

            setLoading(false);
            setPaging(paging);
            setStatusCodes(status_codes.map(status => Status.create(status)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the status codes list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchStatusCodes();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchStatusCodes);
        utils.content.subscribe(panelID, ['status'], {
            onFetch: fetchStatusCodes,
            onUpdate: abstract => {
                setStatusCodes(statusCodes => {
                    return statusCodes.map(statusCode => {
                        return statusCode.id === abstract.getID() ? abstract.object : statusCode;
                    })
                });
            }
        })
        return () => {
            utils.events.off(panelID, 'dealership_change');
            utils.content.unsubscribe(panelID);
        }

    }, []);
    const getButtons = () => {
        return [{
            key: 'new',
            onClick: onNewStatusCode,
            style: 'default',
            title: 'New Status'
        }];
    }

    return (
        <Panel
        index={index}
        name={'Status Codes'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchStatusCodes();
                }
            },
            search: {
                placeholder: 'Search by status name...',
                onChange: setSearchText
            },
            removePadding: true
        }}>
            {statusCodes.length === 0 && (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No custom lead status codes were found in the system',
                    title: 'No Status Codes Found'
                })
            )}
            {statusCodes.map((statusCode, index) => {
                return (
                    Views.entry({
                        bottomBorder: index !== statusCodes.length - 1,
                        key: index,
                        onClick: onStatusCodeClick.bind(this, statusCode),
                        rightContent: (
                            <div
                            className={'text-button'}
                            onClick={onChangeActiveStatus.bind(this, statusCode)}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 100,
                                height: '100%',
                                maxWidth: 75,
                                textAlign: 'center',
                                border: `1px solid ${statusCode.active ? Appearance.colors.primary() : Appearance.colors.grey()}`,
                                background: Appearance.colors.softGradient(statusCode.active ? Appearance.colors.primary() : Appearance.colors.grey()),
                                borderRadius: 5,
                                overflow: 'hidden'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: 'white',
                                    fontWeight: '600',
                                    width: '100%'
                                }}>{statusCode.active ? 'Active' : 'Not Active'}</span>
                            </div>
                        ),
                        subTitle: `Created ${moment(statusCode.date).format('MMMM Do, YYYY [at] h:mma')}`,
                        title: statusCode.text,
                        icon: {
                            path: 'images/custom-status-code-clear.png',
                            imageStyle: {
                                backgroundColor: statusCode.color
                            }
                        }
                    })
                )
            })}
        </Panel>
    )
}

export const StatusDetails = ({ abstract, index, options, utils }) => {

    const layerID = `status_details_${abstract.object.code}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `${abstract.object.active ? 'Deactivate' : 'Activate'} Status`,
            message: `Are you sure that you want to ${abstract.object.active ? 'deactivate' : 'activate'} the "${abstract.object.text}" status?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${abstract.object.active ? 'Deactivate' : 'Activate'}`,
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onChangeActiveStatusConfirm, 250);
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {

            // submit request to server
            setLoading(true);
            let next_status = !abstract.object.active;
            await Request.post(utils, '/dealerships/', {
                active: next_status,
                id: abstract.getID(),
                type: 'update_active_flag_for_status'
            });

            // update local active flag for status
            abstract.object.active = next_status;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This status has been ${next_status ? 'activated' : 'deactivated'}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this status code. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteClick = () => {
        utils.alert.show({
            title: 'Delete Status',
            message: 'Are you sure that you want to delete this status? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onDeleteStatusConfirm, 250);
                    return;
                }
            }
        })
    }

    const onDeleteStatusConfirm = async () => {
        try {

            // send request to server
            setLoading(true);
            await Request.post(utils, '/dealerships/', {
                id: abstract.getID(),
                type: 'delete_status'
            });

            // end loading and notify subscribers that new data is available
            setLoading(false);
            utils.content.fetch('status');

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.text}" status has been deleted`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this status. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {

        // prevent moving forward if status is global and user is not an administrator
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This status is offered to all dealership in the Global Data ecosystem and can not be modified. Please create a new status if you would like to have additional status options.'
            });
            return;
        }

        // show editing layer
        utils.layer.open({
            id: `edit_status_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditStatus.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                title: abstract.object.active ? 'Deactivate' : 'Activate',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'delete',
                title: 'Delete from Dealership',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
            if(key === 'delete') {
                onDeleteClick();
                return;
            }
        })
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                color: abstract.object.color,
                key: 'color',
                title: 'Color',
                value: abstract.object.color && abstract.object.color.toUpperCase()
            },{
                key: 'id',
                title: 'ID',
                value: abstract.object.id
            },{
                key: 'text',
                title: 'Name',
                value: abstract.object.text
            },{
                color: abstract.object.active ? Appearance.colors.green : Appearance.colors.red,
                key: 'active',
                title: 'Status',
                value: abstract.object.active ? 'Active' : 'Inactive'
            }]
        },{
            key: 'capabilities',
            title: 'Capabilities',
            items: Object.keys(abstract.object.capabilities).map(key => ({
                key: key,
                title: `Available for ${key === 'demo_requests' ? 'Demo Requests' : Utils.ucFirst(key)}`,
                value: abstract.object.capabilities[key] ? 'Yes' : 'No'
            }))
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for "${abstract.object.text}" Status`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const SurveyMonkeyPreferences = ({ index, options, utils }) => {
    
    const layerID = 'survey_monkey_preferences';
    const [layerState, setLayerState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preferences, setPreferences] = useState({});

    const onConfigurationClick = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('programs.survey_monkey.actions.edit') === false) {
            return utils.user.permissions.reject();
        }

        // show alert with options to configure the global data plugin 
        utils.alert.show({
            title: 'Configure Survey Monkey',
            message: `To start receiving leads from Survey Monkey, you'll need to install the Global Data Survey Monkey plugin with your Survey Monkey account.`,
            buttons: [{
                key: 'confirm',
                title: 'Get Started',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    window.open('https://www.surveymonkey.com/apps/1LQG7O63KaHnGcT61M8_2BKg_3D_3D/details/');
                    return;
                }
            }
        });
    }

    const onEnabledStatusClick = () => {

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('programs.survey_monkey.actions.edit') === false) {
            return utils.user.permissions.reject();
        }

        // show sheet with options to manage survey monkey integration status
        utils.sheet.show({
            items: [{
                key: 'status',
                title: `${preferences.enabled ? 'Disconnect from' : 'Connect to'} Survey Monkey`,
                style: preferences.enabled ? 'destructive' : 'default'
            }]
        }, key => {
            switch(key) {
                case 'status':
                onSetEnabledStatus();
                break;
            }
        });
    }

    const onManageHiddenSurveyMonkeySurveys = () => {
        let dealership = utils.dealership.get();
        utils.layer.open({
            Component: ManageHiddenSurveyMonkeySurveys.bind(this, {
                dealership: dealership,
                onChange: fetchPreferences
            }),
            id: `manage_hidden_survey_monkey_surveys_${dealership.id}`,
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'hidden_surveys',
                permissions: ['programs.survey_monkey.actions.edit'],
                title: 'Manage Deleted Surveys',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'hidden_surveys':
                onManageHiddenSurveyMonkeySurveys();
                break;
            }
        });
    }

    const onSetEnabledStatus = () => {
        utils.alert.show({
            title: `${preferences.enabled ? 'Disconnect From' : 'Connect To'} Survey Monkey`,
            message: `Are you sure that you want to ${preferences.enabled ? 'disconnect from' : 'connect to'} Survey Monkey? ${preferences.enabled ? 'This means that your Survey Monkey account will no longer communicate with your Global Data account.' : 'This means that your Survey Monkey account will begin to communicate with your Global Data account.'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: preferences.enabled ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${preferences.enabled ? 'Disconnect' : 'Connect'}`,
                style: preferences.enabled ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onSetEnabledStatusConfirm, 250);
                    return;
                }
            }
        });
    }

    const onSetEnabledStatusConfirm = async () => {
        try {

            // declare next enabled status
            let status = preferences.enabled ? false : true;

            // send request to server
            setLoading('status');
            await Request.post(utils, '/survey_monkey/', {
                status: status,
                type: 'set_enabled_status'
            });

            // update survey monkey api integration status
            setPreferences(prefs => {
                prefs.enabled = status;
                return prefs;
            });
            
            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your Survey Monkey account has been ${status ? 'connected to' : 'disconnected from'} your Global Data account.`,
                onClick: () => {
                    if(status === false) {
                        setLayerState('close');
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${preferences.enabled ? 'disconnecting from' : 'connecting to'}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSurveyClick = survey => {
        utils.layer.open({
            id: `survey_monkey_survey_details_${survey.id}`,
            Component: SurveyMonkeySurveyDetails.bind(this, {
                onChange: setPreferences,
                preferences: preferences,
                survey: survey
            })
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            permissions: ['programs.survey_monkey.actions.edit'],
            text: 'Options'
        }];
    }

    const getPreferences = () => {

        // determine if a loading component is needed
        if(loading === true) {
            return (
                <LayerItem title={'Preferences'}>
                    <div style={Appearance.styles.unstyledPanel()}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: 15
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                            style={{
                                height: 50,
                                width: 50
                            }}/>
                        </div>
                    </div>
                </LayerItem>
            )
        }

        // prepare preferences fields
        let fields = [{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                color: Appearance.colors.orange,
                key: 'token',
                loading: loading === 'status',
                onClick: onConfigurationClick,
                title: 'Survey Monkey',
                value: 'Not Configured',
                visible: preferences.token ? false : true
            },{
                color: preferences.enabled ? Appearance.colors.green : Appearance.colors.red ,
                key: 'enabled',
                loading: loading === 'status',
                onClick: onEnabledStatusClick,
                title: 'Survey Monkey',
                value: preferences.enabled ? 'Connected' : 'Disconnected',
                visible: preferences.token ? true : false
            }]
        }];

        return (
            <FieldMapper
            fields={fields}
            utils={utils} />
        )
    }

    const getSurveys = () => {

        // determine if a loading component is needed
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }

        // show a placeholder if no surveys have been synced
        let surveys = preferences && preferences.surveys || [];
        if(surveys.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'We did not find any surveys linked with your Global Data account',
                    title: 'No Surveys Found'
                })
            )
        }
        return surveys.sort((a,b) => {
            return a.title.localeCompare(b.title);
        }).map((survey, index) => {
            return (
                Views.entry({
                    ...getSurveySubTitleProps(survey),
                    bottomBorder: index !== surveys.length - 1,
                    hideIcon: true,
                    key: index,
                    onClick: onSurveyClick.bind(this, survey),
                    title: survey.title
                })
            )
        });
    }

    const getSurveySubTitleProps = survey => {

        // determine if lead generation has been disabled for the survey
        if(survey.enabled === false) {
            return {
                subTitle: 'Lead Generation Disabled',
                style: {
                    subTitle: {
                        color: Appearance.colors.red
                    }
                }
            }
        }

        // determine if a lead map has not yet been generated for this survey
        if(!preferences.lead_maps[survey.id]) {
            return {
                subTitle: 'Additional Configuration Required',
                style: {
                    subTitle: {
                        color: Appearance.colors.orange
                    }
                }
            }
        }

        // fallback to successful entry for a configured survey
        return {
            subTitle: 'Lead Generation Enabled',
            style: {
                subTitle: {
                    color: Appearance.colors.green
                }
            }
        }
    }

    const fetchPreferences = async () => {
        try {
            setLoading(true);
            let { preferences } = await Request.get(utils, '/survey_monkey/', {
                dealership_id: utils.dealership.get().id,
                type: 'preferences'
            });
            setLoading(false);
            setPreferences(preferences);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the survey monkey preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        // TODO => add websockets listener for app_install and app_uninstall changes
        fetchPreferences();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Survey Monkey Preferences'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getPreferences()}
            <LayerItem title={'Surveys'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {getSurveys()}
                </div>
            </LayerItem>
        </Layer>
    )
}

export const SurveyMonkeySurveyDetails = ({ onChange, preferences, survey }, { index, options, utils }) => {
    
    const layerID = `survey_monkey_survey_details_${survey.id}`;
    const [collectors, setCollectors] = useState([]);
    const [data, setData] = useState(null);
    const [defaultValues, setDefaultValues] = useState({});
    const [edits, setEdits] = useState(null);
    const [items, setItems] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [leadTypes, setLeadTypes] = useState([]);
    const [leadSubTypes, setLeadSubTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const onCollectorValueChange = (collector, user) => {
        setEdits(edits => {
            if(!edits.collectors[collector.id]) {
                edits.collectors[collector.id] = {};
            }
            return update(edits, {
                collectors: {
                    [collector.id]: {
                        value: {
                            $set: user && user.user_id
                        }
                    }
                }
            });
        });
    }

    const onCreateNewCustomValue = async (question, title) => {
        try {

            // set loading and submit request to server
            setLoading('custom_value');
            await Utils.sleep(0.5);
            let { id } = await Request.post(utils, '/survey_monkey/', {
                title: title,
                type: 'add_custom_lead_map_value'
            });

            // end loading and update items list
            setLoading(false);
            setItems(values => {
                return values.concat([{ 
                    custom: true,
                    id: id,
                    title: title 
                }]).sort((a,b) => {
                    return a.title.localeCompare(b.title);
                })
            });

            // update selection for question with new value
            setEdits(edits => {
                if(!edits.lead_map[question.id]) {
                    edits.lead_map[question.id] = {};
                }
                return update(edits, {
                    lead_map: {
                        [question.id]: {
                            value: {
                                $set: id
                            }
                        }
                    }
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your custom value. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteSurvey = () => {
        utils.alert.show({
            title: 'Delete Survey from Global Data',
            message: 'Are you sure that you want to delete this survey from Global Data? This survey will no longer be available for automatic lead generation.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onDeleteSurveyConfirm, 250);
                    return;
                }
            }
        });
    }

    const onDeleteSurveyConfirm = async () => {
        try {

            // send request to server
            setLoading('options');
            await Request.post(utils, '/survey_monkey/', {
                id: survey.id,
                type: 'delete_survey'
            });

            // remove survey from local list of surveys
            preferences.surveys = preferences.surveys.filter(s => s.id !== survey.id);
            
            // notify subscribers of data change
            if(typeof(onChange) === 'function') {
                onChange(preferences);
            }

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This survey has been removed from Global Data',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this survey from Global Data. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadValueChange = (question, item) => {

        // request a column name from the user if applicable
        if(item && item.id === 'custom_value') {
            let title = null;
            utils.alert.show({
                title: 'Custom Value',
                message: 'What would you like to call this custom value?',
                content: (
                    <div style={{
                        paddingBottom: 12,
                        paddingLeft: 12,
                        paddingRight: 12,
                        width: '100%'
                    }}>
                        <TextField
                        placeholder={'Value Name'}
                        onChange={text => title = text}/>
                    </div>
                ),
                buttons: [{
                    key: 'confirm',
                    title: 'Done',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm' && title) {
                        onCreateNewCustomValue(question, title);
                        return;
                    }
                }
            });
            return;
        }
        
        // update edits with new selection for question
        setEdits(edits => {
            if(!edits.lead_map[question.id]) {
                edits.lead_map[question.id] = {};
            }
            return update(edits, {
                lead_map: {
                    [question.id]: {
                        custom: {
                            $set: item.custom || false
                        },
                        value: {
                            $set: item && item.id
                        }
                    }
                }
            })
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                permissions: ['programs.survey_monkey.actions.edit'],
                title: 'Delete Survey from Global Data',
                style: 'destructive'
            },{
                key: 'enabled',
                permissions: ['programs.survey_monkey.actions.edit'],
                title: `${survey.enabled ? 'Disable' : 'Enable'} Lead Generation`,
                style: survey.enabled ? 'destructive' : 'default'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'delete':
                onDeleteSurvey();
                break;

                case 'enabled':
                onSetEnabledStatus();
                break;
            }
        });
    }

    const onSetEnabledStatus = () => {
        utils.alert.show({
            title: `${survey.enabled ? 'Disable' : 'Enable'} Lead Generation`,
            message: `Are you sure that you want to ${survey.enabled ? 'disable' : 'enable'} lead generation? ${survey.enabled ? 'This means that leads will no longer be generated when a new survey monkey response is submitted.' : 'This means that a lead will be automatically created in Global Data when a new survey monkey response is submitted using this survey.'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: survey.enabled ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${survey.enabled ? 'Disable' : 'Enable'}`,
                style: survey.enabled ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onSetEnabledStatusConfirm, 250);
                    return;
                }
            }
        });
    }

    const onSetEnabledStatusConfirm = async () => {
        try {

            // declare next enabled status
            let status = survey.enabled ? false : true;

            // send request to server
            setLoading('options');
            await Request.post(utils, '/survey_monkey/', {
                id: survey.id,
                status: status,
                type: 'set_survey_enabled_status'
            });

            // remove survey from local list of surveys
            preferences.surveys = preferences.surveys.map(s => {
                if(s.id === survey.id) {
                    s.enabled = status;
                }
                return s;
            });
            
            // notify subscribers of data change
            if(typeof(onChange) === 'function') {
                onChange(preferences);
            }

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Lead generation for this survey has been ${status ? 'enabled' : 'disabled'}`,
                onClick: () => {
                    if(status === false) {
                        setLayerState('close');
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the lead generation status for this survey. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = async () => {
        try {
            setLoading('submit');
            await Request.post(utils, '/survey_monkey/', {
                collectors: edits.collectors,
                default_values: defaultValues,
                id: survey.id,
                map: edits.lead_map,
                type: 'update_survey_preferences'
            });

            // notify subscribers that data has changed
            if(typeof(onChange) === 'function') {
                onChange({ 
                    ...preferences, 
                    collectors: update(preferences.collectors || {}, {
                        [survey.id]: {
                            $set: edits.collectors
                        }
                    }),
                    lead_maps: update(preferences.lead_maps || {}, {
                        [survey.id]: {
                            $set: edits.lead_map
                        }
                    })
                });
            }

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The changes to your survey preferences have been saved'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your survey preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmit,
            permissions: ['programs.survey_monkey.actions.edit'],
            text: 'Submit'
        }];
    }

    const getContent = () => {
        if(!data || loading === true) {
            return (
                <div style={{
                    padding: 12
                }}>
                    <div style={Appearance.styles.unstyledPanel()}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: 15
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                            style={{
                                height: 50,
                                width: 50
                            }}/>
                        </div>
                    </div>
                </div>
            )
        }

        // prepare lead map items and generate question inputs
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    padding: 12,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader(),
                        display: 'block'
                    }}>{'Survey Configuration'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        whiteSpace: 'normal'
                    }}>{`Below you'll find a list of the questions from your survey. Review each question and determine if you would like the answer to be used when generating a new lead from a survey response.`}</span>
                </div>
                {getQuestionsSection()}
                {getSurveyCreditSection()}
                {getDefaultValuesSection()}
            </div>
        )
    }

    const getDefaultValuesSection = () => {

        // prepare fields for default values section
        let fields = [{
            key: 'lead_type',
            onChange: item => setDefaultValues(props => ({ ...props, lead_type: item && item.id })),
            onLoad: setLeadTypes,
            title: 'Lead Type',
            value: getValueForType('lead_type', leadTypes)
        },{
            key: 'lead_sub_type',
            onChange: item => setDefaultValues(props => ({ ...props, lead_sub_type: item && item.id })),
            onLoad: setLeadSubTypes,
            title: 'Lead Sub-Type',
            value: getValueForType('lead_sub_type', leadSubTypes)
        }];

        return (
            <>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12,
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subHeader(),
                    display: 'block'
                }}>{'Preferences'}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    whiteSpace: 'normal'
                }}>{`Below you can customize how leads are entered into your dealership when a customer provides their information using this survey.`}</span>
            </div>
            <div style={{
                padding: 12,
                width: '100%'
            }}>
                {fields.map((field, index) => {

                    // determine which input component to show
                    let component = null;
                    switch(field.key) {
                        case 'lead_type':
                        component = (
                            <LeadTypePickerField
                            {...field}
                            utils={utils} />
                        )
                        break;

                        case 'lead_sub_type':
                        component = (
                            <LeadSubTypePickerField
                            {...field}
                            utils={utils} />
                        )
                        break;
                    }

                    return (
                        <div 
                        key={index}
                        style={{
                            ...Appearance.styles.unstyledPanel(),
                            marginBottom: 12
                        }}>
                            <div style={{
                                backgroundColor: Appearance.colors.divider(),
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    display: 'block',
                                    maxWidth: '100%',
                                    whiteSpace: 'wrap',
                                    width: '100%'
                                }}>{field.title}</span>
                            </div>
                            <div style={{
                                borderTop: `1px solid ${Appearance.colors.divider()}`,
                                display: 'flex',
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                {component}
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const getLeadMappingPreferences = (question, index) => {

        // determine if nested rows are available
        if(question.rows) {
            return (
                <div 
                key={index}
                style={{
                    padding: 12,
                    width: '100%'
                }}>
                    {question.rows.map(row => {
                        let key = `${index}.${row.position}`;
                        return (
                            <div 
                            key={key}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                marginBottom: 12
                            }}>
                                <div style={{
                                    backgroundColor: Appearance.colors.divider(),
                                    padding: '8px 12px 8px 12px',
                                    width: '100%'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        display: 'block',
                                        maxWidth: '100%',
                                        whiteSpace: 'wrap',
                                        width: '100%'
                                    }}>{`${index + 1}.${row.position} ${row.title}`}</span>
                                </div>
                                {getLeadMappingPreferences(row, key)}
                            </div>
                        )
                    })}
                </div>
            )
        }

        // fallback to rendering default components
        let { enabled, value } = edits.lead_map[question.id] || {};
        return (
            <div 
            key={index}
            style={{
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    borderTop: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '8px 12px 8px 12px',
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.key()
                    }}>{'Use for Lead Generation'}</span>
                    <ListField
                    disablePlaceholder={true}
                    items={[{
                        id: 'yes',
                        title: 'Yes'
                    },{
                        id: 'no',
                        title: 'No'
                    }]}
                    onChange={item => {
                        setEdits(edits => {
                            if(!edits.lead_map[question.id]) {
                                edits.lead_map[question.id] = {};
                            }
                            return update(edits, {
                                lead_map: {
                                    [question.id]: {
                                        enabled: {
                                            $set: item && item.id === 'yes'
                                        }
                                    }
                                }
                            })
                        });
                    }} 
                    style={{
                        maxWidth: 225
                    }}
                    value={enabled === true ? 'Yes' : 'No'}/>
                </div>
                {enabled === true && (
                    <div style={{
                        alignItems: 'center',
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '8px 12px 8px 12px',
                        width: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.key()
                        }}>{'Use Question Response For'}</span>
                        <ListField
                        disablePlaceholder={true}
                        items={items}
                        placeholder={'Choose a lead value...'}
                        onChange={onLeadValueChange.bind(this, question)} 
                        style={{
                            maxWidth: 225
                        }}
                        value={value && items.find(item => item.id === value).title}/>
                    </div>
                )}
            </div>
        )
    }

    const getQuestionsSection = () => {
        return (
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12,
                width: '100%'
            }}>
                {data.pages.map(page => {
                    return page.questions.map((question, index) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                marginBottom: 12
                            }}>
                                <div style={{
                                    backgroundColor: Appearance.colors.divider(),
                                    padding: '8px 12px 8px 12px',
                                    width: '100%'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        display: 'block',
                                        maxWidth: '100%',
                                        whiteSpace: 'wrap',
                                        width: '100%'
                                    }}>{`${index + 1}. ${question.title}`}</span>
                                </div>
                                {getLeadMappingPreferences(question, index)}
                            </div>
                        )
                    });
                })}
            </div>
        )
    }

    const getSurveyCreditSection = () => {
        return (
            <>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12,
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subHeader(),
                    display: 'block'
                }}>{'Survey Credit'}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    whiteSpace: 'normal'
                }}>{`You can assign survey credit to a user in your dealership based on the collector label that you have setup in Survey Monkey. Choose a user from the list next to the collector to make an association.`}</span>
            </div>
            <div style={{
                padding: 12,
                width: '100%'
            }}>
                {collectors.map((collector, index) => {
                    let { enabled, value } = edits.collectors[collector.id] || {};
                    return (
                        <div 
                        key={index}
                        style={{
                            ...Appearance.styles.unstyledPanel(),
                            marginBottom: 12
                        }}>
                            <div style={{
                                backgroundColor: Appearance.colors.divider(),
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    display: 'block',
                                    maxWidth: '100%',
                                    whiteSpace: 'wrap',
                                    width: '100%'
                                }}>{collector.title}</span>
                            </div>
                            <div style={{
                                alignItems: 'center',
                                borderTop: `1px solid ${Appearance.colors.divider()}`,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.key()
                                }}>{'Use for Survey Credit'}</span>
                                <ListField
                                disablePlaceholder={true}
                                items={[{
                                    id: 'yes',
                                    title: 'Yes'
                                },{
                                    id: 'no',
                                    title: 'No'
                                }]}
                                onChange={item => {
                                    setEdits(edits => {
                                        if(!edits.collectors[collector.id]) {
                                            edits.collectors[collector.id] = {};
                                        }
                                        return update(edits, {
                                            collectors: {
                                                [collector.id]: {
                                                    enabled: {
                                                        $set: item && item.id === 'yes'
                                                    }
                                                }
                                            }
                                        })
                                    });
                                }} 
                                style={{
                                    maxWidth: 225
                                }}
                                value={enabled === true ? 'Yes' : 'No'}/>
                            </div>
                            {enabled === true && (
                                <div style={{
                                    alignItems: 'center',
                                    borderTop: `1px solid ${Appearance.colors.divider()}`,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '8px 12px 8px 12px',
                                    width: '100%'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.key()
                                    }}>{'Assign Survey Credit To'}</span>
                                    <UserLookupField
                                    containerStyle={{
                                        maxWidth: 225
                                    }}
                                    hideAvatar={true}
                                    onChange={onCollectorValueChange.bind(this, collector)} 
                                    utils={utils}
                                    user={value && users.find(user => user.user_id === value)}/>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const getValueForType = (key, targets) => {
        let match = defaultValues[key] && targets.find(type => type.id === defaultValues[key]);
        return match && match.text;
    }

    const fetchDetails = async () => {
        try {

            // fetch lead columns and details for survey from server
            setLoading(true);
            let response = await Request.get(utils, '/survey_monkey/', {
                custom_lead_map_values: true,
                id: survey.id,
                type: 'survey_details'
            });
            console.log(response);

            // end loading and set survey details
            setLoading(false);
            setCollectors(response.collectors.map(collector => ({
                id: collector.id,
                title: collector.name
            })));
            setData(response.survey); 
            setDefaultValues(response.default_values || {});
            setUsers(response.users);

            // prepare list of default lead columns for mapping
            let targets = [{
                id: 'custom_value',
                title: '* Custom Value *'
            },{
                id: 'locality',
                title: 'City'
            },{
                id: 'date',
                title: 'Date Created'
            },{
                id: 'email_address',
                title: 'Email Address'
            },{
                id: 'first_name',
                title: 'First Name'
            },{
                id: 'last_name',
                title: 'Last Name'
            },{
                id: 'lead_credit_user',
                title: 'Lead Credit'
            },{
                id: 'lead_script',
                title: 'Lead Script'
            },{
                id: 'lead_sub_type',
                title: 'Lead Sub Type'
            },{
                id: 'lead_type',
                title: 'Lead Type'
            },{
                id: 'homeowner_status',
                title: 'Homeowner Status'
            },{
                id: 'marital_status',
                title: 'Marital Status'
            },{
                id: 'out_of_service_area',
                title: 'Out of Service Area (Yes/No)'
            },{
                id: 'notes',
                title: 'Notes'
            },{
                id: 'occupational_status',
                title: 'Occupational Status'
            },{
                id: 'phone_number',
                title: 'Phone Number'
            },{
                id: 'priority',
                title: 'Priority'
            },{
                id: 'program_credit',
                title: 'Program'
            },{
                id: 'spouse_email_address',
                title: 'Spouse Email Address'
            },{
                id: 'spouse_first_name',
                title: 'Spouse First Name'
            },{
                id: 'spouse_last_name',
                title: 'Spouse Last Name'
            },{
                id: 'spouse_phone_number',
                title: 'Spouse Phone Number'
            },{
                id: 'administrative_area_level_1',
                title: 'State/Province'
            },{
                id: 'status',
                title: 'Status'
            },{
                id: 'street_address_1',
                title: 'Street Address'
            },{
                id: 'postal_code',
                title: 'Zipcode'
            }];

            // add custom values to default values
            targets = targets.concat(response.custom_lead_map_values).sort((a,b) => {
                return a.title.localeCompare(b.title);
            });

            // update local state with values
            setItems(targets);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this survey. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    const setupTarget = () => {
        setEdits({
            collectors: preferences.collectors[survey.id] || {},
            lead_map: preferences.lead_maps[survey.id] || {},
        });
    }

    useEffect(() => {
        fetchDetails();
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for "${survey.title}"`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            removePadding: true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}
