import React, { useRef, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';
import ProgressBar from 'views/ProgressBar.js';
import Utils from 'files/Utils.js';

export const AltBadge = ({ className, content, invert, labelStyle, onClick, style }) => {

    const ref = useRef(null);

    const onElementClick = evt => {
        evt.target = ref.current;
        onClick(evt);
    }

    const getContent = () => {
        
        // prepare list of badge targets
        let targets = Array.isArray(content) ? content : [content];

        // filter out invalid entries and return components
        return targets.filter(props => {
            return props && props.color && props.text ? true : false;
        }).map((props, index) => {
            return (
                <div
                key={index}
                className={onClick ? 'text-button' : ''}
                onClick={onClick ? onElementClick : null}
                ref={ref}
                style={{
                    alignItems: 'center',
                    backgroundColor: invert ? '#FFFFFF' : Utils.hexToRGBA(props.color, 0.25),
                    borderRadius: 20,
                    display: 'flex',
                    marginRight: 8,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    padding: '2px 10px 2px 10px',
                    position: 'relative',
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    ...props.style,
                    ...style
                }}>
                    <span
                    className={className}
                    style={{
                        color: window.theme === 'dark' && invert !== true ? '#FFFFFF' : props.color,
                        display: 'block',
                        fontSize: 10,
                        fontWeight: '800',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        ...labelStyle
                    }}>{props.text.toString().toUpperCase()}</span>
                </div>
            )
        });
    }

    return getContent();
}

export const AsyncViewComponent = props => {

    const [loading, setLoading] = useState(props.loading || false);

    const onItemClick = async () => {
        try {
            setLoading(true);
            await props.onClick();
            setLoading(false);
        } catch(e) {
            console.error(e.message);
        }
    }

    return (
        <ViewEntry 
        {...props}
        loading={loading}
        onClick={onItemClick} />
    )
}

export const ContentContainer = ({ children, initVal = 'init', loading, props }) => {

    const getContent = () => {
        if(loading === initVal) {
            return (
                <ViewLoader />
            )
        }
        if(!children || children.length === 0) {
            return (
                <ViewEntry 
                {...props} 
                bottomBorder={false}
                hideIcon={true}/>
            )
        }
        return children.map((props, index) => (
            <ViewEntry 
            key={index}
            {...props} />
        ));
    }
    return getContent();
}

export const ViewEntry = ({ badge, bottomBorder, bottomContent, className, hideIcon = false, hideOnClickArrow = false, hoverClassName, icon, loading, onClick, rightContent, style, subTitle, supportingTitle, title }) => {

    const ref = useRef(null);

    const onElementClick = evt => {
        evt.target = ref.current;
        onClick(evt);
    }

    const onUnreadIconClick = evt => {
        evt.stopPropagation();
        icon.unread.onClick(evt);
    }

    const getBadges = () => {

        // prevent moving forward if no badge value was provided
        if(!badge) {
            return null;
        }

        // prepare badge target and render component if applicable
        let targets = Array.isArray(badge) ? badge : (badge && badge.color && badge.text ? [badge] : []);
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 8,
                minWidth: 0
            }}>
                {targets.filter(props => {
                    return props && props.color && props.text ? true : false;
                }).map((target, index, targets) => (
                    <AltBadge 
                    content={target} 
                    key={index} 
                    style={{
                        marginRight: index !== targets.length - 1 ? 8 : 0 
                    }}/>
                ))}
            </div>
        )
    }

    const getIcon = () => {

        // determine if a hide-icon flag was provided
        if(hideIcon === true) {
            return null;
        }

        // determine if icon content was provided as a callable function
        if(typeof(icon) === 'function') {
            return icon();
        }

        // fallback to rendering icon component
        let { badge, className, imageStyle, onClick, path, style, unread } = icon || {};
        return (
            <div style={{
                height: 30,
                minWidth: 30,
                minHeight: 30,
                position: 'relative',
                width: 30,
                ...style
            }}>
                <img
                className={`${className || ''} ${onClick ? 'text-button' : ''}`}
                onClick={onClick}
                src={path || 'images/blue-loader-dot.png'}
                style={{
                    ...Appearance.icons.standard(),
                    minHeight: Appearance.icons.standard().height,
                    minWidth: Appearance.icons.standard().width,
                    ...imageStyle
                }}/>
                {badge && badge.text && (
                    <div style={{
                        alignItems: 'center',
                        backgroundColor: badge.color || Appearance.colors.primary(),
                        borderRadius: '50%',
                        display: 'flex',
                        height: 15,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        padding: 4,
                        position: 'absolute',
                        right: -6,
                        textAlign: 'center',
                        top: -2,
                        width: 15
                    }}>
                        <span style={{
                            color: 'white',
                            fontSize: 9,
                            fontWeight: 600
                        }}>{badge.text}</span>
                    </div>
                )}
                {unread && unread.value && (
                    <div
                    className={typeof(unread.onClick) === 'function' ? 'text-button' : ''}
                    onClick={typeof(unread.onClick) === 'function' ? onUnreadIconClick : null}
                    style={{
                        alignItems: 'center',
                        backgroundColor: Appearance.colors.primary(),
                        borderRadius: '50%',
                        display: 'flex',
                        height: 15,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        position: 'absolute',
                        right: -6,
                        textAlign: 'center',
                        top: -2,
                        width: 15
                    }}>
                        <div style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            height: 5,
                            overflow: 'hidden',
                            width: 5
                        }} />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div
        onClick={typeof(onClick) === 'function' ? onElementClick : null}
        ref={ref}
        style={{
            borderBottom: bottomBorder !== false && `1px solid ${Appearance.colors.divider()}`,
            overflow: 'hidden',
            width: '100%'
        }}>
            <div 
            className={`${onClick ? (hoverClassName || `view-entry ${window.theme}`) : ''}`}
            style={{
                padding: '8px 10px 8px 10px',
                position: 'relative',
                ...style
            }}>
                <div
                className={className || ''}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    position: 'relative',
                    width: '100%'
                }}>
                    {getIcon()}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minWidth: 0,
                        paddingLeft: hideIcon === true ? 0 : 12,
                        textAlign: 'left'
                    }}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                ...style && style.title
                            }}>{title}</span>
                            {getBadges()}
                        </div>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            ...style && style.subTitle
                        }}>{subTitle}</span>
                        {typeof(supportingTitle) === 'string' && (
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                ...style && style.supportingTitle
                            }}>{supportingTitle}</span>
                        )}
                    </div>
                    {rightContent}
                    {typeof(onClick) === 'function' && hideOnClickArrow === false && (
                        <img
                        src={'images/next-arrow-grey-small.png'}
                        style={{
                            height: 12,
                            marginLeft: 8,
                            objectFit: 'contain',
                            opacity: 0.75,
                            width: 12
                        }} />
                    )}
                </div>
                {loading === true && (
                    <div style={{
                        bottom: 0,
                        borderRadius: 1,
                        height: 2,
                        left: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        right: 0
                    }}>
                        <ProgressBar/>
                    </div>
                )}
            </div>
            {bottomContent}
        </div>
    )
}

export const ViewLoader = () => {
    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 12,
            width: '100%'
        }}>
            <LottieView
            autoPlay={true}
            loop={true}
            source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
            style={{
                height: 45,
                width: 45
            }}/>
        </div>
    )
}

export const ViewRow = ({ bottomBorder = true, color, key, label, onClick, style, value }) => {
    return (
        <div
        className={typeof(onClick) === 'function' ? `view-entry ${window.theme}` : ''}
        key={key}
        onClick={onClick}
        style={{
            alignItems: 'center',
            borderBottom: bottomBorder && `1px solid ${Appearance.colors.divider()}`,
            display: 'flex',
            flexDirection: 'row',
            padding: '8px 12px 8px 12px'
        }}>
            <span style={{
                ...Appearance.textStyles.key(),
                ...style && style.key,
                flexGrow: 1
            }}>{label}</span>
            <span style={{
                ...Appearance.textStyles.value(),
                ...style && style.value,
                ...color && {
                    color: color
                }
            }}>{value}</span>
            {typeof(onClick) === 'function' && (
                <img
                src={'images/next-arrow-grey-small.png'}
                style={{
                    height: 12,
                    marginLeft: 8,
                    objectFit: 'contain',
                    opacity: 0.75,
                    width: 12
                }} />
            )}
        </div>
    )
}

export default {
    entry: props => {
        return <ViewEntry {...props} />
    },
    icon: {
        remove: props => (
            <img
            {...props}
            className={'cursor-pointer'}
            src={'images/red-x-icon.png'}
            style={{
                background: `linear-gradient(90deg, ${Appearance.colors.textField(0)}, ${Appearance.colors.textField(0.9)}, ${Appearance.colors.textField()})`,
                height: '100%',
                objectFit: 'contain',
                paddingLeft: 30,
                position: 'absolute',
                right: 12,
                width: 45,
            }} />
        ),
        right: (type, onClick) => {
            let name = null;
            switch(type) {
                case 'arrow':
                name = 'next-arrow-light-grey.png';
                break;

                case 'arrow-white':
                name = 'next-arrow-white.png';
                break;

                case 'down-arrow':
                name = 'down-arrow-grey-small.png';
                break;

                case 'else-redirect':
                name = 'else-redirect-icon.png';
                break;

                case 'next-redirect':
                name = 'next-redirect-icon.png';
                break;

                case 'new':
                name = 'plus-button-blue-small.png';
                break;

                case 'red-x':
                name = 'red-x-icon.png';
                break;

                case 'up-arrow':
                name = 'up-arrow-grey-small.png';
                break;

                case 'warning':
                name = 'warning-icon-yellow.png';
                break;

                default:
                return null;
            }
            return (
                <img
                src={`images/${name}`}
                className={onClick ? 'text-button' : null}
                onClick={onClick}
                style={{
                    height: 18,
                    marginLeft: 8,
                    objectFit: 'contain',
                    opacity: 1,
                    width: 18
                }} />
            )
        }
    },
    loader: () => (
        <ViewLoader />
    ),
    row: props => (
        <ViewRow {...props} />
    )
};
