import React, { useState, useEffect } from 'react';
import { circle } from '@turf/circle';

import Appearance from 'styles/Appearance.js';
import AddressLookupField from 'views/AddressLookupField.js';
import CustomSlider from 'views/CustomSlider.js';
import ListField from 'views/ListField.js';
import { Map } from 'views/MapElements.js';
import Utils from 'files/Utils.js';

const LocationRadiusLookupField = ({ channel = 'everything', onChange, utils, value }) => {

    const [features, setFeatures] = useState(null);
    const [place, setPlace] = useState(null);
    const [radius, setRadius] = useState(1);
    const [selectionStyle, setSelectionStyle] = useState(null);

    const onLocationChange = result => {

        // prepare result payload
        let next = {
            address: {
                administrative_area_level_1: result.administrative_area_level_1,
                country: result.country,
                locality: result.locality,
                postal_code: result.postal_code,
                street_address_1: result.street_address_1,
                street_address_2: result.street_address_2,
            },
            location: result.location
        };

        // update local state with selection
        setPlace(next);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange({
                poi: result,
                radius: radius,
                selection: selectionStyle && selectionStyle.id || 'inside'
            });
        }
    }

    const onSelectionStyleChange = item => {

        // update local state
        setSelectionStyle(item);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange({
                poi: place,
                radius: radius,
                selection: item && item.id || 'inside'
            });
        }
    }

    const onRadiusChange = val => {

        // update local state
        setRadius(val);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange({
                poi: place,
                radius: val,
                selection: selectionStyle && selectionStyle.id || 'inside'
            });
        }
    }

    const onRemoveItem = () => {
        setPlace(null);
        if(typeof(onChange) === 'function') {
            onChange(null);
        }
    }

    const onUpdateFeatures = () => {

        // no feature generation is required if no place data is available
        if(!place || !place.location) {
            setFeatures(null);
            return;
        }

        // prepare geojson for circle object
        let result = circle([place.location.longitude, place.location.latitude], radius || 1, {
            steps: 50, 
            units: 'miles'
        });

        // update local state with features
        setFeatures({
            id: 'locaton_radius_polygon',
            data: {
                features: [result],
                type: 'FeatureCollection',
            },
            layer: {
                type: 'fill',
                paint: {
                    'fill-color': Appearance.colors.primary(),
                    'fill-opacity': 0.35,
                    'fill-outline-color': Appearance.colors.primary()
                }
            },
            region: Utils.getRegionFromAnnotations(result.geometry.coordinates[0].map(coordinate => [coordinate[1], coordinate[0]]))
        });
    }

    const getLabelFor = key => {
        return key === 'outside' ? `Select ${channel} OUTSIDE the circle` : `Select ${channel} INSIDE the circle`;
    }

    useEffect(() => {
        if(place && radius) {
            onUpdateFeatures();
        }
    }, [place, radius]);

    useEffect(() => {
        if(value) {
            setPlace(value.poi);
            setRadius(value.radius || 0);
            setSelectionStyle(value.selection === 'outside' ? { id: 'outside', title: getLabelFor('outside') } : { id: 'inside', title: getLabelFor('inside') })
        }
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            {features && (
                <Map
                features={features}
                isScrollEnabled={true}
                isZoomEnabled={true}
                isRotationEnabled={true}
                style={{
                    height: 350,
                    marginBottom: 8,
                    width: '100%'
                }}/>
            )}
            <AddressLookupField
            customComponents={false}
            geocode={true}
            onChange={onLocationChange}
            placeholder={'Search by name or address...'}
            utils={utils}
            value={place}
            appendContent={place && (
                <img
                src={`images/red-x-icon.png`}
                className={'text-button'}
                onClick={onRemoveItem}
                style={{
                    height: 18,
                    marginLeft: 8,
                    objectFit: 'contain',
                    opacity: 1,
                    width: 18
                }} />
            )}/>
            {place !== null && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <ListField
                    disablePlaceholder={true} 
                    items={[{
                        id: 'inside',
                        title: getLabelFor('inside')
                    },{
                        id: 'outside',
                        title: getLabelFor('outside')
                    }]}
                    insetLabel={'Selection Style'}
                    onChange={onSelectionStyleChange} 
                    style={{
                        marginTop: 8
                    }}
                    value={selectionStyle && selectionStyle.title}/>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 8,
                        paddingLeft: 12,
                        width: '100%'
                    }}>
                        <CustomSlider
                        step={1}
                        min={1}
                        max={100}
                        value={radius}
                        onChange={(_, value) => onRadiusChange(value)} />
                        <div style={{
                            alignItems: 'center',
                            border: `1px solid ${Appearance.colors.divider()}`,
                            borderRadius: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginLeft: 12,
                            minWidth: 85,
                            padding: '6px 10px 6px 10px'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{`${radius} ${radius === 1 ? 'mile' : 'miles'}`}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LocationRadiusLookupField;
