import React, { useEffect, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';

import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import PhoneInput from 'react-phone-number-input';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import ProgressBar from 'views/ProgressBar.js';
import Utils from 'files/Utils.js';

const TextField = React.forwardRef(({
    append, appendContent, autoCapitalize, autoComplete, autoCorrect, className, containerStyle, expandWithText, fieldStyle, format, focused, icon, iconStyle, insetLabel, insetLabelStyle, insetContainerStyle, isSecure, loading, onChange, onBlur, onClick, placeholder, prepend, prependContent, spellCheck, useDelay, value
}, ref) => {

    const phoneInputRef = useRef(null);

    const [active, setActive] = useState(focused);
    const [countryCode, setCountryCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState(value !== null && value !== undefined ? value : '');

    const onFieldClick = () => {
        setActive(true);
        if(typeof(onClick) === 'function') {
            onClick();
        }
    }

    const onFieldBlur = () => {
        setActive(false);
        if(typeof(onBlur) === 'function') {
            onBlur();
        }
    }

    const onPhoneInputTextChange = text => {
        try {

            // clear previous timeout if present
            if(textTimeout) {
                clearTimeout(textTimeout);
            }

            // determine if a phone number content validation is needed
            if(text && text.length > 0) {

                // prepare phone number util instance and parse phone number value
                let instance = PhoneNumberUtil.getInstance();
                let result = instance.parseAndKeepRawInput(text);

                // format phone number with country code prefix
                instance.format(result, PhoneNumberFormat.INTERNATIONAL);
            }

            // remove error message from previous input
            setErrorMessage(null);

            // update state and notify listeners of text change
            setText(text);
            if(typeof(onChange) === 'function') {
                if(useDelay === true) {
                    setTextTimeout(setTimeout(onChange.bind(this, text), 250));
                } else {
                    onChange(text);
                }
            }

        } catch(e) {
            console.error(e.message);
            let error = formatPhoneUtilError(e);
            setErrorMessage(error.message);
        }
    }

    const onTextChange = evt => {

        // clear previous timeout if present
        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        // format text result and determine if additional specialty formatting is required
        let result = evt.target.value.replace(prepend || '', '').replace(append || '', '');
        switch(format) {
            case 'double':
            result = parseFloat(result.replace(/[^\d.-]/g,''));
            break;

            case 'integer':
            result = parseInt(result.replace(/[^\d.-]/g,''));
            break;

            case 'number':
            result = result.replace(/[^\d.-]/g,'');
            break;

            default:
            if(typeof(format) === 'function') {
                result = format(result);
            }
        }

        // set text with timeout if applicable
        setText(result);
        if(useDelay === true) {
            setTextTimeout(setTimeout(onChange.bind(this, result), 250));
        } else {
            onChange(result);
        }
    }

    const getContent = () => {
        if(format !== 'phone_number') {
            return getField();
        }
        return (
            <div
            className={`dummy-field ${window.theme} ${active ? 'active':''} ${className || ''}`}
            style={{
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: 35,
                padding: 0,
                position: 'relative',
                ...containerStyle
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 12, 
                    paddingRight: 12,
                    width: '100%'
                }}>
                    <PhoneInput
                    countryCallingCodeEditable={false}
                    defaultCountry={countryCode}
                    international={true}
                    onChange={onPhoneInputTextChange}
                    placeholder={placeholder}
                    value={text}
                    ref={phoneInputRef}
                    style={{
                        width: '100%'
                    }}/>
                    {appendContent}
                </div>
                {typeof(errorMessage) === 'string' && (
                <div style={{
                    borderTop: `1px solid ${Appearance.colors.softBorder()}`,
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.red,
                    }}>{errorMessage}</span>
                </div>
            )}
            </div>
        )
    }

    const getField = () => {
        return (
            <div
            className={`dummy-field ${window.theme} ${active ? 'active' : 'not-active'} ${className || ''}`}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                height: expandWithText ? 'auto' : 35,
                minHeight: 35,
                position: 'relative',
                ...containerStyle
            }}>
                {getIcon(icon) && (
                    <div style={{
                        marginLeft: 4,
                        paddingRight: 8
                    }}>
                        <i
                        className={getIcon(icon)}
                        style={{
                            color: Appearance.colors.icon(),
                            fontSize: 13,
                            ...iconStyle
                        }}/>
                    </div>
                )}
                {insetLabel && (
                    <div style={{
                        borderRight: `1px solid ${Appearance.colors.softBorder()}`,
                        marginRight: 8,
                        paddingRight: 8,
                        ...insetContainerStyle
                    }}>
                        <span style={{
                            color: Appearance.colors.subText(),
                            fontSize: 10,
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            ...insetLabelStyle
                        }}>{insetLabel}</span>
                    </div>
                )}
                {getFieldComponent()}
                {loading === true && (
                    <div style={{
                        borderRadius: 2,
                        bottom: 0,
                        height: 2,
                        left: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        right: 0
                    }}>
                        <ProgressBar />
                    </div>
                )}
                {appendContent}
            </div>
        )
    }

    const getFieldComponent = () => {
        if(typeof(onClick) === 'function') {
            return (
                <span
                className={`cursor-pointer ${className || ''}`}
                onClick={onFieldClick}
                onMouseLeave={onFieldBlur}
                style={{
                    backgroundColor: Appearance.colors.transparent,
                    borderWidth: 0,
                    color: value ? Appearance.colors.text() : Appearance.colors.subText(),
                    flexGrow: 1,
                    fontSize: 12,
                    fontWeight: 500,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    ...fieldStyle
                }}>{value || placeholder}</span>
            )
        }
        if(isSecure || expandWithText !== true) {
            return (
                <input
                autoCapitalize={autoCapitalize === false ? 'off' : autoCapitalize}
                autoComplete={autoComplete || 'off'}
                autoCorrect={autoCorrect === false ? 'off':'on'}
                className={`dummy-field-input ${window.theme}`}
                inputMode={getInputMode()}
                onBlur={onFieldBlur}
                onChange={onTextChange}
                onClick={onFieldClick}
                placeholder={placeholder || ''}
                ref={ref}
                spellCheck={spellCheck === false ? 'false' : 'true'}
                type={isSecure ? 'password' : 'text'}
                value={(prepend || '') + (text || '') + (append || '')}
                style={{
                    backgroundColor: Appearance.colors.transparent,
                    color: Appearance.colors.text(),
                    border: 'none',
                    fontSize: Utils.isMobile() ? 16 : 12,
                    fontWeight: 500,
                    height: 'auto',
                    width: '100%',
                    ...fieldStyle
                }} />
            )
        }

        return (
            <textarea
            autoCapitalize={autoCapitalize === false ? 'off' : autoCapitalize}
            autoComplete={autoComplete || 'off'}
            autoCorrect={autoCorrect === false ? 'off':'on'}
            className={`dummy-field-input ${window.theme}`}
            onBlur={onFieldBlur}
            onClick={onFieldClick}
            onChange={onTextChange}
            placeholder={placeholder || ''}
            ref={ref}
            spellCheck={spellCheck === false ? 'false' : 'true'}
            type={isSecure === true ? 'password' : 'text'}
            value={(prepend || '') + (text || '') + (append || '')}
            style={{
                backgroundColor: Appearance.colors.transparent,
                border: 'none',
                color: Appearance.colors.text(),
                fontSize: Utils.isMobile() ? 16 : 12,
                fontWeight: 500,
                height: 'auto',
                minHeight: 125,
                paddingTop: expandWithText ? 0 : 18.5,
                resize: 'none',
                verticalAlign: 'middle',
                width: '100%',
                ...fieldStyle
            }} />
        )
    }

    const getInputMode = () => {
        if([ 'integer' ].includes(format)) {
            return 'numeric';
        }
        if([ 'double', 'integer', 'number', 'phone_number' ].includes(format)) {
            return 'decimal';
        }
        return null;
    }

    const setupFormat = () => {
        try {

            // prevent moving forward if phone number formatting is not required
            if(format !== 'phone_number' || phoneInputRef.current === null) {
                return;
            }

            // determine country code using browser locale
            let locale = new Intl.Locale(navigator.language);
            setCountryCode(locale.region.toUpperCase());

            // prepare fallback timezone values
            let timezone = moment.tz.guess();
            let tz_countries = moment.tz.zone(timezone).countries();
            let tz = tz_countries && tz_countries.length > 0 ? tz_countries[0].toLowerCase() : null;

            // set default country using device timezone if no phone number value was provided
            if(!value) {
                setCountryCode(tz && tz.toUpperCase());
                return;
            }

            // prepare phone number util instance and parse phone number value
            let instance = PhoneNumberUtil.getInstance();
            let result = instance.parseAndKeepRawInput(value, locale || 'US');

            // determine which country to select using the region code for the result
            let country = instance.getRegionCodeForNumber(result);
            if(country) {
                setCountryCode(country.toUpperCase());
            }
            
            // format phone number with country code prefix
            let formatted = instance.format(result, PhoneNumberFormat.INTERNATIONAL);
            setText(formatted);
            setErrorMessage(null);

        } catch(e) {

            // strange error occurs intermitently and appears to be package related
            if(e.message.includes('toUpperCase') === true) {
                return;
            }
            
            // analyze relevant error and format for client
            console.error(e.message);
            let error = formatPhoneUtilError(e);
            setErrorMessage(error.message);
        }
    }

    useEffect(() => {
        setupFormat();
    }, [format]);

    useEffect(() => {
        setActive(focused);
    }, [focused])

    useEffect(() => {
        setText(value || '');
    }, [value]);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        }}>
            {prependContent}
            {getContent()}
        </div>
    )
})

export default TextField;

export const TextFieldContainer = React.forwardRef(({ containerStyle, fieldStyle, icon, iconStyle, loading, onChange, onClick, value }, ref) => {

    const [active, setActive] = useState(false);

    const onFieldClick = async () => {
        try {
            setActive(true);
            if(typeof(onClick) === 'function') {
                onClick();
            }
            await Utils.sleep(0.25);
            setActive(false);

        } catch(e) {
            console.error(e.message);
        }
    }

    const getContent = () => {
        return (
            <div
            className={`dummy-field ${window.theme} ${active ? 'active':''}`}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                height: 35,
                minHeight: 35,
                position: 'relative',
                ...containerStyle
            }}>
                {getIcon(icon) && (
                    <div style={{
                        marginLeft: 4,
                        paddingRight: 8
                    }}>
                        <i
                        className={getIcon(icon)}
                        style={{
                            color: Appearance.colors.icon(),
                            fontSize: 13,
                            ...iconStyle
                        }}/>
                    </div>
                )}
                <span
                onClick={onFieldClick}
                className={'cursor-pointer'}
                style={{
                    backgroundColor: Appearance.colors.transparent,
                    borderWidth: 0,
                    color: value ? Appearance.colors.text() : Appearance.colors.subText(),
                    flexGrow: 1,
                    fontSize: 12,
                    fontWeight: 500,
                    ...fieldStyle
                }}>{value}</span>
                <div style={{
                    bottom: 0,
                    height: 2,
                    left: 0,
                    position: 'absolute',
                    right: 0
                }}>
                    <div style={{
                        borderRadius: 2,
                        display: loading ? 'block' : 'none',
                        height: 2,
                        overflow: 'hidden'
                    }}>
                        <ProgressBar />
                    </div>
                </div>
            </div>
        )
    }

    return getContent();
})

export const formatPhoneUtilError = e => {
    switch(e.message) {
        case 'Invalid country calling code':
        return new Error('Invalid country code');

        case 'The string supplied did not seem to be a phone number':
        return new Error('Phone number is invalid');

        case 'The string supplied is too short to be a phone number':
        return new Error('Phone number is too short');

        case 'The string supplied is too long to be a phone number':
        return new Error('Phone number is too long');

        default:
        return e;
    }
}

export const getIcon = (icon) => {
    switch(icon) {

        case 'user':
        return 'fas fa-user';

        case 'spouse':
        return 'fas fa-house-user';

        case 'user-secret':
        return 'fas fa-user-secret';

        case 'email':
        return 'fas fa-envelope';

        case 'phone':
        return 'fas fa-phone';

        case 'calendar':
        return 'fas fa-calendar-alt';

        case 'house':
        return 'fas fa-home';

        case 'search':
        return 'fas fa-search';

        case 'lock':
        return 'fas fa-lock';

        case 'location':
        return 'fas fa-location-arrow';

        case 'payment':
        return 'fas fa-money-check-alt';

        case 'asterick':
        return 'fas fa-asterisk';

        case 'route':
        return 'fas fa-route';

        case 'guardian':
        return 'fas fa-user-friends';

        case 'title':
        return 'fas fa-signature';

        case 'description':
        return 'fas fa-stream';

        case 'featured':
        return 'fas fa-hand-holding-heart';

        case 'wifi':
        return 'fas fa-wifi';

        case 'pattern':
        return 'fas fa-th';

        case 'office':
        return 'fas fa-briefcase';

        case 'globe':
        return 'far fa-globe';

        default:
        return;
    }
}
