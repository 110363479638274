import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import DatePickerField from 'views/DatePickerField.js';
import Feedback from 'classes/Feedback.js';
import { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import PickerField from 'views/PickerField.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const BookDemo = ({ defaultDate, onBookDemo, onDateChange, onUserChange, onLoad, lead, request, presetUsers = {}, utils }) => {

    const [assignmentsCollapsed, setAssignmentsCollapsed] = useState(true);
    const [duration, setDuration] = useState(10800);
    const [entries, setEntries] = useState([]);
    const [feedbackTemplate, setFeedbackTemplate] = useState(null);
    const [feedbackTemplates, setFeedbackTemplates] = useState([]);
    const [operatingHours, setOperatingHours] = useState(null);
    const [override, setOverride] = useState(false);
    const [partner, setPartner] = useState(null);
    const [rideAlong, setRideAlong] = useState(null);
    const [targetDate, setTargetDate] = useState(defaultDate ? moment(defaultDate) : moment());
    const [trainee, setTrainee] = useState(null);
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([]);

    const isDateRangeAvailable = ({ start_date, end_date }) => {

        // all days are available if no user has been selected
        if(!user) {
            return true;
        }

        // convert dates to unix timestamps for easier comparissions
        let unixStart = moment(start_date).unix();
        let unixEnd = moment(end_date).unix();

        // check if start date and end date fall within at least one of the selected user's availability entries
        let target = entries.find(entry => entry.user.user_id === user.user_id);
        let slot = target && target.timeslots.find(timeslot => {
            return unixStart >= timeslot.start_date && unixEnd <= timeslot.end_date;
        });
        return slot && slot.available === true ? true : false;
    }

    const onBookTimeslot = async ({ start_date, end_date }) => {
        try {

            // notify subscribers that load has begun
            if(typeof(onLoad) === 'function') {
                onLoad(true);
            }
            await Utils.sleep(0.25);

            // check if dealership three day rule is enabled
            let preferences = utils.dealership.get().preferences;
            if(preferences.three_day_rule) {
                let days = moment(start_date).startOf('day').diff(moment().startOf('day'), 'days');
                if(days > 3) {
                    throw new Error('The dealership is currently accepting demo appointments scheduled up to three days in advance. Please speak with your dealer if you have any questions.');
                }
            }

            // submit request to server
            let { id, lead_status } = await Request.post(utils, '/demos/', {
                end_date: moment(end_date).format('YYYY-MM-DD HH:mm:ss'),
                feedback_template_id: feedbackTemplate && feedbackTemplate.id,
                lead_id: lead && lead.id,
                partner_user_id: partner && partner.user_id,
                primary_user_id: user && user.user_id,
                request_id: request && request.id,
                ride_along_user_id: rideAlong && rideAlong.user_id,
                start_date: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
                trainee_user_id: trainee && trainee.user_id,
                type: 'new'
            });

            // notify subscribers of new fetch and load end
            utils.content.fetch('demo');
            if(typeof(onLoad) === 'function') {
                onLoad(false);
            }

            // notify subscribers that demo booking props are available
            if(typeof(onBookDemo) === 'function') {
                onBookDemo({
                    id: id,
                    start_date: moment(start_date),
                    end_date: moment(end_date),
                    lead_status: lead_status
                });
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue booking this demo. ${e.message || 'An unknown error occurred'}`
            })
            if(typeof(onLoad) === 'function') {
                onLoad(false);
            }
        }
    }

    const onChangeAssignment = selectEvent => {
        let id = Utils.attributeForKey.select(selectEvent, 'id');
        if(!id) {
            setUser(null);
            return;
        }
        let entry = entries.find(entry => entry.user.user_id === parseInt(id));
        if(entry) {
            setUser(entry.user);
        }
    }

    const onChangePartner = selectEvent => {
        let id = Utils.attributeForKey.select(selectEvent, 'id');
        if(!id) {
            setPartner(null);
            return;
        }
        let entry = entries.find(entry => entry.user.user_id === parseInt(id));
        if(entry) {
            setPartner(entry.user);
        }
    }

    const onChangeRideAlong = selectEvent => {
        let id = Utils.attributeForKey.select(selectEvent, 'id');
        if(!id) {
            setRideAlong(null);
            return;
        }
        let entry = entries.find(entry => entry.user.user_id === parseInt(id));
        if(entry) {
            setRideAlong(entry.user);
        }
    }

    const onChangeTrainee = selectEvent => {
        let id = Utils.attributeForKey.select(selectEvent, 'id');
        if(!id) {
            setTrainee(null);
            return;
        }
        let entry = entries.find(entry => entry.user.user_id === parseInt(id));
        if(entry) {
            setTrainee(entry.user);
        }
    }

    const onTimeslotClick = (entry, available) => {
        if(available === false) {
            utils.alert.show({
                title: 'Unavailable Timeslot',
                message: `Are you sure that you want to book this timeslot? It looks like ${user.full_name} is not available at this time`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'destructive'
                },{
                    key: 'cancel',
                    title: 'Do Not Book',
                    style: 'default'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        onBookTimeslot(entry);
                        return;
                    }
                }
            })
            return;
        }

        utils.alert.show({
            title: 'Book this Timeslot',
            message: `Are you sure that you want to book this timeslot?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Book',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onBookTimeslot(entry);
                    return;
                }
            }
        })
    }

    const getAssignments = () => {
        return (
            <LayerItem
            required={false}
            collapsed={assignmentsCollapsed}
            onVisibilityChange={val => setAssignmentsCollapsed(val)}
            title={'Dispatch'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12,
                    paddingTop: 8
                }}>
                    <LayerItem
                    required={false}
                    title={'Primary'}>
                        <select
                        className={`custom-select ${window.theme}`}
                        value={user ? `${user.full_name} (${user.user_id})` : 'Choose to view users...'}
                        onChange={onChangeAssignment}
                        style={{
                            width: '100%'
                        }}>
                            <option>{'Click to view users...'}</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} id={user.user_id}>{`${user.full_name} (${user.user_id})`}</option>
                                )
                            })}
                        </select>
                    </LayerItem>

                    <LayerItem
                    required={false}
                    title={'Partner'}>
                        <select
                        className={`custom-select ${window.theme}`}
                        value={partner ? `${partner.full_name} (${partner.user_id})` : 'Choose to view users...'}
                        onChange={onChangePartner}
                        style={{
                            width: '100%'
                        }}>
                            <option>{'Click to view users...'}</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} id={user.user_id}>{`${user.full_name} (${user.user_id})`}</option>
                                )
                            })}
                        </select>
                    </LayerItem>

                    <LayerItem
                    required={false}
                    title={'Safety Associate'}>
                        <select
                        className={`custom-select ${window.theme}`}
                        value={rideAlong ? `${rideAlong.full_name} (${rideAlong.user_id})` : 'Choose to view users...'}
                        onChange={onChangeRideAlong}
                        style={{
                            width: '100%'
                        }}>
                            <option>{'Click to view users...'}</option>
                            {users.filter(user => {
                                return user.level === User.levels.get().safety_associate;
                            }).map((user, index) => {
                                return (
                                    <option key={index} id={user.user_id}>{`${user.full_name} (${user.user_id})`}</option>
                                )
                            })}
                        </select>
                    </LayerItem>

                    <LayerItem
                    required={false}
                    title={'Trainee'}>
                        <select
                        className={`custom-select ${window.theme}`}
                        value={trainee ? `${trainee.full_name} (${trainee.user_id})` : 'Choose to view users...'}
                        onChange={onChangeTrainee}
                        style={{
                            width: '100%'
                        }}>
                            <option>{'Click to view users...'}</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} id={user.user_id}>{`${user.full_name} (${user.user_id})`}</option>
                                )
                            })}
                        </select>
                    </LayerItem>
                </div>
            </LayerItem>
        )
    }

    const getDemoDateSelector = () => {
        return (
            <LayerItem
            title={'Date for Demo'}
            required={targetDate ? false : true}>
                <DatePickerField
                utils={utils}
                selected={targetDate}
                onDateChange={date => setTargetDate(date)} />
            </LayerItem>
        )
    }

    const getDurationSelect = () => {

        // duration is the total length of the timeslot itself
        let tmpDate = null;
        let durations = [ 3600, 5400, 7200, 9000, 10800, 12600, 14400, 16200, 18000 ];
        return (
            <LayerItem
            title={'Demo Duration'}
            required={duration ? false : true}
            childrenStyle={{
                paddingLeft: 0,
                paddingRight: 0
            }}>
                <select
                className={`custom-select ${window.theme}`}
                value={Utils.parseDuration(duration)}
                onChange={e => {
                    let id = Utils.attributeForKey.select(e, 'id');
                    setDuration(parseInt(id));
                }}
                style={{
                    width: '100%'
                }}>
                    {durations.map((duration, index) => {
                        return (
                            <option key={index} id={duration}>{Utils.parseDuration(duration)}</option>
                        )
                    })}
                </select>
            </LayerItem>
        )
    }

    const getFeedbackTemplateSelector = () => {
        return (
            <LayerItem
            required={false}
            title={'Customer Feedback Template'}>
                <ListField
                items={feedbackTemplates}
                value={feedbackTemplate ? feedbackTemplate.title : null}
                onChange={template => setFeedbackTemplate(template)} />
            </LayerItem>
        )
    }

    const getTimeslots = () => {

        // render timeslots options
        // spacing is the amount of time between each beginning timeslot value
        let spacing = 1800;
        let length = (duration / 60 / 60);

        // use dealership hours of operation if applicable
        // set startHour to 5:00am and endHour to 12:00am if no operating hours are found
        let endHour = 24;
        let startHour = 5;
        let { end, start } = operatingHours || {};
        if(end && start) {

            // extract hour value from start and end operating times
            endHour = moment(end, 'HH:mm:ss').hours();
            startHour = moment(start, 'HH:mm:ss').hours();

            // convert ending hour to 24 if midnight is represented as hour 0
            if(endHour === 0) {
                endHour = 24;
            }
        }

        // set starting date and ending date use startHour and endHour
        let startDate = moment(targetDate).startOf('day').add(startHour, 'hours');
        let max = moment(targetDate).startOf('day').add(endHour + length, 'hours');

        // add a day to the max date if the end hour comes before the start hour
        // this means that the ending time is for the next day
        if(endHour < startHour) {
            max = max.add(1, 'days');
        }

        // create array of start values spaced out by "spacing" value
        // create array of timeslots starting at "start" date and ending at "max" date
        let range = (max.unix() - startDate.unix()) / 1800;
        let starts = [...new Array(range >= 0 ? range : 0)].map((_, index) => moment(startDate).add(index * spacing, 'seconds'));
        let slots = starts.map(date => {
            let end_date = moment(date).add(duration, 'seconds');
            return {
                start_date: date,
                end_date: end_date
            }
        })

        // filter down to acceptable timeslots found within the start->max window
        let timeslots = slots.filter(dates => {
            return dates.start_date <= max && dates.end_date <= max;
        });

        // available slots for user based on override state
        return (
            <LayerItem
            required={true}
            title={'Select a Timeslot'}
            childrenStyle={{
                paddingLeft: 0,
                paddingRight: 0
            }}>
                {timeslots.map((dates, index) => {
                    let available = isDateRangeAvailable(dates);
                    return (
                        <div
                        key={index}
                        className={`view-entry ${window.theme}`}
                        onClick={onTimeslotClick.bind(this, dates, available)}
                        style={{
                            marginBottom: 8,
                            borderRadius: 10,
                            border: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            {Views.entry({
                                title: `${dates.start_date.format('h:mma')} to ${dates.end_date.format('h:mma')}`,
                                subTitle: available ? `Timeslot for ${Utils.parseDuration(duration)}` : `Not Available for ${user.full_name}`,
                                icon: {
                                    path: `images/${available ? 'plus-button-blue-small' : 'prohibitory-red-small'}.png`
                                },
                                bottomBorder: false,
                                bottomBorder: false
                            })}
                        </div>
                    )
                })}
            </LayerItem>
        )
    }

    const fetchUsers = async () => {
        try {

            let { users } = await Request.get(utils, '/dealerships/', {
                type: 'availability',
                date: moment(targetDate).format('YYYY-MM-DD')
            })

            setUsers(users.map(entry => entry.user));
            setEntries(users.map(entry => {
                return {
                    ...entry,
                    user: User.create(entry.user)
                }
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this demo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = async () => {
        try {
            let { operating_hours, templates } = await Request.get(utils, '/leads/', {
                type: 'booking_targets',
                paging: false
            });

            // set operating hours for dealership and default feedback template
            setOperatingHours(operating_hours ? operating_hours.default : null);
            setFeedbackTemplates(templates.map(template => Feedback.Template.create(template)));

            // set default demo duration
            let preferences = utils.dealership.get().preferences;
            setDuration(preferences.default_demo_duration ? (preferences.default_demo_duration * 60 * 60) : 10800);

            // attach users if applicable
            let { assigned, partner, ride_along, trainee } = presetUsers;
            if(assigned || partner || ride_along || trainee) {
                setPartner(partner);
                setRideAlong(ride_along);
                setTrainee(trainee);
                setUser(assigned);
                setAssignmentsCollapsed(false);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the information for this window. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(typeof(onDateChange) === 'function') {
            onDateChange(targetDate);
        }
    }, [targetDate])

    useEffect(() => {
        if(typeof(onUserChange) === 'function') {
            onUserChange(user);
        }
    }, [user])

    useEffect(() => {
        fetchUsers();
    }, [override, targetDate]);

    useEffect(() => {
        setupTarget();
        utils.events.on('book_demo', 'dealership_preferences_update', setupTarget);
        return () => {
            utils.events.off('book_demo', 'dealership_preferences_update', setupTarget);
        }
    }, []);

    return (
        <>
        {getAssignments()}
        {getDemoDateSelector()}
        {getDurationSelect()}
        {getFeedbackTemplateSelector()}
        {getTimeslots()}
        </>
    )
}

export default BookDemo;
