import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { DealershipSelector } from 'managers/Dealerships.js';
import LottieView from 'views/Lottie.js';
import { PaymentsOverview } from 'managers/Users.js';
import Request from 'files/Request.js';
import { Spring } from '@react-spring/core';
import Task from 'classes/Task.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

const Sidebar = ({ active, activeDealership, content, onLogoutClick, onMobileClose, onClick, user, utils }) => {

    const [loading, setLoading] = useState(false);
    const [openMenus, setOpenMenus] = useState([]);
    const [springToggle, setSpringToggle] = useState(null);
    const [tasks, setTasks] = useState([]);

    const onCancelTask = task => {
        utils.alert.show({
            title: 'Cancel Task',
            message: 'Are you sure that you want to cancel this task? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelTaskConfirm(task);
                    return;
                }
            }
        });
    }

    const onCancelTaskConfirm = async task => {
        try {
            setLoading(task.id);
            await Utils.sleep(0.25);
            await Request.post(utils, '/utils/', {
                type: 'cancel_task',
                id: task.id
            });

            setLoading(false);
            setTasks(tasks => {
                return tasks.filter(prev_task => prev_task.id !== task.id);
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancellin this task. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadCounterClick = () => {

    }

    const onRemoveTask = index => {
        setTasks(tasks => {
            return update(tasks, {
                $splice: [[ index, 1 ]]
            });
        });
    }

    const onSelectDealership = () => {
        utils.layer.open({
            id: 'dealership_selector',
            Component: DealershipSelector
        });
    }

    const onToggleMenu = key => {
        if(openMenus.includes(key)) {
            setOpenMenus(openMenus => update(openMenus, {
                $splice: [
                    [openMenus.indexOf(key), 1]
                ]
            }));
            return;
        }
        setOpenMenus(openMenus => update(openMenus, {
            $push: [key]
        }));
    }

    const onUserComponentClick = evt => {
        utils.sheet.show({
            sort: false,
            items: [{
                key: 'payments',
                title: 'Credits and Payments',
                style: 'default'
            },{
                key: 'profile',
                title: 'My Profile',
                style: 'default'
            },{
                key: 'logout',
                title: 'Logout',
                style: 'default'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            switch(key) {
                case 'logout':
                onLogoutClick();
                break;

                case 'payments':
                utils.layer.open({
                    id: `payments_overview_${user.user_id}`,
                    abstract: Abstract.create({
                        type: 'user',
                        object: user
                    }),
                    Component: PaymentsOverview
                })
                break;

                case 'profile':
                utils.alert.aft({
                    title: 'Just a Second',
                    message: `To provide the best experience possible, we've moved user management over to AFT. Please login to AFT to update your profile.`
                });
                break;
            }
        });
    }

    const onUserTaskProgressChange = data => {
        try {
            setTasks(tasks => {
                if(!tasks.find(task => task.id === data.id)) {
                    return update(tasks, {
                        $push: [data]
                    });
                }
                return tasks.map(task => {
                    return task.id === data.id ? data : task;
                });
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const isItemRestricted = item => {
        return utils.user.get().level > item.level;
    }

    const getContent = () => {
        if(!user) {
            return null;
        }
        return (
            <div
            key={'sidebar'}
            style={{
                backgroundColor: Utils.isMobile() && Appearance.colors.background(),
                position: 'relative',
                zIndex: 1100,
            }}>
                <nav className={'main-navbar navbar align-items-stretch navbar-light flex-md-nowrap p-0 d-lg-none d-md-none d-sm-none d-flex text-left'}>
                    <a className={'toggle-sidebar d-sm-inline d-md-none d-lg-none'}>
                        <img
                        src={`images/close-button-light-small.png`}
                        onClick={onMobileClose}
                        style={{
                            height: 30,
                            objectFit: 'contain',
                            width: 30
                        }} />
                    </a>
                </nav>
                <div
                className={`nav-wrapper ${window.theme}`}
                style={{
                    backgroundColor: Appearance.colors.transparent
                }}>
                    <ul className={'nav flex-column py-md-0 d-block'}>
                        <div
                        className={'pt-0 pt-md-3'}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        	paddingBottom: 25,
                            paddingLeft: 15,
                            paddingRight: 15,
                        	paddingTop: 25,
                            textAlign: 'center'
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={require('files/lottie/logo-desktop.json')}
                            style={{
                                height: 175,
                                width: 175
                            }}/>
                            <span style={{
                                color: Appearance.colors.text(),
                                fontSize: 18,
                                fontWeight: 800
                            }}>{getTitle()}</span>
                            <span style={{
                                color: Appearance.colors.subText(),
                                fontSize: 14,
                                fontWeight: 600
                            }}>{getSubTitle()}</span>
                            {getTaskProgressComponents()}
                            {getUserComponent()}
                            {getSelector()}
                        </div>
                        {getContentComponents()}
                    </ul>
                    <div style={{
                        padding: '8px 12px 8px 12px',
                        textAlign: 'center'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            display: 'block'
                        }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                    </div>
                </div>
            </div>
        )
    }

    const getContentComponents = () => {

        // loop through targets and render components
        return content.map(item => {

            // determine if the item has subviews
            if(item.subViews) {

                // render a dropdown if at least one visible item if available
                let items = Object.values(item.subViews);
                if(items.length >= 1) {
                    return getItem({
                        ...item,
                        active: active && item.key === active.view,
                        items: items.map(subView => {
                            return {
                                ...subView,
                                active: active && item.key === active.view && active.subView === subView.key,
                                onClick: onClick
                            }
                        })
                    })
                }

                // render a singular item as a fallback if at least one panel was provided
                return items[0] && items[0].panels.length > 0 && getItem({
                    ...item,
                    active: active && active.view === item.key,
                    key: items[0].key,
                    onClick: onClick.bind(this, {
                        action: item.action,
                        subView: items[0].key,
                        view: item.key
                    })
                })
            }

            return getItem({
                ...item,
                active: active && active.view === item.key,
                onClick: onClick.bind(this, {
                    action: item.action,
                    view: item.key
                })
            })
        });
    }

    const getItem = ({ active, icon, items, key, onClick, title }) => {
        return (
            <div
            key={key}
            className={'cursor-pointer'}
            onClick={items ? onToggleMenu.bind(this, key) : onClick}
            onMouseEnter={setSpringToggle.bind(this, key)}
            onMouseLeave={setSpringToggle.bind(this, null)}
            style={{
                marginBottom: 12,
                paddingLeft: 20,
                paddingRight: Utils.isMobile() ? 20 : 0
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <Spring
                    config={{ mass: 1, tension: 180, friction: 8 }}
                    from={{ transform: 'scale(1)' }}
                    to={{ transform: springToggle === key ? 'scale(1.15)' : 'scale(1.0)' }}
                    toggle={springToggle === key ? true : false}>
                    {props => (
                        <div style={{
                            alignItems: 'center',
                            backgroundColor: active ? Appearance.colors.primary() : Appearance.colors.layerBackground(),
                            borderRadius: 20,
                            boxShadow: '-10px 10px 15px rgba(0,0,0,0.05)',
                            display: 'flex',
                            height: 40,
                            justifyContent: 'center',
                            marginRight: 12,
                            minHeight: 40,
                            minWidth: 40,
                            overflow: 'hidden',
                            padding: 10,
                            width: 40,
                            ...props
                        }}>
                            <img
                            src={`images/${icon}${active || window.theme === 'dark' ? '-white':''}.png`}
                            style={{
                                height: '100%',
                                objectFit: 'contain',
                                width: '100%'
                            }} />
                        </div>
                    )}
                    </Spring>

                    <span style={{
                        color: active ? Appearance.colors.primary() : Appearance.colors.text(),
                        flexGrow: 1,
                        fontSize: 14,
                        fontWeight: 500,
                        overflow: 'hidden',
                        paddingRight: 12,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                    }}>{title}</span>
                    <div style={{
                        backgroundColor: active ? Appearance.colors.primary() : Appearance.colors.transparent,
                        borderRadius: 1.5,
                        height: 25,
                        overflow: 'hidden',
                        width: 3
                    }} />
                </div>
                {items && openMenus.includes(key) && (
                    <div style={{
                        backgroundColor: Appearance.colors.layerBackground(),
                        borderRadius: 10,
                        boxShadow: '-10px 10px 15px rgba(0,0,0,0.025)',
                        marginLeft: 30,
                        marginTop: 8,
                        overflow: 'hidden'
                    }}>
                        {items.map((item, index) => {
                            return (
                                <div
                                key={item.key}
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%'
                                }}>
                                    <a
                                    className={`dropdown-item ${window.theme}`}
                                    onClick={() => {
                                        setOpenMenus([]);
                                        item.onClick({
                                            action: item.action,
                                            level: item.level,
                                            subView: item.key,
                                            view: key
                                        });
                                    }}
                                    style={{
                                        borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                        fontSize: 13,
                                        ...getLabelStyles(item)
                                    }}>{item.title}</a>
                                    {isItemRestricted(item) && (
                                        <img
                                        src={'images/lock-icon-small-red.png'}
                                        style={{
                                            height: 20,
                                            marginRight: 12,
                                            objectFit: 'contain',
                                            width: 20
                                        }} />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const getLabelStyles = item => {
        if(isItemRestricted(item)) {
            return {
                color: Appearance.colors.red,
                fontWeight: 600
            };
        }
        return {
            color: item.active ? Appearance.colors.primary() : Appearance.colors.text(),
            fontWeight: 400
        }
    }

    const getSelector = () => {
        let canChangeDealership = user.level < User.levels.get().dealer;
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginTop: 12,
                width: '100%'
            }}>
                {canChangeDealership && (
                    <Button
                    color={'primary'}
                    label={'View Other Dealerships'}
                    onClick={onSelectDealership}
                    type={'large'} />
                )}
                <div style={{
                    backgroundColor: Appearance.colors.softBorder(),
                    height: 1,
                    marginTop: canChangeDealership ? 12 : 0,
                    width: 50
                }} />
            </div>
        )
    }

    const getSubTitle = () => {
        if(activeDealership) {
            return activeDealership.address ? activeDealership.address.street_address_1 : 'Address Not Available';
        }
    }

    const getTaskModifierButton = (task, index) => {
        if(task.complete) {
            return (
                <img
                className={'text-button'}
                src={'images/close-button-light-small.png'}
                onClick={onRemoveTask.bind(this, index)}
                style={{
                    height: 20,
                    marginLeft: 8,
                    objectFit: 'contain',
                    width: 20
                }}/>
            )
        }
        return (
            <img
            className={'text-button'}
            src={'images/red-x-icon.png'}
            onClick={onCancelTask.bind(this, task)}
            style={{
                height: 20,
                marginLeft: 8,
                objectFit: 'contain',
                width: 20
            }}/>
        )
    }

    const getTaskProgressValue = task => {
        switch(task.category) {
            case Task.categories.lead_import:
            if(task.complete) {
                return `${Utils.softNumberFormat(task.total)} ${task.total === 1 ? 'lead' : 'leads'} have been imported`;
            }
            return `${Utils.softNumberFormat(task.progress)} ${task.progress === 1 ? 'lead' : 'leads'} imported...`;

            case Task.categories.lead_address_conversion:
            if(task.complete) {
                return `${Utils.softNumberFormat(task.total)} ${task.total === 1 ? 'lead' : 'leads'} have been geocoded`;
            }
            return `${Utils.softNumberFormat(task.progress)} ${task.progress === 1 ? 'lead' : 'leads'} geocoded...`;

            default:
            return 'Unknown progress value';
        }
    }

    const getTaskProgressComponents = () => {
        if(tasks.length === 0) {
            return null;
        }
        return (
            <div style={{
                marginTop: 20,
                paddingLeft: 15,
                paddingRight: 15,
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    border: `1px solid ${Appearance.colors.softBorder()}`,
                    textAlign: 'left',
                    width: '100%'
                }}>
                    {tasks.map((task, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== tasks.length - 1,
                                icon: {
                                    className: task.complete ? null : 'rotate',
                                    path: task.complete ? 'images/checkmark-button-green-small.png' : 'images/task-progress-light-grey.png'
                                },
                                key: index,
                                loading: loading === task.id,
                                rightContent: getTaskModifierButton(task, index),
                                subTitle: getTaskProgressValue(task),
                                title: getTaskTitle(task)
                            })
                        )
                    })}
                </div>
            </div>
        )
    }

    const getTaskTitle = task => {
        switch(task.category) {
            case Task.categories.lead_import:
            return task.complete ? 'Lead Import Complete' : 'Lead Import In Progress';

            case Task.categories.lead_address_conversion:
            return task.complete ? 'Lead Geocoding Complete' : 'Lead Geocoding In Progress';

            default:
            return 'Unknown Task';
        }
    }

    const getTitle = () => {
        if(activeDealership) {
            return activeDealership.name;
        }
    }

    const getUserComponent = () => {
        if(!user) {
            return null;
        }
        return (
            <>
            <div style={{
                backgroundColor: Appearance.colors.softBorder(),
                height: 1,
                marginTop: 20,
                width: 50
            }} />
            <div style={{
                paddingBottom: 0,
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
                textAlign: 'left',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    border: `1px solid ${Appearance.colors.softBorder()}`
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: user.avatar
                        },
                        onClick: onUserComponentClick,
                        subTitle: User.levels.toText(user.level),
                        title: user.full_name
                    })}
                </div>
            </div>
            </>
        )
    }

    const connectToSockets = async () => {
        try {
            if(user) {
                await utils.sockets.persistOn('tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            if(user) {
                await utils.sockets.off('tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        connectToSockets();
        return disconnectFromSockets;
    }, [user]);

    return getContent();
}

export default Sidebar;
