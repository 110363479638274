import Lead from 'classes/Lead.js';
import User from 'classes/User.js';

import { AppointmentsOverview, MarketingAverageCallTime, MarketingDirectorStats, ReportPanel, ReportProgramInteractions } from 'managers/Reports.js';
import { CustomerFeedbackResponses, DemosCalendar, DemosList, FeedbackTemplates } from 'managers/Demos.js';
import { CallLogsCalendar, CallEmailLogs, Leads, LeadBoard, LeadImportHistory, LeadLocations } from 'managers/Leads.js';
import { Dealerships, DealershipLocationsMap, EventTypesList, LeadScriptsList, LeadSubTypesList, LeadTypesList, StatusCodesList } from 'managers/Dealerships.js';
import { Notifications, SystemEvents, Users, UsersAvailabilityCalendar, UserGroups } from 'managers/Users.js';
import { Programs, ProgramTemplates, UserProgramEnrollment } from 'managers/Programs.js';

export const getContent = utils => {
    try {

        // declare user and prepare general layout
        let user = utils.user.get();
        let dashboard = [{
            key: 'demos_calendar',
            Component: DemosCalendar
        },{
            key: 'demos',
            Component: DemosList.bind(this, {
                cache_key: 'general',
                category: 'demos'
            })
        },{
            key: 'demo_requests',
            Component: DemosList.bind(this, {
                cache_key: 'general',
                category: 'demo_requests'
            })
        },{
            key: 'leads',
            title: 'Leads',
            Component: Leads.bind(this, {
                id: 'leads',
                name: 'Leads',
                props: {
                    cache_key: 'general',
                    manage_duplicates: true
                }
            })
        }]

        // determine which type of user account is requesting panel content
        switch(user.level) {
            case User.levels.get().marketing_director:
            dashboard = [{
                key: 'demos_calendar',
                Component: DemosCalendar
            },{
                key: 'assigned_leads',
                permissions: ['leads.list.assigned'],
                Component: Leads.bind(this, {
                    id: 'assigned_leads',
                    name: 'Assigned Leads',
                    props: {
                        cache_key: 'assigned',
                        assignments: true
                    }
                })
            },{
                key: 'leads',
                Component: Leads.bind(this, {
                    id: 'leads',
                    name: 'Leads',
                    permissions: ['leads.list.general'],
                    props: {
                        cache_key: 'general_marketing',
                        exclude_status_codes: [ Lead.status.get().assigned ],
                        manage_duplicates: true
                    }
                })
            },{
                key: 'lead_scripts_list',
                permissions: ['leads.list.scripts'],
                Component: LeadScriptsList
            }];
            break;
        }

        // create user lists and allow visibility for levels with less access than current user
        let levels = [{
            key: 'admin',
            level: User.levels.get().admin,
            title: 'Administrators',
            shortName: 'Admin'
        },{
            key: 'region_director',
            level: User.levels.get().region_director,
            title: 'Region Directors',
            shortName: 'Region Director'
        },{
            key: 'division_director',
            level: User.levels.get().division_director,
            title: 'Division Directors',
            shortName: 'Division Director'
        },{
            key: 'dealer',
            level: User.levels.get().dealer,
            title: 'Dealers',
            shortName: 'Dealer'
        },{
            key: 'booking_coordinator',
            level: User.levels.get().booking_coordinator,
            title: 'Booking Coordinators',
            shortName: 'Booking Coordinator',
            permissions: ['users.list.booking_coordinators'],
        },{
            key: 'marketing_director',
            level: User.levels.get().marketing_director,
            title: 'Marketing Directors',
            shortName: 'Marketing Director',
            permissions: ['users.list.marketing_directors'],
        },{
            key: 'safety_advisor',
            level: User.levels.get().safety_advisor,
            title: 'Safety Advisors',
            shortName: 'Safety Advisor',
            permissions: ['users.list.safety_advisors'],
        },{
            key: 'safety_associate',
            level: User.levels.get().safety_associate,
            title: 'Safety Associates',
            shortName: 'Safety Associate',
            permissions: ['users.list.safety_associates'],
        }];
        
        return {
            dashboard: {
                key: 'dashboard',
                title: 'Dashboard',
                icon: 'navigation-icon-dashboard',
                panels: dashboard
            },
            ...user.level <= User.levels.get().dealer && {
                dealerships: {
                    key: 'dealerships',
                    title: 'Dealerships',
                    icon: 'navigation-icon-dealerships',
                    panels: [{
                        key: 'dealerships',
                        Component: Dealerships
                    },{
                        key: 'event_types_list',
                        Component: EventTypesList
                    },{
                        key: 'status_codes_list',
                        Component: StatusCodesList
                    }]
                }
            },
            ...user.level === User.levels.get().dealer && {
                dealership_profile: {
                    key: 'dealership_profile',
                    title: 'My Dealership',
                    icon: 'navigation-icon-dealerships'
                }
            },
            dialer: {
                icon: 'navigation-icon-dialer',
                key: 'dialer',
                title: 'Dialer',
                permissions: ['dialer']
            },
            demos: {
                key: 'demos',
                title: 'Demos and Requests',
                icon: 'navigation-icon-demos',
                subViews: {
                    browse: {
                        key: 'browse',
                        title: 'Browse',
                        panels: [{
                            key: 'demos',
                            permissions: ['demos.list.all'],
                            Component: DemosList.bind(this, {
                                cache_key: 'general',
                                category: 'demos'
                            })
                        },{
                            key: 'demo_requests',
                            permissions: ['demo_requests.list.all'],
                            Component: DemosList.bind(this, {
                                cache_key: 'general',
                                category: 'demo_requests'
                            })
                        }]
                    },
                    feedback: {
                        key: 'feedback',
                        title: 'Feedback',
                        panels: [{
                            key: 'customer_feedback_responses',
                            permissions: ['demos.list.customer_responses'],
                            Component: CustomerFeedbackResponses
                        },{
                            key: 'feedback_templates',
                            Component: FeedbackTemplates,
                            visible: utils.user.get().level <= User.levels.get().dealer
                        }]
                    },
                    import: {
                        key: 'import',
                        title: 'Import',
                        permissions: ['demos.actions.import']
                    }
                }
            },
            leads: {
                key: 'leads',
                title: 'Leads',
                icon: 'navigation-icon-leads',
                subViews: {
                    new: {
                        key: 'new',
                        permissions: ['leads.actions.new'],
                        title: 'Add New'
                    },
                    browse: {
                        key: 'browse',
                        title: 'Browse',
                        panels: [{
                            key: 'lead_board',
                            permissions: ['leads.board'],
                            Component: LeadBoard
                        },{
                            key: 'leads',
                            permissions: ['leads.list.general'],
                            Component: Leads.bind(this, {
                                id: 'leads',
                                name: 'Leads',
                                props: {
                                    cache_key: 'general',
                                    manage_duplicates: true
                                }
                            })
                        },{
                            key: 'new_leads',
                            permissions: ['leads.list.new'],
                            Component: Leads.bind(this, {
                                id: 'new_leads',
                                name: 'New Leads',
                                filter_props: {
                                    status_codes: [Lead.status.get().new]
                                },
                                props: {
                                    cache_key: 'new'
                                }
                            })
                        },{
                            key: 'assigned_leads',
                            permissions: ['leads.list.assigned'],
                            Component: Leads.bind(this, {
                                id: 'assigned_leads',
                                name: 'Assigned Leads',
                                props: {
                                    cache_key: 'assigned',
                                    assignments: true
                                }
                            })
                        },{
                            key: 'follow_up_leads',
                            permissions: ['leads.list.follow_up'],
                            Component: Leads.bind(this, {
                                id: 'follow_up_leads',
                                name: 'Follow Up Leads',
                                props: {
                                    cache_key: 'follow_ups',
                                    follow_ups: true
                                }
                            })
                        },{
                            key: 'unreleased_leads',
                            permissions: ['leads.list.unreleased'],
                            visible: user.level <= User.levels.get().dealer,
                            Component: Leads.bind(this, {
                                id: 'unreleased_leads',
                                name: 'Unreleased Leads',
                                props: {
                                    cache_key: 'unreleased',
                                    released: false
                                }
                            })
                        },{
                            key: 'transfer_leads',
                            permissions: ['leads.list.transfers'],
                            visible: user.level <= User.levels.get().dealer,
                            Component: Leads.bind(this, {
                                id: 'transfer_leads',
                                name: 'Lead Transfers',
                                props: {
                                    cache_key: 'transfers',
                                    transfers: true
                                }
                            })
                        },{
                            key: 'archived_leads',
                            permissions: ['leads.list.archived'],
                            Component: Leads.bind(this, {
                                id: 'archived_leads',
                                name: 'Archived Leads',
                                props: {
                                    cache_key: 'archived',
                                    show_archived_only: true
                                }
                            })
                        },{
                            key: 'do_not_call_leads',
                            permissions: ['leads.list.do_not_call'],
                            Component: Leads.bind(this, {
                                id: 'do_not_call_leads',
                                name: 'Do Not Call Leads',
                                filter_props: {
                                    status_codes: [ Lead.status.get().do_not_call ]
                                },
                                props: {
                                    cache_key: 'do_not_call'
                                }
                            })
                        },{
                            key: 'out_of_service_area_leads',
                            permissions: ['leads.list.out_of_service_area'],
                            Component: Leads.bind(this, {
                                id: 'out_of_service_area_leads',
                                name: 'Out of Service Area Leads',
                                props: {
                                    cache_key: 'out_of_service_area',
                                    out_of_service_area: true
                                }
                            })
                        }]
                    },
                    calls_and_emails: {
                        key: 'calls_and_emails',
                        title: 'Calls and Emails',
                        panels: [{
                            key: 'call_logs_calendar',
                            title: 'Call and Email Assignments',
                            permissions: ['calls.calendar'],
                            Component: CallLogsCalendar
                        },{
                            key: 'call_email_logs',
                            permissions: ['calls.list.all'],
                            Component: CallEmailLogs.bind(this, {
                                upcoming: false
                            })
                        },{
                            key: 'upcoming_call_email_logs',
                            permissions: ['calls.list.all'],
                            visible: user.level <= User.levels.get().dealer,
                            Component: CallEmailLogs.bind(this, {
                                upcoming: true
                            })
                        },{
                            key: 'do_not_call_leads',
                            permissions: ['leads.list.do_not_call'],
                            Component: Leads.bind(this, {
                                id: 'do_not_call_leads',
                                name: 'Do Not Call Leads',
                                filter_props: {
                                    status_codes: [ Lead.status.get().do_not_call ]
                                },
                                props: {
                                    cache_key: 'do_not_call'
                                }
                            })
                        }]
                    },
                    imports: {
                        key: 'imports',
                        title: 'Imports',
                        panels: [{
                            key: 'lead_import_history',
                            permissions: ['leads.list.import_history'],
                            Component: LeadImportHistory
                        }]
                    },
                    resources: {
                        key: 'resources',
                        level: User.levels.get().dealer,
                        title: 'Resources',
                        panels: [{
                            key: 'lead_types_list',
                            permissions: ['leads.list.types'],
                            Component: LeadTypesList
                        },{
                            key: 'lead_sub_types_list',
                            permissions: ['leads.list.sub_types'],
                            Component: LeadSubTypesList
                        },{
                            key: 'lead_scripts_list',
                            permissions: ['leads.list.scripts'],
                            Component: LeadScriptsList
                        }]
                    },
                    unassign: {
                        key: 'unassign_leads',
                        level: User.levels.get().dealer,
                        title: 'Unassign',
                        permissions: ['leads.actions.unassign']
                    },
                }
            },
            locations: {
                key: 'locations',
                title: 'Locations',
                icon: 'navigation-icon-locations',
                permissions: ['leads.list.locations'],
                panels: [{
                    key: 'dealership_locations_map',
                    visible: user.level <= User.levels.get().dealer,
                    Component: DealershipLocationsMap
                },{
                    key: 'lead_locations',
                    Component: LeadLocations
                }]
            },
            programs_and_surveys: {
                key: 'programs_and_surveys',
                title: 'Programs and Surveys',
                icon: 'navigation-icon-programs',
                subViews: {
                    browse: {
                        key: 'browse',
                        title: 'Browse',
                        level: User.levels.get().dealer,
                        panels: [{
                            key: 'programs',
                            permissions: ['programs.list.all'],
                            Component: Programs.bind(this, {
                                id: 'programs',
                                title: 'Program',
                                props: {
                                    allow_sharing: false
                                }
                            })
                        },{
                            key: 'surveys',
                            permissions: ['programs.list.surveys'],
                            Component: Programs.bind(this, {
                                id: 'surveys',
                                title: 'Survey',
                                props: {
                                    allow_sharing: true
                                }
                            })
                        },{
                            key: 'user_program_enrollment',
                            permissions: ['programs.list.enrollment'],
                            Component: UserProgramEnrollment
                        }]
                    },
                    survey_monkey: {
                        key: 'survey_monkey',
                        title: 'Survey Monkey',
                        permissions: ['programs.survey_monkey']
                    },
                    templates: {
                        key: 'templates',
                        title: 'Templates',
                        level: User.levels.get().dealer,
                        permissions: ['programs.list.templates'],
                        panels: [{
                            key: 'program_templates',
                            Component: ProgramTemplates
                        }]
                    }
                }
            },
            reports: {
                key: 'reports',
                title: 'Reports',
                icon: 'navigation-icon-reports',
                subViews: {
                    demos: {
                        key: 'demos',
                        title: 'Demos',
                        level: User.levels.get().dealer,
                        panels: [{
                            key: 'appointments_overview',
                            permissions: ['reports.demos.appointment_overview'],
                            Component: AppointmentsOverview
                        }]
                    },
                    leads: {
                        key: 'leads',
                        title: 'Leads',
                        level: User.levels.get().dealer,
                        panels: [{
                            key: 'lead_credit',
                            permissions: ['reports.leads.credit'],
                            Component: ReportPanel.bind(this, {
                                id: 'lead_credit'
                            })
                        },{
                            key: 'lead_daily_follow_up',
                            permissions: ['reports.leads.follow_ups'],
                            Component: ReportPanel.bind(this, {
                                id: 'lead_daily_follow_up'
                            })
                        },{
                            key: 'lead_pending',
                            permissions: ['reports.leads.pending'],
                            Component: ReportPanel.bind(this, {
                                id: 'lead_pending'
                            })
                        },{
                            key: 'lead_affiliate_leads',
                            permissions: ['reports.leads.affiliates'],
                            Component: ReportPanel.bind(this, {
                                id: 'lead_affiliate_leads'
                            })
                        }]
                    },
                    marketing: {
                        key: 'marketing',
                        title: 'Marketing',
                        level: User.levels.get().dealer,
                        panels: [{
                            key: 'marketing_average_call_time',
                            permissions: ['reports.marketing.call_times'],
                            Component: MarketingAverageCallTime
                        },{
                            key: 'marketing_director_stats',
                            permissions: ['reports.marketing.statistics'],
                            Component: MarketingDirectorStats
                        }]
                    },
                    programs: {
                        key: 'programs',
                        title: 'Programs and Surveys',
                        level: User.levels.get().dealer,
                        panels: [{
                            capabilities: ['global_data.program_interactions_report'],
                            key: 'report_program_interactions',
                            permissions: ['reports.programs.program_interactions'],
                            Component: ReportProgramInteractions
                        },,{
                            key: 'user_program_registrations',
                            permissions: ['reports.programs.associate_registrations'],
                            Component: ReportPanel.bind(this, {
                                id: 'user_program_registrations'
                            })
                        },{
                            key: 'program_registrations',
                            permissions: ['reports.programs.registrations'],
                            Component: ReportPanel.bind(this, {
                                id: 'program_registrations'
                            })
                        }]
                    },
                    users: {
                        key: 'users',
                        title: 'Users',
                        level: User.levels.get().dealer,
                        panels: [{
                            key: 'just_the_facts',
                            permissions: ['reports.users.just_the_facts'],
                            Component: ReportPanel.bind(this, {
                                id: 'just_the_facts'
                            })
                        },{
                            key: 'marketing_director_progress_overview',
                            permissions: ['reports.users.marketing_director_progress_overview'],
                            Component: ReportPanel.bind(this, {
                                id: 'marketing_director_progress_overview'
                            })
                        },{
                            key: 'safety_advisor_progress_overview',
                            permissions: ['reports.users.safety_advisor_progress_overview'],
                            Component: ReportPanel.bind(this, {
                                id: 'safety_advisor_progress_overview'
                            })
                        }]
                    }
                }
            },
            users: {
                key: 'users',
                title: 'Users and Groups',
                icon: 'navigation-icon-users',
                subViews: {
                    accounts: {
                        key: 'accounts',
                        title: 'Accounts',
                        panels: levels.filter(entry => {
                            return entry.level >= utils.user.get().level;
                        }).map(entry => {
                            return {
                                key: `${entry.key}_users`,
                                permissions: entry.permissions,
                                Component: Users.bind(this, {
                                    level: entry.level,
                                    title: entry.title,
                                    shortName: entry.shortName,
                                    panelID: `${entry.key}_users`
                                })
                            }
                        })
                    },
                    availability: {
                        key: 'availability',
                        title: 'Availability',
                        permissions: ['users.list.availability'],
                        panels: [{
                            key: 'users_availability_calendar',
                            Component: UsersAvailabilityCalendar
                        }]
                    },
                    groups: {
                        key: 'groups',
                        title: 'Groups',
                        level: User.levels.get().dealer,
                        permissions: ['users.list.groups'],
                        panels: [{
                            key: 'user_groups',
                            Component: UserGroups
                        }]
                    },
                    notifications: {
                        key: 'notifications',
                        title: 'Notifications',
                        permissions: ['users.list.notifications'],
                        panels: [{
                            key: 'notifications',
                            Component: Notifications
                        }]
                    },
                    system_events: {
                        key: 'system_events',
                        title: 'System Events',
                        permissions: ['users.list.system_events'],
                        panels: [{
                            key: 'system_events',
                            Component: SystemEvents
                        }]
                    }
                }
            },
            ...user.level <= User.levels.get().dealer && {
                settings: {
                    key: 'settings',
                    title: 'Settings',
                    icon: 'navigation-icon-settings',
                    permissions: ['settings']
                }
            }
        }

    } catch(e) {
        return {};
    }
}
