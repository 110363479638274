import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import { LayerItem } from 'structure/Layer.js';

const Checkbox = ({ checked, color, enabled, onChange, style }) => {

    const [active, setActive] = useState(checked || false);

    const onBoxChange = evt => {
        if(enabled === false) {
            return;
        }
        let next = !active;
        setActive(next);
        if(typeof(onChange) === 'function') {
            onChange(next, evt);
        }
    }

    useEffect(() => {
        setActive(checked || false);
    }, [checked]);

    return (
        <div
        className={enabled === false ? '' : 'text-button'}
        onClick={onBoxChange}
        style={{
            backgroundColor: Appearance.colors.background(),
            border: `1px solid ${Appearance.colors.softBorder()}`,
            borderRadius: 5,
            height: 20,
            minHeight: 20,
            minWidth: 20,
            opacity: enabled === false ? 0.25 : 1,
            overflow: 'hidden',
            padding: 4,
            width: 20,
            ...style
        }}>
            {active && (
                <div style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 10,
                    overflow: 'hidden',
                    backgroundColor: color || Appearance.colors.primary()
                }}/>
            )}
        </div>
    )
}
export default Checkbox;

export const CheckboxWrapper = ({ children, collapsed, items, onChange, onVisibilityChange, style, title }) => {

    const onCheckboxChange = (index, checked) => {
        try {
            let nextItems = update(items, {
                [index]: {
                    selected: {
                        $set: checked
                    }
                }
            });
            if(typeof(onChange) === 'function') {
                let selectedIDs = nextItems.filter(item => {
                    return item.selected === true;
                }).map(item => {
                    return item.id;
                });
                onChange({
                    ids: selectedIDs,
                    items: nextItems
                });
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    return (
        <LayerItem
        title={title}
        collapsed={collapsed}
        headerStyle={Appearance.textStyles.title()}
        onVisibilityChange={onVisibilityChange}
        style={{
            width: '100%',
            ...style
        }}>
            {children}
            {items.map((item, index) => {
                return (
                    <div
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: index !== items.length - 1 ? 8 : 12
                    }}>
                        <Checkbox
                        checked={item.selected}
                        onChange={onCheckboxChange.bind(this, index)}
                        style={{
                            marginRight: 8
                        }}/>
                        <span style={{
                            ...Appearance.textStyles.subTitle()
                        }}>{`${item.title}`}</span>
                        {item.badge > 1 && (
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                marginLeft: 4,
                                fontWeight: 600,
                                color: Appearance.colors.text()
                            }}>{`(${item.badge})`}</span>
                        )}
                    </div>
                )
            })}
        </LayerItem>
    )
}
