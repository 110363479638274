import React, { useEffect, useState } from 'react';
import Appearance from 'styles/Appearance.js';

const PermissionsContainer = ({ children, permission, style, utils }) => {

    const [disabled, setDisabled] = useState(null);

    const onIconClick = () => {
        utils.user.permissions.reject();
    }

    const onPermissionsChange = data => {
        setDisabled(permission === '__manual_overide' || data.values[permission] === false);
    }

    const getContent = () => {
        if(disabled === true) {
            return (
                <div style={{
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 15,
                    overflow: 'hidden',
                    position: 'relative'
                }}>
                    <div style={{
                        filter: 'blur(10px)',
                        opacity: 0.25,
                        transform: 'translateZ(0)'
                    }}>
                        <div style={style}>
                            {children}
                        </div>
                    </div>
                    <div style={{
                        alignItems: 'center',
                        backgroundColor: window.theme === 'dark' ? Appearance.colors.dim : 'rgba(0,0,0,0.05)',
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '100%'
                    }}>
                        <img 
                        className={'text-button'}
                        onClick={onIconClick}
                        src={'images/grey-x-icon.png'}
                        style={{
                            height: 45,
                            width: 45
                        }} />
                    </div>
                </div>
            );
        }
        return children; 
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('users', `on_permissions_change_${utils.user.get().user_id}`, onPermissionsChange);
        } catch(e) {
            console.error(e.message)
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('users', `on_permissions_change_${utils.user.get().user_id}`, onPermissionsChange);
        } catch(e) {
            console.error(e.message)
        }
    }

    useEffect(() => {
        connectToSockets();
        setDisabled(permission === '__manual_overide' || utils.user.permissions.get(permission) === false);
        return disconnectFromSockets;
    }, [permission]);

    return getContent();
}

export default PermissionsContainer;