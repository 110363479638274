import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { UserDetails } from 'managers/Users.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const MultipleUserLookupField = ({ onChange, users, utils }) => {

    const [selectedUsers, setSelectedUsers] = useState(users || []);

    const onAddUser = user => {
        if(!user) {
            return;
        }
        setSelectedUsers(users => {
            return update(users, {
                $push: [user]
            });
        })
    }

    const onRemoveUser = (index, e) => {
        e.stopPropagation();
        setSelectedUsers(users => {
            return update(users, {
                $splice: [[
                    index, 1
                ]]
            });
        })
    }

    const onUserClick = user => {
        utils.layer.open({
            abstract: Abstract.create({
                object: user,
                type: 'users'
            }),
            Component: UserDetails,
            id: `user_details_${user.user_id}`,
            permissions: ['users.details']
        })
    }

    useEffect(() => {
        if(typeof(onChange) === 'function') {
            onChange(selectedUsers);
        }
    }, [selectedUsers])

    return (
        <>
        <UserLookupField
        utils={utils}
        useDelay={true}
        icon={'users'}
        preserveResult={false}
        placeholder={'Search by first or last name...'}
        onChange={onAddUser}
        containerStyle={{
            width: '100%'
        }}/>
        {selectedUsers.length > 0 && (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%',
                marginTop: 8,
                textAlign: 'left'
            }}>
                {selectedUsers.sort((a,b) => {
                    if(a.last_name === b.last_name) {
                        return a.first_name.localeCompare(b.first_name);
                    }
                    return a.last_name.localeCompare(b.last_name);
                }).map((user, index, users) => {
                    return (
                        Views.entry({
                            key: index,
                            title: user.full_name,
                            subTitle: user.phone_number || 'No phone number provided',
                            icon: {
                                path: user.avatar
                            },
                            onClick: onUserClick.bind(this, user),
                            firstItem: index === 0,
                            singleItem: users.length === 1,
                            lastItem: index === users.length - 1,
                            bottomBorder: index !== users.length - 1,
                            rightContent: (
                                <img
                                className={'text-button'}
                                src={'images/red-x-icon.png'}
                                onClick={onRemoveUser.bind(this, index)}
                                style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: 'contain',
                                    marginLeft: 8
                                }} />
                            )
                        })
                    )
                })}
            </div>
        )}
        </>
    )
}

export default MultipleUserLookupField;
