import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';

const DualDatePickerField = props => {

    const { blockStyle, dateFormat, deselect = false, endDate, highlightDates, onEndDateChange, onStartDateChange, startDate, style, utils } = props;

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const onFieldClick = field => {
        utils.datePicker.show({
            date: field === 'start' ? selectedStartDate : selectedEndDate,
            ...props,
            onDateChange: date => onDateChange(field, date),
            ...blockStyle && {
                highlightDates: highlightDates || [selectedStartDate, selectedEndDate]
            }
        })
    }

    const onDateChange = (field, date, evt) => {

        // stop event propagation if applicable
        if(evt && typeof(evt.stopPropagation) === 'function') {
            evt.stopPropagation();
        }

        // determine if the changed field is the start field
        if(field === 'start') {
            setSelectedStartDate(date);
            if(typeof(onStartDateChange) === 'function') {
                onStartDateChange(date && moment(date));
            }
        }

        // determine if the changed field is the end field
        if(field === 'end') {
            setSelectedEndDate(date);
            if(typeof(onEndDateChange) === 'function') {
                onEndDateChange(date && moment(date));
            }
        }

        // run date validation against optional block styling
        runBlockStyle(date);
    }

    const runBlockStyle = date => {
        if(!blockStyle) {
            return;
        }
        switch(blockStyle) {
            case 'week':

            let startDate = moment(date).startOf('week');
            let endDate = moment(date).endOf('week');

            setSelectedStartDate(startDate)
            setSelectedEndDate(endDate);

            if(typeof(onStartDateChange) === 'function') {
                onStartDateChange(startDate);
            }
            if(typeof(onEndDateChange) === 'function') {
                onEndDateChange(endDate);
            }
            break;
        }
    }

    useEffect(() => {
        setSelectedStartDate(startDate && moment(startDate));
        setSelectedEndDate(endDate && moment(endDate));
    }, [endDate, startDate]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            ...style
        }}>
            <div
            onClick={onFieldClick.bind(this, 'start')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                height: 35,
                minWidth: 150,
                paddingRight: selectedStartDate ? 8 : 12,
                position: 'relative'
            }}>
                <span style={{
                    color: Appearance.colors.text(),
                    fontSize: 12,
                    fontWeight: 500,
                    textAlign: 'center',
                    width: '100%'
                }}>{selectedStartDate ? moment(selectedStartDate).format(dateFormat || 'MMMM Do, YYYY') : 'Start Date'}</span>
                {deselect === true && selectedStartDate && (
                    <img
                    className={'text-button'}
                    onClick={onDateChange.bind(this, 'start', null)}
                    src={'images/red-x-icon.png'}
                    style={{
                        height: 20,
                        justifySelf: 'flex-end',
                        objectFit: 'contain',
                        width: 20
                    }} />
                )}
            </div>
            <img
            src={'images/next-arrow-grey.png'}
            style={{
                height: 35,
                minHeight: 35,
                minWidth: 35,
                objectFit: 'contain',
                padding: 10,
                width: 35
            }} />
            <div
            onClick={onFieldClick.bind(this, 'end')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.textField(),
                borderColor: Appearance.colors.divider(),
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                height: 35,
                minWidth: 150,
                paddingRight: selectedStartDate ? 8 : 12,
                position: 'relative'
            }}>
                <span style={{
                    color: Appearance.colors.text(),
                    fontSize: 12,
                    fontWeight: 500,
                    textAlign: 'center',
                    width: '100%'
                }}>{selectedEndDate ? moment(selectedEndDate).format(dateFormat || 'MMMM Do, YYYY') : 'End Date'}</span>
                {deselect === true && selectedEndDate && (
                    <img
                    className={'text-button'}
                    onClick={onDateChange.bind(this, 'end', null)}
                    src={'images/red-x-icon.png'}
                    style={{
                        height: 20,
                        justifySelf: 'flex-end',
                        objectFit: 'contain',
                        width: 20
                    }} />
                )}
            </div>
        </div>
    )
}
export default DualDatePickerField;
