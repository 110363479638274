import React, { useState, useEffect, useRef } from 'react';
import 'styles/full_calendar.css';

import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';

export const WeekCalendar = ({ defaultDate, draggable, events, onEventClick, onEventDrop, onEventRender, onEventResize, propsKey }) => {

    const ref = useRef(null);
    const [calEvents, setCalEvents] = useState(null);

    const onClick = ({ event }) => {
        if(typeof(onEventClick) === 'function') {
            onEventClick(propsKey ? event.extendedProps[propsKey] : event.extendedProps.event);
        }
    }

    useEffect(() => {
        if(defaultDate && ref.current) {
            ref.current._calendarApi.gotoDate(moment(defaultDate).toDate())
        }
    }, [defaultDate]);

    useEffect(() => {
        setCalEvents(events);
    }, [events]);

    return (
        <FullCalendar
        {...draggable && {
            selectable: true,
            editable: true,
            droppable: true,
            eventDurationEditable: true,
            eventDrop: onEventDrop,
            eventReceive: onEventDrop,
            eventDragMinDistance: 10,
            eventResize: onEventResize
        }}
        events={calEvents}
        eventClick={onClick}
        eventContent={object => {
            if(typeof(onEventRender) === 'function') {
                return onEventRender(propsKey ? object.event.extendedProps[propsKey] : object.event.extendedProps.event);
            }
            return object;
        }} 
        firstDay={1}
        initialDate={defaultDate ? defaultDate.toDate() : moment().toDate()}
        initialView={'dayGridWeek'}
        plugins={[ dayGridPlugin, interactionPlugin ]}
        ref={ref}
        themeSystem={'bootstrap4'} />
    )
}
