import React, { useState, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import Utils from 'files/Utils.js';

const RichTextEditor = ({ content, onChange, placeholder, utils, viewer = false }) => {

    const [editorState, setEditorState] = useState(null);

    const onEditorStateChange = state => {
        setEditorState(state);
        if(typeof(onChange) === 'function') {
            onChange(state);
        }
    }

    const setupEditor = () => {
        try {

            // create empty state for editor if target is new
            if(!content) {
                let state = EditorState.createEmpty();
                setEditorState(state);
                return;
            }

            // convert existing state from raw, its possible that the content provided is already an editor state
            if(content && content.blocks) {
                content = convertFromRaw(content);
                content = EditorState.createWithContent(content);
            }
            setEditorState(content);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing and presenting your content. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupEditor();
    }, [content]);

    return (
        <div style={{
            width: '100%'
        }}>
            <Editor
            editorClassName={`rt-editor-container ${window.theme} ${viewer ? 'read-only' : 'editable'}`}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            placeholder={placeholder}
            readOnly={viewer}
            toolbarClassName={`rt-editor-toolbar ${window.theme}`}
            toolbarHidden={viewer}
            toolbar={{
                options: Utils.isMobile() ? ['emoji', 'colorPicker', 'inline',  'textAlign', 'list'] : ['inline', 'emoji', 'colorPicker', 'textAlign', 'list'],
                blockType: {
                    inDropdown: false,
                    options: [ 'Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6' ]
                },
                colorPicker: {
                    icon: window.theme === 'dark' ? 'images/color-picker-icon-white.svg' : 'images/color-picker-icon-black.svg',
                    popupClassName: `rt-modal ${window.theme}`
                },
                emoji: {
                    icon: window.theme === 'dark' ? 'images/emoji-icon-white.svg' : 'images/emoji-icon-black.svg',
                    popupClassName: `rt-modal ${window.theme}`
                },
                fontSize: {
                    className: `rt-button ${window.theme}`,
                    dropdownClassName: `rt-modal ${window.theme}`,
                    icon: window.theme === 'dark' ? 'images/font-size-icon-white.svg' : 'images/font-size-icon-black.svg',
                },
                inline: {
                    inDropdown: false,
                    options: [ 'bold', 'italic', 'underline', 'strikethrough' ],
                    bold: { icon: window.theme === 'dark' ? 'images/bold-icon-white.svg' : 'images/bold-icon-black.svg' },
                    italic: { icon: window.theme === 'dark' ? 'images/italic-icon-white.svg' : 'images/italic-icon-black.svg' },
                    underline: { icon: window.theme === 'dark' ? 'images/underline-icon-white.svg' : 'images/underline-icon-black.svg' },
                    strikethrough: { icon: window.theme === 'dark' ? 'images/strikethrough-icon-white.svg' : 'images/strikethrough-icon-black.svg' }
                },
                list: {
                    unordered: { icon: window.theme === 'dark' ? 'images/ul-icon-white.svg' : 'images/ul-icon-black.svg' },
                    ordered: { icon: window.theme === 'dark' ? 'images/ol-icon-white.svg' : 'images/ol-icon-black.svg' },
                    indent: { icon: window.theme === 'dark' ? 'images/indent-icon-white.svg' : 'images/indent-icon-black.svg' },
                    outdent: { icon: window.theme === 'dark' ? 'images/outdent-icon-white.svg' : 'images/outdent-icon-black.svg' }
                },
                textAlign: {
                    left: { icon: window.theme === 'dark' ? 'images/left-align-icon-white.svg' : 'images/left-align-icon-black.svg' },
                    center: { icon: window.theme === 'dark' ? 'images/center-align-icon-white.svg' : 'images/center-align-icon-black.svg' },
                    right: { icon: window.theme === 'dark' ? 'images/right-align-icon-white.svg' : 'images/right-align-icon-black.svg' },
                    options: [ 'left', 'center', 'right' ]
                }
            }} />
        </div>
    )
}

export default RichTextEditor;
