import React, { useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

const IconPickerField = ({ category, onChange, style, utils, value }) => {

    const [active, setActive] = useState(false);
    const [icons, setIcons] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(value);

    const onFieldClick = () => {
        setActive(active => !active);
    }

    const onIconChange = icon => {
        setSelectedIcon(icon);
        if(typeof(onChange) === 'function') {
            onChange(icon);
        }
    }

    const getFieldValue = () => {
        if(selectedIcon) {
            return selectedIcon.name;
        }
        return active ? 'Click to hide icons...' : 'Click to view icons...';
    }

    const fetchEventTypeIcons = async () => {
        try {
            let { icons } = await Request.get(utils, '/events/', { type: 'type_icons' });
            setIcons(icons);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of event type icons. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchIcons = () => {
        switch(category) {
            case 'event_type':
            fetchEventTypeIcons();
            break;

            case 'lead_type':
            fetchLeadTypeIcons();
            break;
        }
    }

    const fetchLeadTypeIcons = async () => {
        try {
            let { icons } = await Request.get(utils, '/dealerships/', { type: 'lead_type_icons' });
            setIcons(icons);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of lead type icons. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setSelectedIcon(value);
    }, [value])

    useEffect(() => {
        fetchIcons();
    }, []);

    return (
        <div style={{
            width: '100%',
            ...style
        }}>
            <div
            className={`dummy-field ${window.theme} ${active ? 'active' : ''}`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexGrow: 1,
                position: 'relative',
                height: 35
            }}>
                <span
                onClick={onFieldClick}
                className={'cursor-pointer'}
                style={{
                    flexGrow: 1,
                    borderWidth: 0,
                    fontSize: 12,
                    fontWeight: 500,
                    color: selectedIcon ? Appearance.colors.text() : Appearance.colors.subText(),
                    backgroundColor: Appearance.colors.transparent
                }}>{getFieldValue()}</span>
            </div>
            {active && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8,
                    padding: 6,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%'
                }}>
                    {icons.map((icon, index) => {
                        return (
                            <div
                            key={index}
                            className={'text-button'}
                            onClick={onIconChange.bind(this, icon)}
                            style={{
                                width: 40,
                                height: 40,
                                marginLeft: 4,
                                marginRight: 4,
                                marginTop: 4,
                                marginBottom: 4,
                                borderRadius: '50%',
                                overflow: 'hidden',
                                backgroundColor: selectedIcon && selectedIcon.id === icon.id ? Appearance.colors.primary() : Appearance.colors.grey()
                            }}>
                                <img
                                src={icon.url}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }} />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default IconPickerField;
