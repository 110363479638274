import React, { useEffect, useRef, useState } from 'react';
import { convertFromRaw, convertToRaw } from 'draft-js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Content from 'managers/Content.js';
import { EditorState } from 'draft-js';
import { FileHandler, getFileIconPath } from 'views/FilePickerField.js';
import LottieView from 'views/Lottie.js';
import Note from 'classes/Note.js';
import Request from 'files/Request.js';
import RichTextEditor from 'views/RichTextEditor.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const Notes = ({ abstract, permissions, utils }) => {

    const sorting = useRef({ 
        sort_key: 'date',
        sort_type: Content.sorting.type.descending
    });

    const [editing, setEditing] = useState(null);
    const [loading, setLoading] = useState('init');
    const [notes, setNotes] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAddNote = note => {
        setNotes(notes => {
            notes.unshift(note);
            return [...notes];
        });
    }

    const onDeleteNote = id => {
        utils.alert.show({
            title: 'Delete Note',
            message: 'Are you sure that you want to delete this note? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteNoteConfirm(id);
                    return;
                }
            }
        });
    }

    const onDeleteNoteConfirm = async id => {
        try {
            setLoading(id);
            await Request.post(utils, '/utils/', {
                id: id,
                type: 'delete_note'
            });

            setLoading(false);
            setNotes(notes => notes.filter(note => note.id !== id));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this note. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNoteClick = (note, evt) => {
        utils.sheet.show({
            items: [{
                key: 'edit',
                permissions: permissions && permissions.edit,
                title: 'Edit Note',
                style: 'default',
                visible: editing !== note.id
            },{
                key: 'delete',
                permissions: permissions && permissions.delete,
                title: 'Delete Note',
                style: 'destructive'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            switch(key) {
                case 'delete':
                onDeleteNote(note.id);
                break;

                case 'edit':
                setEditing(note.id);
                break;
            }
        });
    }

    const onReminderClick = (note, evt) => {

        // declare previous reminder for current user
        let user = utils.user.get();
        let reminder = note.reminders[user.user_id];

        // show option to remove existing reminder if applicable
        if(reminder) {
            utils.sheet.show({
                items: [{
                    key: 'cancel_reminder',
                    title: reminder && `Cancel Reminder for ${reminder.date.format('MMM Do, YYYY h:mm A')}`,
                    style: 'destructive'
                }],
                sort: false,
                target: evt.target,
                title: 'Remind me about this'
            }, key => {
                if(key === 'cancel_reminder') {
                    onRemoveReminder(note);
                    return;
                }
            });
            return;
        }
        
        // prepare list of reminder durations
        utils.sheet.show({
            items: [{
                key: '1_hour',
                title: `In 1 Hour (${moment().add(1, 'hours').format('h:mm A')})`,
                style: 'default'
            },{
                key: '3_hours',
                title: `In 3 Hours (${moment().add(3, 'hours').format('h:mm A')})`,
                style: 'default'
            },{
                key: 'tomorrow',
                title: `Tomorrow (${moment().add(1, 'days').format('MMM Do, YYYY')} 9:00 AM)`,
                style: 'default'
            },{
                key: 'next_week',
                title: `Next Week (${moment().add(1, 'weeks').format('MMM Do, YYYY')} 9:00 AM)`,
                style: 'default'
            }],
            sort: false,
            target: evt.target,
            title: 'Remind me about this'
        }, key => {
            if(key !== 'cancel') {
                onSetReminder(note, key);
                return;
            }
        });
    }

    const onRemoveFile = (note, attachment, evt) => {

        // stop event from propagating to root component
        evt.stopPropagation();

        // verify that user has permission to use this feature
        if(permissions && permissions.delete) {
            let match = permissions.delete.find(key => utils.user.permissions.get(key) === false);
            if(match) {
                utils.user.permissions.reject();
                return;
            }
        }

        // request confirmation to remove file
        utils.alert.show({
            title: 'Remove File',
            message: 'Are you sure that you want to remove this file? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveFileConfirm(note, attachment);
                    return;
                }
            }
        });
    }

    const onRemoveFileConfirm = async (note, attachment) => {
        try {

            // start loading and fetch user details
            setLoading(note.id);
            await Request.post(utils, '/utils/', {
                file_names: [attachment.file_name],
                id: note.id,
                type: 'remove_note_attachments'
            });

            // end loading and show user details layer
            setLoading(false);
            setNotes(notes => {
                return notes.map(n => {
                    if(n.id === note.id) {
                        n.attachments = n.attachments.filter(a => a.file_name !== attachment.file_name)
                    }
                    return n;
                });
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing the file. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRemoveReminder = async note => {
        try {

            // declare current user and set loading flag
            let user = utils.user.get();
            setLoading(note.id);

            // send request to server
            await Request.post(utils, '/utils/', {
                id: note.id,
                type: 'remove_note_reminder',
                user_id: user.user_id
            });

            // end loading and update local state for notes
            setLoading(false);
            setNotes(notes => {
                let index = notes.findIndex(n => n.id === note.id);
                return update(notes, {
                    [index]: {
                        reminders: {
                            $unset: [`${user.user_id}`]
                        }
                    }
                })
            });

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `We'll no longer send you a notification for this note`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing your reminder. ${e.message || 'An unknown error occurred'}` 
            });
        }
    }

    const onSetReminder = async (note, key) => {
        try {

            // declare current user and set loading flag
            let user = utils.user.get();
            setLoading(note.id);

            // send request to server
            let { reminder } = await Request.post(utils, '/utils/', {
                duration_code: key,
                id: note.id,
                type: 'set_note_reminder',
                user_id: user.user_id
            });

            // end loading and update local state for notes
            setLoading(false);
            setNotes(notes => {
                let index = notes.findIndex(n => n.id === note.id);
                let result = update(notes, {
                    [index]: {
                        reminders: {
                            [user.user_id]: {
                                $set: {
                                    ...reminder,
                                    date: moment.utc(reminder.date).local(),
                                    pending: true
                                }
                            }
                        }
                    }
                });
                console.log(result);
                return result;
            });

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `We'll send you a notification for this note on ${moment.utc(reminder.date).local().format('MMM Do, YYYY [at] h:mma')}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting your reminder. ${e.message || 'An unknown error occurred'}` 
            });
        }
    }

    const onUpdateNote = note => {

        // end editing for note
        setEditing(null);

        // update local state for note
        setNotes(notes => {
            let index = notes.find(n => n.id === note.id);
            return update(notes, {
                [index]: {
                    $set: note
                }
            });
        });
    }

    const onUserClick = async (note, evt) => {
        try {

            // prevent click from propagating to root elements
            evt.stopPropagation();

            // start loading and fetch user details
            setLoading(note.id);
            let user = await User.get(utils, note.user.user_id);

            // end loading and show user details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${user.user_id}`,
                permissions: ['users.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {

        // no additional logic is needed if no notes have been created
        if(notes.length === 0) {
            return null;
        }

        // declare current user and search filtered note targets
        let targets = getNotes();
        let user = utils.user.get();

        return (
            <div style={{
                padding: 12
            }}>
                <TextField
                icon={'search'}
                onChange={text => setSearchText(text && text.toLowerCase())} 
                placeholder={'Search for something...'}/>
                <div style={{
                    height: '100%',
                    overflowY: 'scroll',
                    marginTop: 12,
                    width: '100%'
                }}>
                    
                    {loading === 'init' && (
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: 15
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                            style={{
                                height: 50,
                                width: 50
                            }}/>
                        </div>
                    )}
                    {targets.map((note, index) => {

                        // declare previous reminder for current user
                        let reminder = note.reminders[user.user_id];
                        
                        return (
                            <div
                            key={note.id}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                marginBottom: index !== notes.length - 1 ? 12 : 0
                            }}>
                                {Views.entry({
                                    bottomBorder: true,
                                    hideOnClickArrow: true,
                                    icon: {
                                        onClick: onUserClick.bind(this, note),
                                        path: note.user.avatar
                                    },
                                    loading: loading === note.id,
                                    subTitle: note.date && Utils.formatDate(note.date),
                                    title: note.user.full_name,
                                    rightContent: (
                                        <div style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                            <img
                                            className={'text-button'}
                                            onClick={onReminderClick.bind(this, note)}
                                            src={reminder && reminder.pending ? 'images/reminder-icon-primary.png' : (window.theme === 'dark' ? 'images/reminder-icon-white.png' : 'images/reminder-icon-grey.png')}
                                            style={{
                                                height: 18,
                                                objectFit: 'contain',
                                                width: 18
                                            }} />
                                            {note.user.user_id === utils.user.get().user_id && (
                                                <img
                                                className={'text-button'}
                                                onClick={onNoteClick.bind(this, note)}
                                                src={'images/details-button-light-grey.png'}
                                                style={{
                                                    height: 18,
                                                    marginLeft: 8,
                                                    objectFit: 'contain',
                                                    width: 18
                                                }} />
                                            )}
                                        </div>
                                    )
                                })}
                                {editing === note.id && (
                                    <div style={{
                                        padding: 12
                                    }}>
                                        <NoteEditor 
                                        abstract={abstract}
                                        note={note}
                                        onEndEditing={setEditing.bind(this, null)}
                                        onUpdateNote={onUpdateNote.bind(this, note)}
                                        utils={utils} />
                                    </div>
                                )}
                                {editing !== note.id && (
                                    <>
                                    <RichTextEditor
                                    content={note.content}
                                    utils={utils}
                                    viewer={true}/>
                                    {note.attachments.length > 0 && (
                                        <div style={{
                                            borderTop: `1px solid ${Appearance.colors.divider()}`
                                        }}>
                                            {note.attachments.map((attachment, i, attachments) => {
                                                return (
                                                    Views.entry({
                                                        bottomBorder: i !== attachments.length - 1,
                                                        icon: { 
                                                            path: getFileIconPath(attachment.mime),
                                                            imageStyle: {
                                                                backgroundColor: Appearance.colors.transparent,
                                                                borderRadius: 0,
                                                                boxShadow: 'none',
                                                                objectFit: 'contain'
                                                            } 
                                                        },
                                                        key: i,
                                                        onClick: window.open.bind(this, attachment.url),
                                                        rightContent: (
                                                            Views.icon.right('red-x', onRemoveFile.bind(this, note, attachment))
                                                        ),
                                                        subTitle: attachment.metadata && attachment.metadata.description || 'No description was provided',
                                                        title: attachment.metadata && attachment.metadata.name || attachment.name
                                                    })
                                                )
                                            })}
                                        </div>
                                    )}
                                    </>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const getNotes = () => {

        // return notes as-is if no search text if set
        if(!searchText) {
            return notes;
        }

        return notes.filter(note => {

            // check for matches across the user full name and content message
            if(note.user.full_name.toLowerCase().includes(searchText) || note.message.toLowerCase().includes(searchText)) {
                return true;
            }

            // check for matches across the attachment name or file name
            let match = note.attachments.find(attachment => {
                if(attachment.metadata) {
                    let { description, name } = attachment.metadata;
                    return (description && description.toLowerCase().includes(searchText)) || (name && name.toLowerCase().includes(searchText));
                }
                return attachment.name.toLowerCase().includes(searchText);
            });
            return match ? true : false;
        });
    }

    const fetchNotes = async () => {
        try {
            let { notes } = await Request.get(utils, '/utils/', {
                target_id: abstract.getID(),
                target_type: abstract.type,
                type: 'notes_for_target',
                ...sorting.current
            });

            setLoading(false);
            setNotes(notes.map(note => Note.create(note)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your notes. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchNotes();
    },[]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={{
                borderBottom: notes.length > 0 && `1px solid ${Appearance.colors.divider()}`,
                padding: 12
            }}>
                <NoteEditor 
                abstract={abstract}
                onAddNote={onAddNote}
                permissions={permissions}
                utils={utils} />
            </div>
            {getContent()}
        </div>
    );
}

const NoteEditor = ({ abstract, note, onAddNote, onEndEditing, onUpdateNote, permissions, routed = false, utils }) => {

    const fileInput = useRef(null);

    const [content, setContent] = useState(null);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [removeFiles, setRemoveFiles] = useState([]);

    const onAddAttachments = async targets => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to server
                let { attachments } = await Request.post(utils, '/utils/', {
                    attachments: targets,
                    id: note.id,
                    type: 'add_note_attachments'
                });
    
                // update local attachments array with server results
                note.attachments = attachments;
                resolve();
    
            } catch(e) {
                reject(e);
            }
        })
    }

    const onAddFilesClick = () => {

        // verify that user has permission to use this feature
        if(permissions) {
            let target = note ? permissions.edit : permissions.new;
            let match = target && target.find(key => utils.user.permissions.get(key) === false);
            if(match) {
                utils.user.permissions.reject();
                return;
            }
        }

        // manually trigger file input to show file picker
        fileInput.current.value = null;
        fileInput.current.click();
    }

    const onConfigureFile = index => {
        FileHandler.configureFile(utils, files[index], result => {
            setFiles(files => {
                return update(files, {
                    [index]: {
                        $set: result
                    }
                })
            });
        });
    }

    const onFileChange = async ({ target }) => {

        // prevent moving forward if no files were selected
        if(target.files.length === 0) {
            return;
        }

        // parse selected files and update local state
        let results = await FileHandler.parse(target.files);
        setFiles(files => files.concat(results));
    }

    const onRemoveAttachments = async targets => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to server
                await Request.post(utils, '/utils/', {
                    file_names: targets.map(attachment => attachment.file_name),
                    id: note.id,
                    type: 'remove_note_attachments'
                });
    
                // update local attachments array with server results
                note.attachments = note.attachments.filter(attachment => {
                    return targets.find(target => target.file_name === attachment.file_name) ? false : true;
                });
                resolve();
    
            } catch(e) {
                reject(e);
            }
        });
    }

    const onRemoveFile = (file, index, evt) => {

        // prevent event from propagation up to root component
        evt.stopPropagation();

        // verify that user has permission to use this feature
        if(permissions && permissions.delete) {
            let match = permissions.delete.find(key => utils.user.permissions.get(key) === false);
            if(match) {
                utils.user.permissions.reject();
                return;
            }
        }

        // add file to queue of removed files
        setRemoveFiles(files => {
            files.push(file);
            return files;
        });

        // update list of visible files
        setFiles(files => {
            return update(files, {
                $splice: [[index, 1]]
            })
        });
    }

    const onSubmit = async () => {
        try {

            // verify that user has permission to use this feature
            if(permissions && permissions.new) {
                let match = permissions.new.find(key => utils.user.permissions.get(key) === false);
                if(match) {
                    utils.user.permissions.reject();
                    return;
                }
            }

            // prevent moving forward if no content has been provded
            if(!content) {
                throw new Error('Please write an update before submitting your note');
            }

            // convert editor state to raw content and prepare plain text message
            let raw = convertToRaw(content.getCurrentContent());
            let message = getUnstyledText(raw).trim();
            
            // prevent moving forward if a message was not provided
            if(message.length === 0) {
                throw new Error('Please write an update before submitting your note');
            }

            // update note if a note object was provided
            if(note) {

                // opening editing and set edits
                note.open();
                note.set({
                    content: raw,
                    message: message
                });

                // start loading and apply edits
                setLoading(true);
                await note.update(utils);

                // update attachments if at least one attachment was added
                let attachments = files.filter(file => file.data);
                if(attachments.length > 0) {
                    await onAddAttachments(attachments);
                }

                // remove attachments if at least one attachment has been queued
                if(removeFiles.length > 0) {
                    await onRemoveAttachments(removeFiles);
                }

                // end loading and notify subscribers that data has changed
                setLoading(false);
                if(typeof(onUpdateNote) === 'function') {
                    onUpdateNote(note);
                }
                return;
            }

            // create new note target
            let target = Note.new();
            target.date = moment.utc().local();
            target.client = utils.dealership.get();
            target.target_id = abstract.getID();
            target.target_type = abstract.type;
            target.user = utils.user.get();

            // opening editing and set edits for new note
            target.open();
            target.set({
                attachments: files,
                content: raw,
                message: message
            });
            
            // start loading and apply edits
            setLoading(true);
            await target.submit(utils);

            // end loading and notify subscribers that data has changed
            setLoading(false);
            if(typeof(onAddNote) === 'function') {
                onAddNote(target);
            }

            // notify parent that frame has submitted note, this is used for the mobile app
            if(window.ReactNativeWebView) {
                let payload = JSON.stringify({ id: target.id, type: 'new_note' });
                window.ReactNativeWebView.postMessage(payload);
            }

            // reset submission fields
            fileInput.current.value = null;
            setContent(null);
            setFiles([]);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${note ? 'updating' : 'creating'} your note. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getEditingControls = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 12,
                minWidth: 0,
                width: '100%'
            }}>
                <Button 
                color={'dark'}
                label={'Add Files'}
                onClick={onAddFilesClick} 
                type={'large'}
                utils={utils} />

                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {note && routed === false && (
                        <div style={{
                            marginRight: 8
                        }}>
                            <Button 
                            color={Appearance.colors.grey()}
                            label={'Cancel'}
                            onClick={onEndEditing} 
                            type={'large'}
                            utils={utils} />
                        </div>
                    )}
                    <Button 
                    color={'primary'}
                    label={'Submit'}
                    loading={loading === true}
                    onClick={onSubmit} 
                    type={'large'}
                    utils={utils} />
                </div>

                <input
                accept={getFileTypes()}
                className={`custom-file-input ${window.theme}`}
                multiple={true}
                onChange={onFileChange}
                ref={fileInput}
                style={{
                     display: 'none'
                }}
                type={'file'}/> 
            </div>
        )
    }

    const getFiles = () => {
        return files.length > 0 && (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginTop: 8
            }}>
                {files.map((file, index) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== files.length - 1,
                            icon: { 
                                path: getFileIconPath(file.mime),
                                imageStyle: {
                                    backgroundColor: Appearance.colors.transparent,
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                    objectFit: 'contain'
                                } 
                            },
                            key: index,
                            onClick: onConfigureFile.bind(this, index),
                            rightContent: (
                                Views.icon.right('red-x', onRemoveFile.bind(this, file, index))
                            ),
                            subTitle: file.description || 'Click to configure file name and description',
                            title: file.name || file.file_name
                        })
                    )
                })}
            </div>
        )
    }

    const getFileTypes = () => {
        return ['doc','docx','jpg','m4v','mov','mp4','pdf','png','txt','xls','xlsx'].map(type => FileHandler.getMimeTypes(type)).join(', ');
    }

    const getUnstyledText = raw => {
        let values = raw.blocks.map(block => block.text); 
        return values.join('. ');
    }

    const setupNote = () => {
        
        // set default files
        setFiles(note.attachments || []);

        // prepare content for editor
        let raw = convertFromRaw(note.content);
        let state = EditorState.createWithContent(raw);
        setContent(state);
    }

    useEffect(() => {
        if(note) {
            setupNote();
        }
    }, [note]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <RichTextEditor
            content={content}
            onChange={setContent}
            placeholder={'Write an update'}
            utils={utils}/>
            {getFiles()}
            {getEditingControls()}
        </div>
    )
}

export const NotesRoute = ({ abstract, note_id, permissions, utils }) => {

    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);

    const fetchNoteDetails = async () => {
        try {

            let { note } = await Request.get(utils, '/utils/', {
                id: note_id,
                type: 'note_details'
            });

            console.log(note);
            setLoading(false);
            setNote(Note.create(note));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this note. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(note_id) {
            fetchNoteDetails();
        }
    }, []);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: 12,
            width: '100%'
        }}>
            <div style={{
                maxWidth: 750,
                width: '100%'
            }}>
                <NoteEditor 
                abstract={abstract}
                note={note}
                permissions={permissions}
                routed={true}
                utils={utils} />
            </div>
        </div>
    );
}

export default Notes;