import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';

import Appearance from 'styles/Appearance.js';
import Layer from 'structure/Layer.js';
import Utils from 'files/Utils.js';

const PrintContent = ({ headers, title, onFetch, onRenderItem }, { index, options, utils }) => {

    const layerID = 'print_content';
    const printContent = useRef(null);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const onPrintContent = useReactToPrint({
        documentTitle: title,
        content: () => printContent.current
    });

    const prepareContent = async () => {
        try {

            // fetch print results from parents and update local state
            let results = await onFetch({ print: true }) || [];
            console.log(results);
            setResults(results);

            // show an error alert if no results were returned
            if(!results || results.length === 0) {
                setLoading(false);
                utils.alert.show({
                    title: 'Just a Second',
                    message: `It looks like there are no results available to print`,
                    onClick: setLayerState.bind(this, 'close')
                })
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content to print. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    const onResultsChange = async () => {
        try {
            if(results.length === 0 || !printContent.current) {
                return;
            }
            await Utils.sleep(0.25);
            setLoading(false);
            onPrintContent();

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content to print. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        onResultsChange();
    }, [results])

    useEffect(() => {
        prepareContent();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Print Preview for "${title}"`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'fullscreen'
        }} >
            <div
            ref={printContent}
            style={{
                border: `1px solid ${Appearance.colors.divider()}`,
                borderRadius: 10,
                overflow: 'hidden'
            }}>
                <table
                className={'px-3 py-2 m-0'}
                style={{
                    width: '100%'
                }}>
                    <thead style={{
                        width: '100%'
                    }}>
                        <tr style={{
                            borderBottom: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            {headers.filter(header => {
                                return header.visible !== false;
                            }).map((header, index) => {
                                return (
                                    <td
                                    key={index}
                                    className={'px-3 py-2 text-button flexible-table-column'}>
                                        <span style={Appearance.textStyles.title()}>{header.title}</span>
                                    </td>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody style={{
                        width: '100%'
                    }}>
                        {results.filter(item => {
                            return item.visible !== false;
                        }).map(onRenderItem).map((item, index) => {
                            return (
                                <tr
                                key={index}
                                style={{
                                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                                }}>
                                    {headers.map((header, index) => (
                                        <td
                                        key={index}
                                        className={'px-3 py-2 flexible-table-column'}>
                                            <span style={{
                                                ...Appearance.textStyles.subTitle(),
                                                whiteSpace: 'break-spaces'
                                            }}>{item[header.key]}</span>
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </Layer>
    )
}
export default PrintContent;
