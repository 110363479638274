import moment from 'moment-timezone';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class EventClass {

    date = null;
    dealership_id = null;
    demo = null;
    end_date = null;
    id = null;
    lead = null;
    primary_user;
    props = null;
    start_date = null;
    status = null;
    title = null;
    type = null;
    user = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    create = (props = {}) => {
        this.date = props.date && moment(props.date);
        this.dealership_id = props.dealership_id;
        this.demo = props.demo;
        this.end_date = props.end_date && moment(props.end_date);
        this.id = props.id;
        this.lead = props.lead;
        this.primary_user = props.primary_user && User.create(props.primary_user);
        this.props = props.props || {};
        this.start_date = props.start_date && moment(props.start_date);
        this.status = props.status;
        this.title = props.title;
        this.type = props.type;
        this.user = props.user && User.create(props.user);
        return this;
    }

    open = () => {
        this.edits = {
            demo: this.demo,
            end_date: this.end_date,
            lead: this.lead,
            primary_user: this.primary_user,
            props: this.props || {},
            start_date: this.start_date,
            title: this.title,
            type: this.type
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.demo = this.edits.demo;
        this.end_date = this.edits.end_date;
        this.lead = this.edits.lead;
        this.primary_user = this.edits.primary_user;
        this.props = this.edits.props;
        this.start_date = this.edits.start_date;
        this.title = this.edits.title;
        this.type = this.edits.type;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to server
                let { id, status } = await Request.post(utils, '/events/', {
                    notes: this.edits.props.notes,
                    type: 'new',
                    ...this.toJSON(this.edits)
                });

                // set id, status, and end editing
                this.id = id;
                this.status = status;
                this.close();

                // notify event subscribers that a fetch is needed
                utils.content.fetch('event');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                await Request.post(utils, '/events/', {
                    id: this.id,
                    type: 'update',
                    ...this.toJSON(this.edits)
                });

                // end editing
                this.close();

                // notify subscribers that new event data is available
                utils.content.update({
                    type: 'event',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = target => {
        return {
            demo_id: target.demo && target.demo.id,
            end_date: target.end_date && target.end_date.format('YYYY-MM-DD HH:mm:ss'),
            evt_type: target.type && target.type.id,
            lead_id: target.lead && target.lead.id,
            id: this.id,
            notes: target.notes, // only for new targets
            primary_user_id: target.primary_user && target.primary_user.user_id,
            props: target.props,
            start_date: target.start_date && target.start_date.format('YYYY-MM-DD HH:mm:ss'),
            title: target.title
        }
    }
}

class EventTypeClass {

    active = null;
    date = null;
    dealership_id = null;
    icon = null;
    id = null;
    title = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    create = (props = {}) => {
        this.active = props.active;
        this.date = props.date && moment(props.date);
        this.dealership_id = props.dealership_id;
        this.icon = props.icon;
        this.id = props.id;
        this.title = props.title;
        return this;
    }

    open = () => {
        this.edits = {
            icon: this.icon,
            title: this.title
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.icon = this.edits.icon;
        this.title = this.edits.title;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to server
                let { id } = await Request.post(utils, '/events/', {
                    type: 'new_type',
                    ...this.toJSON(this.edits),
                    ...props
                })

                // set id and end editing
                this.id = id;
                this.close();

                // notify event subscribers that a fetch is needed
                utils.content.fetch('event_type');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                await Request.post(utils, '/events/', {
                    id: this.id,
                    type: 'update_type',
                    ...this.toJSON(this.edits)
                })

                // end editing
                this.close();

                // notify event subscribers that a fetch is needed
                utils.content.fetch('event');

                // notify subscribers that new event type data is available
                utils.content.update({
                    type: 'event_type',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = props => {
        return {
            icon: props.icon && props.icon.id,
            id: this.id,
            title: props.title
        }
    }
}

const fetchEvent = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { event } = await Request.get(utils, '/events/', {
                type: 'details',
                id: id
            });
            let obj = new EventClass().create(event);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    });
}

export default {
    create: props => new EventClass().create(props),
    get: fetchEvent,
    new: () => new EventClass(),
    Type: {
        create: props => new EventTypeClass().create(props),
        new: () => new EventTypeClass(),
    }
}