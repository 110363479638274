import moment from 'moment-timezone';

import Lead from 'classes/Lead.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class DemoRequestClass {

    created = null;
    date = null;
    dealership_id = null;
    id = null;
    lead = null;
    requested_by_user = null;
    status = null;
    timezone = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.created = props.created && moment(props.created);
        this.date = props.date && moment(props.date);
        this.dealership_id = props.dealership_id;
        this.id = props.id;
        this.lead = props.lead && Lead.create(props.lead);
        this.requested_by_user = props.requested_by_user && User.create(props.requested_by_user);
        this.status = props.status;
        this.timezone = props.timezone;
        return this;
    }

    open = () => {
        this.edits = {
            date: this.date,
            lead: this.lead,
            timezone: this.timezone
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.date = this.edits.date;
        this.lead = this.edits.lead;
        this.timezone = this.edits.timezone;
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { status } = await Request.post(utils, '/demos/', {
                    type: 'update_request',
                    id: this.id,
                    ...this.toJSON(this.edits)
                })

                this.close();
                this.status = status;
                utils.content.update({
                    type: 'demo_request',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            date: target.date && moment(target.date).format('YYYY-MM-DD HH:mm:ss'),
            dealership_id: target.dealership_id,
            id: this.id,
            lead: target.lead && target.lead.id,
            requested_by_user: target.requested_by_user && target.requested_by_user.user_id,
            timezone: target.timezone
        }
    }
}

const fetchDemoRequest = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { request } = await Request.get(utils, '/demos/', {
                type: 'request_details',
                id: id
            });
            let obj = new DemoRequestClass().create(request);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

const setDemo = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.post(utils, '/demos/', {
                type: 'new',
                ...props
            });
            resolve(response);

        } catch(e) {
            reject(e);
        }
    })
}

const getAutoAssignments = target => {

    let presets = {};
    let { lead, requested_by_user } = target;

    // automatically set assigned user if applicable
    if(requested_by_user && requested_by_user.level <= User.levels.get().safety_advisor) {
        presets.assigned = requested_by_user;

    } else if(lead.assignment_user && lead.assignment_user.level <= User.levels.get().safety_advisor) {
        presets.assigned = lead.assignment_user;

    } else if(lead.user && lead.user.level <= User.levels.get().safety_advisor) {
        presets.assigned = lead.user;
    }

    // automatically set ride along if applicable
    if(requested_by_user && requested_by_user.level === User.levels.get().safety_associate) {
        presets.ride_along = requested_by_user;

    } else if(lead.user && lead.user.level === User.levels.get().safety_associate) {
        presets.ride_along = lead.user;

    } else if(lead.enrollment_user && lead.enrollment_user.level === User.levels.get().safety_associate) {
        presets.ride_along = lead.enrollment_user;
    }
    return presets;
}

export default {
    new: () => new DemoRequestClass(),
    set: (utils, props) => setDemo(utils, props),
    get: (utils, id) => fetchDemoRequest(utils, id),
    create: props => new DemoRequestClass().create(props),
    assignments: {
        guess: target => getAutoAssignments(target)
    }
}
