import React, { useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Utils from 'files/Utils.js';

const RequestAgreement = ({ onAccept, onClose, onDecline, url, utils }) => {

    const [animations, setAnimations] = useSpring(() => ({
        top: window.innerHeight,
        config: { mass: 1, tension: 180, friction: 16 }
    }));

    const onAcceptClick = async () => {
        try {
            await onCloseView();
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(onAccept) === 'function') {
                onAccept();
            }
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue accepting the agreement. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCloseView = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setAnimations({ top: window.innerHeight });
                await Utils.sleep(0.5);
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    const onDeclineClick = async () => {
        try {
            await onCloseView();
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(onDecline) === 'function') {
                onDecline();
            }
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue declining the agreement. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setAnimations({ top: 20 });
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 20,
            right: 20,
            bottom: 0
        }}>
            <animated.div style={{
                ...Appearance.styles.panel(),
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                height: 'calc(100vh - 40px)',
                width: '100%',
                maxWidth: 600,
                ...animations
            }}>
                <iframe
                src={url}
                style={{
                    border: 'none',
                    width: '100%',
                    flexGrow: 1
                }} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    padding: 20
                }}>
                    <div style={{
                        paddingRight: 4,
                        width: '50%'
                    }}>
                        <Button
                        type={'large'}
                        label={'Decline'}
                        color={'danger'}
                        onClick={onDeclineClick} />
                    </div>
                    <div style={{
                        paddingLeft: 4,
                        width: '50%'
                    }}>
                        <Button
                        type={'large'}
                        label={'Accept'}
                        color={'primary'}
                        onClick={onAcceptClick} />
                    </div>
                </div>
            </animated.div>
        </div>
    )
}
export default RequestAgreement;
