class AbstractClass {

    object = null;
    type = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.object = props.object;
        return this;
    }

    isNewTarget = () => {
        return this.getID() ? false : true;
    }

    isSame = abstract => {
        return this.getTag() === abstract.getTag();
    }

    getType = () => {
        return this.type;
    }

    getTag = () => {
        return `${this.type}-${this.getID()}`;
    }

    getID = () => {
        return this.type === 'user' ? this.object.user_id : this.object.id
    }

    getTitle = () => {

        switch(this.type) {

            case 'call_log':
            if(this.object.lead_full_name) {
                return `${this.object.method === 'email' ? 'Email' : 'Phone Call'} for ${this.object.lead_full_name}`;
            }
            if(this.object.lead_id) {
                return `${this.object.method === 'email' ? 'Email' : 'Phone Call'} for Lead #${this.object.lead_id}`;
            }
            return `${this.object.method === 'email' ? 'Email' : 'Phone Call'}`;

            case 'dealership':
            return this.object.id ? `"${this.object.name}" Dealership` : 'New Dealership';

            case 'demo':
            if(this.object.lead && this.object.lead.full_name) {
                return `Demo for ${this.object.lead.full_name}`
            }
            return this.object.id ? `Demo #${this.object.id}` : 'New Demo';

            case 'demo_request':
            if(this.object.lead && this.object.lead.full_name) {
                return `Demo Request for ${this.object.lead.full_name}`
            }
            return this.object.id ? `Demo Request #${this.object.id}` : 'New Request Demo';

            case 'event':
            return this.object.id ? `${this.object.title} ${this.object.type.title} Event` : 'New Event';

            case 'event_type':
            return this.object.id ? `${this.object.title} Event Type` : 'New Event Type';

            case 'feedback_response':
            return `Customer Response from ${this.object.full_name}`;

            case 'feedback_template':
            return this.object.id ? `"${this.object.title}" Feedback Template` : 'New Feedback Template';

            case 'lead':
            return this.object.id ? `Lead${this.object.full_name ? ` for ${this.object.full_name}` : ''}` : 'New Lead';

            case 'lead_program':
            return this.object.id ? `Lead Program for ${this.object.full_name}` : 'New Lead Program';

            case 'lead_script':
            return this.object.id ? `${this.object.title}` : 'New Lead Script';

            case 'lead_script_rebuttal':
            return this.object.id ? `"${this.object.title}" Lead Script Rebuttal` : 'New Lead Script Rebuttal';

            case 'lead_transfer':
            return this.object.lead ? this.object.lead.full_name : 'Name unavailable';

            case 'lead_type':
            return this.object.id ? `${this.object.text}` : 'New Lead Type';

            case 'location':
            return this.object.id ? `Location "${this.object.name}"` : 'New Location';

            case 'notification':
            return this.object.title;

            case 'program':
            return this.object.id ? this.object.name : 'New Program';

            case 'program_agreement_template':
            return this.object.id ? this.object.title : 'New Program Agreement Template'

            case 'status':
            return this.object.code ? this.object.text : 'New Status';

            case 'system_event':
            return `System Event #${this.object.id} from ${this.object.user.full_name}`;

            case 'template':
            return this.object.id ? `"${this.object.title}" Template` : 'New Template';

            case 'user':
            if(this.object && this.object.full_name) {
                return this.object.full_name;
            }
            return this.object.user_id ? `User Account #${this.object.user_id}` : 'New User Account';

            case 'user_group':
            return this.object.id ? `"${this.object.title}" User Group` : 'New User Group';

            default:
            return 'Unknown Object';
        }
    }

    isSame = abstract => {
        return this.getTag() === abstract.getTag();
    }
}
export default {
    create: props => new AbstractClass().create(props)
};

export const buildTag = (props) => {

    let so = new AbstractClass();
    so.type = props.type;
    so.object = props.object || {
        ...(props.type !== 'users' ? {
            id: props.id
        }:{
            user_id: props.id
        })
    }
    return so.getTag();
}
