import API from 'files/api.js';
import SocketIO from 'socket.io-client';

class SocketManagerClass {

    auth = null;
    namespaces = ['aft', 'dealerships', 'demos', 'events', 'notifications', 'leads', 'system', 'tasks', 'users'];
    sockets = null;

    constructor() {
        return this;
    }

    connect = async (user, dealership) => {
        return new Promise(async (resolve, reject) => {

            // prepare authorization object
            this.auth = {
                token: user.token,
                user_id: user.user_id,
                version: API.version
            };

            // prepare main sockets object
            let sockets = {
                main: new SocketIO(API.sockets, {
                    autoConnect: true,
                    forceNew: false,
                    jsonp: false,
                    query: { auth: JSON.stringify(this.auth) },
                    reconnection: true,
                    reconnectionAttempts: 30,
                    reconnectionDelay: 10000,
                    transports: ['websocket', 'polling']
                })
            };

            sockets.main.on('error', e => {
                console.error(`[websockets]: ${e.message}`);
            });

            sockets.main.on('connect_timeout', () => {
                let error = new Error('The request to connect to our live update server has timed-out');
                reject(error);
            });

            sockets.main.on('connect_error', e => {
                console.error(`[websockets]: ${e.message}`);
                let error = new Error('We ran into an issue while trying to connect to our live update server');
                reject(error);
            });

            sockets.main.on('disconnect', reason => {
                console.error(`[websockets]: disconnected ${reason}`);
            });

            sockets.main.on('connect', async () => {

                // loop through socket namespaces and connect
                console.log('[websockets]: connected');
                this.namespaces.forEach(target => {

                    // reopen connection if target already exists
                    if(sockets[target]) {
                        console.warn(`[websockets]: reusing existing connection for /${target}`);
                        return;
                    }

                    // open new connection to target 
                    console.log(`[websockets]: connecting to /${target}`);
                    sockets[target] = sockets.main.io.socket(`/${target}`, {
                        autoConnect: true,
                        forceNew: true,
                        jsonp: false,
                        query: { auth: JSON.stringify(this.auth) },
                        reconnection: true,
                        reconnectionAttempts: 30,
                        reconnectionDelay: 10000,
                        transports: ['websocket', 'polling']
                    });
                });

                this.sockets = sockets;
                resolve();
            });
        })
    }

    emit = async (socketKey, key, data) => {
        return new Promise((resolve, reject) => {
            try {
                if(!key) {
                    throw new Error(`missing key for socket emit`);
                }
                if(!this.sockets[socketKey]) {
                    throw new Error(`${socketKey} endpoint is not available`);
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`${socketKey} endpoint is not connected`);
                }
                this.sockets[socketKey].emit(key, {
                    ...data,
                    auth: this.auth
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    off = async (socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!key || !callback) {
                    throw new Error(`missing ${key ? 'callback' : 'key'} for socket emit`);
                }
                if(!this.sockets[socketKey]) {
                    throw new Error(`${socketKey} endpoint is not available`);
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`${socketKey} endpoint is not connected`);
                }
                this.sockets[socketKey].off(key, callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    on = async (socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!key || !callback) {
                    throw new Error(`missing ${key ? 'callback' : 'key'} for socket emit`);
                }
                if(!this.sockets[socketKey]) {
                    throw new Error(`${socketKey} endpoint is not available`);
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`${socketKey} endpoint is not connected`);
                }
                this.sockets[socketKey].on(key, callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    persistEmit = (socketKey, key, data) => {
        let index = 0;
        let interval = setInterval(async () => {
            try {
                index++;
                await this.emit(socketKey, key, data);
                clearInterval(interval);
                console.log(`[websockets]: data sent to ${socketKey}:${key}`);
            } catch(e) {
                if(index > 10) {
                    clearInterval(interval);
                    console.error(`[websockets]: unable to connect to ${socketKey}:${key}`);
                    return;
                }
                console.log(`[websockets]: waiting for ${socketKey}:${key}`);
            }
        }, 500);
    }

    persistOn = async (socketKey, key, callback) => {
        let index = 0;
        let interval = setInterval(async () => {
            try {
                index++;
                await this.on(socketKey, key, callback);
                clearInterval(interval);
                console.log(`[websockets]: listener added for ${socketKey}:${key}`);
            } catch(e) {
                if(index > 10) {
                    clearInterval(interval);
                    console.error(`[websockets]: unable to add ${socketKey}:${key} listener`);
                    return;
                }
                console.log(`[websockets]: waiting for ${socketKey}:${key}`);
            }
        }, 500);
    }
}

export default {
    new: () => new SocketManagerClass()
};
