import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper';

import AddressLookupField from 'views/AddressLookupField.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import ColorPickerField from 'views/ColorPickerField.js';
import DatePickerField from 'views/DatePickerField.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import DealershipLookupField from 'views/DealershipLookupField.js';
import DemoLookupField from 'views/DemoLookupField.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import DurationPickerField from 'views/DurationPickerField.js';
import EventTypeLookupField from 'views/EventTypeLookupField.js';
import FilePickerField from 'views/FilePickerField.js';
import IconPickerField from 'views/IconPickerField.js';
import ImagePickerField from 'views/ImagePickerField.js';
import { LayerItem } from 'structure/Layer.js';
import LeadLookupField from 'views/LeadLookupField.js';
import LeadScriptEditor from 'views/LeadScriptEditor.js';
import LeadScriptPickerField from 'views/LeadScriptPickerField.js';
import LeadSubTypePickerField from 'views/LeadSubTypePickerField.js';
import LeadTypePickerField from 'views/LeadTypePickerField.js';
import LocationRadiusLookupField from 'views/LocationRadiusLookupField.js';
import ListField from 'views/ListField.js';
import MultipleListField from 'views/MultipleListField.js';
import PatternPickerField from 'views/PatternPickerField.js';
import PickerField from 'views/PickerField.js';
import ProgramAgreementTemplatePickerField from 'views/ProgramAgreementTemplatePickerField.js';
import ProgramLookupField from 'views/ProgramLookupField.js';
import { ProgramPrivacyEditor } from 'managers/Programs.js';
import QuestionEditor from 'views/QuestionEditor.js';
import TagLookupField from 'views/TagLookupField.js';
import TextField, { TextFieldContainer } from 'views/TextField.js';
import TimeRangePicker from 'views/TimeRangePicker.js';
import TimezonePickerField from 'views/TimezonePickerField.js';
import UserLookupField from 'views/UserLookupField.js';

const getItemComponent = ({ item, onComponentClick, utils }) => {

    let { component, icon, items, onChange, onValidate, props = {}, value } = item;
    switch(component) {

        case 'address_lookup':
        return (
            <AddressLookupField
            utils={utils}
            icon={icon}
            value={value}
            geocode={true}
            onChange={place => {
                if(typeof(onChange) === 'function') {
                    onChange({
                        location: place && place.location,
                        address: place && {
                            administrative_area_level_1: place.administrative_area_level_1,
                            country: place.country,
                            locality: place.locality,
                            postal_code: place.postal_code,
                            street_address_1: place.street_address_1,
                            street_address_2: place.street_address_2
                        }
                    });
                }
            }}
            {...props} />
        )

        case 'bool_list':
        return (
            <ListField
            items={[{
                id: 'yes',
                title: 'Yes'
            },{
                id: 'no',
                title: 'No'
            }]}
            onChange={item => onChange(item ? item.id === 'yes' : null)}
            value={typeof(value) === 'boolean' ? (value ? 'Yes' : 'No') : null}
            {...props} />
        );

        case 'bool_toggle':
        return (
            <BoolToggle
            enabled={'Yes'}
            disabled={'No'}
            isEnabled={value}
            onChange={onChange}
            {...props}
            style={{
                margin: null
            }} />
        )

        case 'color_picker':
        return (
            <ColorPickerField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'date_duration_picker':
        return (
            <DateDurationPickerField
            utils={utils}
            selected={value}
            placeholder={'Date'}
            onChange={onChange}
            style={{
                width: '100%'
            }}
            {...props} />
        )

        case 'date_picker':
        return (
            <DatePickerField
            utils={utils}
            icon={icon}
            value={value}
            selected={value}
            onValidate={onValidate}
            onDateChange={onChange}
            {...props} />
        )

        case 'date_time_picker':
        return (
            <DateDurationPickerField
            utils={utils}
            icon={icon || 'calendar'}
            selected={value}
            onChange={onChange}
            {...props} />
        )

        case 'dealership_lookup':
        return (
            <DealershipLookupField
            utils={utils}
            inline={true}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'demo_lookup':
        return (
            <DemoLookupField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'dual_date_picker':
        return (
            <DualDatePickerField
            endDate={item.endDate}
            icon={icon}
            onEndDateChange={item.onEndDateChange}
            onStartDateChange={item.onStartDateChange}
            startDate={item.startDate}
            utils={utils}
            {...props} />
        )

        case 'duration_picker':
        return (
            <DurationPickerField
            onChange={onChange}
            style={{
                width: '100%'
            }}
            {...props} />
        )

        case 'event_type_lookup':
        return (
            <EventTypeLookupField
            onChange={onChange}
            utils={utils}
            value={value}
            {...props} />
        )

        case 'file_picker':
        return (
            <FilePickerField
            onChange={onChange}
            utils={utils}
            value={value}
            {...props} />
        )

        case 'icon_picker':
        return (
            <IconPickerField
            onChange={onChange}
            utils={utils}
            value={value}
            {...props} />
        )

        case 'image_picker':
        return (
            <ImagePickerField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'lead_lookup':
        return (
            <LeadLookupField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'lead_type_picker':
        return (
            <LeadTypePickerField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'lead_script_editor':
        return (
            <div
            className={`dummy-field ${window.theme} text-button`}
            onClick={onComponentClick.bind(this, item)}
            style={{
                width: '100%',
                height: 35
            }}>
                <span style={{
                    flexGrow: 1,
                    borderWidth: 0,
                    fontSize: 12,
                    fontWeight: 500,
                    color: value ? Appearance.colors.text() : Appearance.colors.subText(),
                    backgroundColor: Appearance.colors.transparent,
                }}>{value ? 'Click to make changes...' : (props && props.placeholder ? props.placeholder : 'No script content added...')}</span>
            </div>
        )

        case 'lead_script_picker':
        return (
            <LeadScriptPickerField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'lead_sub_type_picker':
        return (
            <LeadSubTypePickerField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'location_radius_lookup':
        return (
            <LocationRadiusLookupField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'list':
        return (
            <ListField
            items={items}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'multiple_list':
        return (
            <MultipleListField
            items={items}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'pattern_picker':
        return (
            <PatternPickerField
            utils={utils}
            selected={value}
            collapsible={true}
            onChange={onChange}
            {...props} />
        )

        case 'picker':
        return (
            <PickerField
            icon={icon}
            items={items}
            value={value}
            disableScroll={true}
            onChange={onChange}
            {...props} />
        )

        case 'privacy_editor':
        return (
            <TextFieldContainer
            utils={utils}
            value={`Click to ${value ? 'edit':'create'} content...`}
            onClick={() => {
                utils.layer.open({
                    id: props.abstract ? `program_privacy_editor_${props.abstract.getID()}` : 'new_program_privacy_editor',
                    abstract: props.abstract,
                    Component: ProgramPrivacyEditor.bind(this, {
                        content: value,
                        onChange: onChange
                    })
                });
            }} />
        )

        case 'program_agreement_template_picker':
        return (
            <ProgramAgreementTemplatePickerField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'program_lookup':
        return (
            <ProgramLookupField
            utils={utils}
            value={value}
            onChange={onChange}
            {...props} />
        )

        case 'question_editor':
        return (
            <QuestionEditor
            utils={utils}
            value={value}
            {...props} />
        )

        case 'tag_lookup':
        return (
            <TagLookupField
            utils={utils}
            defaultTags={value}
            onChange={onChange}
            {...props} />
        )

        case 'time_range_picker':
        return (
            <TimeRangePicker
            utils={utils}
            onChange={onChange}
            {...props} />
        )

        case 'timezone_picker':
        return (
            <TimezonePickerField
            utils={utils}
            value={value}
            onChange={onChange}
            style={{
                width: '100%'
            }}
            {...props} />
        )

        case 'textfield':
        return (
            <TextField
            icon={icon}
            onChange={onChange}
            onValidate={onValidate}
            value={value}
            {...props} />
        )

        case 'textview':
        return (
            <TextField
            expandWithText={true}
            icon={icon}
            onChange={onChange}
            onValidate={onValidate}
            useDelay={false}
            value={value}
            {...props} />
        )

        case 'user_lookup':
        return (
            <UserLookupField
            icon={icon}
            utils={utils}
            user={value}
            placeholder={'Search by first or last name...'}
            onChange={onChange}
            {...props} />
        )

        default:
        return null;
    }
}

export const validateRequiredFields = async get => {
    return new Promise((resolve, reject) => {
        let items = get().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined || item.value === '';
        });
        if(required) {
            let error = new Error(`One or more required fields are incomplete. Please fill out the "${required.title.toLowerCase()}" before moving on`);
            reject(error);
            return;
        }
        resolve();
    })
}

const AltFieldMapper = ({ dialer, fields, utils }) => {

    const hoverEl = useRef(null);
    const [hover, setHover] = useState({});
    const [hoverSize, setHoverSize] = useState({
        width: 0,
        height: 0
    })

    const onComponentClick = item => {
        switch(item.component) {
            case 'lead_script_editor':
            utils.layer.open({
                id: 'lead_script_editor',
                Component: LeadScriptEditor.bind(this, {
                    utils: utils,
                    ...item
                })
            });
            break;
        }
    }

    const onMouseEnter = (key, evt) => {
        setHover({
            key: key,
            top: evt.target.offsetTop,
            left: evt.target.offsetLeft
        });
    }

    const onMouseLeave = () => {
        setHover({});
        setHoverSize({
            width: 0,
            height: 0
        })
    }

    const onRenderItem = (item, index, items) => {
        return (
            <AltFieldItem
            key={index}
            bottomBorder={index !== items.length - 1}
            dialer={dialer}
            hover={hover}
            hoverEl={hoverEl}
            hoverSize={hoverSize}
            item={item}
            onComponentClick={onComponentClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            utils={utils} />
        )
    }

    const getContent = () => {

        let sections = fields.filter(section => section.visible !== false);
        if(dialer === true) {
            return (
                <div
                className={'row m-0 px-0 pt-2 pb-0'}>
                    {sections.map((section, index) => {
                        return section.items.filter(item => {
                            return item.visible !== false && item.dialer !== false;
                        }).map((item, i, items) => {
                            return (
                                <div
                                key={i}
                                className={`col-12 col-lg-4 px-3 pt-0 ${index !== sections.length - 1 ? 'pb-2' : 'pb-0'}`}>
                                    {onRenderItem(item, index, items)}
                                </div>
                            )
                        });
                    })}
                </div>
            )
        }

        return sections.map((section, index) => {
            return (
                <LayerItem
                key={index}
                title={section.title}
                collapsed={false}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        borderRadius: 15,
                        width: '100%',
                        marginBottom: 15,
                        padding: 12,
                        paddingTop: 8, // accounts for text line-height
                        overflow: 'visible'
                    }}>
                        {section.items.filter(item => item.visible !== false).map(onRenderItem)}
                    </div>
                </LayerItem>
            )
        })
    }

    useEffect(() => {
        if(hoverEl.current) {
            setHoverSize(props => update(props, {
                width: {
                    $set: hoverEl.current.clientWidth
                },
                height: {
                    $set: hoverEl.current.clientHeight
                }
            }))
        }
    }, [hover, hoverEl.current])

    return getContent();
}

export default AltFieldMapper;

export const AltFieldItem = ({ bottomBorder, componentStyle, containerStyle, dialer, hover, hoverEl, hoverSize, item, onComponentClick, onMouseEnter, onMouseLeave, utils }) => {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: bottomBorder !== false ? 12 : 0,
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
            }}>
                {item.required !== false && !item.value && (
                    <div style={{
                        width: 5,
                        height: 5,
                        borderRadius: 2.5,
                        overflow: 'hidden',
                        backgroundColor: Appearance.colors.red,
                        marginRight: 8
                    }} />
                )}
                <span style={{
                    ...Appearance.textStyles.subHeader(),
                    display: 'block',
                    marginBottom: 4
                }}>{dialer === true ? (item.dialer_title || item.title) : item.title}</span>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
                ...componentStyle
            }}>
                {getItemComponent({
                    item: item,
                    onComponentClick: onComponentClick,
                    utils: utils
                })}
                {item.description && dialer !== true && (
                    <img
                    className={'text-button'}
                    src={'images/help-button-grey.png'}
                    onMouseEnter={onMouseEnter ? onMouseEnter.bind(this, item.key) : null}
                    onMouseLeave={onMouseLeave}
                    style={{
                        height: 25,
                        marginLeft: 8,
                        minHeight: 25,
                        minWidth: 25,
                        objectFit: 'contain',
                        width: 25
                    }} />
                )}
                {hover && hover.key === item.key && (
                    <div
                    ref={hoverEl}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        borderRadius: 15,
                        position: 'absolute',
                        top: hoverSize.height ? (hover.top + 10 - (hoverSize.height / 2)) : 0,
                        left: hoverSize.width ? (hover.left - hoverSize.width - 10) : 0,
                        padding: '8px 12px 8px 12px',
                        width: 'auto',
                        maxWidth: 250,
                        zIndex: 9999,
                        textAlign: 'right',
                        opacity: hoverSize.width && hoverSize.height ? 1 : 0,
                        boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25), -5px -5px 15px rgba(85,85,85,0.25)' : '5px 5px 15px rgba(175,175,174,0.25), -5px -5px 15px rgba(255,255,255,0.75)'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'wrap'
                        }}>{item.description}</span>
                    </div>
                )}
            </div>
        </div>
    )
}
