import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import Appearance from 'styles/Appearance.js';

const Sheet = ({ items, message, onClick, onClose, position = 'top', sort, target, title, utils }) => {

    const hovering = useRef(false);
    const scrollOffset = useRef(window.scrollY || 0);

    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        bottom: -500,
        opacity: 0,
        transform: 'scale(0.5)'
    }));
    const [offset, setOffset] = useState(null);
    const [sheetHeight, setSheetHeight] = useState(0);
    const [sheetItems, setSheetItems] = useState([]);
    const [sheetWidth, setSheetWidth] = useState(0);
    const [size, setSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    const onCloseSheet = callback => {

        // animate components out of view
        setAnimations({
            bottom: -sheetHeight,
            opacity: 0,
            transform: 'scale(0.5)'
        });

        // notify caller that animation has completed
        if(typeof(callback) === 'function') {
            setTimeout(callback, 250);
        }
    }

    const onDocumentEvent = evt => {
        let keys = ['sheet', 'sheet-item', 'sheet-item-text'];
        if(!evt.target.className || keys.includes(evt.target.className) === false) {
            onRequestCloseSheet();
        }
    }

    const onItemClick = item => {
        onCloseSheet(() => {

            // notify subscribers that sheet has closed
            if(typeof(onClose) === 'function') {
                onClose();
            }

            // prevent moving forward if feature is disabled for current user
            if(item.permissions) {
                let match = item.permissions.find(key => utils.user.permissions.get(key) === false);
                if(match) {
                    return utils.user.permissions.reject();
                }
            }

            // notify subscribers that item has been selected
            if(typeof(onClick) === 'function') {
                onClick(item.key, item);
            }
        });
    }

    const onRequestCloseSheet = () => {
        onCloseSheet(onClose);
    }

    const onScrollEvent = () => {

        // prevent sheet close if user is hovering over sheet
        if(hovering.current === true) {
            return;
        }

        // determine scroll offset and close if at least 10 pixels were scrolled in either direction
        let offset = scrollOffset.current - Math.abs(window.scrollY);
        if(Math.abs(offset) > 10) {
            onRequestCloseSheet();
        }
    }

    const onWindowSizeChange = () => {
        setSize({
            height: window.innerHeight,
            width: window.innerWidth
        });
    }

    const getColor = item => {
        if(item.highlight === true) {
            return '#FFFFFF';
        }
        if(item.style === 'destructive') {
            return Appearance.colors.red;
        }
        if(item.style === 'cancel') {
            return Appearance.colors.text();
        }
        return Appearance.colors.primary();
    }

    const getContent = () => {

        // prevent moving forward if a target was required but an origin has not yet been set
        if(target && !offset) {
            return null;
        }

        // show a custom placed sheet if applicable
        if(target) {
            return (
                <animated.div 
                className={'sheet custom-scrollbars'}
                ref={ref => {
                    setSheetHeight(ref && ref.clientHeight || 0);
                    setSheetWidth(ref && ref.clientWidth || 0);
                }}
                style={{
                    backgroundColor: Appearance.colors.sheet(),
                    border: `2px solid ${Appearance.colors.softBorder()}`,
                    borderRadius: 10,
                    boxShadow: '0px 15px 20px rgba(0,0,0,0.1)',
                    left: getLeftOffset(),
                    maxHeight: size.height * 0.75,
                    opacity: animations.opacity,
                    overflow: 'hidden',
                    position: 'absolute',
                    textAlign: 'center',
                    top: getTopOffset(),
                    transform: animations.transform,
                    width: getWidth(),
                    zIndex: 9999
                }}>
                    {getSheetContents()}
                </animated.div>
            )
        }

        // fallback to showing a traditional sheet
        return (
            <div style={{
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                left: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                zIndex: 9500
            }}>
                <animated.div
                onClick={onRequestCloseSheet}
                style={{
                    backgroundColor: Appearance.colors.dim,
                    bottom: 0,
                    height: '100%',
                    left: 0,
                    opacity: animations.opacity,
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    width: '100%'
                }} />

                <animated.div 
                ref={ref => {
                    setSheetHeight(ref && ref.clientHeight || 0);
                    setSheetWidth(ref && ref.clientWidth || 0);
                }}
                style={{
                    backgroundColor: Appearance.colors.sheet(),
                    borderRadius: 10,
                    bottom: animations.bottom,
                    opacity: animations.opacity,
                    overflow: 'hidden',
                    position: 'fixed',
                    textAlign:'center',
                    width: size.width > 300 ? 300 : (size.width - 30),
                    zIndex: 9999
                }}>
                    {getSheetContents()}
                </animated.div>
             </div>
        )
    }

    const getSheetContents = () => {
        return (
            <div 
            onMouseEnter={() => hovering.current = true}
            onMouseLeave={() => hovering.current = false}
            style={{
                width: '100%'
            }}>
                {(title || message) && (
                    <div style={{
                        borderBottomColor: Appearance.colors.divider(),
                        borderBottomWidth: 1,
                        padding: 15
                    }}>
                        {typeof(title) === 'string' && (
                            <span style={{
                                color: Appearance.colors.text(),
                                display: 'block',
                                fontSize: 18,
                                fontWeight: '800',
                                marginBottom: message ? 4:0
                            }}>{title}</span>
                        )}
                        {typeof(message) === 'string' && (
                            <span style={{
                                color: Appearance.colors.subText(),
                                display: 'block',
                                fontSize: 13,
                                fontWeight: '500',
                                whiteSpace: 'pre-line'
                            }}>{message}</span>
                        )}
                    </div>
                )}
                {sheetItems.filter(i => {
                    return i.visible !== false;
                }).map((item, index) => {
                    return (
                        <div
                        key={item.key}
                        onClick={onItemClick.bind(this, item)}
                        style={{
                            alignItems: 'center',
                            borderTop: index > 0 || !target || title || message ? `1px solid ${Appearance.colors.divider()}` : null,
                            height: 50,
                            padding: 4,
                            width: '100%'
                        }}>
                            <div 
                            className={`sheet-item ${window.theme}`}
                            style={{
                                alignItems: 'center',
                                backgroundColor: item.highlight === true ? Appearance.colors.primary() : null,
                                borderRadius: 4,
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                                justifyContent: 'center',
                                minWidth: 0,
                                width: '100%'
                            }}>
                                {item.leftContent}
                                <span 
                                className={'sheet-item-text'}
                                style={{
                                    color: getColor(item),
                                    fontSize: 13,
                                    fontWeight: 400,
                                    textAlign: 'center',
                                    width: '100%'
                                }}>{item.title}</span>
                                {item.rightContent}
                            </div>
                        </div>
                    );
                })}
            </div>
        )
    }

    const getLeftOffset = () => {

        // determine if sheet overflows screen
        let maxOffsetX = offset.x + sheetWidth;
        if(maxOffsetX > window.innerWidth) {
            return (offset.x + offset.width) - sheetWidth;
        }

        // return default offset x
        return (offset.x + (offset.width / 2)) - (getWidth() / 2);
    }

    const getTopOffset = () => {

        // determine if a top position was requested (default)
        if(position === 'top') {
            return (offset.y + window.scrollY) - sheetHeight - 15;
        }
        
        // calculate position for a bottom request
        return (offset.y + window.scrollY) + (target.clientHeight) + 15;
    }

    const getWidth = () => {
        return size.width > 300 ? 300 : (size.width - 30)
    }

    const animateComponentsIntoView = () => {

        // determine if a target was supplied for sheet placement
        if(target) {
            let rect = target.getBoundingClientRect();
            setOffset(rect);
        }

        // animate components into of view
        setTimeout(() => {
            setAnimations({
                bottom: 15,
                opacity: 1,
                transform: 'scale(1)'
            });
        }, target ? 0 : 250);
    }

    const setupItems = () => {

        // remove items marked as non-visible
        let targets = items.filter(i => i.visible !== false);

        // sort sheet items by title ascending if sorting is enabled
        if(sort !== false) {
            targets = targets.sort((a,b) => {
                if(a.style === 'cancel') {
                    return 1;
                }
                return a.title.localeCompare(b.title);
            })
        }

        // append a cancel button if no cancel button is present
        if(!targets.find(item => item.key === 'cancel')) {
            targets.push({
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            })
        }

        // automatically close sheet if there are no visible items to select
        if(targets.length === 0) {
            if(typeof(onClose) === 'function') {
                onClose();
            }
        }

        // set items and show sheet
        setSheetItems(targets);
    }

    useEffect(() => {

        animateComponentsIntoView();
        setupItems();

        // add target based listeners if a target was provided
        if(target) {
            document.body.addEventListener('mousedown', onDocumentEvent);
            window.addEventListener('scroll', onScrollEvent);
        }

        // add window change listeners
        window.addEventListener('resize', onWindowSizeChange);
        
        return () => {
            document.body.removeEventListener('mousedown', onDocumentEvent);
            window.removeEventListener('resize', onWindowSizeChange);
            window.removeEventListener('scroll', onScrollEvent);
        }

    }, []);

    return getContent();
}
export default Sheet;
