import moment from 'moment-timezone';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class NoteClass {

    attachments = null;
    client = null;
    content = null;
    date = null;
    id = null;
    message = null;
    reminders = {};
    target_id = null;
    target_type = null;
    user = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    close = () => {
        this.content = this.edits.content;
        this.message = this.edits.message;
    }

    create = (props = {}) => {

        this.attachments = props.attachments;
        this.client = props.client && Dealership.create(props.client);
        this.content = props.content;
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.message = props.message;
        this.target_id = props.target_id;
        this.target_type = props.target_type;
        this.user = props.user && User.create(props.user);

        // prepare local dates for note reminders
        this.reminders = props.reminders;
        Object.keys(this.reminders).forEach(key => {
            let date = moment.utc(this.reminders[key].date).local();
            this.reminders[key] = {
                ...this.reminders[key],
                date: date,
                pending: date > moment()
            }
        });

        return this;
    }

    open = () => {
        this.edits = {
            attachments: this.attachments,
            content: this.content,
            message: this.message
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // send update request to server
                let { attachments, id } = await Request.post(utils, '/utils/', {
                    client_id: this.client.id,
                    dealership_id: this.client.id,
                    target_id: this.target_id,
                    target_type: this.target_type,
                    type: 'new_note',
                    user_id: this.user.id,
                    ...this.edits
                });

                // set record id from server
                this.attachments = attachments;
                this.id = id;

                // close edits and notify subscribers of data change
                this.close();
                utils.content.fetch('note');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // send update request to server
                await Request.post(utils, '/utils/', {
                    client_id: this.client.id,
                    dealership_id: this.client.id,
                    id: this.id,
                    target_id: this.target_id,
                    target_type: this.target_type,
                    type: 'update_note',
                    user_id: this.user.id,
                    ...this.edits
                });

                // close edits and notify subscribers of data change
                this.close();
                utils.content.update({
                    object: this,
                    type: 'note'
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

export default {
    create: props => new NoteClass().create(props),
    new: () => new NoteClass()
}