import React, { useRef, useState, useEffect } from 'react';

import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

const TimezonePickerField = ({ containerStyle, defaultValue, onChange, utils, value }) => {

    const [items, setItems] = useState([]);

    const onValueChange = val => {
        if(typeof(onChange) === 'function') {
            onChange(val.title);
        }
    }

    const fetchTimezones = async () => {
        try {
            let { timezones } = await Request.get(utils, '/utils/', {
                type: 'timezones'
            });
            setItems(timezones.map(zone => ({
                code: zone.title,
                title: zone.title
            })));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the timezones list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTimezones();
    }, []);

    return (
        <div style={{
            width: '100%',
            ...containerStyle
        }}>
            <ListField
            utils={utils}
            value={value}
            defaultValue={defaultValue}
            items={items}
            onChange={onValueChange} />
        </div>
    )
}
export default TimezonePickerField;
