import moment from 'moment-timezone';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class CallLogClass {

    assign_to = null;
    author = null;
    complete = null;
    created = null;
    created_by = null;
    direction = null;
    end_date = null;
    follow_up_end_date = null;
    follow_up_start_date = null;
    id = null;
    lead_id = null;
    lead_full_name = null;
    method = null;
    notes = null;
    start_date = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.assign_to = props.assign_to && User.create(props.assign_to);
        this.author = props.author && User.create(props.author);
        this.complete = props.complete;
        this.created = props.created;
        this.created_by = props.created_by;
        this.direction = props.direction;
        this.end_date = props.end_date && moment(props.end_date);
        this.follow_up_end_date = props.follow_up_end_date && moment(props.follow_up_end_date);
        this.follow_up_start_date = props.follow_up_start_date && moment(props.follow_up_start_date);
        this.id = props.id;
        this.lead_id = props.lead_id;
        this.lead_full_name = props.lead_full_name;
        this.method = props.method;
        this.notes = props.notes;
        this.start_date = props.start_date && moment(props.start_date);
        return this;
    }

    open = () => {
        this.edits = {
            assign_to: this.assign_to,
            complete: this.complete,
            direction: this.direction,
            end_date: this.end_date,
            follow_up_end_date: this.follow_up_end_date,
            follow_up_start_date: this.follow_up_start_date,
            method: this.method,
            notes: this.notes,
            start_date: this.start_date
        }
        return this.edits;
    }

    set = props => {

        // prepare end date if applicable
        if(props.start_date && this.edits.end_date) {
            let seconds = moment(this.edits.end_date).unix() - moment(this.edits.start_date).unix();
            props.end_date = moment(props.start_date).add(seconds, 'seconds');
        }

        // prepare start date if applicable
        if(props.follow_up_start_date && this.edits.follow_up_end_date) {
            let seconds = moment(this.edits.follow_up_end_date).unix() - moment(this.edits.follow_up_start_date).unix();
            props.follow_up_end_date = moment(props.follow_up_start_date).add(seconds, 'seconds');
        }
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.assign_to = this.edits.assign_to || this.assign_to;
        this.complete = this.edits.complete || this.complete;
        this.direction = this.edits.direction || this.direction;
        this.end_date = this.edits.end_date || this.end_date;
        this.follow_up_end_date = this.edits.follow_up_end_date || this.follow_up_end_date;
        this.follow_up_start_date = this.edits.follow_up_start_date || this.follow_up_start_date;
        this.method = this.edits.method || this.method;
        this.notes = this.edits.notes || this.notes;
        this.start_date = this.edits.start_date || this.start_date;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/dealerships/', {
                    type: 'new_call_log',
                    ...this.toJSON(this.edits),
                    lead_id: this.lead.id,
                    author: utils.user.get().user_id,
                    ...props
                })

                // update call log target
                this.id = response.id;
                this.close();
                utils.content.fetch('call_log');

                resolve(response);

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/dealerships/', {
                    type: 'update_call_log',
                    ...this.toJSON(this.edits)
                })

                this.close();
                utils.content.update({
                    object: this,
                    type: 'call_log'
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            end_date: target.end_date ? moment(target.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            start_date: target.start_date ? moment(target.start_date).format('YYYY-MM-DD HH:mm:ss') : null,
            author: this.author ? this.author.user_id : null,
            method: target.method,
            follow_up_start_date: target.follow_up_start_date ? moment(target.follow_up_start_date).format('YYYY-MM-DD HH:mm:ss') : null,
            follow_up_end_date: target.follow_up_end_date ? moment(target.follow_up_end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            complete: target.complete,
            direction: target.direction,
            notes: target.notes,
            assign_to: target.assign_to ? target.assign_to.user_id : null
        }
    }
}

const fetchCall = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { call_log } = await Request.get(utils, '/dealerships/', {
                type: 'call_log_details',
                id: id
            });
            let obj = new CallLogClass().create(call_log);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    create: props => new CallLogClass().create(props),
    get: (utils, id) => fetchCall(utils, id),
    new: () => new CallLogClass()
}
