import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Appearance from 'styles/Appearance.js';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = ({ barColor, trackColor }) => {
    
    const Component = withStyles({
        barColorPrimary: {
            backgroundColor: barColor || Appearance.colors.soft()
        },
        colorPrimary: {
            backgroundColor: trackColor || Appearance.colors.grey(),
        }
    })(LinearProgress);

    return <Component />
};

export default ProgressBar;
