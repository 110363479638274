import API from 'files/api.js';

const buildRequest = (url, props) => {
    let newURL = `${url}?type=${props.type}`;
    for(var i in props) {
        if(i == 'type') {
            continue;
        }
        newURL += `&${i}=${typeof(props[i]) === 'object' ? JSON.stringify(props[i]) : encodeURIComponent(props[i])}`;
    }
    return newURL;
}

const Request = {
    get: async (utils, url, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // declare dealership and active slot
                let dealership = utils.dealership.get();
                let slot = utils.dealership.slot.get();

                // attach dealership and slot variables, user props can overwrite both of these variables
                props = {
                    dealership_id: dealership && dealership.id,
                    ...slot && {
                        slot_end_hour: slot.end_hour,
                        slot_start_hour: slot.start_hour
                    },
                    ...props
                }

                // build and send request
                let response = await fetch(buildRequest(`/api/${API.beta_env ? 'beta' : `v${API.version}`}${url}`, props), {
                    headers: utils.api.headers(),
                    method: 'GET',
                    withCredentials: true
                });

                // determine if response code represents a 5xx level error
                if([502, 503, 504].includes(response.status)) {
                    throw new Error('Please try again in a few minutes');
                }

                // prevent moving forward if any other status than success is returned
                if(response.status !== 200) {
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                }

                // parse json response body and resolve
                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    },
    post: async (utils, url, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // declare dealership and active slot
                let dealership = utils.dealership.get();
                let slot = utils.dealership.slot.get();

                // attach dealership and slot variables, user props can overwrite both of these variables
                props = {
                    dealership_id: dealership && dealership.id,
                    ...slot && {
                        slot_end_hour: slot.end_hour,
                        slot_start_hour: slot.start_hour
                    },
                    ...props
                }

                // build and send request
                let response = await fetch(`/api/${API.beta_env ? 'beta' : `v${API.version}`}${url}`, {
                    method: 'POST',
                    withCredentials: true,
                    headers: utils.api.headers(),
                    body: JSON.stringify(props)
                });

                // determine if response code represents a 5xx level error
                if([502, 503, 504].includes(response.status)) {
                    throw new Error('Please try again in a few minutes');
                }

                // check for conflicts and allow user to respond
                if(response.status === 409) {
                    let json = await response.json();
                    let error = new Error();
                    error.code = response.status;
                    error.category = json.category;
                    error.message = json.message;
                    error.response = json;
                    throw error;
                }

                // prevent moving forward if any other status than success is returned
                if(response.status !== 200) {
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                }

                // parse json response body and resolve
                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    }
}
export default Request;
