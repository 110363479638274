import React, { useState, useEffect } from 'react';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { FloatingMenuIndex } from 'structure/Layer.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import { UserDetails } from 'managers/Users.js';
import Views from 'views/Main.js';

const UserLookupField = ({ avatarClick, containerStyle, hideAvatar, icon, inline, levels, onChange, placeholder, preserveResult = true, restrictToDealership, user, utils }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [text, setText] = useState('');

    const onUserClick = () => {
        if(avatarClick === false) {
            return;
        }
        utils.layer.open({
            abstract: Abstract.create({
                object: selected,
                type: 'user'
            }),
            Component: UserDetails,
            id: `user_details_${selected.user_id}`,
            permissions: ['users.details']
        })
    }

    const onClick = async user => {

        // empty results array
        setResults([]);

        // update state for selected value and textfield label
        if(preserveResult === true) {
            setSelected(user);
            setText(user.full_name);
        }

        // reset textfield label is preserve results is false
        if(preserveResult === false) {
            setText('');
        }

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(user);
        }
    }

    const onTextChange = async text => {
        try {

            // update textfield label and reset selected value state
            setText(text);
            setSelected(false);
            setResults([]);

            // prevent moving forward if less than 3 characters were provided
            if(text.length < 3) {

                // notify subscribers that data has changed
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
                return;
            }

            // set loading flag and send request to server
            setLoading(true);
            let { users } = await Request.get(utils, '/users/', {
                levels: levels,
                limit: 10,
                restrict_to_dealership: restrictToDealership,
                search_text: text,
                type: 'all_admin'
            });

            // end loading and update results state
            setLoading(false);
            setResults(users);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getStyles = () => {
        if(inline === false) {
            return {
                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                border: `1px solid rgba(175,175,175,0.25)`,
                borderRadius: 8,
                boxShadow: window.theme === 'dark' ? '4px 4px 7px rgba(174,174,174,0.25)' : '4px 4px 7px rgba(0,0,0,0.15)',
                left: 0,
                maxHeight: 250,
                overflowY: 'scroll',
                padding: 0,
                position: 'absolute',
                right: 0,
                textAlign: 'left',
                top: 43,
                zIndex: FloatingMenuIndex
            }
        }
        return {
            backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
            border: `1px solid ${Appearance.colors.divider()}`,
            borderRadius: 12,
            overflow: 'hidden',
            padding: 0,
            marginBottom: 12,
            marginTop: 8,
            textAlign: 'left'
        }
    }

    useEffect(() => {
        setSelected(user);
        setText(user ? user.full_name : '');
    }, [user]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
            }}>
                {selected && hideAvatar !== true && (
                    <img
                    src={selected.avatar}
                    {...avatarClick !== false && {
                        className: 'text-button',
                        onClick: onUserClick
                    }}
                    style={{
                        borderRadius: 17.5,
                        height: 35,
                        marginRight: 8,
                        minHeight: 35,
                        minWidth: 35,
                        objectFit: 'cover',
                        overflow: 'hidden',
                        width: 35,
                    }}/>
                )}
                <TextField
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                icon={icon || 'search'}
                loading={loading}
                onChange={onTextChange}
                placeholder={placeholder || 'Search by first or last name...'}
                useDelay={true}
                value={text}
                style={{
                    flexGrow: 1,
                    ...Appearance.textStyles.title()
                }}/>
            </div>
            {results.length > 0 && (
                <div style={getStyles()}>
                    {results.map((user, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== results.length - 1,
                                icon: {
                                    path: user.avatar
                                },
                                key: index,
                                onClick: onClick.bind(this, user),
                                style: {
                                    padding: '6px 12px 6px 12px'
                                },
                                subTitle: user.phone_number || 'Phone number not available',
                                title: user.full_name
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default UserLookupField;
