import moment from 'moment-timezone';
import Request from 'files/Request.js';

class StatusClass {

    active = null;
    capabilities = null;
    code = null;
    color = null;
    date = null;
    dealership_id = null;
    id = null;
    text = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    create = (props = {}) => {
        this.active = props.active;
        this.capabilities = props.capabilities || {};
        this.code = props.code;
        this.color = props.color;
        this.date = props.date && moment(props.date) ;
        this.dealership_id = props.dealership_id;
        this.id = props.id;
        this.text = props.text;
        return this;
    }

    open = () => {
        this.edits = {
            capabilities: this.capabilities || { 
                demos: true, 
                demo_requests: true, 
                events: true, 
                leads: true 
            },
            color: this.color,
            text: this.text
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.capabilities = this.edits.capabilities;
        this.color = this.edits.color;
        this.text = this.edits.text;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to sever
                let { code, id } = await Request.post(utils, '/dealerships/', {
                    type: 'new_status',
                    ...this.toJSON(this.edits),
                    ...props
                })

                // set record id and code
                this.code = code;
                this.id = id;

                // close editing
                this.close();

                // notify subscribers of new data
                utils.content.fetch('status');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to sever
                await Request.post(utils, '/dealerships/', {
                    id: this.id,
                    type: 'update_status',
                    ...this.toJSON(this.edits)
                });

                // close editing
                this.close();

                // notify subscribers of data change
                utils.content.update({
                    object: this,
                    type: 'status'
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = props => {
        let target = props || this;
        return {
            capabilities: target.capabilities,
            color: target.color,
            id: this.id,
            text: target.text
        }
    }
}

export default {
    create: props => new StatusClass().create(props),
    new: () => new StatusClass()
}